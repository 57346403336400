import React, { useContext, useEffect, useState } from "react";
import { Option, Select } from "baseui/select";
import { Grid, Cell } from "baseui/layout-grid";
import useMBContext from "context/useMBContext";
import { ORG_USER_ROLE, userOrgsInterface, VANPOOLER_ROLE } from "interfaces/user";
import { riderInstabookManageDetails } from "api/vanpools";
import { riderMyPoolDisplayInterface } from "interfaces/vanpool";
import { Route, Switch, useHistory, useParams, useRouteMatch } from "react-router-dom";
import { routes } from "misc/http";
import { MyPoolsDetailsRouter } from "components/MyPools/MyPoolsPage/MyPoolsPage";
import { makeTabScrollableContent, TabsScrollableContent } from "components/common/Buttons/Buttons";
import { computeMyPoolDisplay } from "components/MyPools/utils";
import { GlobalContext } from "context/global";
import { VanpoolAdminPage } from "./VanpoolManage";
import { pathPrefix } from "./AdminCommon";
import { params } from "interfaces/params";

interface AdminMyPoolsDetailsRouterProps {}

const AdminMyPoolsDetailsRouter: React.FC<AdminMyPoolsDetailsRouterProps> = ({}) => {
  const [vanpools, setVanpools] = useState<riderMyPoolDisplayInterface[]>([]);
  const { vanpool } = useParams<params>();

  useEffect(() => {
    riderInstabookManageDetails({
      ids: [vanpool],
    }).then((pools) => {
      const myPools = pools
        .map((p) => ({
          ...p,
          profile: {
            userRoles: [VANPOOLER_ROLE.COORDINATOR],
            orgRoles: [ORG_USER_ROLE.VANPOOL_MANAGER],
          },
        }))
        .map((x) => computeMyPoolDisplay(x));
      setVanpools(myPools);
    });
  }, [vanpool]);

  return <MyPoolsDetailsRouter pathPrefix={pathPrefix} pools={vanpools} showAsMember={false} />;
};

interface AdminPageProps {
  user: userOrgsInterface;
}

const AdminPage: React.FC<AdminPageProps> = ({ user }) => {
  const { selectedAccount, setAccount, selectedOrganization, setOrganization } = useMBContext();
  const history = useHistory();
  const accountOptions = user.accounts
    .map((acc): Option => ({ label: acc.name, id: acc.id }))
    .concat([{ label: "Any", id: "null" }]);
  const selectAccount = (value: Option[]) => {
    const id = value[0].id;
    setAccount(id === "null" ? null : (id as any));
  };

  const orgOptions = user.organizations
    .map((org): Option => ({ label: org.name, id: org.id }))
    .concat([{ label: "Any", id: "null" }]);
  const selectOrganization = (value: Option[]) => {
    const id = value[0].id;
    setOrganization(id === "null" ? null : (id as any));
  };

  interface Params {
    page: string;
  }
  const match = useRouteMatch<Params>(pathPrefix + "/:page");
  const page = match?.params?.page || "vanpools";
  const [activeKey, setActiveKey] = useState<string>(page);

  const switchToTab = (tab: string) => {
    setActiveKey(tab);
    history.push(`${routes.user.admin.main}/${tab}`);
  };

  return (
    <>
      <Switch>
        <Route path={pathPrefix + `${routes.user.mypools}/:vanpool`}>
          <AdminMyPoolsDetailsRouter />
        </Route>
        <Route>
          <div>
            <Grid>
              <Cell span={[2, 4, 6]}>
                Account:
                <Select
                  size="compact"
                  clearable={false}
                  deleteRemoves={false}
                  onSelectResetsInput={false}
                  options={accountOptions}
                  value={[
                    accountOptions.find((x) => x.id === selectedAccount?.id) ||
                      accountOptions.find((x) => x.id === "null")!,
                  ]}
                  onChange={(params) => selectAccount(params.value as Option[])}
                  required
                ></Select>
              </Cell>
              <Cell span={[2, 4, 6]}>
                Organization:
                <Select
                  size="compact"
                  clearable={false}
                  deleteRemoves={false}
                  onSelectResetsInput={false}
                  options={orgOptions.filter(
                    (x) =>
                      !selectedAccount ||
                      user.organizations.find((o) => o.id === x.id)?.accountId ===
                        selectedAccount?.id ||
                      x.id === "null",
                  )}
                  value={[
                    orgOptions.find((x) => x.id == selectedOrganization?.id) ||
                      orgOptions[orgOptions.length - 1],
                  ]}
                  onChange={(params) => selectOrganization(params.value as Option[])}
                  required
                ></Select>
              </Cell>
            </Grid>
          </div>
          <div style={{ marginTop: "10px" }}>
            <Grid
              overrides={{
                Grid: { style: { height: "100%" } },
                GridWrapper: { style: { height: "100%" } },
              }}
            >
              <Cell span={12}>
                <TabsScrollableContent
                  activeKey={activeKey}
                  onChange={({ activeKey }) => switchToTab(activeKey.toString())}
                >
                  {makeTabScrollableContent("Vanpools", <VanpoolAdminPage />)}
                </TabsScrollableContent>
              </Cell>
            </Grid>
          </div>
        </Route>
      </Switch>
    </>
  );
};

export default AdminPage;
