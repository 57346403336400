import { SingleStopSelector } from "./components/SingleStopSelector";
import { MAP_BOUNDS } from "components/Map";
import useMBContext from "context/useMBContext";
import { routes } from "misc/http";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { STOP_TYPE } from "interfaces/stop";
import { params } from "interfaces/params";
import { routeDisplayInterface, stopDisplayInterface } from "interfaces/route";
import { tripPropertiesInterface } from "interfaces/trip";
import { getRoute2 } from "api/mapbox";

export const SelectDropoffStopPage: React.FC = ({}) => {
  const {
    setMapDisplayOptions,
    allRoutes,
    selectedRouteID,
    createWalklines,
    setSuggestedLine,
    updateRouteWithStops,
  } = useMBContext();
  const { vanpool } = useParams<params>();
  const { userOrigin, userDestination } = useMBContext();

  const [activeRoute, setActiveRoute] = useState<routeDisplayInterface | undefined>();
  const [stopList, setStopList] = useState<stopDisplayInterface[]>([]);

  const history = useHistory();

  useEffect(() => {
    const route = allRoutes.find((route) => {
      return route.id === selectedRouteID;
    });

    if (!route || !vanpool) {
      // if no vanpool is selected then go back to search results
      history.push(routes.onboarding.searchResults);
      return;
    }

    setStopList(route.service.outbound.stops);
    setActiveRoute(route);
    createWalklines(
      route.stops[route.outboundPickup].place.coordinates,
      route.stops[route.outboundDropoff].place.coordinates,
    ).then((lines) => {
      setSuggestedLine(lines);
    });
    setMapDisplayOptions({
      bounds: MAP_BOUNDS.ROUTES | MAP_BOUNDS.USER_ORIG_DEST,
    });
  }, [vanpool]);

  useEffect(() => {
    if (!userOrigin || !userDestination || !activeRoute) return;

    activeRoute.stops.forEach((stop) => {
      if (!(stop.stopType === STOP_TYPE.DROPOFF || stop.stopType === null)) return;

      getRoute2(stop.place.coordinates, userDestination.coordinates, "walking").then((thisLine) => {
        const walkFromDropoffMinutes = thisLine.data.routes[0].duration / 60;
        stop.walkMinutes = Math.round(walkFromDropoffMinutes);
        setStopList([...activeRoute.stops]);
      });
    });
  }, [activeRoute]);

  const handleStopSelection = (props: tripPropertiesInterface) => {
    if (!activeRoute) return;

    const updatedRoute = updateRouteWithStops(activeRoute, props);
    setActiveRoute(updatedRoute);
  };

  const goToSummary = () => {
    if (!activeRoute) return;

    history.push(
      routes.onboarding.poolDetails.summary +
        `/${activeRoute.id}` +
        `?color=${activeRoute.colorHexCode?.replace("#", "")}`,
    );
  };

  return (
    <>
      {activeRoute && (
        <>
          <div className="Navbar">
            <div className="Navbar-header">
              <div
                className="Navbar-back"
                onClick={() => {
                  history.push(
                    routes.onboarding.poolDetails.selectStops.pickup + `/${activeRoute.id}`,
                  );
                }}
              />
              <div className="Navbar-header-title">Choose a dropoff location</div>
              <div className="Navbar-filler" />
            </div>
          </div>
          <div className="Interface">
            <SingleStopSelector
              type={STOP_TYPE.DROPOFF}
              stops={stopList}
              props={{
                pickup: activeRoute.outboundPickup,
                dropoff: activeRoute.outboundDropoff,
              }}
              onSelect={handleStopSelection}
              restrictStops={true}
              confirmCallback={goToSummary}
            />
          </div>
        </>
      )}
    </>
  );
};
