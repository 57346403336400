import { riderInstabook } from "api/vanpools";
import { MBButton } from "components/common/Buttons/Buttons";
import {
  ManageModal,
  ManageModalSettings,
  MANAGE_TYPE,
} from "components/MyPools/MyPoolManagement/ManageModal/ManageModal";
import useMBContext from "context/useMBContext";
import {
  INSTABOOK_ACTION,
  INSTABOOK_ROLE,
  riderMyPoolInterface,
  riderVanpoolDayDetailsInterface,
} from "interfaces/vanpool";
import React, { useState } from "react";

interface EditRidersProps {
  pool: riderMyPoolInterface;
  day: riderVanpoolDayDetailsInterface;
  onEdit?: () => void;
}

export const EditRiders: React.FC<EditRidersProps> = ({ pool, day, onEdit }) => {
  const { user } = useMBContext();

  const [modalSettings, setModalSettings] = useState<ManageModalSettings>({
    show: false,
    type: MANAGE_TYPE.RIDER,
  });

  const editRiders = (userIds: string[]) => {
    const previouslyBookedRiderIds = day.outbound.riders.map((rider) => {
      return rider.rider.id;
    });

    // book users that weren't already booked
    const bookUserIds = userIds.filter((userId) => {
      return !previouslyBookedRiderIds.includes(userId);
    });
    // cancel users that were deselected
    const cancelUserIds = previouslyBookedRiderIds.filter((userId) => {
      return !userIds.includes(userId);
    });

    Promise.all([
      ...bookUserIds.map((userId) => {
        const bookArgs = {
          action: INSTABOOK_ACTION.BOOK,
          role: INSTABOOK_ROLE.RIDER,
          dates: [day.date],
          vanpoolId: pool.id,
          userId,
        };
        if (userId === user?.id) {
          return riderInstabook({
            ...bookArgs,
            properties: pool.profile.properties,
          });
        }

        return riderInstabook(bookArgs);
      }),
      ...cancelUserIds.map((userId) => {
        const cancelArgs = {
          action: INSTABOOK_ACTION.CANCEL,
          role: INSTABOOK_ROLE.RIDER,
          dates: [day.date],
          vanpoolId: pool.id,
          userId,
        };
        if (userId === user?.id) {
          return riderInstabook({
            ...cancelArgs,
            properties: pool.profile.properties,
          });
        }

        return riderInstabook(cancelArgs);
      }),
    ]).finally(() => {
      // we're not passing back the updated vanpoolDays that instabook returns
      // because Promise.all returns in the order that promises are passed in
      // and not in the order they're resolved, so there's no way to guarantee
      // that we pass back the most recently resolved instabook. instead we're
      // just manually querying and refreshing the page data onEdit
      onEdit && onEdit();
    });
  };

  return (
    <>
      <ManageModal
        pool={pool}
        rideDay={day}
        settings={modalSettings}
        assignCallback={editRiders}
        hideModalCallback={() => {
          setModalSettings({ ...modalSettings, show: false });
        }}
      />
      <div className="ButtonContainer">
        <MBButton
          kind="secondary"
          onClick={() => {
            setModalSettings({ ...modalSettings, show: true });
          }}
        >
          Edit riders
        </MBButton>
      </div>
    </>
  );
};
