import React from "react";
import moment, { Moment } from "moment";
import { vanpoolDayDisplayInterface } from "interfaces/vanpool";
import { myPoolMemberProfileInterface } from "interfaces/user";
import { getMyPoolUserPerms, getWarnings } from "../utils";
import { ReactComponent as DriveIcon } from "assets/img/drive-icon.svg";
import { styled } from "baseui";
import { expand } from "inline-style-expand-shorthand";

interface CalendarCellInterface {
  date: Moment;
  profile: myPoolMemberProfileInterface;
  poolDay?: vanpoolDayDisplayInterface;
  onClick?: () => void;
}

export const CalendarCell: React.FC<CalendarCellInterface> = ({
  date,
  profile,
  poolDay,
  onClick,
}) => {
  const userPerms = getMyPoolUserPerms(profile);
  const status = poolDay?.status;

  const hasContent =
    status &&
    (!status.noRiders || status.isDriving || (userPerms.canProvideVehicle && !status.driverNeeded));

  const tooltip = hasContent && poolDay ? getWarnings(poolDay, profile).join("\n") : undefined;

  const isWarn =
    status &&
    hasContent &&
    (status.isBooked || userPerms.canProvideVehicle) &&
    (!status.rideConfirmed || status.isWaitlisted || status.isMissed);
  const isDriving = status?.isDriving;
  const icon = isWarn ? "⚠️" : isDriving ? <DriveIcon /> : undefined;

  const Cell = styled("div", ({ $theme }) => ({
    ...(moment().isSame(date, "day") &&
      expand({
        backgroundColor: $theme.colors.mono300,
        borderRadius: "6px",
      })),
    width: "45px",
    height: "45px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }));

  const Icon = styled("div", ({ $theme }) => ({
    position: "absolute",
    top: "-7px",
    left: "24px",
    zIndex: "1",
  }));

  const Content = styled("div", ({ $theme }) => ({
    ...expand({
      padding: "5.5px",
    }),
    position: "relative",
    width: "30px",
    height: "30px",
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "500",
    borderRadius: "50%",
    cursor: onClick ? "pointer" : "default",
    color: status ? $theme.colors.mono700 : $theme.colors.mono500,
    opacity: status && status.isPast ? "0.5" : "1.0",
    ...(hasContent &&
      userPerms.canProvideVehicle && {
        ...(isWarn
          ? {
              backgroundColor: $theme.colors.warning400,
              outline: "solid 1.5px #fa7",
              color: $theme.colors.warning,
            }
          : {
              backgroundColor: $theme.colors.primary300,
              outline: "solid 1.5px #76b5ff",
              color: $theme.colors.primary,
            }),
      }),
    ...(status?.isBooked && {
      outline: "none",
      color: $theme.colors.mono100,
      backgroundColor: isWarn ? $theme.colors.warning : $theme.colors.primary,
    }),
  }));

  return (
    <Cell>
      <Content onClick={onClick} title={tooltip}>
        {icon && <Icon>{icon}</Icon>}
        <p>{date.format("D")}</p>
      </Content>
    </Cell>
  );
};
