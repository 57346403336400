import React, { useContext } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { GlobalContext } from "context/global";
import "components/App/App.scss";
import ErrorBoundary from "components/ErrorBoundary";
import HelpModal from "components/common/HelpModal";
import ErrorModal from "components/common/ErrorModal";
import ShareModal from "components/common/ShareModal";
import Mapbox from "components/Map";
import Header from "components/common/Header";
import { routes } from "misc/http";
import NotFoundPage from "components/NotFoundPage";
import ProfilePage from "components/Profile";
import { ToasterContainer } from "baseui/toast";
import FBSSO from "components/common/FBSSO/fbsso";
import { MyPoolsPage } from "components/MyPools/MyPoolsPage/MyPoolsPage";
import AdminPage from "components/Admin/AdminPage";
import { SelectHomeAddressPage } from "components/Onboarding/SelectHomeAddressPage";
import { OnboardingSearchResultsPage } from "components/Onboarding/OnboardingSearchResultsPage";
import { SelectWorkAddressPage } from "components/Onboarding/SelectWorkAddressPage";
import { VanpoolSummaryPage } from "components/Onboarding/VanpoolSummaryPage";
import { SelectPickupStopPage } from "components/Onboarding/SelectPickupStopPage";
import { SelectDropoffStopPage } from "components/Onboarding/SelectDropoffStopPage";
import { IntroPage } from "components/Onboarding/IntroPage";
import { SuggestARouteProfilePage } from "components/Onboarding/SuggestARouteProfilePage";

const App = () => {
  const { user } = useContext(GlobalContext);

  return (
    <ErrorBoundary>
      <Switch>
        <Route path="/portal/meta">
          <FBSSO />
        </Route>
        <Route>
          <HelpModal />
          <ShareModal />
          <ErrorModal />
          <ToasterContainer />
          <main className="App">
            <Header className="App-Header" />
            <React.Suspense fallback={null}>
              <Switch>
                <Route path={routes.onboarding.intro}>
                  <div className="App-SimpleLayout" style={{ overflowY: "auto" }}>
                    <IntroPage />
                  </div>
                </Route>
                <Route path={routes.onboarding.selectAddress.home}>
                  <SelectHomeAddressPage />
                </Route>
                <Route path={routes.onboarding.selectAddress.work}>
                  <SelectWorkAddressPage />
                </Route>
                <Route path={routes.onboarding.prefix}>
                  {/* routes with a map */}
                  <div className="App-SidebarLayout">
                    <Route path={routes.onboarding.suggest}>
                      <SuggestARouteProfilePage />
                    </Route>
                    <Route path={routes.onboarding.searchResults}>
                      <OnboardingSearchResultsPage />
                    </Route>
                    <Route path={`${routes.onboarding.poolDetails.selectStops.pickup}/:vanpool`}>
                      <SelectPickupStopPage />
                    </Route>
                    <Route path={`${routes.onboarding.poolDetails.selectStops.dropoff}/:vanpool`}>
                      <SelectDropoffStopPage />
                    </Route>
                    <Route path={`${routes.onboarding.poolDetails.summary}/:vanpool`}>
                      <VanpoolSummaryPage />
                    </Route>
                    <div className="Map">
                      <Mapbox />
                    </div>
                  </div>
                </Route>
                <Route path={routes.user.mypools}>
                  <div className="App-SimpleLayout">
                    <MyPoolsPage />
                  </div>
                </Route>
                <Route path={routes.user.admin.main}>
                  <div className="App-SimpleLayout">{user && <AdminPage user={user} />}</div>
                </Route>
                <Route path="/" exact>
                  <Redirect to={routes.user.mypools} />
                </Route>
                <Route path="/profile" exact>
                  <div className="App-SimpleLayout" style={{ overflowY: "auto" }}>
                    <ProfilePage />
                  </div>
                </Route>
                <Route path="*" exact={true}>
                  <NotFoundPage />
                </Route>
              </Switch>
            </React.Suspense>
          </main>
        </Route>
      </Switch>
    </ErrorBoundary>
  );
};
export default App;
