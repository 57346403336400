import React, { useContext } from "react";
import "./ErrorModal.scss";
import { GlobalContext } from "context/global";

interface ErrorModalInterface {
  loggedUser?: () => void;
}

const ErrorModal: React.FC<ErrorModalInterface> = () => {
  const { errorModalData, setErrorModalData } = useContext(GlobalContext);

  const closeModal = () => {
    if (setErrorModalData) {
      setErrorModalData((prevVal) => ({
        ...prevVal,
        show: false,
        title: "",
        content: "",
      }));
    }
  };

  return (
    <div>
      {errorModalData?.show && (
        <div className="ErrorModal">
          <div className="ErrorModal-Box">
            <div className="ErrorModal-Title">{errorModalData!.title}</div>
            <div className="ErrorModal-Message">{errorModalData!.content}</div>
            <button className="ErrorModal-Submit" onClick={closeModal}>
              OK
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ErrorModal;
