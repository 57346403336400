import { getRoute2, getSuggestions } from "api/mapbox";
import { routeMakerConfig } from "api/pledge";
import { fetchUserProfile, updateUserProfile } from "api/user";
import { styled } from "baseui";
import { Button } from "baseui/button";
import { Checkbox, LABEL_PLACEMENT, STYLE_TYPE } from "baseui/checkbox";
import { FormControl } from "baseui/form-control";
import { Option, Value } from "baseui/select";
import { toaster } from "baseui/toast";
import DataList from "components/common/Form/DataList";
import DayPicker from "components/common/Form/DayPicker";
import { MBInput } from "components/common/Inputs/MBInput";
import { MBSelect } from "components/common/Inputs/MBSelect";
import { MAP_BOUNDS } from "components/Map";
import { MAP_LINE_TYPE } from "components/Map/mapline";
import useMBContext from "context/useMBContext";
import { formatPhoneNumber } from "helpers/formatHelpers";
import { geoPointDataInterface } from "interfaces/map";
import { addressInterface, placeInterface } from "interfaces/place";
import { vanpoolSearchSettingsInterface } from "interfaces/vanpool";
import { routes } from "misc/http";
import {
  checkValidStopTimes,
  mapboxToAddress,
  mapboxToUserLocation,
  parseFrom24HourTime,
  parseTo24HourTime,
  placeToUserLocation,
  userLocationToAddress,
} from "misc/utils";
import moment from "moment";
import React, { FormEvent, useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";

const SuggestARouteProfileContainer = styled("div", ({ $theme }) => ({
  overflow: "auto",
  display: "flex",
  padding: "1.5em",
  maxWidth: "700px",
  width: "100%",
  height: "calc(100% - 10px)",
  margin: "0 auto 2rem",
  flexDirection: "column",
}));

export const SuggestARouteProfilePage: React.FC = ({}) => {
  const [processingRequest, setProcessingRequest] = useState<boolean>(false);
  const [homePlaces, setHomePlaces] = useState<geoPointDataInterface[]>([]);
  const [homeAddress, setHomeAddress] = useState<addressInterface>();
  const [homeAddressVisited, setHomeAddressVisited] = useState<boolean>(false);

  const [allowedWorkPlaces, setAllowedWorkPlaces] = useState<placeInterface[]>([]);
  const [workPlaces, setWorkPlaces] = useState<geoPointDataInterface[]>([]);
  const [workAddress, setWorkAddress] = useState<addressInterface>();
  const [workAddressVisited, setWorkAddressVisited] = useState<boolean>(false);
  const [arriveWorkTime, setArriveWorkTime] = useState<Value>([{ id: "9:00 AM" }]);
  const [leaveWorkTime, setLeaveWorkTime] = useState<Value>([{ id: "5:00 PM" }]);
  const [rideDays, setRideDays] = useState<Array<string>>([]);
  const [rideDaysVisited, setRideDaysVisited] = useState<boolean>(false);
  const [canDrive, setCanDrive] = useState<boolean>(false);
  const [driveDays, setDriveDays] = useState<Array<string>>([]);
  const [driveDaysVisited, setDriveDaysVisited] = useState<boolean>(false);
  const [vehicleCapacity, setVehicleCapacity] = useState<number>();
  const [vehicleCapacityVisited, setVehicleCapacityVisited] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(true);

  const {
    user,
    userOrigin,
    userDestination,
    setUserOrigin,
    setUserDestination,
    setAllRoutes,
    setDisplayRoutes,
    setSuggestedLine,
    setMapDisplayOptions,
  } = useMBContext();

  const history = useHistory();

  useEffect(() => {
    if (!user) return;

    setAllRoutes([]);
    setDisplayRoutes([]);

    fetchUserProfile().then((u) => {
      // prioritize user's current search address over their saved profile
      userOrigin !== undefined
        ? setHomeAddress(userLocationToAddress(userOrigin))
        : setHomeAddress(u.home);

      if (u.search) {
        setWorkAddress(u.search.work);
        setRideDays(u.search.rideDays);
        setArriveWorkTime([{ id: parseFrom24HourTime(u.search.arriveTime) }]);
        setLeaveWorkTime([{ id: parseFrom24HourTime(u.search.departTime) }]);

        if (!u.search.driveDays) return;

        setCanDrive(u.search.driveDays.length > 0);
        setDriveDays(u.search.driveDays);
        setVehicleCapacity(u.search.vehicleCapacity);
      }

      if (userDestination !== undefined) {
        setWorkAddress(userLocationToAddress(userDestination));
      }

      const userOrganizationId = user.organizations.find((x) => !!x.routeMaker)?.id;

      userOrganizationId
        ? routeMakerConfig(userOrganizationId)
            .then((config) => {
              if (config.allowedPlaces) {
                setAllowedWorkPlaces(config.allowedPlaces);
              }
            })
            .finally(() => {
              setLoading(false);
            })
            .catch((errors) => {})
        : setLoading(false);
    });
  }, [user]);

  const checkValidSearchProfile = () => {
    return (
      homeAddress !== undefined &&
      workAddress !== undefined &&
      (rideDays.length > 0 || driveDays.length > 0) &&
      (!canDrive || (canDrive && driveDays.length > 0 && vehicleCapacity && vehicleCapacity > 0)) &&
      checkValidStopTimes(arriveWorkTime[0].id, leaveWorkTime[0].id)
    );
  };

  const showAllErrors = () => {
    setHomeAddressVisited(true);
    setWorkAddressVisited(true);
    setRideDaysVisited(true);
    setDriveDaysVisited(true);
    setVehicleCapacityVisited(true);
  };

  const handleAddressSearch = (value: string, type: "HOME" | "WORK") => {
    value !== "" &&
      getSuggestions(value, {
        types: "address,poi",
        country: "US",
      }).then((data: any) => {
        if (type === "HOME") {
          setHomePlaces(data.data.features);
        } else {
          setWorkPlaces(data.data.features);
        }
      });
  };

  const timeList: Array<Option> = [];
  const startTime = moment("00:00", "hh:mm");
  for (let i = 0; i <= 1440; i += 15) {
    startTime.add(i === 0 ? 0 : 15, "minutes");
    timeList.push({ id: startTime.format("h:mm A"), label: startTime.format("h:mm A") });
  }

  const validateForm = (e: FormEvent) => {
    if (!user) return;

    e.preventDefault();
    if (checkValidSearchProfile()) {
      setProcessingRequest(true);
      const formattedPhone = formatPhoneNumber(user.phone);
      const formattedSearch: vanpoolSearchSettingsInterface | undefined =
        homeAddress && workAddress && arriveWorkTime[0].id && leaveWorkTime[0].id
          ? {
              work: workAddress,
              arriveTime: parseTo24HourTime(arriveWorkTime[0].id.toString()),
              departTime: parseTo24HourTime(leaveWorkTime[0].id.toString()),
              rideDays: rideDays,
              driveDays: canDrive ? driveDays : [],
              vehicleCapacity: canDrive ? vehicleCapacity : undefined,
            }
          : undefined;

      updateUserProfile({
        name: user.name,
        email: user.email,
        phone: formattedPhone,
        home: homeAddress,
        search: formattedSearch,
      })
        .then(() => {
          toaster.info(
            "Thank you for the suggestion. You will be notified when a matching route becomes available.",
            { autoHideDuration: 5000 },
          );
          history.push(routes.user.profile);
        })
        .finally(() => {
          setProcessingRequest(false);
        });
    } else {
      toaster.negative("There was an error in updating your information.", {
        autoHideDuration: 4500,
      });
      showAllErrors();
    }
  };

  useEffect(() => {
    if (userDestination && userOrigin) {
      getRoute2(userOrigin.coordinates, userDestination.coordinates, "driving").then((thisLine) => {
        setSuggestedLine([
          {
            coordinates: thisLine.data.routes[0].geometry.coordinates,
            display: {
              type: MAP_LINE_TYPE.SELECTED,
            },
          },
        ]);
      });
    } else {
      setSuggestedLine([]);
    }

    setMapDisplayOptions({ bounds: MAP_BOUNDS.USER_ORIG_DEST, showOrigDestCircle: false });
  }, [userOrigin, userDestination]);

  return (
    <>
      <div className="Navbar">
        <div className="Navbar-header">
          <div
            className="Navbar-back"
            onClick={() => {
              history.goBack();
            }}
          />
          <div className="Navbar-header-title">Suggest Vanpool</div>
          <div className="Navbar-filler" />
        </div>
      </div>
      <SuggestARouteProfileContainer>
        {!loading && (
          <>
            <p style={{ textAlign: "center", marginBottom: "10px" }}>
              Tell us about your commute, and you’ll be notified when a matching vanpool becomes
              available.
            </p>
            <form
              onSubmit={validateForm}
              autoComplete="off"
              style={{
                justifyContent: "space-between",
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div>
                <FormControl
                  label="Home address"
                  error={
                    !homeAddress && homeAddressVisited
                      ? "Home address is required when looking for a vanpool"
                      : null
                  }
                >
                  <>
                    <DataList
                      keyProp="id"
                      nameProp="text"
                      descrProp="place_name"
                      icon="🏠"
                      options={homePlaces}
                      onChange={(val) => {
                        if (val === "") {
                          setUserOrigin(undefined);
                          setHomeAddress(undefined);
                        } else {
                          handleAddressSearch(val, "HOME");
                        }
                      }}
                      onSelect={(place) => {
                        setUserOrigin(mapboxToUserLocation(place));
                        setHomeAddress(mapboxToAddress(place));
                      }}
                      onBlur={() => {
                        setHomeAddressVisited(true);
                      }}
                      val={homeAddress?.name}
                      placeholder="Home address"
                      clearable
                    />
                  </>
                </FormControl>
                <FormControl
                  label="Work address"
                  error={workAddressVisited && !workAddress ? "Please select a destination" : null}
                >
                  <DataList
                    keyProp="id"
                    nameProp={allowedWorkPlaces.length > 0 ? "placeName" : "text"}
                    descrProp={allowedWorkPlaces.length > 0 ? "streetAddress" : "place_name"}
                    icon="🏢"
                    options={allowedWorkPlaces.length > 0 ? allowedWorkPlaces : workPlaces}
                    onChange={(val) => {
                      if (val === "") {
                        setWorkAddress(undefined);
                        setUserDestination(undefined);
                      } else {
                        handleAddressSearch(val, "WORK");
                      }
                    }}
                    onSelect={(place) => {
                      if (allowedWorkPlaces.length > 0) {
                        setWorkAddress(userLocationToAddress(placeToUserLocation(place)));
                        setUserDestination(placeToUserLocation(place));
                      } else {
                        setWorkAddress(mapboxToAddress(place));
                        setUserDestination(mapboxToUserLocation(place));
                      }
                    }}
                    onBlur={() => {
                      setWorkAddressVisited(true);
                    }}
                    val={workAddress?.name}
                    placeholder="Work address"
                    clearable={allowedWorkPlaces.length === 0}
                    searchable={allowedWorkPlaces.length === 0}
                  />
                </FormControl>
                <FormControl
                  label="Arrive at"
                  error={
                    !checkValidStopTimes(arriveWorkTime[0].id, leaveWorkTime[0].id)
                      ? "Arrive time must be earlier than depart time"
                      : ""
                  }
                >
                  <MBSelect
                    options={timeList}
                    value={arriveWorkTime}
                    clearable={false}
                    onChange={({ value }) => {
                      setArriveWorkTime(value);
                    }}
                  />
                </FormControl>
                <FormControl
                  label="Depart at"
                  error={
                    !checkValidStopTimes(arriveWorkTime[0].id, leaveWorkTime[0].id)
                      ? "Depart time must be later than depart time"
                      : ""
                  }
                >
                  <MBSelect
                    options={timeList}
                    value={leaveWorkTime}
                    clearable={false}
                    onChange={({ value }) => {
                      setLeaveWorkTime(value);
                    }}
                  />
                </FormControl>
                <FormControl
                  label="I want to ride on these days"
                  error={
                    rideDaysVisited && rideDays.length === 0 && driveDays.length === 0
                      ? "Please select days to ride"
                      : null
                  }
                >
                  <DayPicker
                    className="RideDayPicker"
                    selectedDays={rideDays}
                    onChange={(days) => {
                      setRideDaysVisited(true);
                      setRideDays(days);
                    }}
                  />
                </FormControl>
                <Checkbox
                  checked={canDrive}
                  checkmarkType={STYLE_TYPE.toggle_round}
                  onChange={(e) => {
                    setCanDrive(e.currentTarget.checked);
                  }}
                  labelPlacement={LABEL_PLACEMENT.left}
                  overrides={{
                    Root: {
                      style: {
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "15px",
                      },
                    },
                    Label: { style: { fontWeight: "normal" } },
                  }}
                >
                  I'm interested in driving
                </Checkbox>
                {canDrive && (
                  <>
                    <FormControl
                      label="I can drive on these days"
                      error={
                        canDrive && driveDaysVisited && driveDays.length === 0
                          ? "Please select days to drive"
                          : null
                      }
                    >
                      <DayPicker
                        className="DriveDayPicker"
                        selectedDays={driveDays}
                        onChange={(days) => {
                          setDriveDaysVisited(true);
                          setDriveDays(days);
                        }}
                      />
                    </FormControl>
                    <FormControl
                      label="I can drive this many passengers"
                      error={
                        canDrive &&
                        vehicleCapacityVisited &&
                        (!vehicleCapacity || (vehicleCapacity && vehicleCapacity < 1))
                          ? "Please enter the number of passengers you can drive"
                          : null
                      }
                    >
                      <MBInput
                        type="number"
                        min={1}
                        value={vehicleCapacity}
                        onBlur={() => setVehicleCapacityVisited(true)}
                        onChange={(e) => {
                          setVehicleCapacity(parseInt(e.currentTarget.value));
                        }}
                      />
                    </FormControl>
                  </>
                )}
              </div>
              <Button
                overrides={{
                  BaseButton: {
                    style: () => ({
                      width: "100%",
                    }),
                  },
                }}
                isLoading={processingRequest}
              >
                Submit
              </Button>
            </form>
          </>
        )}
      </SuggestARouteProfileContainer>
    </>
  );
};
