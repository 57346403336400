import { styled } from "baseui";
import { Moment } from "moment";
import React from "react";

export interface SimpleDateInterface {
  date: Moment;
}

export const SimpleDate: React.FC<SimpleDateInterface> = ({ date }) => {
  const Container = styled("div", ({ $theme }) => ({
    display: "grid",
    gridTemplateRows: "40% 60%",
    justifyItems: "center",
    rowGap: "2px",
    width: "30px",
    color: $theme.colors.mono800,
  }));
  const Weekday = styled("div", ({ $theme }) => ({
    fontSize: "12px",
  }));
  const Date = styled("div", ({ $theme }) => ({
    fontSize: "20px",
  }));
  return (
    <Container>
      <Weekday>{date.format("ddd").toLocaleUpperCase()}</Weekday>
      <Date>{date.format("D")}</Date>
    </Container>
  );
};
