import React from "react";
import "./Avatar.scss";
import useMBContext from "context/useMBContext";

interface AvatarInterface {
  className?: string;
}

const Avatar: React.FC<AvatarInterface> = ({ className }) => {
  const { user } = useMBContext();

  const lastName = user?.name ? user.name.split(" ") : "";

  return (
    <div>
      {user && (
        <span className="Avatar-user-icon">
          {user.name && user.name[0]}
          {lastName[1] && lastName[1][0]}
        </span>
      )}
    </div>
  );
};

export default Avatar;
