import React from "react";
import "./UserDisplay.scss";
import { userSimpleInterface } from "interfaces/user";
import { ReactComponent as ProfileIcon } from "assets/img/default-profile-picture.svg";

interface UserDisplayInterface {
  user: userSimpleInterface;
}

const UserDisplay: React.FC<UserDisplayInterface> = ({ user }) => {
  return (
    <div className="UserDisplay">
      <ProfileIcon />
      {user.name}
    </div>
  );
};

export default UserDisplay;
