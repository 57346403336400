import React, { useContext, useEffect, useState, Suspense } from "react";
import { GlobalContext } from "context/global";
import "./Map.scss";
import ReactMapboxGl, { Marker } from "react-mapbox-gl";
import { LngLatBounds } from "mapbox-gl";
import { MapCirclePin, MapMarkerPin, MapPinIcon, MAP_PIN_TYPE } from "components/Map/MapMarkerPin";
import { MapLine } from "components/Map/mapline";
import "mapbox-gl/dist/mapbox-gl.css";
import { theme } from "assets/theme";
import { coordinate, routeDisplayInterface } from "interfaces/route";
import { FitBounds } from "react-mapbox-gl/lib/map";
import { StopMarker } from "./components/StopMarker";

const Map = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MB_TOKEN ? process.env.REACT_APP_MB_TOKEN : "",
  attributionControl: false,
  logoPosition: "bottom-right",
  minZoom: 1,
});

interface MapRouteProps {
  route: routeDisplayInterface;
  selected?: boolean;
  highlighted?: boolean;
  onSelect?: () => void;
}

const MapRoute: React.FC<MapRouteProps> = ({ route, selected, highlighted, onSelect }) => {
  return (
    <>
      {route.legs.map((leg, index) => (
        <MapLine key={index} line={leg} selected={selected} highlighted={highlighted} />
      ))}
      {route.stops.map((stop, index) => (
        <>
          <MapMarkerPin
            key={index}
            stop={stop}
            routeID={route.id}
            selected={selected}
            highlighted={highlighted}
            onSelect={onSelect}
            // hide pin bubble for new user flow
            showPinBubble={!route.colorHexCode}
          />
          <StopMarker
            coord={stop.place.coordinates}
            inRoute={index >= route.outboundPickup && index <= route.outboundDropoff}
            selected={selected}
            colorHex={route.colorHexCode}
          />
        </>
      ))}
    </>
  );
};

export enum MAP_BOUNDS {
  USER_LOCATION = 1 << 0,
  USER_ORIG_DEST = 1 << 1, // origin and destination, if set
  ROUTE_START = 1 << 2, // pickup of route only
  ROUTE_END = 1 << 3, // dropoff of route only
  ROUTES = ROUTE_START | ROUTE_END, // pickup/dropoff of route
  LINES = 1 << 4,
}

export interface mapDisplayOptions {
  bounds: MAP_BOUNDS;
  showOrigDestCircle?: boolean; // circles on origin/destination, default false
}

export const defaultMapDisplayOptions: mapDisplayOptions = {
  bounds: MAP_BOUNDS.USER_LOCATION,
  showOrigDestCircle: false,
};

const Mapbox: React.FC = () => {
  const [bounds, setBounds] = useState<LngLatBounds>(
    new LngLatBounds(
      {
        lng: theme.defaultLocation.lng + 0.03,
        lat: theme.defaultLocation.lat + 0.03,
      },
      {
        lng: theme.defaultLocation.lng - 0.03,
        lat: theme.defaultLocation.lat - 0.03,
      },
    ),
  );

  const {
    displayRoutes,
    selectedRouteID,
    userLocation,
    userOrigin,
    userDestination,
    suggestedLine,
    highlightedRouteID,
    setSelectedRouteID,
    mapView,
    mapDisplayOptions,
  } = useContext(GlobalContext);

  useEffect(() => {
    if (!mapView) return;

    let newBounds = new LngLatBounds();
    const extendWithBuffer = (coord: coordinate) => {
      newBounds
        .extend([coord.lng - 0.03, coord.lat - 0.03])
        .extend([coord.lng + 0.03, coord.lat + 0.03]);
    };

    const sources = mapDisplayOptions.bounds;

    if (sources & MAP_BOUNDS.USER_LOCATION) {
      userLocation.locationType !== "default" && extendWithBuffer(userLocation.coordinates);
    }
    if (sources & MAP_BOUNDS.USER_ORIG_DEST) {
      userOrigin && extendWithBuffer(userOrigin.coordinates);
      userDestination && extendWithBuffer(userDestination.coordinates);
    }

    if (sources & MAP_BOUNDS.ROUTES) {
      displayRoutes.forEach((route) => {
        // TODO compute bounds from polyline(s)?
        if (sources & MAP_BOUNDS.ROUTE_START) {
          const pickupCoords = route.stops[route.outboundPickup].place.coordinates;
          newBounds.extend([pickupCoords.lng, pickupCoords.lat]);
        }
        if (sources & MAP_BOUNDS.ROUTE_END) {
          const dropoffCoords = route.stops[route.outboundDropoff].place.coordinates;
          newBounds.extend([dropoffCoords.lng, dropoffCoords.lat]);
        }
      });
    }

    !newBounds.isEmpty() && setBounds(newBounds);
  }, [
    displayRoutes,
    userLocation,
    userOrigin,
    userDestination,
    suggestedLine,
    mapView,
    highlightedRouteID,
    mapDisplayOptions,
  ]);

  const mapClick = (map, e) => {
    /*
    console.log('Map clicked!',e.lngLat.lat,e.lngLat.lng);
    const features = map.queryRenderedFeatures(e.point);
    console.log(features);
    */
  };

  const handleRouteSelect = (route: routeDisplayInterface) => {
    setSelectedRouteID(route.id);
  };

  return (
    <>
      <Map
        style={theme.mapboxStyle}
        fitBounds={bounds.toArray() as FitBounds}
        fitBoundsOptions={{
          padding: {
            top: 100,
            bottom: 50,
            left: 50,
            right: 50,
          },
          duration: 0,
        }}
        containerStyle={{
          height: "100%",
          width: "100%",
        }}
        movingMethod="jumpTo"
        onClick={mapClick}
      >
        <Suspense fallback={null}>
          {userLocation.locationType !== "default" && (
            <Marker
              key="locationPin"
              coordinates={[userLocation.coordinates.lng, userLocation.coordinates.lat]}
              style={{ width: "35px" }}
              anchor="center"
            >
              <MapPinIcon
                type={userLocation.locationType === "gps" ? MAP_PIN_TYPE.GPS : MAP_PIN_TYPE.GEOIP}
              />
            </Marker>
          )}
          {userOrigin && (
            <MapCirclePin
              key="userOrigin"
              type={MAP_PIN_TYPE.HOME}
              coord={userOrigin.coordinates}
              showCircle={mapDisplayOptions.showOrigDestCircle === true}
            />
          )}
          {userDestination && (
            <MapCirclePin
              key="userDestination"
              type={MAP_PIN_TYPE.WORK}
              coord={userDestination.coordinates}
              showCircle={mapDisplayOptions.showOrigDestCircle === true}
            />
          )}
          {suggestedLine.map((line, index) => {
            return <MapLine key={index} line={line} />;
          })}
          {displayRoutes.map((route) => {
            return (
              <MapRoute
                key={`route-${route.id}`}
                route={route}
                selected={selectedRouteID === route.id}
                highlighted={highlightedRouteID === route.id}
                onSelect={() => handleRouteSelect(route)}
              />
            );
          })}
          {selectedRouteID &&
            displayRoutes.map((route) => {
              if (selectedRouteID !== route.id) return;

              // render the selected route again to make sure that it is the topmost layer
              // on the map
              return (
                <MapRoute
                  key={`route-${route.id}`}
                  route={route}
                  selected={selectedRouteID === route.id}
                  highlighted={highlightedRouteID === route.id}
                  onSelect={() => handleRouteSelect(route)}
                />
              );
            })}
        </Suspense>
      </Map>
    </>
  );
};

export default Mapbox;
