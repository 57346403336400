import { pledgePlaceInterface } from "interfaces/pledge";
import {
  userInterface,
  userOrgRoleInterface,
  userOrgsInterface,
  userProfileInterface,
} from "interfaces/user";
import { vanpoolSearchSettingsInterface } from "interfaces/vanpool";
import { gql_post } from "misc/http";
import { GQLBase, gqlOp, GQLSchema } from "misc/utils";

export const fetchCurrentUser = async () => {
  const data = `{
    currentRideUser {
      id name email phone
    }
  }`;
  return gql_post<userInterface | null>("currentRideUser", data);
};

export const fetchCurrentUserOrgs = async () => {
  const data = `{
    currentRideUser {
      id name email phone
      organizations { id name roles accountId routeMaker { logoURL } defaultMapLocation { coordinates { lat lng } } }
      accounts { id name isMagicBus roles }
      home { placeId name coordinates { lat lng } streetAddress city state zip county }
      search { work { placeId name coordinates { lat lng } streetAddress city state zip county } rideDays driveDays arriveTime departTime }
    }
  }`;
  return gql_post<userOrgsInterface | null>("currentRideUser", data);
};

const fetchUsersSchema: GQLSchema = {
  params: {
    accountId: "String",
    orgId: "String!",
    ids: "[String]",
  },
  name: "users",
  op: "query",
};

export const fetchAdminUsers = async (params: {
  accountId?: string;
  orgId?: string;
  ids?: string[];
}) => {
  return gqlOp(fetchUsersSchema, params, [new userProfileInterface()]);
};

export const fetchOrgUsers = (params: { accountId?: string; orgId?: string; ids?: string[] }) => {
  return gqlOp(fetchUsersSchema, params, [new userOrgRoleInterface()]);
};

// TODO migrate to log_in_action_sso
export const ssoUserLogin = (params) => {
  const data = `
    mutation ssoLogIn($facebook: FBSSOParams) {
      ssoLogIn(facebook: $facebook) {
        authorization
      }
    }
  `;

  const variables = params;
  return gql_post<{ authorization: string }>("ssoLogIn", data, variables);
};

const fetchUserProfileSchema: GQLSchema = {
  params: {},
  name: "currentRideUser",
  op: "query",
};

export const fetchUserProfile = () => {
  return gqlOp(fetchUserProfileSchema, {}, new userInterface());
};

const updateUserProfileSchema: GQLSchema = {
  params: {
    name: "String!",
    email: "String!",
    phone: "String!",
    home: "AddressInput",
    search: "VanpoolSearchSettingsInput",
  },
  name: "riderUpdateProfile",
  op: "mutation",
};

export class objectInterface extends GQLBase {
  success = false;
}

export const updateUserProfile = (params: {
  name: string;
  email: string;
  phone: string;
  home?: pledgePlaceInterface;
  search?: vanpoolSearchSettingsInterface;
}) => {
  return gqlOp(updateUserProfileSchema, params, new objectInterface());
};

export const confirmUserPhoneUpdate = (smsCode: string) => {
  const data = `
    mutation riderConfirmProfileUpdate($smsCode: String) {
      riderConfirmProfileUpdate(smsCode: $smsCode) {
          success
      }
  }`;
  const vars = { smsCode };
  return gql_post<{ success: boolean }>("riderConfirmProfileUpdate", data, vars);
};
