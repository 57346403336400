import React from "react";
import { styled } from "baseui";
import { StyleObject } from "styletron-standard";
import { expand } from "inline-style-expand-shorthand";

export interface TableEntry {
  isSticky?: boolean;
  data: any;
}

interface StickyTableProps {
  header: any[];
  footer: any[];
  data: any[][];
}

const TableGrid = styled<"div", { $numColumns: number }>("div", ({ $numColumns }) => ({
  maxHeight: "75vh",
  minWidth: "100vw",
  overflow: "auto",
  display: "grid",
  gridTemplateColumns: "150px ".repeat($numColumns),
  position: "relative",
}));

const FixedLeftStyle: React.CSSProperties = {
  position: "sticky",
  left: 0,
  justifyContent: "left",
  ...expand({
    borderLeft: "1px solid #e4e9ed",
  }),
};

const FixedRightStyle: React.CSSProperties = {
  position: "sticky",
  right: 0,
  ...expand({
    borderLeft: "1px solid #e4e9ed",
    borderRight: "1px solid #e4e9ed",
  }),
};

const LastStyle: React.CSSProperties = {
  ...expand({
    borderRight: "none",
  }),
};

const GridCommon: StyleObject = {
  display: "flex",
  padding: "15px",
  backgroundColor: "white",
  justifyContent: "center",
  alignItems: "center",
  ...expand({
    borderRight: "1px solid #e4e9ed",
  }),
};

const GridHeader = styled("div", () => ({
  ...GridCommon,
  backgroundColor: "#f4f5f7",
  position: "sticky",
  top: 0,
  zIndex: 1,
  ...expand({
    borderBottom: "1px solid #e4e9ed",
    borderTop: "1px solid #e4e9ed",
  }),
}));

const GridEntry = styled("div", () => ({ ...GridCommon, height: "85px" }));

const GridFooter = styled("div", () => ({
  ...GridCommon,
  ...expand({
    borderBottom: "1px solid #e4e9ed",
    borderTop: "1px solid #e4e9ed",
  }),
  position: "sticky",
  bottom: 0,
  zIndex: 1,
}));

export const StickyTable: React.FC<StickyTableProps> = ({ header, footer, data }) => {
  return (
    <TableGrid $numColumns={header.length}>
      {header.map((headerEntry, index) => {
        const styleOverrides =
          index === 0
            ? { ...FixedLeftStyle, zIndex: 2 }
            : index === header.length - 1
            ? FixedRightStyle
            : index === header.length - 2
            ? LastStyle
            : {};

        return <GridHeader style={styleOverrides}>{headerEntry}</GridHeader>;
      })}
      {data.map((row, i) => {
        return row.map((dataEntry, j) => {
          let styleOverrides: React.CSSProperties =
            j === 0
              ? FixedLeftStyle
              : j === row.length - 1
              ? FixedRightStyle
              : j === row.length - 2
              ? LastStyle
              : {};

          if (i % 2 === 1) {
            styleOverrides = { ...styleOverrides, backgroundColor: "#f8f8fa" };
          }

          return <GridEntry style={styleOverrides}>{dataEntry}</GridEntry>;
        });
      })}
      {footer.map((footerEntry, index) => {
        const styleOverrides =
          index === 0
            ? { ...FixedLeftStyle, zIndex: 2 }
            : index === header.length - 1
            ? FixedRightStyle
            : index === header.length - 2
            ? LastStyle
            : {};

        return <GridFooter style={styleOverrides}>{footerEntry}</GridFooter>;
      })}
    </TableGrid>
  );
};
