import { MAP_LINE_TYPE } from "components/Map/mapline";
import { MAP_PIN_TYPE } from "components/Map/MapMarkerPin";
import { coordinate, routeDisplayInterface, serviceResultInterface } from "interfaces/route";
import { stopInterface, STOP_TYPE } from "interfaces/stop";
import { LngLat } from "mapbox-gl";
import { decodePolyline } from "./utils";

const _closestStopIndex = (stops: stopInterface[], query: coordinate) => {
  const q = new LngLat(query.lng, query.lat);
  const distances = stops.map((a, index) => ({
    index,
    distance: new LngLat(a.place.coordinates.lng, a.place.coordinates.lat).distanceTo(q),
  }));
  return distances.sort((a, b) => a.distance - b.distance)[0].index;
};

const closestStopIndex = (stops: stopInterface[], stopType: STOP_TYPE, query?: coordinate) => {
  // A->B vanpools don't have closest stops
  if (stops.length === 2) return 0;

  // stops of the right type
  const theStops = stops.filter((a) => !a.stopType || a.stopType === stopType);
  return query ? _closestStopIndex(theStops, query) : 0;
};

export const defaultDisplayRoute = (
  service: serviceResultInterface,
  start?: coordinate,
  end?: coordinate,
  routeColorHexCode?: string,
): routeDisplayInterface => {
  const pickup = closestStopIndex(service.outbound.stops, STOP_TYPE.PICKUP, start);
  const dropoff =
    service.outbound.stops.length -
    1 -
    closestStopIndex([...service.outbound.stops].reverse(), STOP_TYPE.DROPOFF, end);

  return computeDisplayRoute(service, pickup, dropoff, routeColorHexCode);
};

export const computeDisplayRoute = (
  service: serviceResultInterface,
  pickup: number,
  dropoff: number,
  colorHexCode?: string,
): routeDisplayInterface => {
  const inboundPickup = service.inbound?.stops.findIndex(
    (a) => a.place.id === service.outbound.stops[dropoff].place.id,
  );
  const inboundDropoff = service.inbound?.stops.findIndex(
    (a) => a.place.id === service.outbound.stops[pickup].place.id,
  );

  // accumulate cost between pickup and dropoff stops
  const outboundCost = service.outbound.legs
    .filter((_, index) => index >= pickup && index < dropoff)
    .map((a) => a.cost)
    .reduce((a, b) => a + b, service.outbound.basePrice);
  const inboundCost =
    inboundPickup !== undefined &&
    inboundDropoff !== undefined &&
    service.inbound &&
    service.inbound.legs
      .filter((_, index) => index >= inboundPickup && index < inboundDropoff)
      .map((a) => a.cost)
      .reduce((a, b) => a + b, service.inbound.basePrice);

  return {
    id: service.id,
    service,
    outboundPickup: pickup,
    outboundDropoff: dropoff,
    inboundPickup,
    inboundDropoff,
    cost: outboundCost + (inboundCost || 0),
    stops: service.outbound.stops.map((s, index) => ({
      ...s,
      display: {
        type:
          index === pickup
            ? MAP_PIN_TYPE.PICKUP
            : index === dropoff
            ? MAP_PIN_TYPE.DROPOFF
            : undefined,
        isSelectable: true,
      },
      selected: {
        showName: true,
        showTime: true,
        showViewRouteLink: true,
      },
    })),
    legs: service.outbound.legs.map((l, index) => ({
      ...l,
      coordinates: decodePolyline(l.polyline),
      display: {
        type: MAP_LINE_TYPE.BASIC,
      },
      highlighted: {
        type: index >= pickup && index < dropoff ? MAP_LINE_TYPE.HIGHLIGHTED : MAP_LINE_TYPE.BASIC,
      },
      selected: {
        type: index >= pickup && index < dropoff ? MAP_LINE_TYPE.SELECTED : MAP_LINE_TYPE.BASIC,
      },
      color: colorHexCode && index >= pickup && index < dropoff ? colorHexCode : undefined,
    })),
    colorHexCode,
    drivers: service.drivers ? service.drivers : undefined,
  };
};

export const coordinateToString = (coord: coordinate) => {
  return `${coord.lat},${coord.lng}`;
};

export const coordinateFromString = (coordStr: string): coordinate => {
  const sp = coordStr.split(",");
  return { lat: +sp[0], lng: +sp[1] };
};

export const findStopByPlace = (search: stopInterface[], query: stopInterface) => {
  return search.find((a) => a.place.id === query.place.id);
};
