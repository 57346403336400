import { gql_post } from "misc/http";

export const contact = async (message: string, name?: string, email?: string, phone?: string) => {
  const data = `
    mutation riderContact($message: String!, $name: String, $email: String, $phone: String, $metadata: JSONString) {
      riderContact(message: $message, name: $name, email: $email, phone: $phone, metadata: $metadata) {
        success
      }
    }
   `;

  const variables = { message };
  if (name) variables["name"] = name;
  if (email) variables["email"] = email;
  if (phone) variables["phone"] = phone;

  return gql_post("riderContact", data, variables);
};

export const tripFeedback = async (tripIds: string[], feedback: string) => {
  const data = `
    mutation riderTripFeedback($tripIds: [String!]!, $feedback: String!, $metadata: JSONString) {
      riderTripFeedback(tripIds: $tripIds, feedback: $feedback, metadata: $metadata) {
        success
      }
    }
  `;

  const variables = { tripIds, feedback };

  return gql_post("riderTripFeedback", data, variables);
};
