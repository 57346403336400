import React from "react";
import VanpoolGraphic from "assets/img/graphic-join_pool.png";
import { ReactComponent as MapIcon } from "assets/img/icon-map.svg";
import { ReactComponent as CarIcon } from "assets/img/icon-choose_pool.svg";
import { ReactComponent as CalendarIcon } from "assets/img/icon-booking_calendar.svg";
import { styled } from "baseui";
import { MBButton } from "components/common/Buttons/Buttons";
import { ButtonStack } from "components/common/Modals/BaseModal/BaseModal";
import { NavLink, useHistory } from "react-router-dom";
import { routes } from "misc/http";
import useMBContext from "context/useMBContext";
import { goToReturnToCurrent } from "misc/utils";

const InstructionRow = styled("div", ({ $theme }) => ({
  display: "flex",
  flexDirection: "row",
  textAlign: "left",
  gap: "30px",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: "1em",
}));

export const IntroPage: React.FC = () => {
  const history = useHistory();
  const { user } = useMBContext();

  return (
    <div style={{ textAlign: "center", margin: "15px auto", maxWidth: "400px" }}>
      <div style={{ fontWeight: 400, fontSize: "35px", marginBottom: "1em" }}>Join a pool</div>
      <img
        src={VanpoolGraphic}
        style={{ width: "100%", maxWidth: "100%", marginBottom: "0.5em" }}
      />
      <InstructionRow>
        <MapIcon style={{ height: "65px" }} />
        <p style={{ width: "10em" }}>
          <strong>Enter your work</strong> and home locations
        </p>
      </InstructionRow>
      <InstructionRow>
        <CarIcon style={{ height: "65px" }} />
        <p style={{ width: "10em" }}>
          <strong>Choose a pool</strong> and confirm your stops
        </p>
      </InstructionRow>
      <InstructionRow>
        <CalendarIcon style={{ height: "65px" }} />
        <p style={{ width: "10em" }}>
          <strong>Book rides</strong> up to four weeks in advance
        </p>
      </InstructionRow>
      {user !== undefined && (
        <ButtonStack style={{ padding: "1.5em", gap: "30px" }}>
          {user ? (
            <>
              <MBButton
                onClick={() => {
                  history.push(routes.onboarding.selectAddress.work);
                }}
              >
                Continue
              </MBButton>
              <NavLink to={routes.user.mypools}>Skip for now</NavLink>
            </>
          ) : (
            <>
              <MBButton
                onClick={() => {
                  goToReturnToCurrent(routes.user.signup);
                }}
              >
                Sign Up
              </MBButton>
              <MBButton
                kind="secondary"
                onClick={() => {
                  goToReturnToCurrent(routes.user.login);
                }}
              >
                Sign In
              </MBButton>
            </>
          )}
        </ButtonStack>
      )}
    </div>
  );
};
