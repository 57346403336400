import StopInfo from "components/common/StopInfo/StopInfo";
import { stopInterface } from "interfaces/stop";
import { tripRiderInterface } from "interfaces/trip";
import React from "react";
import { RiderBlock } from "./RiderBlock";
import "./StopList.scss";

interface StopListInterface {
  stops: stopInterface[];
  riders: tripRiderInterface[];
  showRiders: boolean;
  allowAttendance?: boolean;
  updateDataCallback?: () => void;
  showWalkTimes?: boolean;
}

export const StopList: React.FC<StopListInterface> = ({
  stops,
  riders,
  showRiders,
  allowAttendance = false,
  showWalkTimes = false,
  updateDataCallback,
}) => {
  return (
    <div className="StopList Wrapper">
      {stops.map((stop, index) => {
        const isArrival = index == stops.length - 1;
        const pickupRiders = riders.filter((rider) => rider.trip.properties.pickup === index);

        return (
          <div
            className={isArrival ? "StopList StopRow Arrival" : "StopList StopRow"}
            key={stop.place.id}
          >
            <div className="StopList LeftRow">
              <div className="StopList BlueCircle" />
              {!isArrival && <div className="StopList BlueLine" />}
            </div>
            <div className="StopList RightRow">
              <div className="StopList Stop">
                <StopInfo stop={stop} isArrival={isArrival} showWalkTime={showWalkTimes} />
              </div>
              {showRiders && (
                <div className="StopList Riders">
                  {pickupRiders.length > 0 && (
                    <div className="StopList Riders RiderBlock">
                      <RiderBlock
                        riders={pickupRiders}
                        allowAttendance={allowAttendance}
                        updateDataCallback={updateDataCallback}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
