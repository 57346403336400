import React from "react";
import { NavLink } from "react-router-dom";
import "./ErrorBoundary.scss";
import logo from "assets/img/logo.png";
import { datadogLogs } from "@datadog/browser-logs";

const { REACT_APP_ENVIRONMENT, REACT_APP_LOG_ENVIRONMENT } = process.env;

const LOG_ENV =
  REACT_APP_ENVIRONMENT === "dev" ? REACT_APP_LOG_ENVIRONMENT || "local" : REACT_APP_ENVIRONMENT;

class ErrorBoundary extends React.Component {
  state: {
    hasError: boolean;
  };

  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // sent to FE so we can track
    datadogLogs.logger.error("component", {
      error: error,
      errorInfo: errorInfo,
    });

    if (LOG_ENV === "local") {
      console.log(error, errorInfo);
    }
  }

  reloadApp() {
    window.location.reload();
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <header className="Header">
            <NavLink to="/" className="Header-logo" data-testid="header-logo">
              <img
                className="Header-logo-image"
                src={logo}
                alt="MagicBus logo"
                data-testid="header-logo-image"
              />
            </NavLink>
          </header>
          <div className="Field">
            <div className="Field-Heading" data-testid="main-heading">
              App Error
            </div>
            <p className="Field-Summary" data-testid="main-summary">
              We are unable to process your request at this time - please try again
            </p>
            <button className="Reload" onClick={this.reloadApp}>
              Reload
            </button>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}
export default ErrorBoundary;
