import moment from "moment";
import { placeInterface } from "interfaces/place";
import { stopInterface } from "interfaces/stop";

export const formatTime = (timeString: string) => {
  const today = new Date();
  const timePieces = timeString.split(":");

  today.setHours(+timePieces[0]);
  today.setMinutes(+timePieces[1]);

  return today.toLocaleTimeString(["en-US"], {
    hour: "2-digit",
    minute: "2-digit",
  });
};

// Format 1725 into $17.25
export const formatPrice = (dollarsAndCents: number) => {
  return `$${(dollarsAndCents / 100).toFixed(2)}`;
};

// Produce formatted address like "street, city state, zip"
export const formatStreetAddress = (place: placeInterface) => {
  return `${place.streetAddress}, ${place.city} ${place.stateCode}, ${place.zipCode}`;
};

export const formatDuration = (a: stopInterface, b: stopInterface) => {
  return moment(b.arrival, "HH:mm").diff(moment(a.departure, "HH:mm"), "minutes");
};

export const formatPhoneNumber = (phoneInput) => {
  if (phoneInput) {
    const phone = phoneInput.replace(/[^+\d]/g, "");
    if (/^(?!\+)\d{10}$/.test(phone)) {
      // US number but user left out country code
      return "+1" + phone;
    } else if (/^[1]\d{10}$/.test(phone)) {
      // US number but the user left out the +
      return "+" + phone;
    } else if (/^(\+[1-9]\d{1,14})$/.test(phone)) {
      // number is in valid E.164 format
      return phone;
    }
  }
  return "";
};

export const formatPhoneNumberDisplay = (phone) => {
  if (phone) {
    if (phone.length == 12) {
      const match = phone.replace(/\D/g, "").match(/^(\d{1,2}?)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        const intlCode = match[1] ? `+${match[1]} ` : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
      }
    } else {
      return phone;
    }
  }
  return undefined;
};

/// convert ride date as 2020-12-14 and time as 08:30 to an actual datetime
export const makeStopTime = (rideDate: string, time: string) => {
  const [hour, minute] = time.split(":");
  return moment(rideDate).hour(parseInt(hour)).minute(parseInt(minute));
};
