import React, { createContext, useContext, useState, ReactNode } from "react";
import { styled, withStyle } from "baseui";
import { DateTime } from "luxon";

export enum RIDERSHIP_REPORT {
  NTD = "ntd",
  FULL = "full",
}

export const RIDERSHIP_REPORTS = [
  { id: RIDERSHIP_REPORT.FULL, label: "Detailed Ridership" },
  { id: RIDERSHIP_REPORT.NTD, label: "NTD Monthly" },
];

export const MONTHS = Array.from({ length: 12 }).map((_, i) => ({
  label: DateTime.fromObject({ month: i + 1 }).toLocaleString({ month: "long" }),
  id: i,
}));

export const CURRENT_YEAR = DateTime.now().year;
export const YEARS = Array.from({ length: 15 }).map((_, idx) => ({
  label: "" + (CURRENT_YEAR - idx),
  id: CURRENT_YEAR - idx,
}));

export const PageContainer = styled("div", ({ $theme }) => ({
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
}));

export const HeaderContainer = styled("div", ({ $theme }) => ({
  position: "sticky",
}));

export const Header = styled("div", ({ $theme }) => ({
  display: "flex",
  gap: "25px",
  alignItems: "center",
  marginLeft: "25px",
  marginBottom: "20px",
}));

export const SelectContainer = styled("div", ({ $theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "5px",
}));

export const ReportContainer = styled("div", ({ $theme }) => ({
  gap: "5px",
  height: "100%",
  overflow: "auto",
}));
