import React, { useContext } from "react";
import { INSTABOOK_ACTION, riderVanpoolInterface } from "interfaces/vanpool";
import { useHistory } from "react-router-dom";
import { riderMyPoolsAction } from "api/vanpools";
import { MBButton } from "components/common/Buttons/Buttons";
import { routes } from "misc/http";
import { ConfirmModalContext } from "components/common/ConfirmModal";

interface LeavePoolProps {
  pool: riderVanpoolInterface;
  onLeave?: () => void;
  pathPrefix?: string;
}

export const LeavePool: React.FC<LeavePoolProps> = ({ pool, onLeave, pathPrefix = "" }) => {
  const { showConfirmModal } = useContext(ConfirmModalContext);
  const history = useHistory();

  const leaveVanpool = () => {
    riderMyPoolsAction({
      vanpoolId: pool.id,
      action: INSTABOOK_ACTION.CANCEL,
    }).then(() => {
      onLeave?.();
      history.replace(pathPrefix + `${routes.user.mypools}`);
    });
  };

  const showLeavePrompt = () => {
    showConfirmModal({
      headerElement: <>Do you want to leave this vanpool?</>,
      bodyElement: <>Your upcoming rides and/or drives will be cancelled.</>,
      confirmButtonText: "Yes, leave",
      cancelButtonText: "No",
      onConfirmCallback: leaveVanpool,
      confirmType: "destructive",
      message: "",
    });
  };

  return (
    <MBButton kind="secondary" destructive onClick={showLeavePrompt}>
      Leave Vanpool
    </MBButton>
  );
};
