import React from "react";
import { Button, ButtonOverrides, ButtonProps } from "baseui/button";
import { Checkbox, CheckboxProps, LABEL_PLACEMENT } from "baseui/checkbox";
import { mergeOverrides, styled, Theme, withStyle } from "baseui";
import { Overrides } from "baseui/overrides";
import { ReactComponent as ProfileIcon } from "assets/img/default-profile-picture.svg";
import { expand } from "inline-style-expand-shorthand";
import { Tab, TabOverrides, Tabs, TabsOverrides, TabsProps } from "baseui/tabs-motion";

// wrapper component with default MB style override since baseweb doesn't
// support global component style overrides

interface MBButtonProps {
  flex?: boolean;
  destructive?: boolean;
}

export const MBButton: React.FC<ButtonProps & MBButtonProps> = ({
  kind,
  flex,
  destructive,
  overrides,
  children,
  ...props
}) => {
  const ov: ButtonOverrides = {
    BaseButton: {
      style: ({ $theme }) => ({
        ...(destructive &&
          kind === "primary" && {
            backgroundColor: "red",
            ":hover": { backgroundColor: "red" },
          }),
        ...(destructive &&
          kind !== "primary" && {
            color: "red",
          }),
        ...((kind === "secondary" || kind === "tertiary") &&
          expand({
            borderWidth: $theme.borders.border100.borderWidth,
            borderColor: $theme.borders.border100.borderColor,
            borderStyle: $theme.borders.border100.borderStyle,
          })),
        ...(flex && { width: "100%" }),
      }),
    },
  };
  return (
    <Button
      overrides={mergeOverrides(ov as Overrides<any>, overrides as Overrides<any>)}
      kind={kind}
      {...props}
    >
      {children}
    </Button>
  );
};

interface MBCheckboxProps extends CheckboxProps {
  image?: React.ReactNode;
}

// List of checkboxes for use in dialogs
export const MBCheckbox: React.FC<MBCheckboxProps> = ({ children, overrides, image, ...props }) => {
  const ov: Overrides<any> = {
    Root: {
      style: {
        ...expand({
          padding: "21px 0px 21px 17px",
        }),
        alignItems: "center",
      },
    },
    Checkmark: {
      style: ({ $theme }) =>
        expand({
          // Baseweb hardcodes these to 3px, we override
          borderWidth: $theme.borders.border100.borderWidth,
        }),
    },
    Label: {
      style: ({ $theme }: { $theme: Theme }) => ({
        // baseweb seems to use LabelMedium by default (which makes sense, but not how we're using it)
        fontFamily: $theme.typography.ParagraphMedium.fontFamily,
        fontSize: $theme.typography.ParagraphMedium.fontSize,
        fontWeight: $theme.typography.ParagraphMedium.fontWeight,
        color: $theme.colors.mono800,
        display: "flex",
        alignItems: "center",
        paddingLeft: image ? "14px" : "8px",
      }),
    },
  };

  return (
    <Checkbox
      labelPlacement={LABEL_PLACEMENT.right}
      overrides={mergeOverrides(ov, overrides as Overrides<any>)}
      {...props}
    >
      {image && (
        <div style={{ marginRight: "14px", alignItems: "center", display: "flex" }}>{image}</div>
      )}
      {children}
    </Checkbox>
  );
};

export const MBProfileCheckbox: React.FC<MBCheckboxProps> = ({
  image = <ProfileIcon />,
  ...props
}) => {
  const ov = { Root: { style: { paddingTop: "10px", paddingBottom: "10px" } } };
  return <MBCheckbox {...props} image={image} overrides={ov}></MBCheckbox>;
};

export const TabsScrollableContent: React.FC<TabsProps> = ({ children, overrides, ...props }) => {
  const ov: TabsOverrides = {
    Root: { style: { height: "100%", position: "relative" } },
    TabHighlight: {
      style: ({ $theme }) => ({
        backgroundColor: $theme.colors.primary,
        height: "2px",
        bottom: "3px",
      }),
    },
    TabBorder: {
      style: ({ $theme }) => ({
        height: "2px",
      }),
    },
  };
  return (
    <Tabs {...props} overrides={mergeOverrides(ov as Overrides<any>, overrides as Overrides<any>)}>
      {children}
    </Tabs>
  );
};

const TabPanelOverrides: TabOverrides = {
  TabPanel: {
    style: {
      paddingLeft: 0,
      paddingRight: 0,
      height: "calc(100% - 51px)",
      overflow: "hidden",
      position: "absolute",
      top: "46px",
      width: "100%",
    },
  },
  Tab: {
    style: ({ $isActive, $theme }) => ({
      fontFamily: $theme.typography.LabelMedium.fontFamily,
      fontWeight: "normal",
      fontSize: "12px",
      color: $isActive ? $theme.colors.primary : $theme.colors.mono800,
    }),
  },
};

export const ContentContainer = styled("div", ({ $theme }) => ({
  position: "relative",
  height: "100%",
}));

// BUG can't use {$theme} here due to typescript error
const ContentAndBottomContainer = withStyle(ContentContainer, () => ({
  display: "grid",
  gridTemplateRows: "auto min-content",
}));

const ContentScrollContainer = styled("div", () => ({
  overflowY: "auto",
  overflowX: "hidden",
  height: "100%",
}));

const FixedBottom = styled("div", () => ({
  ...expand({
    padding: "22px",
  }),
  width: "100%",
  display: "flex",
  flexFlow: "column",
  rowGap: "10px",
  alignItems: "center",
  justifyContent: "center",
  boxShadow: "0px -7px 10px -7px rgba(0, 0, 0, 0.1)",
}));

export const makeTabScrollableContent = (
  title: string,
  content: React.ReactNode,
  key: string = title.toLowerCase(),
) => (
  <Tab title={title} overrides={TabPanelOverrides} key={key}>
    <ContentAndBottomContainer>
      <ContentScrollContainer>{content}</ContentScrollContainer>
    </ContentAndBottomContainer>
  </Tab>
);

// Create a tab with a content panel that scrolls, and a fixed bottom panel
// We can't make a wrapper component, as Tabs looks directly as its children
// (which must be <Tab>)
export const makeTabScrollableContentFixedBottom = (
  title: string,
  content: React.ReactNode,
  bottom: React.ReactNode,
  key: string = title.toLocaleLowerCase(),
) => (
  <Tab title={title} overrides={TabPanelOverrides} key={key}>
    <ContentAndBottomContainer>
      <ContentScrollContainer>{content}</ContentScrollContainer>
      <FixedBottom>{bottom}</FixedBottom>
    </ContentAndBottomContainer>
  </Tab>
);

export const ResponsiveContainer = styled("div", ({ $theme }) => ({
  margin: "0 auto",
  maxWidth: "710px",
  width: "100%",
}));
