import {
  getVanpoolDayDetails,
  InstabookInterface,
  riderInstabook,
  riderInstabookWithDetails,
} from "api/vanpools";
import { ConfirmModalContext } from "components/common/ConfirmModal";
import useMBContext from "context/useMBContext";
import {
  INSTABOOK_ACTION,
  INSTABOOK_ROLE,
  riderMyPoolInterface,
  vanpoolDayDisplayInterface,
  vanpoolDayDetailsDisplayInterface,
  riderVanpoolDayDetailsInterface,
} from "interfaces/vanpool";
import { Moment } from "moment";
import { useContext, useState } from "react";
import { computeVanpoolDayDisplay } from "../utils";

interface RideDetailsProps {
  pool: riderMyPoolInterface;
  date: Moment;
}

export const useRideDay = ({ pool, date }: RideDetailsProps) => {
  const [rideDay, setRideDay] = useState<vanpoolDayDetailsDisplayInterface>();
  const { showConfirmModal } = useContext(ConfirmModalContext);
  const { user } = useMBContext();

  const instabook = (poolDate: Moment, action: INSTABOOK_ACTION, roleInput: INSTABOOK_ROLE) => {
    riderInstabookWithDetails({
      vanpoolId: pool.id,
      action: action,
      role: roleInput,
      dates: [poolDate],
      properties: pool.profile.properties,
    }).then((poolDays) => {
      setRideDay(computeVanpoolDayDisplay(poolDays[0], pool));
    });
  };

  const bookOrCancelRide = (day: vanpoolDayDisplayInterface) => {
    const role = INSTABOOK_ROLE.RIDER;
    const alreadyRiding = day.status.isRiding;
    const action = alreadyRiding ? INSTABOOK_ACTION.CANCEL : INSTABOOK_ACTION.BOOK;

    const onConfirm = () => instabook(day.date, action, role);
    if (alreadyRiding || day.status.isDriving) {
      const message = alreadyRiding
        ? "Cancel your ride on this day?"
        : "Switch to riding instead of driving?";

      showConfirmModal({
        message,
        date: day.date,
        onConfirmCallback: onConfirm,
      });
    } else {
      onConfirm();
    }
  };

  const bookOrCancelDrive = (day: vanpoolDayDisplayInterface) => {
    const role = INSTABOOK_ROLE.DRIVER;
    const isRiding = day.status.isRiding;
    const action = day.status.isDriving ? INSTABOOK_ACTION.CANCEL : INSTABOOK_ACTION.BOOK;

    const onConfirm = () => instabook(day.date, action, role);
    if (isRiding || day.status.isDriving) {
      const message = day.status.isDriving
        ? "Cancel driving on this day?"
        : "Switch to driving instead of riding?";

      showConfirmModal({
        message,
        date: day.date,
        onConfirmCallback: onConfirm,
      });
    } else {
      onConfirm();
    }
  };

  const updateRideDay = (day: riderVanpoolDayDetailsInterface) => {
    setRideDay(computeVanpoolDayDisplay(day, pool));
  };

  const refreshData = () => {
    getVanpoolDayDetails({
      vanpoolId: pool.id,
      startDate: date,
      endDate: date,
    }).then((poolDays) => {
      updateRideDay(poolDays[0]);
    });
  };

  const assignModalSubmit = (params: InstabookInterface) => {
    if (
      [INSTABOOK_ROLE.RIDER, INSTABOOK_ROLE.DRIVER].includes(params.role) &&
      params.userId &&
      params.userId !== user?.id
    ) {
      // we can't just setRideDay using the data returned from instabook here because when
      // assigning a driver it will return the vanpoolDay data of the driver, not the current user
      // so we instabook without details and then requery the date for details
      riderInstabook(params).then((days) => {
        refreshData();
      });
    } else {
      riderInstabookWithDetails(params).then((days) => {
        days.map((day) => {
          updateRideDay(day);
        });
      });
    }
  };

  return {
    rideDay,
    updateRideDay,
    refreshData,
    bookOrCancelRide,
    bookOrCancelDrive,
    assignModalSubmit,
  };
};
