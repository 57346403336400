import { styled } from "baseui";
import { vanpoolRules } from "interfaces/vanpool";
import React from "react";
import { CollapsibleContainer } from "./CollapsibleContainer";

const RulesList = styled("div", ({ $theme }) => ({
  display: "grid",
  fontSize: "14px",
  margin: "9px 0px 18px 0px",
  color: $theme.colors.mono800,
}));

const RuleBullet = styled("span", ({ $theme }) => ({
  color: $theme.colors.primary,
}));

const Rule = styled("div", ({ $theme }) => ({
  display: "grid",
  margin: "0px 15px",
  gridTemplateColumns: "6% 94%",
}));

const NoRules = styled("div", ({ $theme }) => ({
  textAlign: "center",
  background: $theme.colors.mono200,
  padding: "15px",
  marginBottom: "-9px",
  borderRadius: "4px",
}));

export interface RuleListInterface {
  rules: vanpoolRules[];
  collapsible?: boolean;
}
export const RuleList: React.FC<RuleListInterface> = ({ rules, collapsible = false }) => {
  return (
    <>
      <CollapsibleContainer label="RULES" collapsible={collapsible}>
        <RulesList>
          {rules.length > 0 ? (
            rules.map((rule) => {
              return (
                <Rule>
                  <RuleBullet>•</RuleBullet> {rule.rule}
                </Rule>
              );
            })
          ) : (
            <NoRules>This pool has no rules set.</NoRules>
          )}
        </RulesList>
      </CollapsibleContainer>
    </>
  );
};
