import React from "react";
import { Input, InputOverrides, InputProps } from "baseui/input";
import { mergeOverrides } from "baseui";
import { Overrides } from "baseui/overrides";

// wrapper component with default MB style override since baseweb doesn't
// support global component style overrides

export const MBInputBaseOverrides: InputOverrides = {
  Root: {
    style: { backgroundColor: "white" },
  },
  Input: {
    style: {
      ":focus": { border: "none", color: "#4a4a4a" },
    },
  },
  InputContainer: {
    style: {
      backgroundColor: "white",
    },
  },
  ClearIconContainer: {
    style: { backgroundColor: "white" },
  },
  StartEnhancer: {
    style: {
      backgroundColor: "white",
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
};

const MBInput: React.FC<InputProps> = ({ overrides, ...props }) => {
  return (
    <Input
      overrides={mergeOverrides(MBInputBaseOverrides, overrides as Overrides<any>)}
      {...props}
    />
  );
};

export { MBInput };
