import React from "react";
import { NavLink } from "react-router-dom";
import { userAccountInterface } from "interfaces/user";
import { MBButton } from "components/common/Buttons/Buttons";
import { adminVehicleListInterface, organizationInterface } from "api/admin";
import { mergeOverrides, useStyletron } from "baseui";
import { BuilderOverrides, TableBuilder, TableBuilderProps } from "baseui/table-semantic";
import { Overrides } from "baseui/overrides";

export const pathPrefix = "/admin";

export const LinkCell: React.FC<{
  to: string;
  label: string;
}> = ({ to, label }) => {
  const [css] = useStyletron();
  return (
    <div
      className={css({
        display: "flex",
        justifyContent: "end",
      })}
    >
      <NavLink to={to}>
        <MBButton kind="secondary">{label}</MBButton>
      </NavLink>
    </div>
  );
};

export const OrganizationCell: React.FC<{ org: organizationInterface }> = ({ org }) => {
  return (
    <>
      <p>{org.name}</p>
    </>
  );
};

export const VehicleCell: React.FC<{ vehicle: adminVehicleListInterface }> = ({ vehicle }) => {
  return (
    <>
      <p>
        {vehicle.licensePlate} {vehicle.capacity} seats
      </p>
      <p>Assigned to {vehicle.organization.name}</p>
    </>
  );
};

export function AlternatingTable<RowT>({
  children,
  data,
  overrides,
  ...rest
}: TableBuilderProps<RowT>) {
  const ov: BuilderOverrides = {
    TableBodyRow: {
      style: ({ $theme, $rowIndex }: any) => ({
        backgroundColor:
          $rowIndex % 2 ? $theme.colors.backgroundPrimary : $theme.colors.backgroundSecondary,
        ":hover": {
          backgroundColor: $theme.colors.backgroundTertiary,
        },
      }),
    },
    TableBodyCell: {
      style: {
        verticalAlign: "middle",
      },
    },
    TableHead: {
      style: {
        display: "none",
      },
    },
  };

  return (
    <TableBuilder
      overrides={mergeOverrides(ov as Overrides<any>, overrides as Overrides<any>)}
      data={data}
      {...rest}
    >
      {children}
    </TableBuilder>
  );
}

export interface AccountManageProps {
  account: userAccountInterface;
}
