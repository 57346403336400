import React from "react";
import StopInfo from "../StopInfo/StopInfo";
import { formatDuration } from "helpers/formatHelpers";
import "./TripStops.scss";
import { stopDisplayInterface } from "interfaces/route";

interface StopInfoProps {
  pickupStop: stopDisplayInterface;
  dropoffStop: stopDisplayInterface;
  showAddress?: boolean;
  showWalkTimes?: boolean;
}

const TripStops: React.FC<StopInfoProps> = ({
  pickupStop,
  dropoffStop,
  showAddress = true,
  showWalkTimes = false,
}) => {
  return (
    <div className="Trip">
      <div className="Departure">
        <StopInfo stop={pickupStop} showAddress={showAddress} showWalkTime={showWalkTimes} />
      </div>
      <div className="Trip-duration">
        {formatDuration(pickupStop, dropoffStop)}
        {" mins"}
        <div className="Trip-arrow" />
      </div>
      <div className="Arrival">
        <StopInfo
          stop={dropoffStop}
          isArrival={true}
          showAddress={showAddress}
          showWalkTime={showWalkTimes}
        />
      </div>
    </div>
  );
};

export default TripStops;
