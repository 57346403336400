import { SingleStopSelector } from "./components/SingleStopSelector";
import { MAP_BOUNDS } from "components/Map";
import useMBContext from "context/useMBContext";
import { routes } from "misc/http";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { STOP_TYPE } from "interfaces/stop";
import { params } from "interfaces/params";
import { routeDisplayInterface, stopDisplayInterface } from "interfaces/route";
import { tripPropertiesInterface } from "interfaces/trip";
import { getRoute2 } from "api/mapbox";

export const SelectPickupStopPage: React.FC = ({}) => {
  const {
    setMapDisplayOptions,
    allRoutes,
    selectedRouteID,
    createWalklines,
    setSuggestedLine,
    updateRouteWithStops,
  } = useMBContext();
  const { vanpool } = useParams<params>();
  const { userOrigin, userDestination } = useMBContext();

  const [activeRoute, setActiveRoute] = useState<routeDisplayInterface | undefined>();
  const [stopList, setStopList] = useState<stopDisplayInterface[]>([]);

  const history = useHistory();

  useEffect(() => {
    const route = allRoutes.find((route) => {
      return route.id === selectedRouteID;
    });

    if (!route || !vanpool) {
      // if no vanpool is selected then go back to search results
      history.push(routes.onboarding.searchResults);
      return;
    }

    setStopList(route.service.outbound.stops);
    setActiveRoute(route);
    createWalklines(
      route.stops[route.outboundPickup].place.coordinates,
      route.stops[route.outboundDropoff].place.coordinates,
    ).then((lines) => {
      setSuggestedLine(lines);
    });
    setMapDisplayOptions({
      bounds: MAP_BOUNDS.ROUTES | MAP_BOUNDS.USER_ORIG_DEST,
    });
  }, [vanpool]);

  useEffect(() => {
    if (!userOrigin || !userDestination || !activeRoute) return;

    activeRoute.stops.forEach((stop) => {
      if (!(stop.stopType === STOP_TYPE.PICKUP || stop.stopType === null)) return;

      getRoute2(userOrigin.coordinates, stop.place.coordinates, "walking").then((thisLine) => {
        const walkToPickupMinutes = thisLine.data.routes[0].duration / 60;
        stop.walkMinutes = Math.round(walkToPickupMinutes);
        setStopList([...activeRoute.stops]);
      });
    });
  }, [activeRoute]);

  const handleStopSelection = (props: tripPropertiesInterface) => {
    if (!activeRoute) return;
    if (props.pickup >= props.dropoff) {
      props.dropoff = props.pickup + 1;
    }

    const updatedRoute = updateRouteWithStops(activeRoute, props);
    setActiveRoute(updatedRoute);
  };

  return (
    <>
      {activeRoute && (
        <>
          <div className="Navbar">
            <div className="Navbar-header">
              <div
                className="Navbar-back"
                onClick={() => {
                  history.push(routes.onboarding.searchResults);
                }}
              />
              <div className="Navbar-header-title">Choose a pickup location</div>
              <div className="Navbar-filler" />
            </div>
          </div>
          <div className="Interface">
            <SingleStopSelector
              type={STOP_TYPE.PICKUP}
              stops={stopList}
              props={{
                pickup: activeRoute.outboundPickup,
                dropoff: activeRoute.outboundDropoff,
              }}
              onSelect={handleStopSelection}
              confirmCallback={() => {
                history.push(
                  routes.onboarding.poolDetails.selectStops.dropoff + `/${activeRoute.id}`,
                );
              }}
            />
          </div>
        </>
      )}
    </>
  );
};
