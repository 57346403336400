import React, { useEffect, useState } from "react";
import { riderVanpoolInterface, riderMyPoolDisplayInterface } from "interfaces/vanpool";
import { NavLink, useParams } from "react-router-dom";
import { myPoolMemberInterface } from "interfaces/user";
import { routes } from "misc/http";
import { MyPoolContainer, MyPoolsLayout, MyPoolsLayoutInner } from "./MyPoolsPage/MyPoolsPage";
import { ProfileEditor } from "./components/ProfileEditor";
import { params } from "interfaces/params";
import { styled } from "baseui";
import { UserDetails } from "./components/UserDetails";

const SectionHeader = styled("div", ({ $theme }) => ({
  fontWeight: 700,
  fontSize: "15px",
  marginBottom: "15px",
}));

interface MyPoolManageUserProps {
  pool: riderMyPoolDisplayInterface;
  updateProfileCallback: (arg: riderVanpoolInterface) => void;
  pathPrefix?: string;
  showAsMember?: boolean;
}

export const MyPoolManageUserPage: React.FC<MyPoolManageUserProps> = ({
  pool,
  updateProfileCallback,
  pathPrefix = "",
  showAsMember = false,
}) => {
  const [user, setUser] = useState<myPoolMemberInterface>();
  const [loading, setLoading] = useState<boolean>(true);
  const { userId } = useParams<params>();

  useEffect(() => {
    const selectedUser = pool.members.find((user) => {
      return user.id === userId;
    });

    setUser(selectedUser);
    setLoading(false);
  }, [userId]);

  return (
    <>
      {!loading && (
        <>
          <div className="Navbar">
            <div className="Navbar-header">
              <NavLink to={pathPrefix + `${routes.user.mypools}/${pool.id}/manage`}>
                <div className="Navbar-back"></div>
              </NavLink>
              <div className="Navbar-header-title">{user?.name}'s Settings</div>
              <div className="Navbar-filler" />
            </div>
          </div>
          <MyPoolsLayout>
            <MyPoolsLayoutInner>
              <MyPoolContainer>
                {user && (
                  <div style={{ paddingBottom: "20px" }}>
                    <SectionHeader>User Properties</SectionHeader>
                    <UserDetails user={user} />
                    <SectionHeader>Membership Properties</SectionHeader>
                    <ProfileEditor
                      pool={pool}
                      profile={user.profile}
                      profileUser={user}
                      onUpdate={(pools) => {
                        const updatedPool = pools.find((p) => p.id === pool.id);
                        updatedPool &&
                          updateProfileCallback({
                            ...pool,
                            coordinators: updatedPool.coordinators,
                            drivers: updatedPool.drivers,
                            members: updatedPool.members,
                          });
                      }}
                      pathPrefix={pathPrefix}
                    />
                  </div>
                )}
              </MyPoolContainer>
            </MyPoolsLayoutInner>
          </MyPoolsLayout>
        </>
      )}
    </>
  );
};
