import { GQLBase } from "misc/utils";
import { legInterface } from "./route";
import { stopInterface } from "./stop";
import { ORG_USER_FLAG, userInterface, userSimpleInterface } from "./user";

export class tripPropertiesInterface extends GQLBase {
  pickup = 0;
  dropoff = 0;
}

export enum ATTENDANCE_TYPE {
  MANUAL = "MANUAL",
}

export class tripAttendanceInterface extends GQLBase {
  type = ATTENDANCE_TYPE.MANUAL;
}

export class rideInterface extends GQLBase {
  id = "";
  date = "";
  serviceId = "";
  stops = [new stopInterface()];
}

export class tripSimpleInterface extends GQLBase {
  id = "";
  cancelDate?: string;
  properties = new tripPropertiesInterface();
  attendance? = new tripAttendanceInterface();
}

export class tripInterface extends tripSimpleInterface {
  ride = new rideInterface();
}

export class vehicleInterface extends GQLBase {
  id?: string;
  year? = 0;
  color = "";
  make = "";
  model = "";
  description = "";
  state = "";
  licensePlate = "";
  capacity = 1;
  organizationId = "";
}

export class rideDetailInterface extends GQLBase {
  id = "";
  serviceId = "";
  date = "";
  driver? = new userInterface();
  vehicle? = new vehicleInterface();
  stops = [new stopInterface()];
  legs = [new legInterface()];
}

export class tripRiderInterface extends GQLBase {
  rider = new userSimpleInterface();
  trip = new tripSimpleInterface();
}

export class riderVanpoolRideInterface extends rideDetailInterface {
  riders = [new tripRiderInterface()];
}

export class tripDetailInterface extends GQLBase {
  id = "";
  bookId = "";
  bookDate = "";
  cancelDate?: string;
  userFlags: ORG_USER_FLAG[] = [ORG_USER_FLAG.NO_FEE];
  ride = new rideDetailInterface();
  properties = new tripPropertiesInterface();
}
