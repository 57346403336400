import { useStyletron } from "baseui";
import React from "react";

const CheckmarkIcon = ({ color = undefined }: { color?: string }) => {
  const [css] = useStyletron();

  return (
    <svg
      width="14px"
      height="14px"
      viewBox="0 0 14 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g>
        <polyline
          className={css({
            stroke: color || "#FFF",
            strokeWidth: "1.66px",
            fill: "none",
          })}
          points="1.53059896 7.08501519 5.1281467 10.6706272 12.469401 3.32937283"
        ></polyline>
      </g>
    </svg>
  );
};

export default CheckmarkIcon;
