import React, { useRef, useEffect, useState } from "react";
import "./InputValidator.scss";

interface InputValidatorInterface {
  type: string;
  name: string;
  value?: string;
  disabled?: boolean;
  onChange?: (email: string, valid: boolean) => void;
  placeholder?: string;
  pattern?: string;
  error?: string;
  revalidate?: boolean;
}

const InputValidator: React.FC<InputValidatorInterface> = ({
  type = "text",
  name = "",
  value = "",
  disabled = false,
  onChange,
  placeholder = "",
  pattern = "",
  error = "",
  revalidate = false,
}) => {
  const field = useRef<HTMLInputElement>(null);
  const [fieldValid, setFieldValid] = useState<boolean>(true);

  const validateField = () => {
    if (type === "phone") {
      let phone = field
        .current!.value.replace(/[^0-9+]/g, "")
        .replace(/^00/, "+")
        .replace(/^(\+1)?((?:\d{3})(?=\d))(\d{1,3})?(\d{1,4})?.*$/u, "$1 ($2) $3 $4")
        .trim();
      field.current!.value = phone;
    }
    field.current!.value.search(pattern) ? setFieldValid(false) : setFieldValid(true);
    if (onChange) onChange(field.current!.value, fieldValid);
  };

  useEffect(() => {
    if (value) field.current!.value = value;
    if (revalidate) {
      validateField();
    }
  });

  return (
    <div className="InputValidator">
      <label htmlFor="name" className="InputValidator-Label">
        {name}
      </label>
      <input
        ref={field}
        type={type}
        className="mb InputValidator-Field"
        data-testid={name}
        placeholder={placeholder}
        onKeyUp={validateField}
        readOnly={disabled}
        required
      />
      {fieldValid ? "" : <div className="InputValidator-Invalid">{error}</div>}
    </div>
  );
};

export default InputValidator;
