import React, { useState } from "react";
import { INSTABOOK_ACTION, riderVanpoolInterface } from "interfaces/vanpool";
import { useHistory } from "react-router-dom";
import { riderMyPoolsAction } from "api/vanpools";
import { MBButton } from "components/common/Buttons/Buttons";
import { routes } from "misc/http";
import { userSimpleInterface } from "interfaces/user";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "baseui/modal";
import { DatePicker } from "baseui/datepicker";
import { DateTime } from "luxon";
import { Block } from "baseui/block";

interface RemoveUserProps {
  pool: riderVanpoolInterface;
  user: userSimpleInterface;
  removeUserCallback?: () => void;
  pathPrefix?: string;
}

export const RemoveUser: React.FC<RemoveUserProps> = ({
  pool,
  user,
  removeUserCallback,
  pathPrefix = "",
}) => {
  const [show, setShow] = useState(false);
  const [lastRideDate, setLastRideDate] = useState(DateTime.now().toJSDate());
  const history = useHistory();

  const leaveVanpool = () => {
    riderMyPoolsAction({
      vanpoolId: pool.id,
      action: INSTABOOK_ACTION.CANCEL,
      userId: user.id,
      lastRideDate: DateTime.fromJSDate(lastRideDate),
    }).then((pools) => {
      removeUserCallback?.();
      history.push(pathPrefix + `${routes.user.mypools}/${pool.id}/manage`);
    });
  };

  return (
    <>
      <Modal
        isOpen={show}
        onClose={() => setShow(false)}
        autoFocus={false}
        overrides={{
          Dialog: {
            style: () => ({
              textAlign: "center",
            }),
          },
          Close: {
            style: () => ({
              display: "none",
            }),
          },
        }}
      >
        <ModalHeader>Are you sure you want to remove {user.name} from this vanpool?</ModalHeader>
        <ModalBody>
          Their rides and/or drives after the following date will be canceled.
          <Block
            display="grid"
            gridTemplateColumns="auto auto"
            alignItems="center"
            padding="20px 10px 0px 10px"
          >
            Last Ride Date:{" "}
            <DatePicker value={lastRideDate} onChange={({ date }) => setLastRideDate(date)} />
          </Block>
        </ModalBody>
        <ModalFooter>
          <div className="ConfirmModal ButtonStack">
            <MBButton onClick={leaveVanpool} kind="primary" destructive>
              Yes, remove
            </MBButton>
            <MBButton kind="tertiary" onClick={() => setShow(false)}>
              No
            </MBButton>
          </div>
        </ModalFooter>
      </Modal>
      <MBButton kind="secondary" destructive onClick={() => setShow(true)}>
        Remove user
      </MBButton>
    </>
  );
};
