import { GQLBase } from "misc/utils";
import { geoPointDataInterface } from "./map";
import { coordinate } from "./route";

export class placeInterface extends GQLBase {
  id = "";
  placeName = "";
  coordinates = new coordinate();
  streetAddress = "";
  city = "";
  stateCode = "";
  zipCode = "";
  county = "";
}

export interface userLocationInterface {
  locationType: string;
  coordinates: coordinate;
  placeName?: string;
  streetAddress?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  county?: string;
}

export class addressInterface extends GQLBase {
  coordinates = new coordinate();
  name = "";
  streetAddress = "";
  city = "";
  state = "";
  zip = "";
  county = "";
}
