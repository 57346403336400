import { GQLBase } from "misc/utils";
import { placeInterface } from "./place";

export enum STOP_TYPE {
  PICKUP = "PICKUP",
  DROPOFF = "DROPOFF",
}

export class stopInterface extends GQLBase {
  arrival = "";
  departure = "";
  place = new placeInterface();
  stopType?: STOP_TYPE = STOP_TYPE.DROPOFF;
}
