import { fetchRidershipReport } from "api/reports";
import { riderInstabookManageList } from "api/vanpools";
import { useStyletron, withStyle } from "baseui";
import { Option } from "baseui/select";
import {
  StyledRoot,
  StyledTable,
  StyledTableBody,
  StyledTableBodyCell,
  StyledTableBodyRow,
  StyledTableHeadCell,
  StyledTableHeadRow,
} from "baseui/table-semantic";
import { LabelSmall } from "baseui/typography";
import { MBSelect } from "components/common/Inputs/MBSelect";
import { expand } from "inline-style-expand-shorthand";
import { params } from "interfaces/params";
import { ridershipReport } from "interfaces/reports";
import {
  riderMyPoolInterface,
  riderVanpoolListInterface,
  RIDE_STATUS,
  USER_RIDE_STATUS,
} from "interfaces/vanpool";
import { DateTime } from "luxon";
import { routes } from "misc/http";
import React, { useEffect, useState } from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { MyPoolLabel } from "../MyPoolsPage/MyPoolsPage";
import { getMyPoolUserStatus } from "../utils";
import {
  Header,
  HeaderContainer,
  MONTHS,
  PageContainer,
  ReportContainer,
  RIDERSHIP_REPORT,
  RIDERSHIP_REPORTS,
  SelectContainer,
  YEARS,
} from "./MyPoolRidershipReportCommon";
import ExcelJS from "exceljs";
import { MBButton } from "components/common/Buttons/Buttons";

export namespace ridershipNTDReport {
  export interface user {
    id: string;
    profile?: ridershipReport.userProfile;
    oneWayMiles: number;
    statuses: (USER_RIDE_STATUS | undefined)[];
    upt: number; // unlinked passenger trips
    pmt: number; // passenger miles traveled
  }

  export interface periodItem {
    outbound: ridershipReport.schedule;
    inbound?: ridershipReport.schedule;
  }

  interface groupItem {
    key: string;
    users: user[];
    upt: number;
    pmt: number;
  }

  interface group {
    field: ridershipReport.customFieldInterface;
    items: groupItem[];
  }

  export interface pool {
    vrm: number;
    vrh: number;
    pmt: number;
    upt: number;
    atl: number; ///< average trip length (miles)
    att: number; ///< average trip time (minutes)
    op: number; // number of days operated
    items: (periodItem | undefined)[];
    ridership: user[];
    groups: group[];
    info: {
      name: string;
      id: string;
      periodStart: DateTime;
    };
    today_date: number;
  }

  export enum RIDERS {
    ALL = "all",
    NEW = "new",
  }

  const ridershipLetter = (r: USER_RIDE_STATUS): string | undefined => {
    const u = getMyPoolUserStatus(r);
    if (u.isMissed) return "M";
    if (u.isWaitlisted) return "W";
    if (u.isAlternateDriver) return "A";
    if (u.isDriving) return "D";
    if (u.isRiding) return "R";
  };

  const ridershipNTDLetter = (r: USER_RIDE_STATUS, future: boolean): string | undefined => {
    // Desire: for past rides, show R or blank
    // for future rides show S or blank
    // for one way, show O
    const u = getMyPoolUserStatus(r);
    if (u.isDriving) return "D";
    if (!future) {
      if (u.isRiding && !u.isMissed) return "R";
    } else {
      if (u.isRiding) return "S";
    }
  };

  const _vanpoolInformation = (pool: pool, worksheet: ExcelJS.Worksheet) => {
    let rowIndex = 1;
    worksheet.getRow(rowIndex++).values = ["Vanpool Name", pool.info.name];
    worksheet.getRow(rowIndex++).values = ["Vanpool ID", pool.info.id];
    worksheet.getRow(rowIndex++).values = ["Report Period", pool.info.periodStart.toJSDate()];
    worksheet.getRow(rowIndex++).values = ["Report Generated", DateTime.now().toJSDate()];
  };

  const _commuteInformation = (pool: pool, worksheet: ExcelJS.Worksheet) => {
    let rowIndex = 1;
    const headerRow = worksheet.getRow(rowIndex++);
    headerRow.values = ["", ...Array.from({ length: pool.items?.length || 0 }, (_, i) => i + 1)];

    const oneWayMiles = worksheet.getRow(rowIndex++);
    oneWayMiles.values = [
      "Van One-Way Trip Miles",
      ...pool.items?.map((x) =>
        x ? (x.outbound.vrm + (x.inbound?.vrm || x.outbound.vrm)) / 2 : undefined,
      ),
    ];

    const roundTrip = worksheet.getRow(rowIndex++);
    roundTrip.values = [
      "Van Round Trip Commute Time (Minutes)",
      ...pool.items?.map((x) => (x ? (x.outbound.vrh + (x.inbound?.vrh || 0)) * 60 : undefined)),
    ];
  };

  const _ridership = (pool: pool, worksheet: ExcelJS.Worksheet) => {
    let rowIndex = 1;
    const showJI = pool.ridership?.some((x) => x.profile?.ji !== undefined);
    const subHeaderRow = worksheet.getRow(rowIndex++);
    subHeaderRow.values = [
      "Pass No.",
      "Passenger Name",
      ...(showJI ? ["JI?"] : []),
      "New?",
      "One Way Miles",
      ...Array.from({ length: pool.items?.length || 0 }, (_, i) => i + 1),
      "Total Trips",
      "Total Distance",
    ];

    // Add table data
    pool.ridership?.forEach((u, r) => {
      const row = worksheet.getRow(rowIndex + r);
      row.values = [
        r + 1,
        u.profile?.name,
        ...(showJI ? [u.profile?.ji ? "Yes" : "No"] : []),
        u.profile?.new ? "Yes" : "No",
        u.oneWayMiles,
        ...u.statuses.map(
          (s, c) => (s !== undefined && ridershipNTDLetter(s, c + 1 > pool.today_date!)) || "",
        ),
        u.upt,
        u.pmt,
      ];
    });
  };

  const exportRidershipTable = (pool: pool, worksheet: ExcelJS.Worksheet) => {
    // Set column widths/formats
    const showJI = pool.ridership?.some((x) => x.profile?.ji !== undefined);
    const jiOffset = showJI ? 1 : 0;
    const dayCol = 5 + jiOffset;
    const days = pool.items.length || 0;
    const endCol = dayCol + days + 1;
    worksheet.getColumn(1).width = 10;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 10;
    worksheet.getColumn(4).width = 10;
    worksheet.getColumn(5).width = 10;
    Array.from({ length: days }, (_, i) => {
      worksheet.getColumn(dayCol + i).width = 3;
    });
    worksheet.getColumn(dayCol + days + 0).width = 10;
    worksheet.getColumn(dayCol + days + 1).width = 10;

    let rowIndex = 0;

    // Vanpool information
    {
      ++rowIndex;
      const headerRow = worksheet.getRow(rowIndex);
      headerRow.values = ["Vanpool Information"];
      headerRow.font = { bold: true, size: 11 };
      worksheet.mergeCells(rowIndex, 1, rowIndex, endCol);

      ++rowIndex;
      const nameRow = worksheet.getRow(rowIndex);
      nameRow.values = ["Vanpool Name", "", pool.info.name];
      worksheet.mergeCells(rowIndex, 1, rowIndex, 2);
      worksheet.mergeCells(rowIndex, 3, rowIndex, endCol);
      nameRow.font = { size: 9 };

      ++rowIndex;
      const idRow = worksheet.getRow(rowIndex);
      idRow.values = ["Vanpool ID", "", pool.info.id];
      worksheet.mergeCells(rowIndex, 1, rowIndex, 2);
      worksheet.mergeCells(rowIndex, 3, rowIndex, endCol);
      idRow.font = { size: 9 };

      ++rowIndex;
      const periodRow = worksheet.getRow(rowIndex);
      periodRow.values = ["Report Period", "", pool.info.periodStart.toFormat("MMMM yyyy")];
      worksheet.mergeCells(rowIndex, 1, rowIndex, 2);
      worksheet.mergeCells(rowIndex, 3, rowIndex, endCol);
      periodRow.font = { size: 9 };

      ++rowIndex;
      const generatedRow = worksheet.getRow(rowIndex);
      generatedRow.values = ["Report Generated", "", DateTime.now().toJSDate()];
      generatedRow.getCell(3).alignment = { horizontal: "left" };
      worksheet.mergeCells(rowIndex, 1, rowIndex, 2);
      worksheet.mergeCells(rowIndex, 3, rowIndex, endCol);
      generatedRow.font = { size: 9 };

      ++rowIndex;
      worksheet.mergeCells(rowIndex, 1, rowIndex, endCol);
    }

    // Commute information
    {
      ++rowIndex;
      const headerRow = worksheet.getRow(rowIndex);
      headerRow.values = [
        "Commute Information",
        ...Array.from({ length: dayCol - 2 }, () => ""),
        ...Array.from({ length: pool.items?.length || 0 }, (_, i) => i + 1),
      ];
      headerRow.font = { bold: true, size: 10 };
      headerRow.alignment = { horizontal: "center", vertical: "middle" };
      headerRow.getCell(1).font = { size: 11, bold: true };
      headerRow.getCell(1).alignment = { horizontal: "left", vertical: "middle" };
      worksheet.mergeCells(rowIndex, 1, rowIndex, dayCol - 1);

      ++rowIndex;
      const oneWayMiles = worksheet.getRow(rowIndex);
      oneWayMiles.values = [
        "Van One-Way Trip Miles",
        ...Array.from({ length: dayCol - 2 }, () => ""),
        ...pool.items?.map((x) =>
          x ? (x.outbound.vrm + (x.inbound?.vrm || x.outbound.vrm)) / 2 : undefined,
        ),
      ];
      worksheet.mergeCells(rowIndex, 0, rowIndex, dayCol - 1);
      oneWayMiles.font = { size: 9 };
      oneWayMiles.eachCell((cell, col) => {
        if (col >= dayCol) cell.numFmt = "0";
      });

      ++rowIndex;
      const roundTrip = worksheet.getRow(rowIndex);
      roundTrip.values = [
        "Van Round Trip Commute Time (Minutes)",
        ...Array.from({ length: dayCol - 2 }, () => ""),
        ...pool.items?.map((x) => (x ? (x.outbound.vrh + (x.inbound?.vrh || 0)) * 60 : undefined)),
      ];
      worksheet.mergeCells(rowIndex, 0, rowIndex, dayCol - 1);
      roundTrip.font = { size: 9 };
      roundTrip.eachCell((cell, col) => {
        if (col >= dayCol) cell.numFmt = "0";
      });

      ++rowIndex;
      const note = worksheet.getRow(rowIndex);
      note.values = [
        "Note: Mileage above is rounded to the nearest mile; commute time is rounded to the nearest minute.",
      ];
      worksheet.mergeCells(rowIndex, 0, rowIndex, endCol);
      note.font = { size: 8, bold: true };

      ++rowIndex;
      worksheet.mergeCells(rowIndex, 1, rowIndex, endCol);
    }

    // Ridership table
    {
      ++rowIndex;
      const headerRow = worksheet.getRow(rowIndex);
      headerRow.values = ["Ridership"];
      headerRow.font = { bold: true, size: 11 };
      headerRow.alignment = { horizontal: "left", vertical: "middle" };
      worksheet.mergeCells(rowIndex, 0, rowIndex, endCol);

      rowIndex++;
      const subHeaderRow = worksheet.getRow(rowIndex);
      subHeaderRow.values = [
        "Pass No.",
        "Passenger Name",
        ...(showJI ? ["JI?"] : []),
        "New?",
        "One Way Miles",
        ...Array.from({ length: pool.items?.length || 0 }, (_, i) => i + 1),
        "Total Trips",
        "Total Distance",
      ];
      subHeaderRow.font = { bold: true, size: 10 };
      subHeaderRow.alignment = { horizontal: "center", vertical: "middle", wrapText: true };
      subHeaderRow.getCell(2).alignment = { horizontal: "left", vertical: "middle" };
      subHeaderRow.height = 30;

      // Add table data
      rowIndex++;
      pool.ridership?.forEach((u, r) => {
        const row = worksheet.getRow(rowIndex + r);
        row.values = [
          r + 1,
          u.profile?.name,
          ...(showJI ? [u.profile?.ji ? "Yes" : "No"] : []),
          u.profile?.new ? "Yes" : "No",
          u.oneWayMiles,
          ...u.statuses.map(
            (s, c) => (s !== undefined && ridershipNTDLetter(s, c + 1 > pool.today_date!)) || "",
          ),
          u.upt,
          u.pmt,
        ];
        row.alignment = { vertical: "middle", horizontal: "center" };
        row.font = { size: 9 };
        row.getCell(1).alignment = { horizontal: "center" };
        row.getCell(2).alignment = { horizontal: "left" };
        row.getCell(jiOffset + 4).numFmt = "0.00";
        row.getCell(dayCol + days).alignment = { horizontal: "right" };
        row.getCell(dayCol + days + 1).alignment = { horizontal: "right" };
        row.getCell(dayCol + days + 1).numFmt = "0.00";
      });

      // Totals row
      rowIndex += pool.ridership?.length || 0;
      const row = worksheet.getRow(rowIndex);
      row.values = [
        "Totals",
        "",
        ...(showJI ? [pool.ridership?.reduce((a, b) => a + (b.profile?.ji ? 1 : 0), 0)] : []),
        pool.ridership?.reduce((a, b) => a + (b.profile?.new ? 1 : 0), 0),
        "",
        ...Array.from({ length: pool.items?.length || 0 }, (_, i) => ""),
        pool.ridership?.reduce((a, b) => a + b.upt, 0),
        pool.ridership?.reduce((a, b) => a + b.pmt, 0),
      ];
      worksheet.mergeCells(rowIndex, 0, rowIndex, 2);
      row.font = { size: 9, bold: true };
      row.getCell(1).alignment = { horizontal: "right" };
      row.getCell(dayCol + days + 1).numFmt = "0.00";
    }
  };

  const exportTablesToExcel = async (pool: pool) => {
    const workbook = new ExcelJS.Workbook();
    const name = `NTD Monthly ${pool.info.periodStart.toFormat("MMMM yyyy")}`;
    const ridership = workbook.addWorksheet(name);

    // Add Ridership table
    exportRidershipTable(pool, ridership);

    // Add CSV-like raw data tables
    _vanpoolInformation(pool, workbook.addWorksheet("VanpoolInformation"));
    _commuteInformation(pool, workbook.addWorksheet("CommuteInformation"));
    _ridership(pool, workbook.addWorksheet("Ridership"));

    // Save the workbook to a file
    // Create a download link
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${name} - ${pool.info.name}.xlsx`;
    link.click();

    // Clean up the temporary URL
    URL.revokeObjectURL(url);
  };

  export const createCustomFieldBreakdown = (
    field: ridershipReport.customFieldInterface,
    ridership: user[],
  ): group | undefined => {
    // group/sum by user custom field value
    const _valueKey = (v: ridershipReport.customFieldValueInterface | undefined): string =>
      v ? ridershipReport.customFieldValueDisplay(v) : "Unset";
    const userKeys = ridership.map((u) => ({
      user: u,
      key: _valueKey(u.profile?.customFieldValues?.find((x) => x.field === field)),
    }));
    const uniqueKeys = new Set(userKeys.map((u) => u.key));

    // if no user has this custom field, ignore it
    if (uniqueKeys.size === 1 && uniqueKeys.has("Unset")) return undefined;

    const groups = [...uniqueKeys].map((k) => ({
      key: k,
      users: userKeys.filter((u) => u.key === k).map((u) => u.user),
    }));
    const items: groupItem[] = groups.map((g) => ({
      ...g,
      ...g.users.reduce((p, c) => ({ pmt: p.pmt + c.pmt, upt: p.upt + c.upt }), {
        upt: 0,
        pmt: 0,
      }),
    }));

    return {
      field,
      items,
    };
  };

  export const createRidershipNTDReport = (
    vanpool: riderMyPoolInterface,
    pool: ridershipReport.pool,
    userProfiles: ridershipReport.userProfile[],
    customFields: ridershipReport.customFieldInterface[],
    date: DateTime,
    riders: RIDERS,
  ) => {
    // make period bins
    const start = date.startOf("month");
    const end = date.endOf("month");

    // link up custom fields
    customFields = ridershipReport.linkCustomFields(customFields);

    // link rider profile custom fields
    userProfiles.forEach((profile) => {
      profile.customFieldValues = profile.customFieldValues?.map((c) =>
        ridershipReport.parseCustomFieldValue(customFields, c),
      );

      // special handling for JI tagged custom field
      {
        const ji_field = customFields.find((x) => x.tags?.includes("ji"));
        if (ji_field) {
          profile.ji =
            profile.customFieldValues?.find((x) => x.field.id === ji_field.id)?.value !== undefined;
        }
      }
    });

    const p: pool = {
      atl: 0,
      att: 0,
      op: 0,
      pmt: 0,
      upt: 0,
      vrh: 0,
      vrm: 0,
      ridership: [],
      items: Array(start.daysInMonth).fill(undefined),
      groups: [],
      info: {
        name: pool.name,
        id: pool.internalName,
        periodStart: start,
      },
      today_date: 100,
    };
    const users: Map<string, user> = new Map();
    const today = DateTime.utc().startOf("day");
    for (let day of pool.days) {
      // vanpool schedule
      if (day.date < start || day.date > end) continue;

      // today is in this report month; indicate the today date so we can determine rides in the future
      if (day.date.year === today.year && day.date.month === today.month) {
        p.today_date = today.day;
      }

      const idx = day.date.day - 1;
      p.items[idx] = {
        outbound: day.outbound,
        inbound: day.inbound,
      };

      // ridership
      let totalTrips = 0;
      for (let member of day.members) {
        // filter users based on new or not
        const profile = userProfiles.find((x) => x.userId === member.userId);
        if (!(profile?.new || riders === RIDERS.ALL)) continue;

        let u: user | undefined = users.get(member.userId);
        if (!u) {
          u = {
            id: member.userId,
            profile,
            oneWayMiles: 0,
            pmt: 0,
            upt: 0,
            statuses: Array(start.daysInMonth).fill(undefined),
          };
        }
        if (member.upt && day.rideStatus === RIDE_STATUS.OK) {
          u.statuses[idx] = member.ridership;
          u.oneWayMiles = member.pmt / member.upt;
          const status = getMyPoolUserStatus(member.ridership);
          if (
            day.date <= today &&
            ((status.isRiding && !(status.isWaitlisted || status.isMissed)) || status.isDriving)
          ) {
            // accumulate miles and trips when the user actually rides
            u.pmt += member.pmt;
            u.upt += member.upt;

            p.pmt += member.pmt;
            p.upt += member.upt;
            p.atl += member.pmt / member.upt;
            p.att += member.ptt;
            ++totalTrips;
          }
        }
        users.set(member.userId, u);
      }

      p.atl /= totalTrips || 1;
      p.att /= totalTrips || 1;
    }
    p.ridership = Array.from(users.values()).sort((a, b) => a.id.localeCompare(b.id));
    p.groups = customFields
      .map((f) => createCustomFieldBreakdown(f, p.ridership))
      .filter((g) => !!g) as group[];

    return p;
  };

  const TableCell = withStyle(StyledTableBodyCell, ({ $theme }) => ({
    ...expand({
      padding: "4px",
    }),
  }));

  const TableHeadCell = withStyle(StyledTableHeadCell, ({ $theme }) => ({
    ...expand({
      padding: "4px",
    }),
  }));

  export interface NTDReportProps {
    pool: Partial<pool>;
  }

  export const NTDReport: React.FC<NTDReportProps> = ({ pool }) => {
    const showJI = pool.ridership?.some((x) => x.profile?.ji !== undefined);
    return (
      <>
        <StyledRoot $divider="grid">
          <StyledTable>
            <thead>
              <StyledTableHeadRow>
                <TableHeadCell $divider="grid" colSpan={2}>
                  Vanpool Information
                </TableHeadCell>
              </StyledTableHeadRow>
            </thead>
            <tbody>
              <StyledTableBodyRow>
                <TableCell $divider="grid" $style={() => ({ width: "200px" })}>
                  Vanpool Name
                </TableCell>
                <TableCell $divider="grid">{pool.info?.name}</TableCell>
              </StyledTableBodyRow>
              <StyledTableBodyRow>
                <TableCell $divider="grid">Vanpool ID</TableCell>
                <TableCell $divider="grid">{pool.info?.id}</TableCell>
              </StyledTableBodyRow>
            </tbody>
          </StyledTable>
        </StyledRoot>
        <div style={{ height: "30px" }} />
        <StyledRoot $divider="grid">
          <StyledTable>
            <thead>
              <StyledTableHeadRow>
                <TableHeadCell $divider="grid">Commute Information</TableHeadCell>
                {pool.items?.map((v, i) => (
                  <TableHeadCell
                    $divider="grid"
                    $isNumeric={true}
                    $style={() => ({ width: "35px" })}
                    key={i}
                  >
                    {i + 1}
                  </TableHeadCell>
                ))}
              </StyledTableHeadRow>
            </thead>
            <tbody>
              <StyledTableBodyRow>
                <TableCell $divider="grid">Van One-Way Trip Miles</TableCell>
                {pool.items?.map((v, i) => (
                  <TableCell $isNumeric={true} $divider="grid" key={i}>
                    {v?.outbound
                      ? Math.round((v.outbound.vrm + (v.inbound?.vrm || v.outbound.vrm)) / 2)
                      : ""}
                  </TableCell>
                ))}
              </StyledTableBodyRow>
              <StyledTableBodyRow>
                <TableCell $divider="grid" style={{ minWidth: "200px" }}>
                  Van Round Trip Commute Time (Minutes)
                </TableCell>
                {pool.items?.map((v, i) => (
                  <TableCell $isNumeric={true} $divider="grid" key={i}>
                    {v?.outbound ? Math.round((v.outbound.vrh + (v.inbound?.vrh || 0)) * 60) : ""}
                  </TableCell>
                ))}
              </StyledTableBodyRow>
            </tbody>
          </StyledTable>
        </StyledRoot>
        <LabelSmall>
          Note: Mileage above is rounded to the nearest mile; commute time is rounded to the nearest
          minute.
        </LabelSmall>
        <div style={{ height: "30px" }} />
        <StyledRoot $divider="grid">
          <StyledTable>
            <StyledTableHeadRow>
              <TableHeadCell colSpan={7 + (pool.items?.length || 0)}>Ridership</TableHeadCell>
            </StyledTableHeadRow>
            <StyledTableHeadRow>
              <TableHeadCell $divider="grid" $style={() => ({ width: "70px" })}>
                Pass No.
              </TableHeadCell>
              <TableHeadCell $divider="grid">Passenger Name</TableHeadCell>
              {showJI && (
                <TableHeadCell
                  $divider="grid"
                  $style={() => ({ width: "50px", textAlign: "center" })}
                >
                  JI?
                </TableHeadCell>
              )}
              <TableHeadCell
                $divider="grid"
                $style={() => ({ width: "50px", textAlign: "center" })}
              >
                New?
              </TableHeadCell>
              <TableHeadCell
                style={{ maxWidth: "40px", whiteSpace: "pre-wrap", textAlign: "center" }}
                $divider="grid"
              >
                One Way Miles
              </TableHeadCell>
              {pool.items?.map((v, i) => (
                <TableHeadCell $divider="grid" $isNumeric={true} $style={() => ({ width: "25px" })}>
                  {i + 1}
                </TableHeadCell>
              ))}
              <TableHeadCell
                style={{ maxWidth: "40px", whiteSpace: "pre-wrap", textAlign: "center" }}
                $divider="grid"
              >
                Total Trips
              </TableHeadCell>
              <TableHeadCell
                $divider="grid"
                style={{ width: "70px", whiteSpace: "pre-wrap", textAlign: "center" }}
              >
                Total Distance
              </TableHeadCell>
            </StyledTableHeadRow>
            {pool.ridership?.map((u, r) => (
              <StyledTableBodyRow>
                <TableCell $divider="grid" $style={() => ({ textAlign: "center" })}>
                  {r + 1}
                </TableCell>
                <TableCell $divider="grid">{u.profile?.name}</TableCell>
                {showJI && (
                  <TableCell $divider="grid" $style={() => ({ textAlign: "center" })}>
                    {u.profile?.ji ? "Yes" : "No"}
                  </TableCell>
                )}
                <TableCell $divider="grid" $style={() => ({ textAlign: "center" })}>
                  {u.profile?.new ? "Yes" : "No"}
                </TableCell>
                <TableCell $divider="grid" $isNumeric={true}>
                  {u.oneWayMiles.toFixed(2)}
                </TableCell>
                {u.statuses.map((s, c) => (
                  <TableCell $divider="grid">
                    {s !== undefined && ridershipNTDLetter(s, c + 1 > pool.today_date!)}
                  </TableCell>
                ))}
                <TableCell $divider="grid" $isNumeric={true}>
                  {u.upt}
                </TableCell>
                <TableCell $divider="grid" $isNumeric={true}>
                  {u.pmt.toFixed(2)}
                </TableCell>
              </StyledTableBodyRow>
            ))}
            <StyledTableBody>
              <TableCell
                $divider="grid"
                colSpan={2}
                $style={() => ({ fontWeight: "bold", textAlign: "right" })}
              >
                Totals
              </TableCell>
              {showJI && (
                <TableCell
                  $divider="grid"
                  $style={() => ({ textAlign: "right", fontWeight: "bold" })}
                >
                  {pool.ridership?.reduce((a, b) => a + (b.profile?.ji ? 1 : 0), 0)}
                </TableCell>
              )}
              <TableCell
                $divider="grid"
                $style={() => ({ textAlign: "right", fontWeight: "bold" })}
              >
                {pool.ridership?.reduce((a, b) => a + (b.profile?.new ? 1 : 0), 0)}
              </TableCell>
              <TableCell $divider="grid"></TableCell>
              {pool.items?.map((v, i) => (
                <TableHeadCell
                  $divider="grid"
                  $isNumeric={true}
                  $style={() => ({ width: "25px" })}
                ></TableHeadCell>
              ))}
              <TableCell
                $divider="grid"
                $style={() => ({ textAlign: "right", fontWeight: "bold" })}
              >
                {pool.ridership?.reduce((a, b) => a + b.upt, 0)}
              </TableCell>
              <TableCell
                $divider="grid"
                $style={() => ({ textAlign: "right", fontWeight: "bold" })}
              >
                {pool.ridership?.reduce((a, b) => a + b.pmt, 0).toFixed(2)}
              </TableCell>
            </StyledTableBody>
          </StyledTable>
        </StyledRoot>
        {pool.groups?.map((g, gi) => (
          <>
            <div style={{ height: "30px" }} />
            <StyledRoot $divider="grid">
              <StyledTable>
                <StyledTableHeadRow>
                  <TableHeadCell colSpan={5}>{g.field.name}</TableHeadCell>
                </StyledTableHeadRow>
                <StyledTableHeadRow>
                  <TableHeadCell $divider="grid">Value</TableHeadCell>
                  <TableHeadCell
                    $divider="grid"
                    $style={() => ({ width: "60px", textAlign: "center" })}
                  >
                    Users
                  </TableHeadCell>
                  <TableHeadCell
                    $divider="grid"
                    $style={() => ({ width: "45px", textAlign: "center" })}
                  >
                    New
                  </TableHeadCell>
                  <TableHeadCell
                    style={{ width: "50px", whiteSpace: "pre-wrap", textAlign: "center" }}
                    $divider="grid"
                  >
                    Total Trips
                  </TableHeadCell>
                  <TableHeadCell
                    $divider="grid"
                    style={{ width: "90px", whiteSpace: "pre-wrap", textAlign: "center" }}
                  >
                    Total Distance
                  </TableHeadCell>
                </StyledTableHeadRow>
                {g.items.map((i, ii) => (
                  <StyledTableBodyRow>
                    <TableCell $divider="grid">{i.key}</TableCell>
                    <TableCell $divider="grid" $isNumeric={true}>
                      {i.users.length}
                    </TableCell>
                    <TableCell $divider="grid" $isNumeric={true}>
                      {i.users.filter((u) => u.profile?.new).length}
                    </TableCell>
                    <TableCell $divider="grid" $isNumeric={true}>
                      {i.upt}
                    </TableCell>
                    <TableCell $divider="grid" $isNumeric={true}>
                      {i.pmt.toFixed(2)}
                    </TableCell>
                  </StyledTableBodyRow>
                ))}
              </StyledTable>
            </StyledRoot>
          </>
        ))}
      </>
    );
  };

  interface NTDReportPageProps {
    pool: riderMyPoolInterface;
    pathPrefix?: string;
  }

  const riders_options = [
    { id: RIDERS.ALL, label: "All" },
    { id: RIDERS.NEW, label: "New" },
  ];

  export const NTDReportPage: React.FC<NTDReportPageProps> = ({ pool, pathPrefix }) => {
    interface reportState {
      vanpoolId: string;
      month: number;
      year: number;
      reportId: RIDERSHIP_REPORT;
      riders: RIDERS;
    }

    const makeState = (param: params) => {
      return {
        vanpoolId: param.vanpool,
        month: parseInt(param.month || DateTime.now().month + "") - 1,
        year: parseInt(param.year || DateTime.now().year + ""),
        reportId: (param.reportId as RIDERSHIP_REPORT) || RIDERSHIP_REPORT.NTD,
        riders: (param.riders as RIDERS) || RIDERS.ALL,
      };
    };

    interface VanpoolOption extends Option {
      vanpool: riderVanpoolListInterface;
    }

    const param = useParams<params>();
    const history = useHistory();
    const [css] = useStyletron();
    const [state, setState] = useState<reportState>(makeState(param));
    const [report, setReport] = useState<pool>();
    const [vanpools, setVanpools] = useState<VanpoolOption[]>([
      { id: pool.id, label: pool.name, vanpool: new riderVanpoolListInterface() },
    ]);

    useEffect(() => {
      setState(makeState(param));
    }, [param]);

    const loadVanpools = () => {
      if (vanpools.length === 1) {
        riderInstabookManageList({}).then((v) => {
          const options = v.map((x) => ({ id: x.id, label: x.name, vanpool: x }));
          setVanpools(options);
        });
      }
    };

    const updateState = (up: Partial<reportState>) => {
      // push history state so the select view params are preserved
      const val = { ...state, ...up };
      const url = `${pathPrefix}${routes.user.mypools}/${val.vanpoolId}/ridership/${val.reportId}/${
        val.year
      }/${val.month + 1}/${val.riders}`;
      history.push(url);
    };

    useEffect(() => {
      const start = DateTime.utc()
        .set({ year: state.year, month: state.month + 1, day: 1 })
        .startOf("day");
      const end = start.endOf("month");
      fetchRidershipReport({ vanpoolIds: [state.vanpoolId], startDate: start, endDate: end }).then(
        (report) => {
          if (report.perPool[0]?.poolId === pool.id) {
            setReport(
              createRidershipNTDReport(
                pool,
                report.perPool[0],
                report.users,
                report.customFields,
                start,
                state.riders,
              ),
            );
          } else {
            setReport(undefined);
          }
        },
      );
    }, [state.month, state.reportId, state.vanpoolId, state.year, state.riders]);

    return (
      <>
        <div className="Navbar">
          <div
            className="Navbar-header"
            style={{ margin: "0 0 15px 25px", justifyContent: "unset" }}
          >
            <NavLink to={pathPrefix + `${routes.user.mypools}/${pool.id}`}>
              <div className="Navbar-back"></div>
            </NavLink>
            <div className="Navbar-header-title">Ridership Report</div>
            <div className="Navbar-filler" />
          </div>
        </div>
        <PageContainer>
          <HeaderContainer>
            <Header>
              <SelectContainer>
                <MyPoolLabel>REPORT</MyPoolLabel>
                <MBSelect
                  options={RIDERSHIP_REPORTS}
                  value={[RIDERSHIP_REPORTS.find((x) => x.id === state.reportId)!]}
                  onChange={(evt) => updateState({ reportId: evt.option?.id as RIDERSHIP_REPORT })}
                  placeholder={false}
                  clearable={false}
                  overrides={{
                    Root: {
                      style: {
                        minWidth: "180px",
                      },
                    },
                  }}
                />
              </SelectContainer>
              <SelectContainer>
                <MyPoolLabel>VANPOOL</MyPoolLabel>
                <MBSelect
                  options={vanpools}
                  value={[vanpools.find((x) => x.id === state.vanpoolId) as Option]}
                  onOpen={loadVanpools}
                  onChange={(evt) => updateState({ vanpoolId: evt.option?.id as string })}
                  placeholder={false}
                  clearable={false}
                  overrides={{
                    Root: {
                      style: {
                        minWidth: "220px",
                      },
                    },
                  }}
                />
              </SelectContainer>
              <SelectContainer>
                <MyPoolLabel>YEAR</MyPoolLabel>
                <MBSelect
                  options={YEARS}
                  value={[YEARS.find((x) => x.id === state.year)!]}
                  placeholder="Select Year"
                  onChange={(params) => updateState({ year: params.value[0].id as number })}
                  clearable={false}
                />
              </SelectContainer>
              <SelectContainer>
                <MyPoolLabel>MONTH</MyPoolLabel>
                <MBSelect
                  options={MONTHS}
                  value={[MONTHS[state.month]]}
                  placeholder="Select Month"
                  onChange={(params) => updateState({ month: params.value[0].id as number })}
                  clearable={false}
                  searchable={false}
                  overrides={{
                    Root: {
                      style: {
                        minWidth: "120px",
                      },
                    },
                  }}
                />
              </SelectContainer>
              <SelectContainer>
                <MyPoolLabel>RIDERS</MyPoolLabel>
                <MBSelect
                  options={riders_options}
                  value={[riders_options.find((x) => x.id === state.riders)!]}
                  onChange={(params) => updateState({ riders: params.value[0].id as RIDERS })}
                  clearable={false}
                  searchable={false}
                  overrides={{
                    Root: {
                      style: {
                        minWidth: "100px",
                      },
                    },
                  }}
                />
              </SelectContainer>
              <div
                className={css({
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                  paddingRight: "20px",
                })}
              >
                <MBButton onClick={() => report && exportTablesToExcel(report)}>Export</MBButton>
              </div>
            </Header>
          </HeaderContainer>
          <ReportContainer>{report && <NTDReport pool={report} />}</ReportContainer>
        </PageContainer>
      </>
    );
  };
}
