import React, { createContext, useEffect, useState } from "react";
import "./AssignModal.scss";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "baseui/modal";
import { MBButton } from "components/common/Buttons/Buttons";
import { riderMyPoolDisplayInterface } from "interfaces/vanpool";
import { vehicleInterface } from "interfaces/trip";
import { INSTABOOK_ACTION, INSTABOOK_ROLE } from "interfaces/vanpool";
import { userSimpleInterface } from "interfaces/user";
import { InstabookInterface } from "api/vanpools";
import { RiderCountIcon, SeatsCountIcon } from "assets/img/count-icons";
import CheckmarkIcon from "assets/img/checkmark-icon";
import { Moment } from "moment";

export enum ASSIGN_TYPE {
  DRIVER,
  VEHICLE,
}

const unassignedOption: { [key in ASSIGN_TYPE] } = {
  [ASSIGN_TYPE.DRIVER]: "No Driver",
  [ASSIGN_TYPE.VEHICLE]: "No Vehicle",
};

const headers: { [key in ASSIGN_TYPE] } = {
  [ASSIGN_TYPE.DRIVER]: "Assign driver",
  [ASSIGN_TYPE.VEHICLE]: "Provide vehicle",
};

const assignButtonText: { [key in ASSIGN_TYPE] } = {
  [ASSIGN_TYPE.DRIVER]: "Assign",
  [ASSIGN_TYPE.VEHICLE]: "Provide",
};

const instabookRole: { [key in ASSIGN_TYPE] } = {
  [ASSIGN_TYPE.DRIVER]: INSTABOOK_ROLE.DRIVER,
  [ASSIGN_TYPE.VEHICLE]: INSTABOOK_ROLE.VEHICLE,
};

const itemDisplay: { [key in ASSIGN_TYPE] } = {
  [ASSIGN_TYPE.DRIVER]: (driver: userSimpleInterface) => {
    return <div className="AssignModal DriverItem">{driver.name}</div>;
  },
  [ASSIGN_TYPE.VEHICLE]: (vehicle: vehicleInterface, numRiders: number) => {
    return (
      <div className="AssignModal VehicleItem">
        <div>
          <p>{vehicle.description}</p>
          <p>{vehicle.licensePlate}</p>
        </div>
        <div className="AssignModal SeatCapacity">
          <SeatsCountIcon seatCount={vehicle.capacity} overbooked={vehicle.capacity < numRiders} />
        </div>
      </div>
    );
  },
};

const itemShouldWarn: { [key in ASSIGN_TYPE] } = {
  [ASSIGN_TYPE.DRIVER]: () => {
    return false;
  },
  [ASSIGN_TYPE.VEHICLE]: (vehicle: vehicleInterface, numRiders: number) => {
    return vehicle && numRiders > vehicle.capacity;
  },
};

const itemWarningDisplay: { [key in ASSIGN_TYPE] } = {
  [ASSIGN_TYPE.DRIVER]: () => {
    return <></>;
  },
  [ASSIGN_TYPE.VEHICLE]: (vehicle: vehicleInterface, numRiders: number) => {
    return (
      itemShouldWarn[ASSIGN_TYPE.VEHICLE](vehicle, numRiders) && (
        <>
          <div />
          <div className="AssignModal ItemWarning">
            {numRiders - vehicle.capacity} riders will be waitlisted
          </div>
        </>
      )
    );
  },
};

export interface AssignModalInterface {
  type: ASSIGN_TYPE;
  pool: riderMyPoolDisplayInterface;
  numRiders: number;
  date: Moment;
  assignCallback: (ib: InstabookInterface) => void;
  hideModalCallback?: () => void;
  selectedId?: string;
}

export const AssignModal: React.FC<{ show: boolean } & AssignModalInterface> = ({
  show,
  type,
  pool,
  numRiders,
  date,
  assignCallback,
  hideModalCallback,
  selectedId,
}) => {
  const dataList: { [key in ASSIGN_TYPE] } = {
    [ASSIGN_TYPE.DRIVER]: pool.drivers,
    [ASSIGN_TYPE.VEHICLE]: pool.vehicles,
  };

  const [selectedItem, setSelectedItem] = useState<string | undefined>();

  useEffect(() => {
    if (show) {
      // default to no item if an item is selected and default to first item if no item is currently selected
      setSelectedItem(selectedId ? undefined : dataList[type][0].id);
    }
  }, [show]);

  const itemIsSelected = (item) => {
    return item == selectedItem || item?.id === selectedItem;
  };

  const itemIsWarned = (item) => {
    return itemIsSelected(item) && itemShouldWarn[type](item, numRiders);
  };

  const getItemSelectionIcon = (item) => {
    return itemIsSelected(item) ? (
      <CheckmarkIcon color={item && itemIsWarned(item) ? "#ff0000" : "#0076ff"} />
    ) : (
      <div className="AssignModal UnselectedCircle" />
    );
  };

  const getItemClassName = (item) => {
    let className = "AssignModal Item";
    if (itemIsSelected(item)) {
      className += " Selected";

      if (itemIsWarned(item)) {
        className += " Warn";
      }
    }

    return className;
  };

  const assignItem = () => {
    const itemId = selectedItem || selectedId;
    const action = selectedItem ? INSTABOOK_ACTION.BOOK : INSTABOOK_ACTION.CANCEL;
    const userId = type === ASSIGN_TYPE.DRIVER ? itemId : undefined;
    const vehicleId = type === ASSIGN_TYPE.VEHICLE ? itemId : undefined;

    assignCallback({
      vanpoolId: pool.id,
      action: action,
      role: instabookRole[type],
      dates: [date],
      userId,
      vehicleId,
    });
  };

  return (
    <Modal
      isOpen={show}
      onClose={hideModalCallback}
      autoFocus={false}
      overrides={{
        Dialog: {
          style: () => ({
            textAlign: "center",
          }),
        },
      }}
    >
      <ModalHeader>
        <div className="AssignModal ModalHeader">
          <div className="AssignModal ModalHeader HeaderText">{headers[type]}</div>
          {type == ASSIGN_TYPE.VEHICLE && (
            <div className={"AssignModal ModalHeader RiderCount"}>
              <RiderCountIcon riderCount={numRiders} />
            </div>
          )}
        </div>
      </ModalHeader>
      <ModalBody>
        <div className={getItemClassName(undefined)} onClick={() => setSelectedItem(undefined)}>
          {getItemSelectionIcon(undefined)}
          {unassignedOption[type]}
        </div>
        {dataList[type].map((datum) => {
          return (
            <div
              className={getItemClassName(datum)}
              onClick={() => {
                setSelectedItem(datum.id);
              }}
            >
              {getItemSelectionIcon(datum)}
              {itemDisplay[type](datum, numRiders)}
              {itemIsWarned(datum) && itemWarningDisplay[type](datum, numRiders)}
            </div>
          );
        })}
      </ModalBody>
      <ModalFooter>
        <div className="AssignModal ButtonStack">
          <MBButton
            onClick={() => {
              assignItem();
              hideModalCallback?.();
            }}
          >
            {assignButtonText[type]}
          </MBButton>
          <MBButton kind="tertiary" onClick={hideModalCallback}>
            Cancel
          </MBButton>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export interface AssignModalContextInterface {
  showAssignModal: (params: AssignModalInterface) => void;
}

export const AssignModalContext = createContext<AssignModalContextInterface>(
  {} as AssignModalContextInterface,
);

export const AssignModalContextProvider: React.FC<{
  value?: { [key: string]: any };
}> = ({ children, value }) => {
  const [show, setShow] = useState<boolean>(false);
  const [params, setParams] = useState<AssignModalInterface | undefined>();

  const handleHide = () => {
    setParams(undefined);
    setShow(false);
  };

  const showAssignModal = (newParams: AssignModalInterface) => {
    setParams(newParams);
    setShow(true);
  };

  return (
    <AssignModalContext.Provider
      value={{
        ...value,
        showAssignModal,
      }}
    >
      {params && <AssignModal show={show} {...params} hideModalCallback={handleHide} />}
      {children}
    </AssignModalContext.Provider>
  );
};
