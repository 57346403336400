import { makeStopTime } from "helpers/formatHelpers";
import { serviceResultInterface } from "interfaces/route";
import { myPoolMemberProfileInterface, ORG_USER_ROLE, VANPOOLER_ROLE } from "interfaces/user";
import {
  PermsProps,
  PoolDayStatus,
  riderMyPoolDisplayInterface,
  riderMyPoolInterface,
  riderVanpoolInterface,
  RIDE_STATUS,
  USER_RIDE_STATUS,
  vanpoolDayDisplayInterface,
  riderVanpoolDayInterface,
  _vanpoolDayDisplayInterface,
} from "interfaces/vanpool";
import { DateTime } from "luxon";
import { DAY, dayShortToNum } from "misc/utils";
import moment, { Moment } from "moment";

export enum POOL_ACTION_BUTTON_LABEL {
  RIDE = "Ride",
  RIDING = "Riding",
  DRIVE = "Drive",
  DRIVING = "Driving",
  NOT_DRIVING = "Not Driving",
  DRIVER = "Driver",
  NO_DRIVER = "No Driver",
  ASSIGN_DRIVER = "Assign driver",
  PROVIDE_VEHICLE = "Provide vehicle",
  PROVIDING_VEHICLE = "Providing vehicle",
  REQUESTED = "Requested",
  CANCELED = "Ride canceled",
  WAITLISTED = "Waitlisted",
  NOTRIDING = "Not riding",
  NO_VEHICLE = "No Vehicle",
  MISSED = "Missed",
  CHECKEDIN = "Checked in",
}
export const getMyPoolUserPerms = (profile: myPoolMemberProfileInterface): PermsProps => ({
  canRide: profile.userRoles.includes(VANPOOLER_ROLE.RIDER),
  canDrive: profile.userRoles.includes(VANPOOLER_ROLE.DRIVER),
  canProvideVehicle: profile.userRoles.includes(VANPOOLER_ROLE.COORDINATOR),
  isVPM: [ORG_USER_ROLE.VANPOOL_MANAGER, ORG_USER_ROLE.OWNER, ORG_USER_ROLE.MANAGER].some((role) =>
    profile.orgRoles.includes(role),
  ),
});

// Deprecated: use computeVanpoolDisplay below
export const getMyPoolDayStatus = (vanpoolDay: riderVanpoolDayInterface) => {
  return {
    // user statuses
    isBooked: vanpoolDay.userStatus !== 0,
    isRiding: (vanpoolDay.userStatus & USER_RIDE_STATUS.RIDER) !== 0,
    isDriving: (vanpoolDay.userStatus & USER_RIDE_STATUS.DRIVER) !== 0,
    isWaitlisted: (vanpoolDay.userStatus & USER_RIDE_STATUS.RIDER_WAITLIST) !== 0,
    isAlternateDriver: (vanpoolDay.userStatus & USER_RIDE_STATUS.DRIVER_ALTERNATE) !== 0,
    isMissed: (vanpoolDay.userStatus & USER_RIDE_STATUS.RIDER_MISSED) !== 0,

    // ride statuses
    vehicleNeeded: (vanpoolDay.rideStatus & RIDE_STATUS.NO_VEHICLE) !== 0,
    isOverbooked: vanpoolDay.numRiders > vanpoolDay.capacity,
    noRiders: (vanpoolDay.rideStatus & RIDE_STATUS.NO_RIDERS) !== 0,
    rideConfirmed: vanpoolDay.rideStatus === 0,
    driverNeeded: (vanpoolDay.rideStatus & RIDE_STATUS.NO_DRIVERS) !== 0,
    rideCanceled: (vanpoolDay.rideStatus & RIDE_STATUS.CANCELED) !== 0,
    isPast: vanpoolDay.date.isBefore(moment(), "day"),
    isToday: vanpoolDay.date.isSame(moment(), "day"),
    isInbound: false,
  } as PoolDayStatus;
};

export const getMyPoolUserStatus = (userStatus: number) => {
  return {
    // user statuses
    isBooked: userStatus !== 0,
    isRiding: (userStatus & USER_RIDE_STATUS.RIDER) !== 0,
    isDriving: (userStatus & USER_RIDE_STATUS.DRIVER) !== 0,
    isWaitlisted: (userStatus & USER_RIDE_STATUS.RIDER_WAITLIST) !== 0,
    isAlternateDriver: (userStatus & USER_RIDE_STATUS.DRIVER_ALTERNATE) !== 0,
    isMissed: (userStatus & USER_RIDE_STATUS.RIDER_MISSED) !== 0,
  };
};

export const computeMyPoolDisplay = (pool: riderMyPoolInterface): riderMyPoolDisplayInterface => ({
  ...pool,
  perms: getMyPoolUserPerms(pool.profile),
});

export function computeVanpoolDayDisplay<T extends riderVanpoolDayInterface>(
  vanpoolDay: T,
  pool: riderMyPoolInterface,
): T & _vanpoolDayDisplayInterface {
  var isInbound = false;
  const activeService = getActiveServiceForDate(pool, vanpoolDay.date);
  if (activeService) {
    const outboundDropoffDateTime = makeStopTime(
      vanpoolDay.date.format("YYYY-MM-DD"),
      activeService.outbound.stops[activeService.outbound.stops.length - 1].arrival,
    );

    // if ride is today and it is currently later than last outbound stop then show inbound ride
    isInbound =
      vanpoolDay.date.isSame(moment(), "day") &&
      activeService.inbound?.stops !== undefined &&
      moment().isAfter(outboundDropoffDateTime);
  }

  return {
    ...vanpoolDay,
    service: activeService!,
    pool: computeMyPoolDisplay(pool),
    status: {
      ...getMyPoolDayStatus(vanpoolDay),
      isInbound,
    },
  };
}

export const getActiveServiceForDate = (vanpool: riderVanpoolInterface, date: Moment) => {
  return vanpool.services.find((service) => {
    return (
      moment(service.startDate).isSameOrBefore(date) &&
      (!service.endDate || moment(service.endDate).isSameOrAfter(date))
    );
  });
};

export const isServiceDay = (service: serviceResultInterface, day: DateTime) => {
  const days = service.days.map(dayShortToNum);
  return days && days.includes((day.weekday % 7) as DAY);
};

export const getService = (
  services: serviceResultInterface[],
  serviceId?: string,
  date = moment(),
) => {
  return (
    services.find((s) => s.id === serviceId) ||
    services.find(
      (s) =>
        moment(s.startDate).isSameOrBefore(date) &&
        (!s.endDate || moment(s.endDate).isSameOrAfter(date)),
    ) ||
    services.find((s) => moment(s.startDate).isSameOrAfter(date)) ||
    services[0]
  );
};

export const getWarnings = (
  vanpoolDay: vanpoolDayDisplayInterface,
  profile: myPoolMemberProfileInterface,
) => {
  const viewPerms = getMyPoolUserPerms(profile);
  const statuses = vanpoolDay.status;

  const warnings: string[] = [];
  if (viewPerms.canRide && !viewPerms.canDrive && !viewPerms.canProvideVehicle) {
    if (statuses.isRiding && !statuses.rideConfirmed) {
      // only show "Ride pending" to riders who are riding
      warnings.push("⚠️ Ride pending");
    }
  } else {
    if ((statuses.isRiding || viewPerms.canProvideVehicle) && statuses.driverNeeded) {
      // show driver warning to drivers who are riding and all coordinators
      warnings.push("⚠️ Driver needed");
    }
    if (
      (statuses.isDriving || statuses.isRiding || viewPerms.canProvideVehicle) &&
      statuses.vehicleNeeded
    ) {
      // show vehicle warning to drivers who are riding/driving and all coordinators
      warnings.push("⚠️ Vehicle needed");
    }
    if ((statuses.isDriving || viewPerms.canProvideVehicle) && statuses.noRiders) {
      warnings.push("⚠️ No riders");
    }
  }

  if (statuses.isRiding && statuses.isWaitlisted) {
    warnings.push("🔄 Added to waitlist");
  }

  if (statuses.isPast && statuses.isRiding && statuses.rideConfirmed && statuses.isMissed) {
    warnings.push("⚠️ Ride missed");
  }

  return warnings;
};

export const getCalendarHeader = (firstCalendarDay: Moment, lastCalendarDay: Moment) => {
  const calendarStartMonth = firstCalendarDay.format("MMM");
  const calendarEndMonth = lastCalendarDay.format("MMM YYYY");
  if (firstCalendarDay.month() === lastCalendarDay.month()) {
    return calendarEndMonth;
  }
  return calendarStartMonth + " - " + calendarEndMonth;
};
