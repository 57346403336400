import React from "react";
import { riderVanpoolInterface, riderMyPoolDisplayInterface } from "interfaces/vanpool";
import { NavLink } from "react-router-dom";
import { myPoolMemberProfileInterface, userSimpleInterface } from "interfaces/user";
import { routes } from "misc/http";
import { MyPoolContainer, MyPoolsLayout, MyPoolsLayoutInner } from "./MyPoolsPage/MyPoolsPage";
import { ProfileEditor } from "./components/ProfileEditor";

interface MyPoolProfileProps {
  pool: riderMyPoolDisplayInterface;
  profile: myPoolMemberProfileInterface;
  user: userSimpleInterface;
  updateProfileCallback: (arg: riderVanpoolInterface) => void;
  onLeave?: () => void;
  pathPrefix?: string;
  showAsMember?: boolean;
}

export const MyPoolProfilePage: React.FC<MyPoolProfileProps> = ({
  pool,
  profile,
  user,
  updateProfileCallback,
  onLeave,
  pathPrefix = "",
  showAsMember = true,
}) => {
  return (
    <>
      <div className="Navbar">
        <div className="Navbar-header">
          <NavLink to={pathPrefix + `${routes.user.mypools}/${pool.id}`}>
            <div className="Navbar-back"></div>
          </NavLink>
          <div className="Navbar-header-title">My Settings</div>
          <div className="Navbar-filler" />
        </div>
      </div>
      <MyPoolsLayout>
        <MyPoolsLayoutInner>
          <MyPoolContainer>
            <ProfileEditor
              pool={pool}
              profile={profile}
              profileUser={user}
              onUpdate={(pools) => {
                const updatedPool = pools.find((p) => p.id === pool.id);
                updateProfileCallback({ ...pool, ...updatedPool });
              }}
              onLeave={onLeave}
              pathPrefix={pathPrefix}
            />
          </MyPoolContainer>
        </MyPoolsLayoutInner>
      </MyPoolsLayout>
    </>
  );
};
