import React, { useContext } from "react";
import "./MyPoolRideDetails.scss";
import {
  RIDE_STATUS,
  vanpoolDayDetailsDisplayInterface,
  riderVanpoolDayDetailsInterface,
} from "interfaces/vanpool";
import { POOL_ACTION_BUTTON_LABEL } from "../utils";
import { PoolActionButton } from "../components/PoolActionButton";
import { AssignModalContext, AssignModalInterface, ASSIGN_TYPE } from "../AssignModal/AssignModal";
import { ReactComponent as DriveIcon } from "assets/img/drive-icon.svg";
import { DriverDetails } from "./components/DriverDetails";
import { VehicleDetails } from "./components/VehicleDetails";
import { MyPoolStopList } from "./components/MyPoolStopList";
import { RiderBlock } from "./components/RiderBlock";
import { EditRiders } from "./components/EditRider";
import { CancelRide } from "./components/CancelRide";
import { InstabookInterface } from "api/vanpools";
import { MyPoolRideReceipt } from "../MyPoolRideReceipt/MyPoolRideReceipt";
import { NeedRideHelpModal } from "./components/NeedRideHelpModal";
import { styled } from "baseui";
import { RuleList } from "../components/RuleList";
import { MyPoolsLayoutInner } from "../MyPoolsPage/MyPoolsPage";
import { UncancelRide } from "./components/UncancelRide";

const MyPoolRideDetailsCollapsible = styled("div", ({ $theme }) => ({
  marginTop: "25px",
}));

interface MyPoolRideDetailsProps {
  assignModalSubmit: (params: InstabookInterface) => void;
  refreshData: () => void;
  bookOrCancelDrive: (vanpoolDay: vanpoolDayDetailsDisplayInterface) => void;
  updateRideDay: (day: riderVanpoolDayDetailsInterface) => void;
  returnPath: string;
  rideDay: vanpoolDayDetailsDisplayInterface;
  showAsMember: boolean;
}

export const MyPoolRideDetails: React.FC<MyPoolRideDetailsProps> = ({
  rideDay,
  assignModalSubmit,
  refreshData,
  bookOrCancelDrive,
  updateRideDay,
  showAsMember,
}) => {
  const status = rideDay.status;
  const perms = rideDay.pool.perms;
  const { showAssignModal } = useContext(AssignModalContext);
  const assignParams: AssignModalInterface = {
    type: ASSIGN_TYPE.DRIVER,
    pool: rideDay.pool,
    numRiders: rideDay.numRiders,
    date: rideDay.date,
    assignCallback: assignModalSubmit,
  };

  const RideDetailsContainer = styled("div", ({ $theme }) => ({
    marginLeft: "25px",
    marginRight: "25px",
  }));

  const getDriverDisplay = () => {
    if (perms.canProvideVehicle) {
      return (
        <div
          className={`AssignButton ${status.rideCanceled ? "Canceled" : "Active"}`}
          onClick={() => {
            !status.rideCanceled &&
              showAssignModal({
                ...assignParams,
                type: ASSIGN_TYPE.DRIVER,
                selectedId: rideDay.outbound.driver?.id,
              });
          }}
        >
          {rideDay.outbound.driver ? (
            <DriverDetails driver={rideDay.outbound.driver} />
          ) : (
            "⚠️ Assign Driver"
          )}
        </div>
      );
    } else if (status.isDriving || (!rideDay.outbound.driver && perms.canDrive)) {
      return (
        <PoolActionButton
          label={
            status.isDriving ? POOL_ACTION_BUTTON_LABEL.DRIVING : POOL_ACTION_BUTTON_LABEL.DRIVE
          }
          status={!status.isDriving || status.rideConfirmed ? "ok" : "warn"}
          icon={status.isDriving ? (status.rideConfirmed ? "check" : "warn") : undefined}
          selected={status.isDriving}
          onClick={() => bookOrCancelDrive(rideDay)}
        />
      );
    } else {
      return <DriverDetails driver={rideDay.outbound.driver} />;
    }
  };

  const getVehicleDisplay = () => {
    if (perms.canProvideVehicle) {
      return (
        <div
          className={`AssignButton ${status.rideCanceled ? "Canceled" : "Active"}`}
          onClick={() => {
            !status.rideCanceled &&
              showAssignModal({
                ...assignParams,
                type: ASSIGN_TYPE.VEHICLE,
                selectedId: rideDay.vehicleId,
              });
          }}
        >
          {rideDay.outbound.vehicle ? (
            <VehicleDetails vehicle={rideDay.outbound.vehicle} />
          ) : (
            "⚠️ Assign Vehicle"
          )}
        </div>
      );
    } else {
      return <VehicleDetails vehicle={rideDay.outbound.vehicle} />;
    }
  };

  const getDriverMessage = () => {
    if (status.driverNeeded) {
      return "⚠️ Driver needed";
    } else if (status.isAlternateDriver) {
      return (
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <DriveIcon /> You are an alternate driver
        </div>
      );
    } else {
      return "";
    }
  };

  const Editor = (
    <div className="Container">
      {status.noRiders && (perms.canDrive || perms.canProvideVehicle) && !status.isPast && (
        <div className="Notice">
          <p>⚠️ There are no riders on this day.</p>
        </div>
      )}
      {status.isWaitlisted && !status.isPast && (
        <div className="Notice">
          <p>⚠️ You are currently on the waitlist.</p>
          <br />
          <p>You'll receive an email when a seat becomes available.</p>
        </div>
      )}
      <div className="Details">
        <div
          className={`MyPoolRideDetails DriverVehicleStops Wrapper${
            status.rideCanceled ? " Canceled" : ""
          }`}
        >
          {(((!perms.canDrive || perms.canProvideVehicle) && status.isPast) || !status.isPast) && (
            <div className="MyPoolRideDetails DriverVehicleStops DriverBlock">
              <div className="MyPoolRideDetails DriverVehicleStops HeaderBlock">
                <p className="Label">DRIVER</p>
                {perms.canDrive && !perms.canProvideVehicle && <p>{getDriverMessage()}</p>}
              </div>
              {getDriverDisplay()}
            </div>
          )}
          <div className="MyPoolRideDetails DriverVehicleStops VehicleBlock">
            <div className="MyPoolRideDetails DriverVehicleStops HeaderBlock">
              <p className="Label">VEHICLE</p>
            </div>
            {getVehicleDisplay()}
          </div>
          <MyPoolRideDetailsCollapsible>
            <MyPoolStopList
              pool={rideDay.pool}
              rideDay={rideDay}
              rideType="OUTBOUND"
              defaultExpanded={!status.isInbound || status.isWaitlisted || !status.isRiding}
              updateDataCallback={refreshData}
              showAsMember={showAsMember}
            />
          </MyPoolRideDetailsCollapsible>
          {rideDay.inbound && (
            <MyPoolRideDetailsCollapsible>
              <MyPoolStopList
                pool={rideDay.pool}
                rideDay={rideDay}
                rideType="INBOUND"
                defaultExpanded={status.isInbound || status.isWaitlisted || !status.isRiding}
                updateDataCallback={refreshData}
                showAsMember={showAsMember}
              />
            </MyPoolRideDetailsCollapsible>
          )}
          {rideDay.pool.rules.length > 0 && (
            <MyPoolRideDetailsCollapsible>
              <RuleList rules={rideDay.pool.rules} collapsible />
            </MyPoolRideDetailsCollapsible>
          )}
          {perms.canProvideVehicle &&
            rideDay.numRiders > rideDay.capacity &&
            !status.vehicleNeeded && (
              <div className="MyPoolRideDetails DriverVehicleStops Waitlist">
                <p className="Label">WAITLIST</p>
                <RiderBlock
                  // riders with index > ride capacity are waitlisted as returned by backend
                  riders={rideDay.outbound.riders.slice(rideDay.capacity)}
                />
              </div>
            )}
          {perms.canProvideVehicle && !(rideDay.rideStatus & RIDE_STATUS.CANCELED) && (
            <div className="CoordinatorActions" style={{ paddingBottom: "20px" }}>
              <EditRiders pool={rideDay.pool} day={rideDay} onEdit={refreshData} />
              <CancelRide
                vanpoolId={rideDay.pool.id}
                day={rideDay}
                onCancel={(poolDays) => {
                  updateRideDay(poolDays[0]);
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );

  const showFutureCanceled = status.rideCanceled && !status.isPast;
  const showEditor =
    !showFutureCanceled &&
    (!status.isPast || (perms.canDrive && status.isDriving) || perms.canProvideVehicle);
  const showTopSupport =
    (status.rideConfirmed || (!status.driverNeeded && !status.isPast)) &&
    !(perms.canDrive || perms.canProvideVehicle);
  const showReceipt = !showEditor && status.isRiding && status.rideConfirmed;

  return (
    <MyPoolsLayoutInner>
      <div className="MyPoolRideDetails">
        <RideDetailsContainer>
          {showTopSupport && rideDay.outbound.driver && (
            <NeedRideHelpModal driver={rideDay.outbound.driver} />
          )}

          {showFutureCanceled ? (
            <div className="NoRide">This ride was canceled by the coordinator.</div>
          ) : showEditor ? (
            Editor
          ) : showReceipt ? (
            <MyPoolRideReceipt rideDay={rideDay} />
          ) : (
            <></>
          )}
          {status.rideCanceled && perms.canProvideVehicle && (
            <UncancelRide
              vanpoolId={rideDay.pool.id}
              day={rideDay}
              onUncancel={(poolDays) => {
                updateRideDay(poolDays[0]);
              }}
            />
          )}
        </RideDetailsContainer>
      </div>
    </MyPoolsLayoutInner>
  );
};
