import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalProps } from "baseui/modal";
import { styled, withStyle } from "baseui";
import { InputProps } from "baseui/input";
import { MBInput } from "components/common/Inputs/MBInput";
import { expand } from "inline-style-expand-shorthand";

// Themed title for standard modals
export const ModalTitle = styled("div", ({ $theme }) =>
  expand({
    fontFamily: $theme.typography.HeadingXSmall.fontFamily,
    fontSize: $theme.typography.HeadingXSmall.fontSize,
    fontWeight: $theme.typography.HeadingXSmall.fontWeight,
    lineHeight: $theme.typography.HeadingXSmall.lineHeight,
    color: $theme.colors.primary,
    display: "flex",
    justifyContent: "center",
    margin: "20px auto",
  }),
);

export const ModalSeparator = styled("div", ({ $theme }) => ({
  height: "1px",
  borderTopStyle: "inset",
  borderTopColor: $theme.colors.borderOpaque,
  borderTopWidth: "1px",
  opacity: "0.25",
}));

interface ContentScrollerProps {
  size?: "medium";
  padding?: string;
}

export const ContentScroller: React.FC<ContentScrollerProps> = ({
  children,
  size = "medium",
  padding = "16px",
}) => {
  const heights = {
    medium: "320px",
  };
  return (
    <div
      style={{
        minHeight: heights[size],
        maxHeight: heights[size],
        paddingBottom: padding,
        paddingTop: padding,
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      {children}
    </div>
  );
};

export const SearchField: React.FC<InputProps> = ({ ...props }) => {
  return (
    <MBInput
      clearable={true}
      placeholder="Search..."
      overrides={{
        Root: {
          style: {
            paddingLeft: "3px",
            paddingTop: "3px",
            paddingBottom: "3px",
            border: "none",
            backgroundColor: "white",
          },
        },
      }}
      {...props}
    />
  );
};

export const SimpleModalSection = styled("div", ({ $theme }) =>
  expand({
    padding: "20px 16.5px",
  }),
);

export const ButtonStack = styled("div", ({ $theme }) => ({
  display: "grid",
  rowGap: "12px",
}));

const ModalNoCloseButton: React.FC<ModalProps> = ({ children, ...props }) => (
  <Modal autoFocus={false} overrides={{ Close: { style: () => ({ display: "none" }) } }} {...props}>
    {children}
  </Modal>
);

export const BaseModal = {
  Modal: ModalNoCloseButton,
  Header: withStyle(ModalHeader, () => expand({ margin: 0 })),
  Body: withStyle(ModalBody, () => expand({ margin: 0 })),
  Footer: withStyle(ModalFooter, () => expand({ margin: 0, padding: 0 })),
};
