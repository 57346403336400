import React from "react";
import { NavLink } from "react-router-dom";
import { routes } from "misc/http";
import "./NotFoundPage.scss";

const NotFoundPage: React.FC = () => (
  <div className="NotFoundPage">
    <div className="NotFoundPage-text">
      <div className="NotFoundPage-text status">404 error</div>
      <div className="NotFoundPage-text description"> sorry that page can't be found </div>
    </div>

    <NavLink className="NotFoundPage-actionBtn btn btn-primary" to={routes.main}>
      Take me home
    </NavLink>
  </div>
);

export default NotFoundPage;
