import { getVanpoolDays, InstabookInterface, riderInstabook } from "api/vanpools";
import useMBContext from "context/useMBContext";
import {
  INSTABOOK_ROLE,
  riderMyPoolInterface,
  vanpoolDayDisplayInterface,
  riderVanpoolDayInterface,
} from "interfaces/vanpool";
import moment from "moment";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { computeVanpoolDayDisplay } from "../utils";

interface UseMyPoolDatesProps {
  pool: riderMyPoolInterface;
  initialStartDate: moment.Moment;
}

export const useMyPoolDates = ({ pool, initialStartDate }: UseMyPoolDatesProps) => {
  const [loading, setLoading] = useState(false);
  const [vanpoolDays, setVanpoolDays] = useState<vanpoolDayDisplayInterface[]>([]);
  const [currentMonth, setCurrentMonth] = useState<string>("");
  const { user } = useMBContext();

  const scrollSpacerRef = useRef<HTMLDivElement | null>(null);
  const loadMoreRef = useRef<HTMLDivElement | null>(null);
  const [startDate, setStartDate] = useState<moment.Moment>(initialStartDate.startOf("week"));
  const [endDate, setEndDate] = useState<moment.Moment>(moment().add(4, "weeks").endOf("week"));

  // use a ref so that the IntersectionObserver can read the current value
  const startDateRef = useRef<moment.Moment>(startDate);
  const prevY = useRef(0);

  useEffect(() => {
    setLoading(true);
    getVanpoolDays(pool.id, startDate, endDate).then((poolDays) => {
      const display = poolDays.map((x) => computeVanpoolDayDisplay(x, pool));
      setVanpoolDays((days) => [...display, ...days]);
      setLoading(false);
      setCurrentMonth(startDate.format("MMMM YYYY"));

      startDateRef.current = startDate;
    });
  }, [pool, startDate, endDate]);

  const observer = useRef(
    new IntersectionObserver(
      ([entry]) => {
        const currentY = entry.boundingClientRect.y;
        if (currentY > prevY.current && entry.isIntersecting && !loading) {
          // we're not in a react handler here, so we must batch these manually
          unstable_batchedUpdates(() => {
            setEndDate(startDateRef.current.add(-1, "day"));
            setStartDate(moment(startDateRef.current).add(-1, "week"));
          });
        }
        prevY.current = currentY;
      },
      {
        // note, if we wait for the ref corresponding to this element to be current,
        // the intersection observer doesn't fire...
        root: document.querySelector("[data-scroller]"),
        threshold: 0.9,
        rootMargin: "00px",
      },
    ),
  );

  // observe the div element added at the top
  useEffect(() => {
    const currentElement = loadMoreRef.current;
    const currentObserver = observer.current;

    // check if currentElement exist and sets observer
    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    // unobserve the element when not found
    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [loadMoreRef.current]);

  const instabook = (params: InstabookInterface) => {
    riderInstabook({ ...params, properties: pool.profile.properties }).then((poolDays) => {
      if (
        [INSTABOOK_ROLE.RIDER, INSTABOOK_ROLE.DRIVER].includes(params.role) &&
        params.userId &&
        params.userId !== user?.id
      ) {
        // we can't just setRideDay using the data returned from instabook here because when
        // assigning a driver it will return the vanpoolDay data of the driver, not the current user
        // so we have to requery to get the accurate info
        params.dates.sort();
        getVanpoolDays(pool.id, params.dates[0], params.dates[params.dates.length - 1]).then(
          (requeryDays) => {
            updateVanpoolDays(requeryDays);
          },
        );
      } else {
        updateVanpoolDays(poolDays);
      }
    });
  };

  const updateVanpoolDays = (newDays: riderVanpoolDayInterface[]) => {
    const updateDays = [...vanpoolDays];
    newDays.forEach((poolDay) => {
      const dayIndex = updateDays.findIndex((x) => x.date.isSame(poolDay.date));
      updateDays[dayIndex] = computeVanpoolDayDisplay(poolDay, pool);
    });

    setVanpoolDays([...updateDays]);
  };

  useLayoutEffect(() => {
    scrollSpacerRef.current?.scrollIntoView();
  }, [vanpoolDays.length, scrollSpacerRef]);

  const handleScroll = (e) => {
    setTimeout(() => {
      vanpoolDays.every((vanpoolDay) => {
        const currentId = `#id_${vanpoolDay.date.format("YYYY-MM-DD")}`;
        const target = document.querySelector(currentId);
        const top = target?.getBoundingClientRect().top || 0;
        if (top >= 0 && top <= window.innerHeight) {
          setCurrentMonth(vanpoolDay.date.format("MMMM YYYY"));
          return false;
        }
        return true;
      });
    }, 500);
  };

  return {
    vanpoolDays,
    loading,
    instabook,
    scrollSpacerRef,
    loadMoreRef,
    handleScroll,
    currentMonth,
  };
};
