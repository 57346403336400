import React from "react";
import { styled } from "baseui";
import { userBaseInterface } from "interfaces/user";

const InfoLabel = styled("div", ({ $theme }) => ({
  fontWeight: 400,
  color: $theme.colors.mono800,
}));

interface UserInfoRowProps {
  label: string;
  info: string;
}

const UserInfoRow: React.FC<UserInfoRowProps> = ({ label, info }) => {
  return (
    <div style={{ flexDirection: "row", display: "flex", fontSize: "14px", gap: "8px" }}>
      <InfoLabel>{label}:</InfoLabel> {info}
    </div>
  );
};

interface UserDetailsProps {
  user: userBaseInterface;
}

export const UserDetails: React.FC<UserDetailsProps> = ({ user }) => {
  return (
    <div
      style={{
        flexDirection: "column",
        display: "flex",
        fontSize: "14px",
        gap: "8px",
        marginBottom: "15px",
      }}
    >
      <UserInfoRow label="NAME" info={user.name} />
      <UserInfoRow label="EMAIL" info={user.email} />
      {user.phone && <UserInfoRow label="PHONE" info={user.phone} />}
    </div>
  );
};
