import React, { useEffect, useState } from "react";
import { Moment } from "moment";
import {
  autoAssignDayInterface,
  autoAssignSettingsInterface,
  INSTABOOK_ACTION,
  INSTABOOK_ROLE,
  riderMyPoolDisplayInterface,
  riderMyPoolInterface,
} from "interfaces/vanpool";
import {
  myPoolMemberProfileInterface,
  ORG_USER_ROLE,
  userSimpleInterface,
  VANPOOLER_ROLE,
} from "interfaces/user";
import { STOP_TYPE } from "interfaces/stop";
import { getMyPoolUserPerms } from "../utils";
import { LeavePool } from "./LeavePool";
import { OnChangeParams, Select, Value } from "baseui/select";
import { FormControl } from "baseui/form-control";
import { riderMyPoolsAction } from "api/vanpools";
import { MBButton } from "components/common/Buttons/Buttons";
import useMBContext from "context/useMBContext";
import { ButtonStack } from "components/common/Modals/BaseModal/BaseModal";
import { parseFrom24HourTime } from "misc/utils";
import { toaster } from "baseui/toast";
import { RemoveUser } from "./RemoveUser";
import { AutoAssignSchedule } from "./AutoAssignSchedule";
import { MyPoolLabel } from "../MyPoolsPage/MyPoolsPage";
import { ApplyToTrips } from "./ApplyToTrips";

interface ProfileEditorProps {
  pool: riderMyPoolDisplayInterface;
  profileUser: userSimpleInterface;
  profile: myPoolMemberProfileInterface;
  pathPrefix?: string;
  newUser?: boolean;

  onUpdate?: (pools: riderMyPoolInterface[]) => void;
  onLeave?: () => void;
}

export const ProfileEditor: React.FC<ProfileEditorProps> = ({
  pool,
  profileUser,
  profile: initialProfile,
  pathPrefix = "",
  newUser = false,
  onUpdate,
  onLeave,
}) => {
  const [profile, setProfile] = useState<myPoolMemberProfileInterface>(initialProfile);
  const [updatedRoles, setUpdatedRoles] = useState<Value>([]);
  const [rolesVisited, setRolesVisited] = useState<boolean>(false);
  const [pickupVisited, setPickupVisited] = useState<boolean>(false);
  const [dropoffVisited, setDropoffVisited] = useState<boolean>(false);

  const { user } = useMBContext();

  useEffect(() => {
    setProfile(initialProfile);
    setUpdatedRoles(allRoles.filter((x) => initialProfile.userRoles.includes(x.id)));
  }, [initialProfile]);

  const service =
    pool.services.find((service) => service.id === profile.serviceId) || pool.services[0];

  const initialUserPerms = getMyPoolUserPerms(initialProfile);

  const allRoles = [
    { id: VANPOOLER_ROLE.RIDER, label: "Rider" },
    { id: VANPOOLER_ROLE.DRIVER, label: "Driver" },
    { id: VANPOOLER_ROLE.COORDINATOR, label: "Coordinator" },
  ];

  // determine roles that the target user can be
  const canBeRoles = [VANPOOLER_ROLE.COORDINATOR]
    .concat(initialProfile.orgRoles.includes(ORG_USER_ROLE.RIDER) ? [VANPOOLER_ROLE.RIDER] : [])
    .concat(initialProfile.orgRoles.includes(ORG_USER_ROLE.DRIVER) ? [VANPOOLER_ROLE.DRIVER] : []);

  // determine roles that the auth user can grant or remove
  const canGrantRoles = (
    pool.perms.canDrive || pool.perms.canProvideVehicle || pool.perms.isVPM
      ? [VANPOOLER_ROLE.RIDER]
      : []
  )
    .concat(pool.perms.canProvideVehicle || pool.perms.isVPM ? [VANPOOLER_ROLE.DRIVER] : [])
    .concat(pool.perms.isVPM ? [VANPOOLER_ROLE.COORDINATOR] : []);

  const allowedRoles = allRoles.filter(
    (x) => canBeRoles.includes(x.id) && canGrantRoles.includes(x.id),
  );

  const pickupStops = service.outbound.stops.filter((x) => x.stopType !== STOP_TYPE.DROPOFF);
  const dropoffStops = service.outbound.stops.filter((x) => x.stopType !== STOP_TYPE.PICKUP);
  const pickupOptions = pickupStops.map((x) => ({
    id: x.place.id,
    label: x.place.placeName + " @ " + parseFrom24HourTime(x.arrival),
  }));
  const dropoffOptions = dropoffStops.map((x) => ({
    id: x.place.id,
    label: x.place.placeName + " @ " + parseFrom24HourTime(x.arrival),
  }));

  const setPickup = (value: Value) => {
    setProfile((prof) => {
      const pickup = service.outbound.stops.findIndex((x) => x.place.id === value[0].id) || 0;
      const dropoff = Math.max(
        prof.properties?.dropoff || service.outbound.stops.length - 1,
        Math.min(pickup + 1, service.outbound.stops.length - 1),
      );
      return {
        ...prof,
        properties: {
          pickup: pickup,
          dropoff: dropoff,
        },
      };
    });
  };

  const setDropoff = (value: Value) => {
    setProfile((prof) => {
      const dropoff =
        service.outbound.stops.findIndex((x) => x.place.id === value[0].id) ||
        service.outbound.stops.length - 1;
      const pickup = Math.min(prof.properties?.pickup || 0, Math.max(dropoff - 1, 0));
      return {
        ...prof,
        properties: {
          pickup: pickup,
          dropoff: dropoff,
        },
      };
    });
  };

  const autoAssignRideOrDrive = (
    day: string,
    autoAssignDay: autoAssignDayInterface | undefined,
    type: VANPOOLER_ROLE.RIDER | VANPOOLER_ROLE.DRIVER,
    dateNextApply: Moment,
  ) => {
    const newAutoAssignDay = {
      role: type === VANPOOLER_ROLE.RIDER ? INSTABOOK_ROLE.RIDER : INSTABOOK_ROLE.DRIVER,
      day: day,
    };

    const existingAutoAssign: autoAssignSettingsInterface = profile.autoAssign
      ? profile.autoAssign
      : { items: [], dateNextApply: dateNextApply };

    if (autoAssignDay !== undefined) {
      const existingAutoAssignDayIndex = existingAutoAssign.items.findIndex(
        (autoAssignDay) => autoAssignDay.day === day,
      );

      if (autoAssignDay.role === newAutoAssignDay.role) {
        // un-selecting auto assign day
        existingAutoAssign.items.splice(existingAutoAssignDayIndex, 1);
      } else {
        // swapping roles from ride to drive or vice versa
        existingAutoAssign.items[existingAutoAssignDayIndex] = newAutoAssignDay;
      }
      setProfile({
        ...profile,
        autoAssign: { ...existingAutoAssign, dateNextApply: dateNextApply },
      });
    } else {
      setProfile({
        ...profile,
        autoAssign: {
          items: [...existingAutoAssign.items, newAutoAssignDay],
          dateNextApply: dateNextApply,
        },
      });
    }
  };

  const showAllErrors = () => {
    setRolesVisited(true);
    if (!profile.userRoles.includes(VANPOOLER_ROLE.RIDER)) return;
    setDropoffVisited(true);
    setPickupVisited(true);
  };

  const handleSave = (applyToTrips = false) => {
    if (
      (profile.userRoles.includes(VANPOOLER_ROLE.RIDER) && !profile.properties) ||
      updatedRoles.length === 0
    ) {
      showAllErrors();
      return;
    }

    const newRoles = profile.userRoles.filter((x) => !initialProfile.userRoles.includes(x));
    const removedRoles = initialProfile.userRoles.filter(
      (x) => !profile.userRoles.some((y) => x === y),
    );

    const newAutoAssign: autoAssignSettingsInterface | undefined = profile.autoAssign
      ? profile.autoAssign
      : undefined;

    newAutoAssign &&
      removedRoles.forEach((role, index) => {
        newAutoAssign.items = newAutoAssign.items.filter(
          (day) => day.role.valueOf() !== role.valueOf(),
        );
      });

    riderMyPoolsAction({
      vanpoolId: pool.id,
      userId: profileUser.id,
      action: INSTABOOK_ACTION.BOOK,
      properties: profile.userRoles.includes(VANPOOLER_ROLE.RIDER) ? profile.properties : undefined,
      serviceId: service.id,
      rolesAdd: newRoles,
      rolesRemove: removedRoles,
      autoAssign: newAutoAssign,
      applyToTrips,
    }).then((pools) => {
      toaster.show(
        newUser ? `${profileUser.name} has been added.` : "Your profile has been updated.",
        {
          autoHideDuration: 4500,
        },
      );

      onUpdate?.(pools);
    });
  };

  const handleRoleEdit = (params: OnChangeParams) => {
    // don't allow user to remove roles that they can't grant
    if (
      params.type === "remove" &&
      params.option?.id !== undefined &&
      !canGrantRoles.includes(VANPOOLER_ROLE[params.option.id])
    ) {
      toaster.negative("You do not have the permissions to remove that role.", {
        autoHideDuration: 4500,
      });
      return;
    }

    setUpdatedRoles(params.value);
    setProfile((prof) => {
      return { ...prof, userRoles: params.value.map((role) => role.id as VANPOOLER_ROLE) };
    });
  };

  return (
    <>
      <FormControl
        label="Roles"
        error={updatedRoles.length === 0 && rolesVisited ? "Must select roles" : ""}
      >
        <Select
          options={allowedRoles}
          value={updatedRoles}
          onChange={handleRoleEdit}
          clearable={false}
          multi
          disabled={!allowedRoles.length}
          onBlur={() => setRolesVisited(true)}
        />
      </FormControl>
      {profile.userRoles.includes(VANPOOLER_ROLE.RIDER) && (
        <>
          <FormControl
            label="Pickup"
            error={!profile.properties && pickupVisited ? "Must select stops" : ""}
          >
            <Select
              options={pickupOptions}
              value={pickupOptions.filter((x) => {
                if (!profile.properties) return;
                return x.id === service.outbound.stops[profile.properties?.pickup].place.id;
              })}
              onChange={(params) => {
                setPickup(params.value);
              }}
              clearable={false}
              onBlur={() => setPickupVisited(true)}
            />
          </FormControl>
          <FormControl
            label="Dropoff"
            error={!profile.properties && dropoffVisited ? "Must select stops" : ""}
          >
            <Select
              options={dropoffOptions}
              value={dropoffOptions.filter((x) => {
                if (!profile.properties) return;
                return x.id === service.outbound.stops[profile.properties.dropoff].place.id;
              })}
              onChange={(params) => {
                setDropoff(params.value);
              }}
              clearable={false}
              onBlur={() => setDropoffVisited(true)}
            />
          </FormControl>
        </>
      )}
      {(profile.userRoles.includes(VANPOOLER_ROLE.RIDER) ||
        profile.userRoles.includes(VANPOOLER_ROLE.DRIVER)) && (
        <div style={{ marginBottom: "20px" }}>
          <MyPoolLabel style={{ marginTop: "25px", marginBottom: "10px" }}>
            AUTOMATIC RIDE SCHEDULE
          </MyPoolLabel>
          <p style={{ fontSize: "14px", margin: "20px 0px" }}>
            Automatically book trips for me each week:
          </p>
          <div
            style={{
              maxWidth: "600px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <AutoAssignSchedule
              days={service.days}
              autoAssignSettings={profile.autoAssign}
              types={profile.userRoles.filter(
                (role) => role === VANPOOLER_ROLE.RIDER || role === VANPOOLER_ROLE.DRIVER,
              )}
              autoAssignRideCallback={(day, autoAssignDay, dateNextApply) =>
                autoAssignRideOrDrive(day, autoAssignDay, VANPOOLER_ROLE.RIDER, dateNextApply)
              }
              autoAssignDriveCallback={(day, autoAssignDay, dateNextApply) =>
                autoAssignRideOrDrive(day, autoAssignDay, VANPOOLER_ROLE.DRIVER, dateNextApply)
              }
              updateDateCallback={(date) => {
                setProfile({
                  ...profile,
                  autoAssign: {
                    items: profile.autoAssign ? profile.autoAssign.items : [],
                    dateNextApply: date,
                  },
                });
              }}
            />
          </div>
        </div>
      )}
      <ButtonStack>
        <MBButton kind="primary" onClick={() => handleSave()}>
          {newUser ? "Add user" : "Save settings"}
        </MBButton>
        {user?.id === profileUser.id ? (
          <LeavePool pool={pool} pathPrefix={pathPrefix} onLeave={onLeave} />
        ) : (pool.perms.canProvideVehicle || initialUserPerms.isVPM) && !newUser ? (
          <>
            <RemoveUser
              pool={pool}
              user={profileUser}
              pathPrefix={pathPrefix}
              removeUserCallback={() => {
                const memberIndex = pool.members.findIndex(
                  (member) => member.id === profileUser.id,
                );
                pool.members.splice(memberIndex, 1);
                onUpdate?.([pool]);
              }}
            />
            <div style={{ height: "30px" }}></div>
            <ApplyToTrips onConfirm={() => handleSave(true)} service={service} />
          </>
        ) : (
          <></>
        )}
      </ButtonStack>
    </>
  );
};
