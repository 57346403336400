import { InstabookInterface } from "api/vanpools";
import { ConfirmModalContext } from "components/common/ConfirmModal";
import { AssignModalContext, ASSIGN_TYPE } from "components/MyPools/AssignModal/AssignModal";
import { params } from "interfaces/params";
import {
  INSTABOOK_ACTION,
  INSTABOOK_ROLE,
  vanpoolDayDisplayInterface,
  riderVanpoolDayInterface,
} from "interfaces/vanpool";
import { routes } from "misc/http";
import { useContext } from "react";
import { useHistory, useParams } from "react-router-dom";

interface UseDateRowProps {
  instabookCallback: (ib: InstabookInterface) => void;
  pathPrefix: string;
}

export const useDateList = ({ instabookCallback, pathPrefix }: UseDateRowProps) => {
  const { vanpool } = useParams<params>();
  const history = useHistory();
  const { showConfirmModal } = useContext(ConfirmModalContext);
  const { showAssignModal } = useContext(AssignModalContext);

  const bookOrCancelRide = (day: vanpoolDayDisplayInterface) => {
    const role = INSTABOOK_ROLE.RIDER;
    const alreadyRiding = day.status.isRiding;
    const action = alreadyRiding ? INSTABOOK_ACTION.CANCEL : INSTABOOK_ACTION.BOOK;

    const instabookInput = {
      vanpoolId: day.pool.id,
      action,
      role,
      dates: [day.date],
    };

    if (alreadyRiding || day.status.isDriving) {
      const message = alreadyRiding
        ? "Cancel your ride on this day?"
        : "Switch to riding instead of driving?";

      showConfirmModal({
        message,
        date: day.date,
        onConfirmCallback: () => instabookCallback(instabookInput),
      });
    } else {
      instabookCallback(instabookInput);
    }
  };

  const bookOrCancelDrive = (day: vanpoolDayDisplayInterface) => {
    const role = INSTABOOK_ROLE.DRIVER;
    const isRiding = day.status.isRiding;
    const action = day.status.isDriving ? INSTABOOK_ACTION.CANCEL : INSTABOOK_ACTION.BOOK;

    const instabookInput = {
      vanpoolId: day.pool.id,
      action,
      role,
      dates: [day.date],
    };

    if (isRiding || day.status.isDriving) {
      const message = day.status.isDriving
        ? "Cancel driving on this day?"
        : "Switch to driving instead of riding?";

      showConfirmModal({
        message,
        date: day.date,
        onConfirmCallback: () => instabookCallback(instabookInput),
      });
    } else {
      instabookCallback(instabookInput);
    }
  };

  const provideOrRemoveVehicle = (rideDay: vanpoolDayDisplayInterface) => {
    const selected = rideDay.pool.vehicles.find((vehicle) => {
      return vehicle.id === rideDay.vehicleId;
    });
    showAssignModal({
      pool: rideDay.pool,
      date: rideDay.date,
      numRiders: rideDay.numRiders,
      type: ASSIGN_TYPE.VEHICLE,
      selectedId: selected?.id,
      assignCallback: instabookCallback,
    });
  };

  const assignOrRemoveDriver = (rideDay: vanpoolDayDisplayInterface) => {
    const selected = rideDay.pool.drivers.find((driver) => {
      return driver.id === rideDay.driverId;
    });
    showAssignModal({
      pool: rideDay.pool,
      date: rideDay.date,
      numRiders: rideDay.numRiders,
      type: ASSIGN_TYPE.DRIVER,
      selectedId: selected?.id,
      assignCallback: instabookCallback,
    });
  };

  const redirectToRideDetails = (vanpoolDay: riderVanpoolDayInterface) => {
    history.push(
      pathPrefix +
        `${routes.user.mypools}/${vanpool}/details/${vanpoolDay.date.format(
          "YYYY-MM-DD",
        )}?source=dates`,
    );
  };

  return {
    bookOrCancelRide,
    bookOrCancelDrive,
    provideOrRemoveVehicle,
    assignOrRemoveDriver,
    redirectToRideDetails,
  };
};
