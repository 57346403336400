import { styled } from "baseui";
import Loader from "components/common/Loader";
import { MAP_BOUNDS } from "components/Map";
import { VanpoolInfoCard } from "components/Onboarding/components/VanpoolInfoCard/VanpoolInfoCard";
import useMBContext from "context/useMBContext";
import { routeDisplayInterface } from "interfaces/route";
import { routes } from "misc/http";
import React, { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { expand } from "inline-style-expand-shorthand";

const PoolListContainer = styled("div", ({ $theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "15px",
}));

const SuggestARouteContainer = styled("div", ({ $theme }) => ({
  display: "flex",
  gap: "5px",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "24px",
  border: `solid 1px ${$theme.colors.borderOpaque}`,
  borderRadius: "8px",
  marginBottom: "15px",
}));

const SearchResults = styled("div", ({ $theme }) => ({
  ...expand({
    padding: "0.5em",
  }),
  textAlign: "center",
}));

const FoundNearby = styled("div", ({ $theme }) => ({
  fontSize: "0.8rem",
  color: $theme.colors.mono800,
  marginBottom: "1.5rem",
}));

export const OnboardingSearchResultsPage: React.FC = ({}) => {
  const {
    findDynamicRoutes,
    allRoutes,
    userOrigin,
    setUserOrigin,
    userDestination,
    setSuggestedLine,
    setMapDisplayOptions,
    createWalklines,
    selectedRouteID,
    setSelectedRouteID,
    setHighlightedRoute,
    setAllRoutes,
    setDisplayRoutes,
  } = useMBContext();
  const history = useHistory();

  // TODO: add loader
  const [loading, setLoading] = useState<boolean>(true);
  const [expandedRoute, setExpandedRoute] = useState<routeDisplayInterface>();

  useEffect(() => {
    // find relevant pools using origin and destination user selected on SelectAddressPage
    if (!userOrigin) {
      history.push(routes.onboarding.selectAddress.home);
      return;
    } else if (!userDestination) {
      history.push(routes.onboarding.selectAddress.work);
      return;
    }

    findDynamicRoutes(5, userOrigin.coordinates, userDestination.coordinates).then((routes) => {
      if (routes.length > 0) {
        // select first route by default
        setExpandedRoute(routes[0]);
        setSelectedRouteID(routes[0].id);
      }
      setSuggestedLine([]);
      setLoading(false);
    });
    setMapDisplayOptions({
      bounds: MAP_BOUNDS.ROUTES | MAP_BOUNDS.USER_ORIG_DEST,
    });
  }, []);

  useEffect(() => {
    if (!expandedRoute) {
      setSuggestedLine([]);
      return;
    } else {
      createWalklines(
        expandedRoute.stops[expandedRoute.outboundPickup].place.coordinates,
        expandedRoute.stops[expandedRoute.outboundDropoff].place.coordinates,
      ).then((lines) => {
        setSuggestedLine(lines);
      });
    }
  }, [expandedRoute]);

  useEffect(() => {
    setHighlightedRoute(selectedRouteID);
  }, [selectedRouteID]);

  const goBack = () => {
    setExpandedRoute(undefined);
    setSelectedRouteID(null);
    setAllRoutes([]);
    setDisplayRoutes([]);
    setSuggestedLine([]);
    setUserOrigin(undefined);
    history.push(routes.onboarding.selectAddress.home);
  };

  const viewDetails = (route: routeDisplayInterface) => {
    const nextPage =
      route.stops.length > 2
        ? routes.onboarding.poolDetails.selectStops.pickup + `/${route.id}`
        : routes.onboarding.poolDetails.summary +
          `/${route.id}` +
          `?color=${route.colorHexCode?.replace("#", "")}`;
    setDisplayRoutes([route]);
    history.push(nextPage);
  };

  return (
    <>
      <div className="Navbar">
        <div className="Navbar-header">
          <div className="Navbar-back" onClick={goBack} />
          <div className="Navbar-header-title">Select Vanpool</div>
          <div className="Navbar-filler" />
        </div>
      </div>
      <div className="Interface">
        <SearchResults>
          {loading ? (
            <Loader />
          ) : (
            <>
              <FoundNearby>
                {`${allRoutes.length} vanpool${allRoutes.length !== 1 ? "s" : ""} found nearby`}
              </FoundNearby>
              <PoolListContainer>
                {allRoutes.map((route, index) => {
                  return (
                    <VanpoolInfoCard
                      route={route}
                      expanded={expandedRoute?.id === route.id}
                      selectCallback={() => {
                        if (expandedRoute?.id === route.id) {
                          setExpandedRoute(undefined);
                          setSelectedRouteID(null);
                        } else {
                          setExpandedRoute(route);
                          setSelectedRouteID(route.id);
                        }
                      }}
                      viewDetailsCallback={() => {
                        viewDetails(route);
                      }}
                      color={route.colorHexCode}
                    />
                  );
                })}
                <SuggestARouteContainer>
                  No vanpools near you?
                  <NavLink to={routes.onboarding.suggest}>+ Suggest a new vanpool</NavLink>
                </SuggestARouteContainer>
              </PoolListContainer>
            </>
          )}
        </SearchResults>
      </div>
    </>
  );
};
