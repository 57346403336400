import { createTheme, lightThemePrimitives } from "baseui";
import { Borders, Typography } from "baseui/theme";

export const theme = {
  orgId: 0,
  mapboxStyle: "mapbox://styles/magicbus/cle38orav001f01p559os5nw1",
  defaultLocation: {
    lat: 34.0522,
    lng: -118.2437,
  },
  primaryColor: "#397cf1",
  primaryRGB: "57, 124, 241",
};

// Base Web theme

const primitives = {
  ...lightThemePrimitives,
  primary: "#0076ff",
  primary300: "#c2deff",
  primary700: "#0086ff",
  primary600: "#032539",
  mono200: "#f8f8fa",
  mono700: "#7e8183",
  mono800: "#4a4a4a",
  negative: "#ff0000",
  warning: "#ff6000",
  warning400: "#ffd6bd",
};

export const colorBlindFriendlyPalette = {
  blue: primitives.primary,
  rose: "#F2558D",
  aqua: "#0BCFB8",
  coral: "#FB8B48",
  bay: "#0A4DB2",
  mango: "#F6BB49",
  chill: "#09839D",
  royal: "#655BDC",
  citrus: "#97CC02",
  basil: "#B486EE",
  jade: "#05B862",
};

const overrides = {
  colors: {
    buttonPrimaryFill: primitives.primary,
    buttonPrimaryHover: primitives.primary,
    buttonPrimaryText: primitives.mono100,

    buttonSecondaryFill: "transparent",
    buttonSecondaryText: primitives.primary,

    buttonTertiaryFill: "transparent",
    buttonTertiaryText: primitives.mono800,

    tickFillActive: primitives.primary,
    tickFillSelected: primitives.primary,
    tickFillSelectedHover: primitives.primary700,
    tickFillSelectedHoverActive: primitives.primary,
    tickBorder: primitives.mono500,

    toastInfoBackground: primitives.primary600,

    borderOpaque: "#c9d1d7",
  },
  borders: {
    useRoundedCorners: true,
    inputBorderRadius: "4px",
    buttonBorderRadius: "8px",
    border100: {
      borderColor: "#c9d1d7",
      borderStyle: "solid",
      borderWidth: "1px",
    },
  } as Borders,
  typography: {
    ParagraphMedium: {
      fontFamily: "Roboto, sans-serif",
      fontSize: "14px",
      fontWeight: "normal",
      lineHeight: "normal",
    },
    LabelMedium: {
      fontFamily: "Roboto, sans-serif",
      fontSize: "15px",
      fontWeight: 500,
      lineHeight: "normal",
    },
    HeadingXSmall: {
      fontFamily: "Roboto, sans-serif",
      fontSize: "19px",
      fontWeight: "normal",
      lineHeight: "normal",
    },
  } as Typography,
};

export const MBTheme = createTheme(primitives, overrides);
