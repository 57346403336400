import React, { useEffect, useState } from "react";
import "./MyPoolDetails.scss";
import moment, { Moment } from "moment";
import { vanpoolDayDisplayInterface, riderVanpoolDayDetailsInterface } from "interfaces/vanpool";
import { useParams, useHistory, NavLink } from "react-router-dom";
import { params } from "interfaces/params";
import { myPoolMemberProfileInterface } from "interfaces/user";
import { getVanpoolDayDetails, getVanpoolDays } from "api/vanpools";
import TripStops from "components/common/TripStops/TripStops";
import { MBButton } from "components/common/Buttons/Buttons";
import { routes } from "misc/http";
import { serviceResultInterface } from "interfaces/route";
import { riderMyPoolInterface } from "interfaces/vanpool";
import { stopInterface } from "interfaces/stop";
import {
  computeVanpoolDayDisplay,
  getActiveServiceForDate,
  getCalendarHeader,
  getMyPoolDayStatus,
  getMyPoolUserPerms,
} from "../utils";
import { makeStopTime } from "helpers/formatHelpers";
import { tripRiderInterface } from "interfaces/trip";
import CheckmarkIcon from "assets/img/checkmark-icon";
import useMBContext from "context/useMBContext";
import { CalendarCell } from "../components/CalendarCell";
import { MyPoolsLayout, MyPoolsLayoutInner } from "../MyPoolsPage/MyPoolsPage";
import { styled } from "baseui";
import { ButtonStack } from "components/common/Modals/BaseModal/BaseModal";

interface CalendarInterface {
  firstDay: Moment;
  lastDay: Moment;
  vanpoolDays: vanpoolDayDisplayInterface[];
  profile: myPoolMemberProfileInterface;
  pathPrefix?: string;
}

const Calendar: React.FC<CalendarInterface> = ({
  vanpoolDays,
  firstDay,
  lastDay,
  profile,
  pathPrefix = "",
}) => {
  const { vanpool } = useParams<params>();
  const history = useHistory();
  const today = moment();

  const viewRideDetails = (date: Moment) => {
    history.push(
      pathPrefix + `${routes.user.mypools}/${vanpool}/details/${date.format("YYYY-MM-DD")}`,
    );
  };

  const renderDays = () => {
    const dayCells: JSX.Element[] = [];
    const day = moment(firstDay);
    while (day.isSameOrBefore(lastDay)) {
      const vanpoolDay = getVanpoolDayForDate(day);
      const dayCopy = moment(day);
      const viewAction =
        vanpoolDay && !vanpoolDay.status.rideCanceled ? () => viewRideDetails(dayCopy) : undefined;

      dayCells.push(
        <CalendarCell
          key={dayCopy.unix()}
          date={dayCopy}
          poolDay={vanpoolDay}
          profile={profile}
          onClick={viewAction}
        />,
      );
      day.add(1, "days");
    }

    return dayCells;
  };

  const getVanpoolDayForDate = (date: Moment) => {
    return vanpoolDays.find((vanpoolDay) => {
      return vanpoolDay.date.isSame(date, "date");
    });
  };

  return (
    <div className="Calendar">
      <div className="Weekdays">
        {moment.weekdaysShort().map((day, index) => {
          let className = "WeekdayLabel";
          return (
            <div className={className} key={day}>
              {day}
            </div>
          );
        })}
        {renderDays()}
      </div>
    </div>
  );
};

interface NextTripDetailsInterface {
  rideDate: Moment;
  pickupStop: stopInterface;
  dropoffStop: stopInterface;
  isDriving: boolean;
}

export const NextTripDetails: React.FC<NextTripDetailsInterface> = ({
  rideDate,
  pickupStop,
  dropoffStop,
  isDriving = false,
}) => {
  const { vanpool } = useParams<params>();
  const history = useHistory();

  const viewRideDetails = () => {
    history.push(`${routes.user.mypools}/${vanpool}/details/${rideDate?.format("YYYY-MM-DD")}`);
  };

  return (
    <div className="NextTrip">
      <div>
        <div className="NextRide">NEXT RIDE</div>
        <div className="NextTrip Date">{rideDate.format("ddd, MMM D")}</div>
        {isDriving && (
          <div className="NextTrip Driving">
            <CheckmarkIcon color="white" /> You are driving
          </div>
        )}

        <div className="NextTrip Stops">
          <TripStops pickupStop={pickupStop} dropoffStop={dropoffStop} />
        </div>
        <div className="NextTrip ViewDetails">
          <div className="ButtonContainer">
            <MBButton kind="primary" flex onClick={viewRideDetails}>
              View ride details
            </MBButton>
          </div>
        </div>
      </div>
    </div>
  );
};

const FirstRideContainer = styled("div", ({ $theme }) => ({
  padding: "20px",
  marginTop: "-32px",
  color: $theme.colors.mono800,
}));

const Checkmark = styled("div", ({ $theme }) => ({
  fontSize: "35px",
  marginBottom: "5px",
}));

const ReadyToRide = styled("div", ({ $theme }) => ({
  fontSize: "20px",
  marginBottom: "2px",
}));

const RideInstructions = styled("div", ({ $theme }) => ({
  fontSize: "14px",
  marginBottom: "15px",
}));

interface MyPoolDetailsProps {
  pool: riderMyPoolInterface;
  pathPrefix?: string;
  showAsMember?: boolean;
}

export const MyPoolDetails: React.FC<MyPoolDetailsProps> = ({
  pool,
  pathPrefix = "",
  showAsMember = true,
}) => {
  const { user } = useMBContext();
  const params = new URLSearchParams(window.location.search);
  const startDate = moment(params.get("date") || moment());
  const now = moment();
  const activeService = getActiveServiceForDate(pool, now);

  const [vanpoolDays, setVanpoolDays] = useState<vanpoolDayDisplayInterface[]>([]);
  const [nextPoolDay, setNextPoolDay] = useState<vanpoolDayDisplayInterface>();
  const [nextTrip, setNextTrip] = useState<Record<string, any>>();
  const [loading, setLoading] = useState<boolean>(true);
  const [firstCalendarDay, setFirstCalendarDay] = useState<Moment>(
    moment(startDate).startOf("week").startOf("day"),
  );
  const [lastCalendarDay, setLastCalendarDay] = useState<Moment>(
    moment(startDate).add(4, "weeks").endOf("week").startOf("day"),
  );
  const [earliestLoadedDate, setEarliestLoadedDate] = useState<Moment>(firstCalendarDay);
  const [latestLoadedDate, setLatestLoadedDate] = useState<Moment>(lastCalendarDay);
  const history = useHistory();
  const firstTimeRider = params.get("welcome");

  // we create rides 4 weeks in advance. use this date to keep track of the
  // latest calendar page that the user should be able to navigate to
  const lastRideDate = moment().add(4, "weeks").endOf("week").startOf("day");

  // user perms
  const userPerms = getMyPoolUserPerms(pool.profile);

  useEffect(() => {
    if (
      vanpoolDays.length &&
      earliestLoadedDate.isSameOrBefore(firstCalendarDay) &&
      latestLoadedDate.isSameOrAfter(lastCalendarDay)
    ) {
      // if we've already loaded vanpoolDays in this date range,
      // don't load them again
      return;
    }

    getVanpoolDays(pool.id, firstCalendarDay, lastCalendarDay).then((poolDays) => {
      const display = poolDays
        .map((d) => computeVanpoolDayDisplay(d, pool))
        .sort((a, b) => a.date.valueOf() - b.date.valueOf());
      setVanpoolDays([...vanpoolDays, ...display]);

      // keep track of the date range of vanpoolDays that we have loaded
      if (firstCalendarDay.isBefore(earliestLoadedDate)) {
        setEarliestLoadedDate(firstCalendarDay);
      }
      if (lastCalendarDay.isAfter(latestLoadedDate)) {
        setLatestLoadedDate(lastCalendarDay);
      }
    });
  }, [firstCalendarDay, lastCalendarDay]);

  useEffect(() => {
    if (!activeService) {
      setLoading(false);
      return;
    }

    setLoading(true);
    getVanpoolDayDetails({ vanpoolId: pool.id, nextDate: true })
      .then((poolDays) => {
        if (poolDays.length === 0) return;
        const nextRide = poolDays[0];

        setNextPoolDay(computeVanpoolDayDisplay(nextRide, pool));

        const outboundDropoffTime = makeStopTime(
          now.format("YYYY-MM-DD"),
          activeService.outbound.stops[activeService.outbound.stops.length - 1].arrival,
        );
        const inboundDropoffTime = makeStopTime(
          now.format("YYYY-MM-DD"),
          activeService.inbound?.stops[activeService.inbound?.stops.length - 1].arrival || "00:00",
        );

        if (
          now.isSame(nextRide.date, "day") &&
          now.isAfter(outboundDropoffTime) &&
          now.isBefore(inboundDropoffTime)
        ) {
          // next trip is today's inbound trip
          getPickupDropoffStops(nextRide, true);
        } else {
          // next trip is an outbound trip
          getPickupDropoffStops(nextRide, false);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [activeService]);

  const getPickupDropoffStops = (
    poolDay: riderVanpoolDayDetailsInterface,
    isInbound: boolean = false,
  ) => {
    // set the next trip to be the user's pickup and dropoff stops
    if (!activeService || !user) {
      return;
    }

    const date = poolDay.date;
    if (getMyPoolDayStatus(poolDay).isDriving) {
      // if user is driving then next trip is first and last stop
      if (isInbound && activeService.inbound) {
        setNextTrip({
          rideDate: date,
          pickupStop: activeService.inbound.stops[0],
          dropoffStop: activeService.inbound.stops[activeService.inbound.stops.length - 1],
        });
      } else {
        setNextTrip({
          rideDate: date,
          pickupStop: activeService.outbound.stops[0],
          dropoffStop: activeService.outbound.stops[activeService.outbound.stops.length - 1],
        });
      }
    } else {
      if (isInbound && activeService.inbound && poolDay.inbound) {
        setNextTrip({
          rideDate: date,
          ...getRiderStops(user.id, poolDay.inbound.riders, activeService.inbound.stops),
        });
      } else {
        setNextTrip({
          rideDate: date,
          ...getRiderStops(user.id, poolDay.outbound.riders, activeService.outbound.stops),
        });
      }
    }
  };

  const getRiderStops = (riderId: string, riders: tripRiderInterface[], stops: stopInterface[]) => {
    const riderTrip = riders.find((rider) => {
      return rider.rider.id === riderId;
    });
    if (riderTrip) {
      return {
        pickupStop: stops[riderTrip.trip.properties.pickup],
        dropoffStop: stops[riderTrip.trip.properties.dropoff],
      };
    }
  };

  const handleManageSchedule = () => {
    history.push(
      pathPrefix +
        `${routes.user.mypools}/${pool.id}/dates?date=${firstCalendarDay.format("YYYY-MM-DD")}`,
    );
  };

  const handleManagePool = () => {
    history.push(pathPrefix + `${routes.user.mypools}/${pool.id}/manage`);
  };

  const handleViewRidershipReport = () => {
    history.push(pathPrefix + `${routes.user.mypools}/${pool.id}/ridership`);
  };

  const getPoolName = () => {
    // return the shortName of the active service or the latest short name if there is no active service
    const activeServiceName = getActiveServiceForDate(pool, moment())?.name;

    return activeServiceName ? activeServiceName : pool.services[pool.services.length - 1].name;
  };

  const updateCalendarDays = (startDate: Moment) => {
    setFirstCalendarDay(startDate);
    setLastCalendarDay(moment(startDate).add(4, "weeks").endOf("week").startOf("day"));
  };

  const backOneMonth = () => {
    const newFirstDay = moment(firstCalendarDay)
      .subtract(5, "weeks")
      .startOf("week")
      .startOf("day");

    updateCalendarDays(newFirstDay);
  };

  const forwardOneMonth = () => {
    const newFirstDay = moment(firstCalendarDay).add(5, "weeks").startOf("week").startOf("day");

    updateCalendarDays(newFirstDay);
  };

  return (
    <>
      <div className="Navbar">
        <div className="Navbar-header">
          <NavLink to={pathPrefix + routes.user.mypools}>
            <div className="Navbar-back"></div>
          </NavLink>
          <div className="Navbar-header-title">{getPoolName()}</div>
          <div className="Navbar-filler" />
        </div>
      </div>
      <MyPoolsLayout>
        <MyPoolsLayoutInner>
          <div className="MyPool">
            {!loading && (
              <div className="MyPoolDetails">
                {showAsMember && (
                  <>
                    {nextTrip && nextPoolDay ? (
                      <NextTripDetails
                        rideDate={nextTrip.rideDate}
                        pickupStop={nextTrip.pickupStop}
                        dropoffStop={nextTrip.dropoffStop}
                        isDriving={getMyPoolDayStatus(nextPoolDay).isDriving}
                      />
                    ) : firstTimeRider ? (
                      <FirstRideContainer>
                        <Checkmark>✅</Checkmark>
                        <ReadyToRide>You're ready to ride.</ReadyToRide>
                        <RideInstructions>Book rides and manage schedule below.</RideInstructions>
                        <ButtonStack>
                          <MBButton onClick={handleManageSchedule}>Book first ride</MBButton>
                        </ButtonStack>
                      </FirstRideContainer>
                    ) : (
                      <div
                        style={{
                          fontSize: "14px",
                          margin: "50px 0px 50px 0px",
                        }}
                      >
                        You have no upcoming rides
                      </div>
                    )}
                    <div className="MyPool Divider" />
                  </>
                )}

                <div className="CalendarHeader">
                  <div className="MonthNavigator">
                    {firstCalendarDay.isSameOrAfter(pool.services[0]?.startDate) && (
                      <div className="SelectorArrow" onClick={backOneMonth}>
                        {"<"}
                      </div>
                    )}
                  </div>
                  {getCalendarHeader(firstCalendarDay, lastCalendarDay)}
                  <div className="MonthNavigator">
                    {lastCalendarDay.isBefore(lastRideDate) && (
                      <div className="SelectorArrow" onClick={forwardOneMonth}>
                        {">"}
                      </div>
                    )}
                  </div>
                </div>
                <Calendar
                  vanpoolDays={vanpoolDays}
                  firstDay={firstCalendarDay}
                  lastDay={lastCalendarDay}
                  profile={pool.profile}
                  pathPrefix={pathPrefix}
                />
                <div className="ButtonContainer">
                  <MBButton kind="secondary" onClick={handleManageSchedule}>
                    {userPerms.canDrive || userPerms.canProvideVehicle
                      ? "Manage schedule"
                      : "Book rides"}
                  </MBButton>
                  <div style={{ marginTop: "15px" }}></div>
                  <ButtonStack>
                    {showAsMember && (
                      <MBButton
                        kind="secondary"
                        onClick={() =>
                          history.push(pathPrefix + `${routes.user.mypools}/${pool.id}/profile`)
                        }
                      >
                        My settings
                      </MBButton>
                    )}
                    {(userPerms.canProvideVehicle || userPerms.isVPM) && (
                      <ButtonStack style={{ marginTop: "25px" }}>
                        <MBButton kind="secondary" onClick={handleManagePool}>
                          Manage pool
                        </MBButton>
                        <MBButton kind="secondary" onClick={handleViewRidershipReport}>
                          View Ridership
                        </MBButton>
                      </ButtonStack>
                    )}
                  </ButtonStack>
                </div>
              </div>
            )}
          </div>
        </MyPoolsLayoutInner>
      </MyPoolsLayout>
    </>
  );
};
