import React from "react";
import { ReactComponent as WalkTimeIcon } from "assets/img/walk_time.svg";
import { ReactComponent as DriveTimeIcon } from "assets/img/driving_time.svg";
import { styled } from "baseui";

export enum TRAVEL_TYPE {
  WALK,
  DRIVE,
}

const travelIcon: { [key in TRAVEL_TYPE] } = {
  [TRAVEL_TYPE.WALK]: <WalkTimeIcon />,
  [TRAVEL_TYPE.DRIVE]: <DriveTimeIcon />,
};

const TravelTimeContainer = styled("div", ({ $theme }) => ({
  display: "flex",
  gap: "5px",
  alignItems: "center",
  fontSize: "13px",
  color: $theme.colors.mono700,
}));

interface TravelTimeProps {
  type: TRAVEL_TYPE;
  minutes: number;
}

export const TravelTime: React.FC<TravelTimeProps> = ({ type, minutes }) => {
  return (
    <TravelTimeContainer>
      {travelIcon[type]} {minutes} min
    </TravelTimeContainer>
  );
};
