import { getRoute2 } from "api/mapbox";
import { riderMyPoolsAction } from "api/vanpools";
import { styled } from "baseui";
import { MBButton } from "components/common/Buttons/Buttons";
import { ButtonStack } from "components/common/Modals/BaseModal/BaseModal";
import { MAP_BOUNDS } from "components/Map";
import { DaysOfWeekDisplay } from "components/Onboarding/components/VanpoolInfoCard/components/DaysOfWeekDisplay";
import {
  TravelTime,
  TRAVEL_TYPE,
} from "components/Onboarding/components/VanpoolInfoCard/components/TravelTime";
import useMBContext from "context/useMBContext";
import { formatDuration } from "helpers/formatHelpers";
import { params } from "interfaces/params";
import { routeDisplayInterface, stopDisplayInterface } from "interfaces/route";
import { INSTABOOK_ACTION } from "interfaces/vanpool";
import { routes } from "misc/http";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { VanpoolSummary } from "./components/VanpoolSummary";

const VanpoolSummaryPageContainer = styled("div", ({ $theme }) => ({
  position: "relative",
  height: "100%",
}));

const HeaderContainer = styled("div", ({ $theme }) => ({
  position: "absolute",
  top: "0rem",
  display: "flex",
  padding: "0.5em 1em",
  justifyContent: "space-between",
  height: "50px",
  borderBottom: `solid 0.5px ${$theme.colors.borderOpaque}`,
  width: "100%",
}));

const InfoContainer = styled("div", () => ({
  position: "absolute",
  overflowY: "auto",
  top: "50px",
  left: 0,
  right: 0,
  height: "calc(100% - 3.5rem - 50px)",
}));

const SummaryContainer = styled("div", () => ({
  padding: "10px 15px",
  marginBottom: "10px",
}));

const JoinButtonContainer = styled("div", () => ({
  width: "100%",
  position: "absolute",
  bottom: 0,
  left: 0,
  backgroundColor: "white",
  borderTopRightRadius: "15px",
  borderTopLeftRadius: "15px",
  padding: "0.5rem",
}));

const TravelTimes = styled("div", ({ $theme }) => ({
  display: "flex",
  gap: "10px",
}));

export const VanpoolSummaryPage: React.FC = () => {
  const { vanpool } = useParams<params>();
  const {
    allRoutes,
    selectedRouteID,
    createWalklines,
    setMapDisplayOptions,
    userOrigin,
    userDestination,
  } = useMBContext();
  const [activeRoute, setActiveRoute] = useState<routeDisplayInterface | undefined>();
  const [outboundPickupStop, setOutboundPickupStop] = useState<stopDisplayInterface>();
  const [outboundDropoffStop, setOutboundDropoffStop] = useState<stopDisplayInterface>();
  const [needToLoadWalktimes, setNeedToLoadWalktimes] = useState<boolean>(true);
  const history = useHistory();

  useEffect(() => {
    const route = allRoutes.find((route) => {
      return route.id === selectedRouteID;
    });

    if (!route) {
      // if no vanpool is selected then go back to search results
      history.push(routes.onboarding.searchResults);
      return;
    }

    setActiveRoute(route);
    setMapDisplayOptions({
      bounds: MAP_BOUNDS.ROUTES | MAP_BOUNDS.USER_ORIG_DEST,
    });
  }, [selectedRouteID]);

  useEffect(() => {
    if (!activeRoute || !needToLoadWalktimes) return;
    setNeedToLoadWalktimes(false);

    const outboundPickupStopCopy = activeRoute.service.outbound.stops[activeRoute.outboundPickup];
    const outboundDropoffStopCopy = activeRoute.service.outbound.stops[activeRoute.outboundDropoff];

    createWalklines(
      outboundPickupStopCopy.place.coordinates,
      outboundDropoffStopCopy.place.coordinates,
    ).then((lines) => {
      const walkToPickup = lines[0];
      const walkToPickupMinutes = (walkToPickup.travelDuration || 0) / 60;
      const newOutboundPickupStop = {
        ...outboundPickupStopCopy,
        walkMinutes: Math.round(walkToPickupMinutes),
      };
      setOutboundPickupStop(newOutboundPickupStop);
      activeRoute.service.outbound.stops[activeRoute.outboundPickup] = newOutboundPickupStop;

      const walkFromDropoff = lines[1];
      const walkFromDropoffMinutes = (walkFromDropoff.travelDuration || 0) / 60;
      const newOutboundDropoffStop = {
        ...outboundDropoffStopCopy,
        walkMinutes: Math.round(walkFromDropoffMinutes),
      };
      setOutboundDropoffStop(newOutboundDropoffStop);
      activeRoute.service.outbound.stops[activeRoute.outboundDropoff] = newOutboundDropoffStop;
      setActiveRoute({ ...activeRoute });
    });

    if (
      activeRoute.service.inbound &&
      activeRoute.inboundPickup !== undefined &&
      activeRoute.inboundDropoff !== undefined
    ) {
      const inboundPickupStopCopy = activeRoute.service.inbound.stops[activeRoute.inboundPickup];
      const inboundDropoffStopCopy = activeRoute.service.inbound.stops[activeRoute.inboundDropoff];

      // cant use createWalklines for inbound
      userDestination &&
        getRoute2(
          userDestination.coordinates,
          inboundPickupStopCopy.place.coordinates,
          "walking",
        ).then((thisLine) => {
          // why does typescript need us to do this check again when it was already done earlier
          if (!(activeRoute.service.inbound && activeRoute.inboundPickup !== undefined)) return;

          const walkToPickupMinutes = thisLine.data.routes[0].duration / 60;
          const newInboundPickupStop = {
            ...inboundPickupStopCopy,
            walkMinutes: Math.round(walkToPickupMinutes),
          };
          activeRoute.service.inbound.stops[activeRoute.inboundPickup] = newInboundPickupStop;
          setActiveRoute({ ...activeRoute });
        });

      userOrigin &&
        getRoute2(inboundDropoffStopCopy.place.coordinates, userOrigin.coordinates, "walking").then(
          (thisLine) => {
            if (!(activeRoute.service.inbound && activeRoute.inboundDropoff !== undefined)) return;

            const walkFromDropoffMinutes = thisLine.data.routes[0].duration / 60;
            const newInboundDropoffStop = {
              ...inboundDropoffStopCopy,
              walkMinutes: Math.round(walkFromDropoffMinutes),
            };
            activeRoute.service.inbound.stops[activeRoute.inboundDropoff] = newInboundDropoffStop;
            setActiveRoute({ ...activeRoute });
          },
        );
    }
  }, [activeRoute, needToLoadWalktimes]);

  const handleJoin = () => {
    if (!activeRoute) return;
    const vanpoolId = activeRoute.service.id.split("+")[0];

    riderMyPoolsAction({
      vanpoolId: vanpoolId,
      action: INSTABOOK_ACTION.BOOK,
      properties: {
        pickup: activeRoute.outboundPickup,
        dropoff: activeRoute.outboundDropoff,
      },
      serviceId: activeRoute.service.id,
    })
      .then((result) => {
        // goto my pools
        window.location.href = `${routes.user.mypools}/${vanpoolId}?welcome=1`;
      })
      .catch((errors) => {
        console.log("failed to join ", errors);
      });
  };

  return (
    <>
      <div className="Navbar">
        <div className="Navbar-header">
          <div
            className="Navbar-back"
            onClick={() => {
              activeRoute && activeRoute.stops.length > 2
                ? history.push(
                    routes.onboarding.poolDetails.selectStops.dropoff + `/${activeRoute.id}`,
                  )
                : history.push(routes.onboarding.searchResults);
            }}
          />
          <div className="Navbar-header-title">{activeRoute && activeRoute.service.name}</div>
          <div className="Navbar-filler" />
        </div>
      </div>
      <div className="Interface">
        <VanpoolSummaryPageContainer>
          {activeRoute && (
            <>
              <HeaderContainer>
                <TravelTimes>
                  {outboundPickupStop?.walkMinutes !== undefined &&
                    outboundDropoffStop?.walkMinutes !== undefined && (
                      <TravelTime
                        type={TRAVEL_TYPE.WALK}
                        minutes={outboundPickupStop.walkMinutes + outboundDropoffStop.walkMinutes}
                      />
                    )}
                  <TravelTime
                    type={TRAVEL_TYPE.DRIVE}
                    minutes={formatDuration(
                      activeRoute.stops[activeRoute.outboundPickup],
                      activeRoute.stops[activeRoute.outboundDropoff],
                    )}
                  />
                </TravelTimes>
                <DaysOfWeekDisplay days={activeRoute.service.days} />
              </HeaderContainer>
              <InfoContainer>
                <SummaryContainer>
                  <VanpoolSummary route={activeRoute} />
                </SummaryContainer>
              </InfoContainer>
              <JoinButtonContainer>
                <ButtonStack>
                  <MBButton onClick={handleJoin}>Join vanpool</MBButton>
                </ButtonStack>
              </JoinButtonContainer>
            </>
          )}
        </VanpoolSummaryPageContainer>
      </div>
    </>
  );
};
