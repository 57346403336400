import { GQLBase } from "misc/utils";
import moment from "moment";
import { addressInterface } from "./place";
import { serviceResultInterface } from "./route";
import { riderVanpoolRideInterface, vehicleInterface } from "./trip";
import { myPoolMemberInterface, myPoolMemberProfileInterface, userInterface } from "./user";

export enum INSTABOOK_ACTION {
  BOOK = "BOOK",
  CANCEL = "CANCEL",
}

export enum INSTABOOK_ROLE {
  RIDER = "RIDER",
  DRIVER = "DRIVER",
  VEHICLE = "VEHICLE",
  RIDE = "RIDE",
}

export enum RIDE_STATUS {
  OK = 0,
  NO_RIDERS = 1,
  NO_DRIVERS = 2,
  NO_VEHICLE = 4,
  CANCELED = 8,
}

export enum USER_RIDE_STATUS {
  NONE = 0,
  RIDER = 1,
  RIDER_WAITLIST = 2,
  DRIVER = 4,
  DRIVER_ALTERNATE = 8,
  RIDER_MISSED = 16,
}

export class autoAssignDayInterface extends GQLBase {
  role = INSTABOOK_ROLE.RIDE;
  day = "";
  vehicleId? = "";
}

export class autoAssignSettingsInterface extends GQLBase {
  items = [new autoAssignDayInterface()];
  dateNextApply = moment();

  static fromGQL = (s: autoAssignSettingsInterface) => ({
    ...s,
    date: moment(s.dateNextApply),
  });
}

export class vanpoolRules extends GQLBase {
  rule = "";
}

export class riderVanpoolDayInterface extends GQLBase {
  date = moment();
  rideStatus = 0;
  userStatus = 0;
  numRiders = 0;
  capacity = 0;
  vehicleId?: string;
  driverId?: string;

  static fromGQL = (d: riderVanpoolDayInterface) => ({
    ...d,
    date: moment(d.date),
  });
}

export class riderVanpoolDayDetailsInterface extends riderVanpoolDayInterface {
  outbound = new riderVanpoolRideInterface();
  inbound? = new riderVanpoolRideInterface();
}

export class vanpoolSearchSettingsInterface extends GQLBase {
  work = new addressInterface();
  arriveTime = "";
  departTime = "";
  rideDays = [""];
  driveDays? = [""];
  vehicleCapacity? = 0;
}

export interface PoolDayStatus {
  // user
  isBooked: boolean;
  isRiding: boolean;
  isDriving: boolean;
  isWaitlisted: boolean;
  isAlternateDriver: boolean;
  isMissed: boolean;

  // ride
  vehicleNeeded: boolean;
  isOverbooked: boolean;
  noRiders: boolean;
  rideConfirmed: boolean;
  driverNeeded: boolean;
  rideCanceled: boolean;
  isPast: boolean;
  isToday: boolean;
  isInbound: boolean;
}

export interface _vanpoolDayDisplayInterface {
  pool: riderMyPoolDisplayInterface;
  service: serviceResultInterface;
  status: PoolDayStatus;
}

export interface vanpoolDayDisplayInterface
  extends riderVanpoolDayInterface,
    _vanpoolDayDisplayInterface {}
export interface vanpoolDayDetailsDisplayInterface
  extends riderVanpoolDayDetailsInterface,
    _vanpoolDayDisplayInterface {}

export class riderVanpoolInterface extends GQLBase {
  id = "";
  organizationId = "";
  name = "";
  services = [new serviceResultInterface()];
  members = [new myPoolMemberInterface()];
  drivers = [new userInterface()];
  coordinators = [new userInterface()];
  vehicles = [new vehicleInterface()];
  rules = [new vanpoolRules()];
  vehicleAutoAssign = new autoAssignSettingsInterface();
}

export class serviceResultListInterface extends GQLBase {
  name = "";
}

export class riderVanpoolListInterface extends GQLBase {
  id = "";
  organizationId = "";
  name = "";
  services = [new serviceResultListInterface()];
  driverCount = 0;
  vehicleCount = 0;
  coordinatorCount = 0;
  memberCount = 0;
}

export class riderMyPoolInterface extends riderVanpoolInterface {
  profile = new myPoolMemberProfileInterface();
}

export interface PermsProps {
  canRide: boolean;
  canDrive: boolean;
  canProvideVehicle: boolean;
  isVPM: boolean;
}

export interface riderMyPoolDisplayInterface extends riderMyPoolInterface {
  perms: PermsProps;
}
