import React from "react";
import { mergeOverrides, useStyletron } from "baseui";
import { StyleObject } from "styletron-standard";

const GrabHandleIcon = (styleOverrides?: StyleObject) => {
  const [css, theme] = useStyletron();

  return (
    <div className={css({ cursor: "grab" })}>
      <svg
        width="14px"
        height="11px"
        viewBox="0 0 14 11"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <title>Group Copy 50</title>
        <g
          id="Page-1"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
          stroke-linecap="round"
        >
          <g
            id="FRD-142-Vanpool-Rules"
            transform="translate(-2765.000000, -253.000000)"
            stroke={`${theme.colors.mono600}`}
            stroke-width="1.5"
          >
            <g id="Group-Copy-50" transform="translate(2766.425651, 253.826562)">
              <line
                x1="0.179104478"
                y1="0.409090909"
                x2="11.641791"
                y2="0.409090909"
                id="Line-3"
              ></line>
              <line x1="0.179104478" y1="4.5" x2="11.641791" y2="4.5" id="Line-3-Copy"></line>
              <line
                x1="0.179104478"
                y1="8.59090909"
                x2="11.641791"
                y2="8.59090909"
                id="Line-3-Copy-2"
              ></line>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default GrabHandleIcon;
