import { routes } from "misc/http";
import React, { useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import { riderMyPoolInterface } from "interfaces/vanpool";
import { DateRow } from "../DateList/DateRow/DateRow";
import { VANPOOLER_ROLE } from "interfaces/user";
import { DateRowSummary } from "../MyPoolRideDetails/components/DateRowSummary";
import { MyPoolRideDetails } from "../MyPoolRideDetails/MyPoolRideDetails";
import { useRideDay } from "./useMyPoolRidePage";
import { params } from "interfaces/params";
import moment from "moment";
import { MyPoolsLayout } from "../MyPoolsPage/MyPoolsPage";
import { styled } from "baseui";

const MyPoolsLayoutHeaderAction = styled("div", ({ $theme }) => ({
  margin: "0 auto 17px auto",
  maxWidth: "710px",
}));

const MyPoolsLayoutHeaderSummary = styled("div", ({ $theme }) => ({
  marginBottom: "-17px",
  backgroundColor: $theme.colors.mono200,
}));

interface MyPoolRidePageProps {
  pool: riderMyPoolInterface;
  pathPrefix?: string;
  showAsMember?: boolean;
}

export const MyPoolRidePage: React.FC<MyPoolRidePageProps> = ({
  pool,
  pathPrefix = "",
  showAsMember = true,
}) => {
  const { date } = useParams<params>();
  const params = new URLSearchParams(window.location.search);
  const returnPath = params.get("source") ? "/" + params.get("source") : "";

  const {
    rideDay,
    updateRideDay,
    bookOrCancelDrive,
    bookOrCancelRide,
    assignModalSubmit,
    refreshData,
  } = useRideDay({ pool, date: moment(date) });

  useEffect(() => {
    refreshData();
  }, [pool]);

  return (
    <>
      <div className="Navbar">
        <div className="Navbar-header">
          <NavLink to={pathPrefix + `${routes.user.mypools}/${pool.id}${returnPath}?date=${date}`}>
            <div className="Navbar-back" />
          </NavLink>
          <div className="Navbar-header-title">Ride Details</div>
          <div className="Navbar-filler" />
        </div>
        {rideDay && (
          <MyPoolsLayout style={{ boxShadow: "none" }}>
            <MyPoolsLayoutHeaderAction>
              {rideDay.status.isPast ? (
                <MyPoolsLayoutHeaderSummary>
                  <DateRowSummary
                    vanpoolDay={rideDay}
                    userProfile={pool.profile}
                    actionRoles={
                      showAsMember && rideDay.pool.perms.canRide ? [VANPOOLER_ROLE.RIDER] : []
                    }
                  />
                </MyPoolsLayoutHeaderSummary>
              ) : (
                <DateRow
                  vanpoolDay={rideDay}
                  userProfile={pool.profile}
                  actionRoles={
                    showAsMember && rideDay.pool.perms.canRide ? [VANPOOLER_ROLE.RIDER] : []
                  }
                  rideCallback={() => {
                    !rideDay.status.rideCanceled && bookOrCancelRide(rideDay);
                  }}
                />
              )}
            </MyPoolsLayoutHeaderAction>
          </MyPoolsLayout>
        )}
      </div>
      <MyPoolsLayout>
        {rideDay && (
          <MyPoolRideDetails
            returnPath={returnPath}
            assignModalSubmit={assignModalSubmit}
            refreshData={refreshData}
            bookOrCancelDrive={bookOrCancelDrive}
            updateRideDay={updateRideDay}
            rideDay={rideDay}
            showAsMember={showAsMember}
          />
        )}
      </MyPoolsLayout>
    </>
  );
};
