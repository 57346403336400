import { riderInstabookWithDetails } from "api/vanpools";
import { MBButton } from "components/common/Buttons/Buttons";
import { ConfirmModalContext } from "components/common/ConfirmModal";
import {
  INSTABOOK_ACTION,
  INSTABOOK_ROLE,
  riderVanpoolDayDetailsInterface,
  riderVanpoolDayInterface,
} from "interfaces/vanpool";
import React, { useContext } from "react";

interface CancelRideProps {
  vanpoolId: string;
  day: riderVanpoolDayInterface;
  onCancel?: (poolDays: riderVanpoolDayDetailsInterface[]) => void;
}

export const CancelRide: React.FC<CancelRideProps> = ({ vanpoolId, day, onCancel }) => {
  const { showConfirmModal } = useContext(ConfirmModalContext);

  const cancelRide = () => {
    riderInstabookWithDetails({
      action: INSTABOOK_ACTION.CANCEL,
      role: INSTABOOK_ROLE.RIDE,
      dates: [day.date],
      vanpoolId,
    }).then((poolDays) => {
      onCancel?.(poolDays);
    });
  };

  const showCancelPrompt = () => {
    showConfirmModal({
      headerElement: (
        <>Are you sure you want to cancel the ride for {day.date.format("dddd, MMMM D")}?</>
      ),
      bodyElement: (
        <>
          <p>Proceeding will notify all riders and drivers that their trip has been canceled.</p>
        </>
      ),
      confirmButtonText: "Yes, cancel the ride",
      cancelButtonText: "No",
      onConfirmCallback: cancelRide,
      confirmType: "destructive",
      message: "",
    });
  };

  return (
    <div className="ButtonContainer">
      <MBButton kind="secondary" destructive onClick={showCancelPrompt}>
        Cancel Ride
      </MBButton>
    </div>
  );
};
