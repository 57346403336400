import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./Header.scss";
import { routes } from "misc/http";
import logo from "assets/img/logo.png";
import useMBContext from "context/useMBContext";
import Icon from "components/common/Icon";
import Avatar from "components/common/Avatar";
import { useMediaQuery } from "react-responsive";
import { styled } from "baseui";
import { urlReturnToCurrent } from "misc/utils";

export const Navbar = styled("div", ({ $theme }) => ({
  minWidth: "320px",
  display: "grid",
  width: "100vw",
  gridTemplateRows: "max-content auto",
  margin: "0px 25px",
}));

export const NavbarHeader = styled("div", ({ $theme }) => ({
  width: "100%",
  margin: "0 auto",
  maxWidth: "710px",
  padding: "0px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  height: "60px",
}));

export const NavbarHeaderTitle = styled("div", ({ $theme }) => ({
  padding: "0",
  fontSize: "19px",
  color: $theme.colors.primary,
  paddingRight: "1rem",
}));

export const NavbarBack = styled("div", ({ $theme }) => ({
  width: "2rem",
  padding: "1rem 1rem 1rem 0rem",
  margin: "12px 0",
  cursor: "pointer",

  ":before": {
    content: '""',
    display: "block",
    width: "12px",
    height: "2px",
    transform: "translateX(2px) rotate(46deg)",
    transformOrigin: "0 100%",
    backgroundColor: $theme.colors.primary,
    borderRadius: "2px",
    transition: "all 0.2s ease-out",
  },
  ":after": {
    content: '""',
    display: "block",
    width: "12px",
    height: "2px",
    transform: "translateX(2px) rotate(-46deg)",
    transformOrigin: "0 0",
    backgroundColor: $theme.colors.primary,
    borderRadius: "2px",
    transition: "all 0.2s ease-out",
  },
}));

export const NavbarFiller = styled("div", ({ $theme }) => ({
  width: "2rem",
}));

interface HeaderInterface {
  className?: string;
}

const Header: React.FC<HeaderInterface> = ({ className }) => {
  const { setHelpModalVisible, user, isVanpoolManager } = useMBContext();

  const [mobileMenuVisible, setMobileMenuVisible] = useState<boolean>(false);

  const showMobileMenu = () => {
    setMobileMenuVisible(true);
  };

  const hideMobileMenu = () => {
    setMobileMenuVisible(false);
  };

  const showHelpModal = () => {
    hideMobileMenu();
    setHelpModalVisible && setHelpModalVisible(true);
  };

  const isDesktop = useMediaQuery({ minWidth: 992 });

  return (
    <header className={`Header ${className || ""}`} data-testid="header-block">
      <NavLink
        to={routes.user.mypools}
        className="Header-logo"
        onClick={hideMobileMenu}
        data-testid="header-logo"
      >
        <img className="Header-logo-image" src={logo} alt="MagicBus logo" />
      </NavLink>
      <div className="Header-nav-icon" onClick={showMobileMenu}>
        <Icon name="menu" />
      </div>
      <div className="Header-nav-backdrop"></div>
      <nav className={`Header-nav${mobileMenuVisible ? " Header-nav-mobile-visible" : ""}`}>
        <div className="Header-nav-icon-close" onClick={hideMobileMenu}>
          <Icon name="close" />
        </div>
        {!user && (
          <div>
            <a className="Header-nav-item" onClick={showHelpModal}>
              Need Help
            </a>
            <a href={urlReturnToCurrent(routes.user.signup)} className="Header-nav-item">
              Create Account
            </a>
            <a href={urlReturnToCurrent(routes.user.login)} className="Header-nav-item">
              Sign In
            </a>
          </div>
        )}
        {user && (
          <div className="Header-user" data-testid="header-user">
            {!isDesktop && (
              <NavLink
                to={routes.user.profile}
                className="Header-nav-item"
                onClick={hideMobileMenu}
              >
                <Avatar />
              </NavLink>
            )}
            {isVanpoolManager && (
              <NavLink
                to={routes.user.admin.main}
                className="Header-nav-item"
                onClick={hideMobileMenu}
              >
                Admin
              </NavLink>
            )}
            <NavLink to={routes.user.mypools} className="Header-nav-item" onClick={hideMobileMenu}>
              Your Pools
            </NavLink>
            <NavLink
              to={routes.onboarding.suggest}
              className="Header-nav-item"
              onClick={hideMobileMenu}
            >
              Suggest a Route
            </NavLink>
            <a className="Header-nav-item" onClick={showHelpModal}>
              Need Help
            </a>
            {isDesktop && (
              <NavLink to="/profile" className="Header-nav-item" onClick={hideMobileMenu}>
                <Avatar />
              </NavLink>
            )}
          </div>
        )}
      </nav>
    </header>
  );
};

export default Header;
