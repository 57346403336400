import React from "react";
import { stopDisplayInterface } from "interfaces/route";
import { STOP_TYPE } from "interfaces/stop";
import { styled } from "baseui";
import StopInfo from "components/common/StopInfo/StopInfo";
import { ButtonStack } from "components/common/Modals/BaseModal/BaseModal";
import { MBButton } from "components/common/Buttons/Buttons";
import { tripPropertiesInterface } from "interfaces/trip";

const SelectStopContainer = styled("div", ({ $theme }) => ({
  position: "relative",
  height: "100%",
}));

const ConfirmButtonContainer = styled("div", () => ({
  width: "100%",
  position: "absolute",
  bottom: 0,
  left: 0,
  backgroundColor: "white",
  borderTopRightRadius: "15px",
  borderTopLeftRadius: "15px",
  padding: "0.5rem",
}));

const StopSelectorContainer = styled("div", ({ $theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "15px",
  overflowY: "auto",
  height: "calc(100% - 3.5rem)",
}));

const SelectorBubble = styled<"div", { $filled?: boolean }>("div", ({ $theme, $filled }) => ({
  borderRadius: "50%",
  width: "11px",
  height: "11px",
  outline: `solid 1px ${$theme.colors.borderOpaque}`,
  outlineOffset: "3px",
  backgroundColor: $filled ? $theme.colors.primary : "none",
}));

const StopContainer = styled<"div", { $selected?: boolean; $disabled?: boolean }>(
  "div",
  ({ $theme, $selected, $disabled }) => ({
    display: "grid",
    gridTemplateColumns: "15% 69% 11%",
    padding: $selected ? "17px 24px" : "18px 25px",
    border: $selected ? `solid 1px ${$theme.colors.primary}` : "none",
    borderRadius: "8px",
    alignItems: "center",
    opacity: $disabled ? "50%" : "100%",
  }),
);

const SelectedStopTypeText = styled("div", ({ $theme }) => ({
  color: $theme.colors.primary,
  fontSize: "15px",
}));

interface SingleStopSelectorProps {
  type: STOP_TYPE;
  stops: stopDisplayInterface[];
  props: tripPropertiesInterface;
  onSelect: (props: tripPropertiesInterface) => void;
  confirmCallback: () => void;
  restrictStops?: boolean;
}

export const SingleStopSelector: React.FC<SingleStopSelectorProps> = ({
  type,
  stops,
  props,
  onSelect,
  confirmCallback,
  restrictStops = false,
}) => {
  const checkSelectedPickupOrDropoff = (index: number) => {
    return props.pickup === index || props.dropoff === index;
  };

  const checkSelected = (index: number) => {
    return (
      (type === STOP_TYPE.PICKUP && props.pickup === index) ||
      (type === STOP_TYPE.DROPOFF && props.dropoff === index)
    );
  };

  const checkDisabled = (index: number) => {
    // if restrictStops dont allow selecting pickups after dropoff or dropoffs before pickups
    return (
      restrictStops &&
      ((type === STOP_TYPE.PICKUP && index >= props.dropoff) ||
        (type === STOP_TYPE.DROPOFF && index <= props.pickup))
    );
  };

  const selectStop = (index: number) => {
    if (checkSelected(index)) return;

    const newProps: tripPropertiesInterface = {
      pickup: type === STOP_TYPE.PICKUP ? index : props.pickup,
      dropoff: type === STOP_TYPE.DROPOFF ? index : props.dropoff,
    };

    onSelect(newProps);
  };

  return (
    <SelectStopContainer>
      <StopSelectorContainer>
        {stops.map((stop, index) => {
          const selectedPickupOrDropoff = checkSelectedPickupOrDropoff(index);
          const disabled = checkDisabled(index);

          if (stop.stopType === type || stop.stopType === null) {
            return (
              <>
                <StopContainer
                  $selected={checkSelected(index)}
                  $disabled={disabled}
                  onClick={() => {
                    !disabled && selectStop(index);
                  }}
                >
                  <SelectorBubble $filled={selectedPickupOrDropoff} />
                  <StopInfo
                    stop={stop}
                    showWalkTime={true}
                    isArrival={type === STOP_TYPE.DROPOFF}
                  />
                  {selectedPickupOrDropoff && (
                    <SelectedStopTypeText>
                      {props.pickup === index ? "Pickup" : "Dropoff"}
                    </SelectedStopTypeText>
                  )}
                </StopContainer>
              </>
            );
          }
        })}
      </StopSelectorContainer>
      <ConfirmButtonContainer>
        <ButtonStack>
          <MBButton onClick={confirmCallback}>Next</MBButton>
        </ButtonStack>
      </ConfirmButtonContainer>
    </SelectStopContainer>
  );
};
