import React, { useContext } from "react";
import { MBButton } from "components/common/Buttons/Buttons";
import { ConfirmModalContext } from "components/common/ConfirmModal";
import { serviceResultInterface } from "interfaces/route";
import { DateTime } from "luxon";

interface ApplyToTripsProps {
  service: serviceResultInterface;
  onConfirm?: () => void;
}

export const ApplyToTrips: React.FC<ApplyToTripsProps> = ({ service, onConfirm }) => {
  const { showConfirmModal } = useContext(ConfirmModalContext);

  const showLeavePrompt = () => {
    showConfirmModal({
      headerElement: <>Update this user's trips to match pickup/dropoff?</>,
      bodyElement: (
        <>
          Beginning on the current service start date (
          {DateTime.fromISO(service.startDate).toFormat("yyyy-MM-dd")}), trips for this rider will
          be updated to match the pickup/dropoff settings above.
        </>
      ),
      confirmButtonText: "Yes, update",
      cancelButtonText: "No",
      onConfirmCallback: onConfirm,
      confirmType: "default",
      message: "",
    });
  };

  return (
    <MBButton kind="secondary" onClick={showLeavePrompt}>
      Apply Pickup / Dropoff
    </MBButton>
  );
};
