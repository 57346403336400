import axios, { AxiosRequestConfig } from "axios";
import { datadogLogs } from "@datadog/browser-logs";

const { REACT_APP_API_URL, REACT_APP_USER_MANAGEMENT_APP } = process.env;

/**
 * Project routes
 */
export const routes = {
  main: "/",
  help: "/help-center",
  booking: "/booking",
  trips: "/trips",
  not_found: "/404",
  vanpools: {
    prefix: "/vanpool",
    search: "/vanpool/search",
    searchResults: "/vanpool/schedules",
    details: "/vanpool/details",
    book: "/vanpool/book",
    suggest: "/vanpool/suggest",
    routemaker: "/routemaker",
    stops: "/vanpool/stops",
  },
  user: {
    login: REACT_APP_USER_MANAGEMENT_APP + "/login",
    signup: REACT_APP_USER_MANAGEMENT_APP + "/registration",
    logout: REACT_APP_USER_MANAGEMENT_APP + "/logout",
    profile: "/profile",
    mypools: "/mypools",
    admin: {
      main: "/admin",
      organizations: "/admin/organizations",
      vehicles: "/admin/vehicles",
    },
  },
  onboarding: {
    prefix: "/welcome",
    intro: "/welcome/intro",
    selectAddress: {
      prefix: "welcome/address",
      home: "/welcome/address/home",
      work: "/welcome/address/work",
    },
    searchResults: "/welcome/pools",
    poolDetails: {
      prefix: "/welcome/details",
      summary: "/welcome/details/summary",
      selectStops: {
        prefix: "/welcome/details/stops",
        pickup: "/welcome/details/stops/pickup",
        dropoff: "/welcome/details/stops/dropoff",
      },
    },
    suggest: "/welcome/suggest",
  },
};

// FIXME this is kinda gross, but we have to update all the env vars in dev/stage/prod to fix...
const graphqlAPIURL = REACT_APP_API_URL!;
const authURL = REACT_APP_API_URL?.replace("graphql", "authorize")!;

const http = axios.create({ timeout: 20000 });

http.interceptors.request.use(
  (config) => {
    config.headers["Access-Control-Allow-Origin"] = "*";
    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  },
);

http.interceptors.response.use(
  (r) => {
    if (r?.data?.errors?.[0]?.code == "INVALID_TOKEN") {
      // try to refresh
      return http
        .post(authURL + "/refresh", {}, { withCredentials: true })
        .then((res) => {
          return http.request(r.config);
        })
        .catch((errors) => {
          // if refresh fails, redirect to login screen and throw
          window.location.href = routes.user.login;
          throw errors;
        });
    } else {
      return r;
    }
  },
  (error) => {
    return Promise.reject(error);
  },
);

export const handleGraphQLError = (name: string, data: string, variables: any, error: any) => {
  const errors = error?.response?.data?.errors;
  const code = errors?.[0]?.code;
  datadogLogs.logger.error(`graphql ${name}: ${code}`, {
    requestName: name,
    requestData: data,
    requestVariables: variables,
    errors: errors,
    errorCode: errors?.[0]?.code,
  });
};

export const authorize_action = (action, params = {}) => {
  return http.post(authURL + "/" + action, params, { withCredentials: true });
};

export const authorize_action_log_out = () => {
  return authorize_action("logout");
};

export const authorize_action_log_out_all = () => {
  return authorize_action("logout_all");
};

export const authorize_action_log_in_sso = (sso: any) => {
  return authorize_action("login", { sso });
};

export const api_post = async (data: any) => {
  return http.post(graphqlAPIURL, data, { withCredentials: true });
};

const map_null = (a: any) => (a === "null" ? null : a);

// Resolve with query/mutation data, or reject with errors array
export const gql_post = async <T = any>(
  name: string,
  data: string,
  variables?: any,
): Promise<T> => {
  const vars = {
    orgId: map_null(localStorage.getItem("orgId")),
    accountId: map_null(localStorage.getItem("accountId")),
    ...variables,
  };
  return api_post({ query: data, variables: vars })
    .then((data) => {
      if (data?.data?.errors) throw { response: data };
      if (![undefined].includes(data?.data?.data?.[name])) {
        return data.data.data[name];
      }
      throw { response: data };
    })
    .catch((err) => {
      handleGraphQLError(name, data, vars || {}, err);
      throw err?.response?.data?.errors;
    });
};

export default http;
