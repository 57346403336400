import React from "react";
import CheckmarkIcon from "assets/img/checkmark-icon";
import { ReactComponent as DriveIcon } from "assets/img/drive-icon.svg";
import { POOL_ACTION_BUTTON_LABEL } from "../utils";
import { useStyletron } from "baseui";
import { expand } from "inline-style-expand-shorthand";

export interface PoolActionButtonCommonInterface {
  status?: "none" | "ok" | "warn";
  icon?: "warn" | "drive" | "check";
  inactive?: boolean;
  disabled?: boolean;
  canceled?: boolean;
  warnings?: string[];
}

export interface PoolActionButtonInterface extends PoolActionButtonCommonInterface {
  label: POOL_ACTION_BUTTON_LABEL;
  selected?: boolean;
  onClick?: () => void;
  vehicle?: string;
  driver?: string;
}

export const PoolActionButton: React.FC<PoolActionButtonInterface> = ({
  label,
  status = "none",
  selected = false,
  canceled = false,
  disabled = false,
  inactive = false,
  warnings = [],
  icon,
  onClick,
  vehicle,
  driver,
}) => {
  const [css, theme] = useStyletron();

  const hoverText = warnings.join("\n");

  const backgroundColor = canceled
    ? theme.colors.mono200
    : selected
    ? status === "warn"
      ? theme.colors.warning
      : theme.colors.primary
    : "none";

  const color = canceled
    ? "red"
    : selected
    ? theme.colors.mono100
    : status === "warn"
    ? theme.colors.warning
    : status === "none"
    ? theme.colors.mono800
    : theme.colors.primary;

  const borderColor = inactive ? color : theme.borders.border100.borderColor;

  const selectedIcon =
    icon === "warn" ? (
      <span>⚠️</span>
    ) : icon === "drive" ? (
      <DriveIcon />
    ) : icon === "check" ? (
      <CheckmarkIcon color={color} />
    ) : undefined;

  return (
    <div
      className={css({
        ...expand({
          padding: "13px 8px",
          borderStyle: selected ? "none" : theme.borders.border100.borderStyle,
          borderWidth: theme.borders.border100.borderWidth,
          borderColor: borderColor,
          borderRadius: theme.borders.buttonBorderRadius,
        }),
        fontFamily: theme.typography.LabelMedium.fontFamily,
        fontSize: theme.typography.LabelMedium.fontSize,
        fontWeight: theme.typography.LabelMedium.fontWeight,
        lineHeight: theme.typography.LabelMedium.lineHeight,
        color,
        backgroundColor,
        opacity: disabled ? "0.5" : "1",
        cursor: onClick ? "pointer" : "default",
        minWidth: "95px",
        height: "44px",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textAlign: "center",
      })}
      onClick={onClick}
      title={selected ? hoverText : ""}
    >
      {selectedIcon} {vehicle ? vehicle : driver ? driver : label}
    </div>
  );
};
