import React, { useContext, useEffect, useState } from "react";
import { BuilderOverrides, TableBuilder, TableBuilderColumn } from "baseui/table-semantic";
import { riderInstabookManageList } from "api/vanpools";
import { riderVanpoolInterface, riderVanpoolListInterface } from "interfaces/vanpool";
import { NavLink } from "react-router-dom";
import { routes } from "misc/http";
import { useStyletron } from "baseui";
import { MBButton } from "components/common/Buttons/Buttons";
import { GlobalContext } from "context/global";
import { pathPrefix } from "./AdminCommon";

const NameCell: React.FC<{ pool: riderVanpoolListInterface }> = ({ pool }) => {
  return (
    <>
      <p>{pool.name || pool.services?.[0].name}</p>
      <p>{`${pool.memberCount} members, ${pool.driverCount} drivers, ${pool.coordinatorCount} coordinators`}</p>
    </>
  );
};

const ActionCell: React.FC<{
  pool: riderVanpoolListInterface;
  onClick?: () => void;
}> = ({ pool, onClick }) => {
  const [css] = useStyletron();
  return (
    <div
      className={css({
        display: "flex",
        justifyContent: "end",
      })}
    >
      <NavLink to={pathPrefix + `${routes.user.mypools}/${pool.id}`}>
        <MBButton kind="secondary">Manage</MBButton>
      </NavLink>
    </div>
  );
};

const overrides: BuilderOverrides = {
  TableBodyRow: {
    style: ({ $theme, $rowIndex }: any) => ({
      backgroundColor:
        $rowIndex % 2 ? $theme.colors.backgroundPrimary : $theme.colors.backgroundSecondary,
      ":hover": {
        backgroundColor: $theme.colors.backgroundTertiary,
      },
    }),
  },
  TableBodyCell: {
    style: {
      verticalAlign: "middle",
    },
  },
  TableHead: {
    style: {
      display: "none",
    },
  },
};

interface VanpoolPageProps {}

export const VanpoolAdminPage: React.FC<VanpoolPageProps> = ({}) => {
  const { selectedAccount, selectedOrganization } = useContext(GlobalContext);
  const [vanpools, setVanpools] = useState<riderVanpoolListInterface[]>([]);

  useEffect(() => {
    riderInstabookManageList({
      accountId: selectedAccount?.id,
      orgId: selectedOrganization?.id,
    }).then((pools) => {
      setVanpools(pools);
    });
  }, [selectedOrganization, selectedAccount]);

  return (
    <>
      <TableBuilder data={vanpools} overrides={overrides}>
        <TableBuilderColumn header="Name">
          {(row) => <NameCell pool={row as riderVanpoolListInterface} />}
        </TableBuilderColumn>
        <TableBuilderColumn header="Actions">
          {(row) => <ActionCell pool={row as riderVanpoolListInterface} />}
        </TableBuilderColumn>
      </TableBuilder>
    </>
  );
};
