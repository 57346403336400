import { coordinate } from "interfaces/route";
import http from "misc/http";

export const getRoute2 = (start: coordinate, end: coordinate, mode: string) => {
  let apiURL = `https://api.mapbox.com/directions/v5/mapbox/${mode}/${start.lng},${start.lat};${end.lng},${end.lat}`;
  return http.get(apiURL, {
    params: {
      access_token: process.env.REACT_APP_MB_TOKEN,
      geometries: "geojson",
    },
  });
};

export const getRoute = (
  startLon: number,
  startLat: number,
  endLon: number,
  endLat: number,
  mode: string,
) => {
  return getRoute2({ lng: startLon, lat: startLat }, { lng: endLon, lat: endLat }, mode);
};

export const getSuggestions = (
  search: string,
  params: Record<string, string> = {
    types: "region,postcode,district,place,locality,neighborhood,address,poi",
    country: "US",
  },
) => {
  const searchParams = new URLSearchParams(params).toString();

  let apiURL = `https://api.mapbox.com/geocoding/v5/mapbox.places/${search}.json?${searchParams}`;
  return http.get(apiURL, {
    params: {
      access_token: process.env.REACT_APP_MB_TOKEN,
      country: "US",
    },
  });
};

export const geocodeLatLng = (lat: number, lng: number) => {
  const apiURL = `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json`;
  return http.get(apiURL, {
    params: {
      types: "address,poi",
      access_token: process.env.REACT_APP_MB_TOKEN,
      country: "US",
      routing: "true",
    },
  });
};
