import React from "react";
import { NavLink } from "react-router-dom";
import { routes } from "misc/http";
import "./MapMarkerPin.scss";
import { coordinate, stopDisplayInterface } from "interfaces/route";
import { formatTime } from "helpers/formatHelpers";
import { Layer, Marker, Source } from "react-mapbox-gl";
import { v4 as uuidv4 } from "uuid";
import { theme } from "assets/theme";

export interface pinDisplayProperties {
  type?: MAP_PIN_TYPE; // default none
  isSelectable?: boolean;
  showName?: boolean;
  showTime?: boolean;
  showViewRouteLink?: boolean;
}

export enum MAP_PIN_TYPE {
  HOME = 1,
  WORK,
  PICKUP,
  DROPOFF,
  GEOIP,
  GPS,
}
interface MapPinIconProps {
  type: MAP_PIN_TYPE;
  [key: string]: any;
}

export const MapPinIcon: React.FC<MapPinIconProps> = ({ type, ...props }) => {
  const icons: { [key in MAP_PIN_TYPE]: string } = {
    [MAP_PIN_TYPE.HOME]: "Home",
    [MAP_PIN_TYPE.WORK]: "Work",
    [MAP_PIN_TYPE.PICKUP]: "VanPin",
    [MAP_PIN_TYPE.DROPOFF]: "BuildingPin",
    [MAP_PIN_TYPE.GEOIP]: "GeoIP",
    [MAP_PIN_TYPE.GPS]: "GPS",
  };

  const Icon = React.lazy(() => import(`components/common/Icon/Icons/${icons[type]}`));

  return <Icon {...props} className="MapMarkerPin-icon" />;
};

interface MapRoutePinProps {
  stop: stopDisplayInterface;
  selected?: boolean;
  highlighted?: boolean;
  routeNumber?: number;
  name?: string;
  routeID?: string;
  onSelect?: () => void;
  showPinBubble?: boolean;
}

export const MapMarkerPin: React.FC<MapRoutePinProps> = ({
  stop,
  selected,
  highlighted,
  routeNumber,
  name,
  routeID,
  onSelect,
  showPinBubble,
}) => {
  const props: pinDisplayProperties = {
    isSelectable: false,
    showTime: false,
    showViewRouteLink: false,
    ...stop.display,
    ...(highlighted ? stop.highlighted : {}),
    ...(selected ? stop.selected : {}),
  };

  const displayName =
    name ||
    (props.type == MAP_PIN_TYPE.PICKUP
      ? "Pickup"
      : props.type == MAP_PIN_TYPE.DROPOFF
      ? "Dropoff"
      : undefined);

  const displayTime =
    props.type == MAP_PIN_TYPE.PICKUP ? formatTime(stop.departure) : formatTime(stop.arrival);

  return (
    <>
      {props.type && (
        <Marker
          key={`routePin-${routeID}`}
          coordinates={[stop.place.coordinates.lng, stop.place.coordinates.lat]}
          style={{ width: "35px" }}
          anchor="bottom"
        >
          <div
            className={`MapMarkerPin ${props.isSelectable ? "Clickable" : ""}`}
            onClick={onSelect}
          >
            {showPinBubble &&
              (routeNumber || props.showName || props.showTime || props.showViewRouteLink) && (
                <div className="MapMarkerPin-data">
                  {routeNumber ? <div className="MapMarkerPin-data-route">{routeNumber}</div> : ""}
                  {props.showName && <div>{displayName}</div>}
                  {props.showTime && <b>{displayTime}</b>}
                  {props.showViewRouteLink && routeID && (
                    <NavLink
                      className="MapMarkerPin-link"
                      to={routes.vanpools.details + "/" + routeID}
                    >
                      View Route
                    </NavLink>
                  )}
                </div>
              )}
            <MapPinIcon type={props.type} />
          </div>
        </Marker>
      )}
    </>
  );
};

interface MapCirclePinProps {
  coord: coordinate;
  type: MAP_PIN_TYPE;
  showCircle?: boolean;
}

export const MapCirclePin: React.FC<MapCirclePinProps> = ({ coord, type, showCircle = true }) => {
  const id = uuidv4();

  return (
    <>
      {showCircle && (
        <>
          <Source
            id={"circle-source-" + id}
            geoJsonSource={{
              type: "geojson",
              data: {
                type: "Point",
                coordinates: [coord.lng, coord.lat],
              },
            }}
          />
          <Layer
            id={"circle-layer-" + id}
            type="circle"
            sourceId={"circle-source-" + id}
            paint={{
              "circle-radius": {
                stops: [
                  [0, 0],
                  [20, 50000],
                ],
                base: 2,
              },
              "circle-color": theme.primaryColor,
              "circle-opacity": 0.25,
              "circle-translate": [-3, -3],
              "circle-translate-anchor": "viewport",
            }}
          />
        </>
      )}
      <Marker
        key={"circle-marker-" + id}
        coordinates={[coord.lng, coord.lat]}
        style={{ width: "35px" }}
        anchor="center"
      >
        <MapPinIcon type={type} />
      </Marker>
    </>
  );
};
