import { MBTheme } from "assets/theme";
import { styled } from "baseui";
import { MBButton } from "components/common/Buttons/Buttons";
import { ReactComponent as CollapseOpenIcon } from "assets/img/collapse-open-icon.svg";
import { ReactComponent as CollapseClosedIcon } from "assets/img/collapsed-closed-icon.svg";
import { ReactComponent as RecommendedIcon } from "assets/img/recommended.svg";
import React, { useEffect, useState } from "react";
import { TravelTime, TRAVEL_TYPE } from "./components/TravelTime";
import { DaysOfWeekDisplay } from "./components/DaysOfWeekDisplay";
import TripStops from "components/common/TripStops/TripStops";
import useMBContext from "context/useMBContext";
import { formatDuration } from "helpers/formatHelpers";
import { routeDisplayInterface, stopDisplayInterface } from "interfaces/route";
import { getRoute2 } from "api/mapbox";

const VanpoolInfoCardContainer = styled<"div", { $color: string; $selected?: boolean }>(
  "div",
  ({ $theme, $color, $selected }) => ({
    borderRadius: "8px",
    border: $selected ? `solid 2px ${$color}` : `solid 1px ${$theme.colors.borderOpaque}`,
    margin: $selected ? "0px" : "1px",
  }),
);

const VanpoolInfoCardHeader = styled("div", ({ $theme }) => ({
  display: "grid",
  gridTemplateColumns: "9% 91%",
}));

const VanpoolInfoCardHeaderInfo = styled("div", ({ $theme }) => ({
  padding: "20px 10px 20px 0px",
}));

const VanpoolInfoCardColorBlock = styled<"div", { $color: string; $selected?: boolean }>(
  "div",
  ({ $theme, $color, $selected }) => ({
    borderTopLeftRadius: "7px",
    // all these conditionals are because the thicker border for
    // selected vanpools makes some resizing needed
    borderBottomLeftRadius: $selected ? "0px" : "7px",
    width: $selected ? "9px" : "8px",
    marginTop: $selected ? "-1px" : "0px",
    marginLeft: $selected ? "-1px" : "0px",
    backgroundColor: `${$color}`,
  }),
);

const VanpoolInfoCardTitle = styled("div", ({ $theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  fontSize: "19px",
}));

const WalkTimes = styled("div", ({ $theme }) => ({
  display: "flex",
  gap: "10px",
}));

const VanpoolInfoCardTimeRow = styled("div", ({ $theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  paddingRight: "10px",
}));

const VanpoolInfoCardSpacer = styled("div", ({ $theme }) => ({
  margin: "15px 0px",
}));

const VanpoolInfoCardDetails = styled("div", ({ $theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: "-1px",
  borderTop: `dashed 1px ${$theme.colors.borderOpaque}`,
}));

const ShortestWalkBadgeContainer = styled("div", ({ $theme }) => ({
  borderRadius: "4px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "5px",
  width: "120px",
  height: "34px",
  color: $theme.colors.primary,
  fontWeight: 500,
  fontSize: "12px",
  backgroundColor: $theme.colors.mono300,
}));

const ShortestWalkBadge: React.FC = () => {
  return (
    <ShortestWalkBadgeContainer>
      <RecommendedIcon /> Shortest Walk
    </ShortestWalkBadgeContainer>
  );
};

interface VanpoolInfoCardProps {
  route: routeDisplayInterface;
  expanded: boolean;
  selectCallback: () => void;
  viewDetailsCallback: () => void;
  color?: string;
  shortestWalk?: boolean;
}

export const VanpoolInfoCard: React.FC<VanpoolInfoCardProps> = ({
  route,
  expanded,
  selectCallback,
  viewDetailsCallback,
  color = MBTheme.colors.primary,
  shortestWalk = false,
}) => {
  const [outboundPickupStop, setOutboundPickupStop] = useState<stopDisplayInterface>(
    route.service.outbound.stops[route.outboundPickup],
  );
  const [outboundDropoffStop, setOutboundDropoffStop] = useState<stopDisplayInterface>(
    route.service.outbound.stops[route.outboundDropoff],
  );
  const [inboundPickupStop, setInboundPickupStop] = useState<stopDisplayInterface | undefined>(
    route.inboundPickup !== undefined
      ? route.service.inbound?.stops[route.inboundPickup]
      : undefined,
  );
  const [inboundDropoffStop, setInboundDropoffStop] = useState<stopDisplayInterface | undefined>(
    route.inboundDropoff !== undefined
      ? route.service.inbound?.stops[route.inboundDropoff]
      : undefined,
  );

  const [outboundDriveTime, setOutboundDriveTime] = useState<number>(
    formatDuration(outboundPickupStop, outboundDropoffStop),
  );

  const { createWalklines, userDestination, userOrigin } = useMBContext();

  useEffect(() => {
    createWalklines(
      outboundPickupStop.place.coordinates,
      outboundDropoffStop.place.coordinates,
    ).then((lines) => {
      const walkToPickup = lines[0];
      const walkToPickupMinutes = (walkToPickup.travelDuration || 0) / 60;
      const updatedOutboundPickup = {
        ...outboundPickupStop,
        walkMinutes: Math.round(walkToPickupMinutes),
      };
      setOutboundPickupStop(updatedOutboundPickup);

      const walkFromDropoff = lines[1];
      const walkFromDropoffMinutes = (walkFromDropoff.travelDuration || 0) / 60;
      const updatedOutboundDropoff = {
        ...outboundDropoffStop,
        walkMinutes: Math.round(walkFromDropoffMinutes),
      };
      setOutboundDropoffStop(updatedOutboundDropoff);

      setOutboundDriveTime(formatDuration(updatedOutboundPickup, updatedOutboundDropoff));
    });
  }, []);

  useEffect(() => {
    if (!inboundPickupStop || !inboundDropoffStop) return;

    // cant use createWalklines for inbound
    userDestination &&
      getRoute2(userDestination.coordinates, inboundPickupStop.place.coordinates, "walking").then(
        (thisLine) => {
          const walkToPickupMinutes = thisLine.data.routes[0].duration / 60;
          setInboundPickupStop({
            ...inboundPickupStop,
            walkMinutes: Math.round(walkToPickupMinutes),
          });
        },
      );

    userOrigin &&
      getRoute2(inboundDropoffStop.place.coordinates, userOrigin.coordinates, "walking").then(
        (thisLine) => {
          const walkFromDropoffMinutes = thisLine.data.routes[0].duration / 60;
          setInboundDropoffStop({
            ...inboundDropoffStop,
            walkMinutes: Math.round(walkFromDropoffMinutes),
          });
        },
      );
  }, []);

  return (
    <>
      <VanpoolInfoCardContainer $color={color} $selected={expanded}>
        <VanpoolInfoCardHeader
          onClick={() => {
            selectCallback && selectCallback();
          }}
        >
          <VanpoolInfoCardColorBlock $color={color} $selected={expanded} />
          <VanpoolInfoCardHeaderInfo>
            <VanpoolInfoCardTitle>
              {route.service.name}
              {expanded ? <CollapseOpenIcon /> : <CollapseClosedIcon />}
            </VanpoolInfoCardTitle>
            <VanpoolInfoCardSpacer />
            <VanpoolInfoCardTimeRow>
              <WalkTimes>
                {outboundPickupStop.walkMinutes !== undefined &&
                  outboundDropoffStop.walkMinutes !== undefined && (
                    <TravelTime
                      type={TRAVEL_TYPE.WALK}
                      minutes={outboundPickupStop.walkMinutes + outboundDropoffStop.walkMinutes}
                    />
                  )}
                <TravelTime type={TRAVEL_TYPE.DRIVE} minutes={outboundDriveTime} />
              </WalkTimes>
              <DaysOfWeekDisplay days={route.service.days} />
            </VanpoolInfoCardTimeRow>
            {shortestWalk && (
              <>
                <VanpoolInfoCardSpacer />
                <ShortestWalkBadge />
              </>
            )}
          </VanpoolInfoCardHeaderInfo>
        </VanpoolInfoCardHeader>
        {expanded && (
          <VanpoolInfoCardDetails>
            <TripStops
              pickupStop={outboundPickupStop}
              dropoffStop={outboundDropoffStop}
              showAddress={false}
              showWalkTimes={true}
            />
            {inboundPickupStop && inboundDropoffStop && (
              <TripStops
                pickupStop={inboundPickupStop}
                dropoffStop={inboundDropoffStop}
                showAddress={false}
                showWalkTimes={true}
              />
            )}
            <MBButton
              overrides={{
                BaseButton: {
                  style: {
                    backgroundColor: color,
                    width: "92%",
                    marginBottom: "10px",
                    ":hover": {
                      backgroundColor: color,
                    },
                  },
                },
              }}
              onClick={viewDetailsCallback}
            >
              View details
            </MBButton>
          </VanpoolInfoCardDetails>
        )}
      </VanpoolInfoCardContainer>
    </>
  );
};
