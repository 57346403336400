import { ntd_report, pool_day, ridershipReport, trip_report } from "interfaces/reports";
import { serviceResultInterface } from "interfaces/route";
import { gql_post } from "misc/http";
import { GQLBase, gqlOp, GQLSchema } from "misc/utils";
import { DateTime } from "luxon";
import moment from "moment";

export const fetchReports = async (params: {
  startDate: Date;
  endDate?: Date;
  accountId?: string;
  orgId?: string;
}) => {
  const data = `
    query getTripReport($accountId: String, $orgId: String, $startDate: String!, $endDate: String) {
      tripReport2(accountId: $accountId, orgId: $orgId, startDate: $startDate, endDate: $endDate) {
        dateStart dateEnd perDay services { id name }
      }
    }
  `;

  const variables = {
    ...params,
    endDate: params.endDate || new Date(),
  };

  interface trip_report_gql {
    perDay: string;
    services: serviceResultInterface[];
    dateStart: string;
    dateEnd: string;
  }

  return gql_post<trip_report_gql>("tripReport2", data, variables).then<trip_report>((r) => {
    const perDay: pool_day[] = JSON.parse(r.perDay);
    perDay.forEach((x) => (x.day = moment(x.day)));
    r.services.forEach((s) => (s.id = s.id.split("+")[0]));
    return {
      perDay,
      services: r.services,
      dateStart: moment(r.dateStart),
      dateEnd: moment(r.dateEnd),
    };
  });
};

export interface ntd_report_gql {
  dateStart: string;
  dateEnd: string;
  report: string;
}

export const parseNTDReportRaw = (r: ntd_report_gql): ntd_report => {
  const report = JSON.parse(r.report);
  report.mr20s.forEach((x) => {
    x.date = moment(x.date);
  });
  return {
    dateStart: moment(r.dateStart),
    dateEnd: moment(r.dateEnd),
    report,
  };
};

export const fetchNTDReport = (params: {
  startDate: Date;
  endDate?: Date;
  accountId?: string;
  orgId?: string;
}) => {
  const data = `
    query getNTDReport($accountId: String, $orgId: String!, $startDate: String!, $endDate: String) {
      ntdReport(accountId: $accountId, orgId: $orgId, startDate: $startDate, endDate: $endDate) {
        dateStart dateEnd report
      }
    }
  `;

  const variables = {
    ...params,
    endDate: params.endDate || new Date(),
  };

  return gql_post<ntd_report_gql>("ntdReport", data, variables).then<ntd_report>((r) =>
    parseNTDReportRaw(r),
  );
};

const ridershipReportSchema: GQLSchema = {
  params: {
    vanpoolIds: "[String]",
    startDate: "String!",
    endDate: "String",
    orgId: "String",
    accountId: "String",
  },
  name: "ridershipReport",
  op: "query",
};

class ridershipReportInterface extends GQLBase {
  perPool = ""; // JSONString
  users = ""; // JSONString
  customFields = ""; // JSONString
  dateStart = DateTime.now();
  dateEnd = DateTime.now();
}

export const fetchRidershipReport = (params: {
  vanpoolIds: string[];
  startDate: DateTime;
  endDate: DateTime;
  orgId?: string;
  accountId?: string;
}) => {
  return gqlOp(
    ridershipReportSchema,
    params,
    new ridershipReportInterface(),
  ).then<ridershipReport.report>((r) => ({
    perPool: JSON.parse(r.perPool).map((pool) => ({
      ...pool,
      days: pool.days.map((day) => {
        return { ...day, date: DateTime.fromISO(day.date, { setZone: true }) };
      }),
    })),
    users: JSON.parse(r.users),
    customFields: JSON.parse(r.customFields),
    dateStart: DateTime.fromISO(r.dateStart as unknown as string, { setZone: true }),
    dateEnd: DateTime.fromISO(r.dateEnd as unknown as string, { setZone: true }),
  }));
};
