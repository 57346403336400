import React, { useEffect, useState } from "react";
import "./DayPicker.scss";

interface DayPickerInterface {
  className?: string;
  selectedDays?: string[];
  onChange?: (availableDays: string[]) => void;
}

interface dayOfWeek {
  label: string;
  value: string;
  checked: boolean;
}

const DayPicker: React.FC<DayPickerInterface> = ({
  selectedDays,
  onChange,
  className = "",
}: DayPickerInterface) => {
  const [days, setDays] = useState<Array<dayOfWeek>>([
    { label: "Sunday", value: "SU", checked: false },
    { label: "Monday", value: "MO", checked: false },
    { label: "Tuesday", value: "TU", checked: false },
    { label: "Wednesday", value: "WE", checked: false },
    { label: "Thursday", value: "TH", checked: false },
    { label: "Friday", value: "FR", checked: false },
    { label: "Saturday", value: "SA", checked: false },
  ]);
  useEffect(() => {
    if (selectedDays) {
      setDays([
        {
          label: "S",
          value: "SU",
          checked: selectedDays?.includes("SU"),
        },
        {
          label: "M",
          value: "MO",
          checked: selectedDays?.includes("MO"),
        },
        {
          label: "T",
          value: "TU",
          checked: selectedDays?.includes("TU"),
        },
        {
          label: "W",
          value: "WE",
          checked: selectedDays?.includes("WE"),
        },
        {
          label: "T",
          value: "TH",
          checked: selectedDays?.includes("TH"),
        },
        {
          label: "F",
          value: "FR",
          checked: selectedDays?.includes("FR"),
        },
        {
          label: "S",
          value: "SA",
          checked: selectedDays?.includes("SA"),
        },
      ]);
    }
  }, [selectedDays]);

  const handleChange = (dayToggle) => {
    const updatedDays: Array<dayOfWeek> = days.map((day, index, data) => {
      if (day.value === dayToggle) {
        return {
          label: dayToggle,
          value: day.value,
          checked: !data[index].checked,
        };
      } else {
        return day;
      }
    });
    setDays(updatedDays);
    if (onChange) {
      const availableDays: Array<string> = [];
      updatedDays.forEach((day) => {
        if (day.checked) {
          availableDays.push(day.value);
        }
      });
      onChange(availableDays);
    }
  };
  return (
    <div className="DayPicker">
      <div className="DayPicker-Frame">
        {days.map((day) => {
          return (
            <div key={className + day.value}>
              <input
                className="DayPicker-Input"
                type="checkbox"
                id={className + day.value}
                checked={day.checked}
                onChange={() => handleChange(day.value)}
              />
              <label className="DayPicker-Day" htmlFor={className + day.value}>
                {day.label}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DayPicker;
