import { DateTime } from "luxon";
import moment from "moment";
import { serviceResultInterface } from "./route";
import { RIDE_STATUS, USER_RIDE_STATUS } from "./vanpool";

export interface pool_data {
  id: string; // vanpool id
  trips: {
    dynamic: number;
    subscribed: number;
    emptySeats: number;
    unconfirmed: number;
    canceled: number;
    waitlisted: number;
    firstTime: number;
  };
  rides: {
    canceled: number;
    noDriver: number;
    noVehicle: number;
    noRiders: number;
    overbooked: number;
    ok: number;
  };
  vmtReduction: number;
}

export interface pool_day {
  day: moment.Moment;
  pools: pool_data[];
  routes: {
    active: number;
    inactive: number;
  };
}

export interface trip_report {
  perDay: pool_day[];
  services: serviceResultInterface[];
  dateStart: moment.Moment;
  dateEnd: moment.Moment;
}

// NTD

export enum ntd_s10_average_range {
  weekday = 0,
  saturday = 1,
  sunday = 2,
}

export interface ntd_mr20 {
  date: moment.Moment;
  vrm: number; // total miles across fleet during month
  vrh: number; // total hours across fleet during month
  upt: number; // total passenger trips across fleet during month
  pmt: number; // total passenger miles across fleet during month
  voms: number; // total vehicles operating (at least once) during the month
}

export interface ntd_average {
  range: ntd_s10_average_range;
  begin: number; // earliest start time
  end: number; // latest end time

  vam: number; // total vehicle actual miles
  vrm: number; // total vehicle revenue miles

  vah: number; // total vehicle actual hours
  vrh: number; // total vehicle revenue hours

  upt: number; // average unlinked passenger trips
  pmt: number; // average passenger miles traveled

  voms: number; // maximum number of vehicles operated

  operated: number; // total days with > 0 vanpools operated
  not_operated_emergency: number; // total days with 0 vanpools operated (due to declared emergency)
  not_operated_strike: number; // total days with 0 vanpools operated (due to strike)
}

export interface ntd_report {
  dateStart: moment.Moment;
  dateEnd: moment.Moment;
  report: {
    s10: {
      averages: { [key in ntd_s10_average_range]: ntd_average };
      vams: number;
      voms: number;
    };
    mr20s: ntd_mr20[];
  };
}

export namespace ridershipReport {
  export enum CUSTOM_FIELD_TYPE {
    TEXT = 1,
    SELECT = 2,
    SELECT_WITH_OTHER = 3,
    BOOLEAN = 4,
  }

  export enum CUSTOM_FIELD_TARGET {
    USER = 1,
    NONE = 2,
  }

  export interface customFieldOptionInterface {
    id: string;
    name: string;
    child?: customFieldInterface;
  }

  export interface customFieldInterface {
    id: string;
    disabled: boolean;
    name: string;
    target: CUSTOM_FIELD_TARGET;
    type: CUSTOM_FIELD_TYPE;
    options?: customFieldOptionInterface[];
    tags?: string[];
  }

  export const linkCustomFields = (fields: customFieldInterface[]) => {
    const by_id = new Map<string, customFieldInterface>(fields.map((f) => [f.id, f]));
    fields.forEach((f) => {
      f.options?.forEach((o) => {
        if (o.child) {
          o.child = by_id.get(o.child as any);
        }
      });
    });
    return fields;
  };

  export interface customFieldValueInterface {
    field: customFieldInterface;
    value?: any; // JSON string encoded
    child?: customFieldValueInterface;
  }

  export const parseCustomFieldValue = (
    fields: customFieldInterface[],
    c: customFieldValueInterface,
  ): customFieldValueInterface => ({
    ...c,
    field: fields.find((x) => x.id === (c.field as any))!,
    child: c.child ? parseCustomFieldValue(fields, c.child) : undefined,
  });

  export const customFieldGetValueDisplay = (
    field: customFieldInterface,
    value: customFieldValueInterface | undefined,
  ) => {
    if (field.type === CUSTOM_FIELD_TYPE.SELECT) {
      return field.options?.find((x) => x.id === value?.value)?.name;
    } else if (field.type === CUSTOM_FIELD_TYPE.SELECT_WITH_OTHER) {
      return field.options?.find((x) => x.id === value?.value)?.name || value?.value;
    } else if (field.type === CUSTOM_FIELD_TYPE.BOOLEAN) {
      return value?.value ? "true" : "false";
    }
    return value?.value;
  };

  export const customFieldValueDisplay = (value: customFieldValueInterface) => {
    return (
      customFieldGetValueDisplay(value.field, value) +
      (value.child ? ", " + customFieldValueDisplay(value.child) : "")
    );
  };

  export interface pool {
    poolId: string;
    name: string;
    internalName: string;
    days: day[];
  }

  export interface schedule {
    vrm: number; // vehicle revenue miles
    vrh: number; // vehicle revenue hours
  }

  export interface day {
    date: DateTime;
    members: user[];
    rideStatus: RIDE_STATUS;
    outbound: schedule;
    inbound?: schedule;
  }

  export interface user {
    userId: string;
    ridership: USER_RIDE_STATUS;
    pmt: number; // passenger miles traveled
    upt: number; // unlinked passenger trips
    ptt: number; // passenger trip time
  }

  export interface userProfile {
    userId: string;
    name: string;
    new: boolean;
    customFieldValues?: customFieldValueInterface[];
    ji?: boolean; // org-specific custom field interpretation
  }

  export interface report {
    perPool: pool[];
    users: userProfile[];
    customFields: customFieldInterface[];
    dateStart: DateTime;
    dateEnd: DateTime;
  }
}
