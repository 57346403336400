import React from "react";
import { styled, useStyletron } from "baseui";

const Container = styled("div", ({ $theme }) => ({
  display: "flex",
  alignItems: "center",
}));

const Count = styled("div", ({ $theme }) => ({
  paddingTop: "3px",
  fontFamily: $theme.typography.LabelMedium.fontFamily,
  color: $theme.colors.mono800,
}));

export const RiderCountIcon = ({
  riderCount,
  overbooked = false,
}: {
  riderCount: number;
  overbooked?: boolean;
}) => {
  const [css, theme] = useStyletron();

  return (
    <Container>
      <svg
        width="14px"
        height="14px"
        viewBox="0 0 14 14"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g
          className={css({
            fill: overbooked ? theme.colors.negative : theme.colors.mono700,
            strokeWidth: "1px",
          })}
          fillRule="evenodd"
        >
          <path d="M6,8.25 L8,8.25 C10.209139,8.25 12,10.040861 12,12.25 L12,13.25 L12,13.25 L2,13.25 L2,12.25 C2,10.040861 3.790861,8.25 6,8.25 Z"></path>
          <circle cx="7" cy="4.25" r="3.5"></circle>
        </g>
      </svg>
      <Count
        className={css({
          color: overbooked ? theme.colors.negative : theme.colors.mono700,
        })}
      >
        {riderCount}
      </Count>
    </Container>
  );
};

export const SeatsCountIcon = ({
  seatCount,
  overbooked = false,
}: {
  seatCount: number;
  overbooked?: boolean;
}) => {
  const [css, theme] = useStyletron();

  return (
    <Container>
      <svg
        width="14px"
        height="14px"
        viewBox="0 0 14 14"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g
          className={css({
            fill: overbooked ? theme.colors.negative : theme.colors.mono700,
            strokeWidth: "1px",
          })}
          fillRule="evenodd"
        >
          <path d="M10.0665289,0.868985767 L11.5928041,1.16566361 C12.1349418,1.2710445 12.4890032,1.79596206 12.3836223,2.33809978 L12.2830755,2.85536811 L12.2718986,2.90665523 C12.2694943,2.92374869 12.2666306,2.94087291 12.2632986,2.95801468 L11.7897547,5.39042595 L11.7863601,5.42229093 C11.7841571,5.43718962 11.7816176,5.45203853 11.7787431,5.46682627 L10.7045611,10.9968059 C10.5886009,12.3000864 9.49380816,13.3218232 8.16037596,13.3218232 C8.0966659,13.3218232 8.03443739,13.3174006 7.9738001,13.308947 L7.86915497,13.3200257 L7.80922251,13.3218232 L3.87019967,13.3218232 C2.92467587,13.3218232 2.10833458,12.6596747 1.9132378,11.7344977 L1.70947745,10.7682369 C1.59552054,10.2278368 1.94122104,9.6973756 2.48162114,9.58341868 C2.58419958,9.56178744 2.68954145,9.55642075 2.79378711,9.56751524 L7.2914371,10.0447587 C7.42893234,8.81786091 7.19379901,7.75074498 7.53918564,6.29024221 C7.74607861,5.41537411 8.02215597,4.79231281 8.36741772,4.42105833 C8.57965841,4.19273108 8.88648491,4.07709748 9.19671998,4.10764674 L10.0237547,4.19142595 L10.1657547,3.46242595 L9.58436418,3.34950846 C9.04222646,3.24412756 8.68816509,2.71921001 8.79354599,2.17707228 L8.89409277,1.65980395 C8.99947367,1.11766623 9.52439122,0.763604869 10.0665289,0.868985767 Z"></path>
        </g>
      </svg>
      <Count
        className={css({
          color: overbooked ? theme.colors.negative : theme.colors.mono700,
        })}
      >
        {seatCount}
      </Count>
    </Container>
  );
};
