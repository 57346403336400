import { fetchRiderAttendance } from "api/trips";
import { MBButton } from "components/common/Buttons/Buttons";
import UserDisplay from "components/common/UserDisplay/UserDisplay";
import useMBContext from "context/useMBContext";
import { ATTENDANCE_TYPE, tripRiderInterface } from "interfaces/trip";
import { INSTABOOK_ACTION } from "interfaces/vanpool";
import React, { useEffect, useState } from "react";

interface RiderBlockInterface {
  riders: tripRiderInterface[];
  allowAttendance?: boolean;
  updateDataCallback?: () => void;
}

export const RiderBlock: React.FC<RiderBlockInterface> = ({
  riders,
  allowAttendance = false,
  updateDataCallback,
}) => {
  const { user } = useMBContext();
  const [tripRiders, setTripRiders] = useState<tripRiderInterface[]>(riders);

  useEffect(() => {
    setTripRiders(riders);
  }, [riders]);

  const riderAttendance = (rider: tripRiderInterface) => {
    const action = rider.trip.attendance ? INSTABOOK_ACTION.CANCEL : INSTABOOK_ACTION.BOOK;
    fetchRiderAttendance({
      tripId: rider.trip.id,
      type: ATTENDANCE_TYPE.MANUAL,
      action,
    }).then((trip) => {
      // update trip record
      const trip2 = { ...rider.trip, ...trip };
      const r = [...tripRiders];
      r.forEach((x) => {
        if (x.rider.id === rider.rider.id) {
          x.trip = trip2;
        }
      });
      setTripRiders(r);
      updateDataCallback?.();
    });
  };

  const attendanceButton = (rider: tripRiderInterface) => {
    const buttonLabel = rider.trip.attendance ? "✓ On van" : "Check in";
    const buttonKind = rider.trip.attendance ? "primary" : "secondary";

    return (
      <MBButton
        kind={buttonKind}
        onClick={() => {
          riderAttendance(rider);
        }}
        overrides={{
          BaseButton: {
            style: {
              whiteSpace: "nowrap",
              width: "94px",
              height: "44px",
            },
          },
        }}
      >
        {buttonLabel}
      </MBButton>
    );
  };

  const riderLabel = (rider: tripRiderInterface) => {
    const className = rider.rider.id === user?.id ? "HighlightedRider" : "Rider";
    return (
      <div className={className}>
        <UserDisplay user={rider.rider} />
      </div>
    );
  };

  return (
    <div className="RiderBlock">
      {tripRiders && (
        <div className="RiderList">
          {tripRiders.map((rider) => {
            return (
              <div className="SingleRider" key={rider.rider.id}>
                {riderLabel(rider)}
                {allowAttendance && attendanceButton(rider)}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
