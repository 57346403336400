import { GQLBase } from "misc/utils";
import { tripPropertiesInterface } from "./trip";
import { coordinate } from "./route";
import { addressInterface } from "./place";
import { autoAssignSettingsInterface, vanpoolSearchSettingsInterface } from "./vanpool";

export enum ORG_USER_FLAG {
  NO_FEE = "NO_FEE",
}

export enum VANPOOLER_ROLE {
  RIDER = "RIDER",
  DRIVER = "DRIVER",
  COORDINATOR = "COORDINATOR",
}

export enum ORG_USER_ROLE {
  OWNER = "OWNER",
  MANAGER = "MANAGER",
  DRIVER = "DRIVER",
  RIDER = "RIDER",
  VANPOOL_MANAGER = "VANPOOL_MANAGER",
}

export const OrganizationUserRoles = [
  { label: "Rider", value: ORG_USER_ROLE.RIDER },
  { label: "Driver", value: ORG_USER_ROLE.DRIVER },
  { label: "Manager", value: ORG_USER_ROLE.MANAGER },
  { label: "Owner", value: ORG_USER_ROLE.OWNER },
  {
    label: "Vanpool Manager",
    value: ORG_USER_ROLE.VANPOOL_MANAGER,
  },
];

export class userSimpleInterface extends GQLBase {
  id: string = "";
  name: string = "";
}

export class userProfileInterface extends userSimpleInterface {
  email?: string = "";
  phone?: string = "";
}

export class userOrgRoleInterface extends userProfileInterface {
  organizationId = "";
  roles = [ORG_USER_ROLE.RIDER];
}

export class userInterface extends userProfileInterface {
  home? = new addressInterface();
  search? = new vanpoolSearchSettingsInterface();
}

export interface organizationRouteMakerInterface {
  logoURL: string;
}

export interface userOrganizationInterface {
  id: string;
  name: string;
  accountId: string;
  routeMaker?: organizationRouteMakerInterface;
  defaultMapLocation?: {
    coordinates: coordinate;
  };
  roles: ORG_USER_ROLE[];
}

export interface userAccountInterface {
  id: string;
  name: string;
  isMagicBus: boolean;
  roles: ORG_USER_ROLE[];
}

export interface userOrgsInterface extends userInterface {
  organizations: userOrganizationInterface[];
  accounts: userAccountInterface[];
}

export class myPoolMemberProfileInterface extends GQLBase {
  properties? = new tripPropertiesInterface();
  serviceId?: string;
  autoAssign? = new autoAssignSettingsInterface();
  userRoles: VANPOOLER_ROLE[] = [VANPOOLER_ROLE.RIDER];
  orgRoles: ORG_USER_ROLE[] = [ORG_USER_ROLE.RIDER];
}

export class myPoolMemberInterface extends userInterface {
  profile = new myPoolMemberProfileInterface();
}
