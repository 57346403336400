import React from "react";
import { mergeOverrides } from "baseui";
import { Overrides } from "baseui/overrides";
import { Select, SelectProps } from "baseui/select";

// wrapper component with default MB style override since baseweb doesn't
// support global component style overrides

const MBSelect: React.FC<SelectProps> = ({ overrides, ...props }) => {
  return (
    <Select
      overrides={mergeOverrides(
        {
          ValueContainer: {
            style: { backgroundColor: "white" },
          },
          ControlContainer: {
            style: { backgroundColor: "white" },
          },
        },
        overrides as Overrides<any>,
      )}
      {...props}
    />
  );
};

export { MBSelect };
