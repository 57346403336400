import React from "react";
import { Textarea, TextareaProps } from "baseui/textarea";
import { Overrides } from "baseui/overrides";
import { mergeOverrides, useStyletron } from "baseui";

// wrapper component with default MB style override since baseweb doesn't
// support global component style overrides

const MBTextarea: React.FC<TextareaProps> = ({ overrides, ...props }) => {
  const [css, theme] = useStyletron();

  return (
    <Textarea
      overrides={mergeOverrides(
        {
          Root: {
            style: {
              border: `solid 1px ${theme.colors.borderOpaque}`,
            },
          },
          Input: {
            style: {
              backgroundColor: "white",
              minHeight: "50px",
              height: "50px",
              resize: "vertical",
              "@media screen and (max-width: 576px)": {
                resize: "none",
              },
            },
          },
        },
        overrides as Overrides<any>,
      )}
      {...props}
    />
  );
};

export { MBTextarea };
