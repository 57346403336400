import { MBTheme } from "assets/theme";
import { coordinate } from "interfaces/route";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import { Marker } from "react-mapbox-gl";
import { styled } from "baseui";

const SelectedMarker = styled<"div", { $color: string }>("div", ({ $theme, $color }) => ({
  width: "10px",
  height: "10px",
  background: $theme.colors.white,
  border: `2px solid ${$color}`,
  borderRadius: "50%",
}));

const InRouteMarker = styled<"div", { $color: string }>("div", ({ $theme, $color }) => ({
  width: "7px",
  height: "7px",
  background: $color,
  opacity: 0.66,
  border: `1px solid ${$color}`,
  borderRadius: "50%",
}));

const OutOfRouteMarker = styled<"div", { $selected: boolean | undefined }>(
  "div",
  ({ $theme, $selected }) => ({
    width: $selected ? "10px" : "7px",
    height: $selected ? "10px" : "7px",
    background: "#A3ABAF",
    border: $selected ? "1px solid #758188" : "none",
    borderRadius: "50%",
  }),
);

interface StopMarkerProps {
  inRoute: boolean;
  coord: coordinate;
  selected?: boolean;
  colorHex?: string;
}

export const StopMarker: React.FC<StopMarkerProps> = ({
  inRoute,
  coord,
  selected,
  colorHex = MBTheme.colors.primary,
}) => {
  const id = uuidv4();

  return (
    <Marker key={"circle-marker-" + id} coordinates={[coord.lng, coord.lat]} anchor="center">
      {inRoute ? (
        selected ? (
          <SelectedMarker $color={colorHex} />
        ) : (
          <InRouteMarker $color={colorHex} />
        )
      ) : (
        <OutOfRouteMarker $selected={selected} />
      )}
    </Marker>
  );
};
