import React, { useEffect, useState } from "react";
import {
  BaseModal,
  ContentScroller,
  ModalTitle,
  ModalSeparator,
  SearchField,
  SimpleModalSection,
  ButtonStack,
} from "components/common/Modals/BaseModal/BaseModal";
import { fetchAdminUsers } from "api/user";
import { riderVanpoolInterface } from "interfaces/vanpool";
import { userInterface } from "interfaces/user";
import { MBButton } from "components/common/Buttons/Buttons";
import { styled } from "baseui";

const SelectorBubble = styled<"div", { $filled?: boolean }>("div", ({ $theme, $filled }) => ({
  borderRadius: "50%",
  width: "11px",
  height: "11px",
  outline: `solid 1px ${$theme.colors.borderOpaque}`,
  outlineOffset: "3px",
  backgroundColor: $filled ? $theme.colors.primary : "none",
}));

const UserContainer = styled<"div", { $selected?: boolean; $disabled?: boolean }>(
  "div",
  ({ $theme, $selected, $disabled }) => ({
    display: "grid",
    gridTemplateColumns: "10% 90%",
    padding: $selected ? "17px 24px" : "18px 25px",
    margin: "0px 15px",
    border: $selected ? `solid 1px ${$theme.colors.primary}` : "none",
    borderRadius: "8px",
    alignItems: "center",
    justifyContent: "left",
    opacity: $disabled ? "50%" : "100%",
  }),
);

interface AdminAddUserModalProps {
  pool: riderVanpoolInterface;
  addMembersCallback: (newUserIds: string[]) => void;
}

export const AdminAddMemberModal: React.FC<AdminAddUserModalProps> = ({
  pool,
  addMembersCallback,
}) => {
  const [show, setShow] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [users, setUsers] = useState<userInterface[]>([]);
  const [selectedUser, setSelectedUser] = useState<string>("");

  useEffect(() => {
    setSelectedUser("");
    setSearch("");
  }, [show]);

  useEffect(() => {
    if (!show) return;
    fetchAdminUsers({ orgId: pool.organizationId }).then((users) => {
      const newUsers = users.filter((u) => !pool.members.some((p) => p.id === u.id));
      setUsers(newUsers);
    });
  }, [show, pool]);

  return (
    <>
      <BaseModal.Modal isOpen={show} onClose={() => setShow(false)}>
        <BaseModal.Header>
          <ModalTitle>Add Member</ModalTitle>
        </BaseModal.Header>
        <BaseModal.Body>
          <ModalSeparator />
          <SearchField
            value={search}
            onChange={(e) => {
              setSearch(e.currentTarget.value);
            }}
          />
          <ModalSeparator />
          <ContentScroller>
            {users
              .filter((user) => {
                const lowercaseSearch = search.toLowerCase();

                return (
                  user.name.toLowerCase().includes(lowercaseSearch) ||
                  user.email?.toLowerCase().includes(lowercaseSearch)
                );
              })
              .map((user) => (
                <UserContainer
                  $selected={selectedUser === user.id}
                  onClick={() => {
                    setSelectedUser(selectedUser === user.id ? "" : user.id);
                  }}
                >
                  <SelectorBubble $filled={selectedUser === user.id} />
                  {user.name}
                </UserContainer>
              ))}
          </ContentScroller>
          <ModalSeparator />
        </BaseModal.Body>
        <BaseModal.Footer>
          <SimpleModalSection>
            <ButtonStack>
              <MBButton
                kind="primary"
                disabled={selectedUser === ""}
                onClick={() => addMembersCallback([selectedUser])}
              >
                Add member
              </MBButton>
              <MBButton kind="tertiary" onClick={() => setShow(false)}>
                Cancel
              </MBButton>
            </ButtonStack>
          </SimpleModalSection>
        </BaseModal.Footer>
      </BaseModal.Modal>
      <MBButton flex kind="tertiary" onClick={() => setShow(true)}>
        Add member
      </MBButton>
    </>
  );
};
