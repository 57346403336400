import { styled } from "baseui";
import { MBButton } from "components/common/Buttons/Buttons";
import {
  BaseModal,
  ButtonStack,
  ModalTitle,
  SimpleModalSection,
} from "components/common/Modals/BaseModal/BaseModal";
import { userBaseInterface } from "interfaces/user";
import React, { useState } from "react";

interface NeedRideHelpModalInterface {
  driver: userBaseInterface;
}

export const NeedRideHelpModal: React.FC<NeedRideHelpModalInterface> = ({ driver }) => {
  const [show, setShow] = useState<boolean>(false);
  const DriverNameSection = styled("div", ({ $theme }) => ({
    textAlign: "center",
  }));

  const DriverLabel = styled("div", ({ $theme }) => ({
    fontSize: "12px",
    color: $theme.colors.mono800,
    marginBottom: "4px",
  }));

  const DriverName = styled("div", ({ $theme }) => ({
    fontSize: "15px",
    color: $theme.colors.mono800,
  }));

  return (
    <>
      <BaseModal.Modal isOpen={show} onClose={() => setShow(false)}>
        <BaseModal.Header>
          <SimpleModalSection>
            <ModalTitle>What do you need help with?</ModalTitle>
            <DriverNameSection>
              <DriverLabel>DRIVER</DriverLabel>
              <DriverName>{driver.name}</DriverName>
            </DriverNameSection>
          </SimpleModalSection>
        </BaseModal.Header>
        <BaseModal.Body>
          <SimpleModalSection>
            <ButtonStack>
              <MBButton kind="secondary" onClick={() => window.open(`tel:${driver.phone}`)}>
                Call driver
              </MBButton>
              <MBButton kind="secondary" onClick={() => window.open(`sms:${driver.phone}`)}>
                Text driver
              </MBButton>
            </ButtonStack>
          </SimpleModalSection>
        </BaseModal.Body>
        <BaseModal.Footer>
          <SimpleModalSection>
            <ButtonStack>
              <MBButton kind="tertiary" onClick={() => setShow(false)}>
                Cancel
              </MBButton>
            </ButtonStack>
          </SimpleModalSection>
        </BaseModal.Footer>
      </BaseModal.Modal>
      <MBButton flex kind="secondary" onClick={() => setShow(true)}>
        Contact support
      </MBButton>
    </>
  );
};
