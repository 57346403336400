import React, { createContext, useState } from "react";
import "./ConfirmModal.scss";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "baseui/modal";
import { MBButton } from "../Buttons/Buttons";
import { Moment } from "moment";
import { DateDisplay } from "../DateDisplay/DateDisplay";

interface ConfirmModalBaseElements {
  headerElement: JSX.Element;
  bodyElement: JSX.Element;
}

interface ConfirmModalBaseProps {
  onConfirmCallback?: (...args: any) => any;
  hideModalCallback?: (...args: any) => any;
  confirmButtonText?: string;
  cancelButtonText?: string;
  confirmType?: "default" | "destructive";
}

export interface ConfirmModalBaseInterface
  extends ConfirmModalBaseElements,
    ConfirmModalBaseProps {}

const ConfirmModalBase: React.FC<{ show: boolean } & ConfirmModalBaseInterface> = ({
  show,
  headerElement,
  bodyElement,
  onConfirmCallback,
  hideModalCallback,
  confirmButtonText = "Confirm",
  cancelButtonText = "Cancel",
  confirmType = "default",
}) => {
  const handleConfirm = () => {
    onConfirmCallback?.();
    hideModalCallback?.();
  };

  return (
    <Modal
      isOpen={show}
      onClose={hideModalCallback}
      autoFocus={false}
      overrides={{
        Dialog: {
          style: () => ({
            textAlign: "center",
          }),
        },
        Close: {
          style: () => ({
            display: "none",
          }),
        },
      }}
    >
      <ModalHeader>{headerElement}</ModalHeader>
      <ModalBody>{bodyElement}</ModalBody>
      <ModalFooter>
        <div className="ConfirmModal ButtonStack">
          <MBButton
            onClick={handleConfirm}
            kind="primary"
            destructive={confirmType === "destructive"}
          >
            {confirmButtonText}
          </MBButton>
          <MBButton kind="tertiary" onClick={hideModalCallback}>
            {cancelButtonText}
          </MBButton>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export interface ConfirmModalInterface extends ConfirmModalBaseProps {
  message: string;
  title?: string;
  date?: Moment;
}

const ConfirmModal: React.FC<
  { show: boolean } & ConfirmModalInterface & Partial<ConfirmModalBaseElements>
> = ({
  confirmButtonText = "Confirm",
  cancelButtonText = "Cancel",
  message,
  title,
  date,
  ...props
}) => {
  return (
    <ConfirmModalBase
      confirmButtonText={confirmButtonText}
      cancelButtonText={cancelButtonText}
      headerElement={
        <>
          <p>{title && title}</p>
          <p>{date && <DateDisplay date={date} />}</p>
        </>
      }
      bodyElement={<>{message}</>}
      {...props}
    />
  );
};

export { ConfirmModalBase, ConfirmModal };

export type ConfirmModalPassthroughInterface = ConfirmModalInterface &
  Partial<ConfirmModalBaseElements>;

export interface ConfirmModalContextInterface {
  showConfirmModal: (params: ConfirmModalPassthroughInterface) => void;
}

export const ConfirmModalContext = createContext<ConfirmModalContextInterface>(
  {} as ConfirmModalContextInterface,
);

export const ConfirmModalContextProvider: React.FC<{
  value?: { [key: string]: any };
}> = ({ children, value }) => {
  const defaultParams: ConfirmModalInterface = {
    message: "",
    confirmButtonText: "Yes",
    cancelButtonText: "No",
  };
  const [show, setShow] = useState<boolean>(false);
  const [params, setParams] = useState<ConfirmModalPassthroughInterface>(defaultParams);

  const handleHide = () => {
    setShow(false);
  };

  const showConfirmModal = (newParams: ConfirmModalPassthroughInterface) => {
    setParams({ ...defaultParams, ...newParams });
    setShow(true);
  };

  return (
    <ConfirmModalContext.Provider
      value={{
        ...value,
        showConfirmModal,
      }}
    >
      <ConfirmModal show={show} {...params} hideModalCallback={handleHide} />
      {children}
    </ConfirmModalContext.Provider>
  );
};
