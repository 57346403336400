import React, { createContext, Dispatch, SetStateAction, useEffect, useState } from "react";
import { searchResults } from "api/vanpools";
import { fetchCurrentUserOrgs } from "api/user";
import { errorModalPopUpInterface } from "interfaces/errormodal";
import { coordinate, routeDisplayInterface, serviceResultInterface } from "interfaces/route";
import {
  ORG_USER_ROLE,
  userAccountInterface,
  userOrganizationInterface,
  userOrgsInterface,
} from "interfaces/user";
import { userLocationInterface } from "interfaces/place";
import { colorBlindFriendlyPalette, theme } from "assets/theme";
import { tripDetailInterface, tripPropertiesInterface } from "interfaces/trip";
import { computeDisplayRoute, defaultDisplayRoute } from "misc/routing";
import { authorize_action_log_in_sso, authorize_action_log_out, routes } from "misc/http";
import { defaultMapDisplayOptions, mapDisplayOptions } from "components/Map";
import { fetchUserLocation } from "api/ipinfo";
import { getRoute } from "api/mapbox";
import { lineDisplayInterface, MAP_LINE_TYPE } from "components/Map/mapline";

export interface globalContextInterface {
  user: userOrgsInterface | null | undefined;
  selectedAccount: userAccountInterface | undefined;
  setAccount: (id: string | null) => void;
  selectedOrganization: userOrganizationInterface | undefined;
  setOrganization: (id: string | null) => void;
  isMBAdmin: boolean;
  isAccManagerRole: boolean;
  isOrgManagerRole: boolean;
  isVPMRole: boolean;
  isAccManagerRoleOrGreater: boolean;
  isOrgManagerRoleOrGreater: boolean;
  isVPMRoleOrGreater: boolean;
  isVanpoolManager: boolean;
  ssoLogin: (params: any) => Promise<userOrgsInterface>;
  logout: () => void;
  findDynamicRoutes: (
    radius: number,
    start?: coordinate,
    end?: coordinate,
  ) => Promise<routeDisplayInterface[]>;
  allRoutes: routeDisplayInterface[];
  setAllRoutes: (routes: routeDisplayInterface[]) => void;
  updateRouteWithStops: (
    route: routeDisplayInterface,
    props: tripPropertiesInterface,
  ) => routeDisplayInterface;
  displayRoutes: routeDisplayInterface[];
  setDisplayRoutes: (routes: routeDisplayInterface[]) => void;
  selectedRouteID: string | null;
  setSelectedRouteID: Dispatch<SetStateAction<string | null>>;
  highlightedRouteID: string | null;
  setHighlightedRoute: Dispatch<SetStateAction<string | null>>;
  resetRoutes: () => void;

  errorModalData: errorModalPopUpInterface;
  setErrorModalData: Dispatch<SetStateAction<errorModalPopUpInterface>>;
  helpModalVisible: boolean;
  setHelpModalVisible: Dispatch<SetStateAction<boolean>>;
  shareModalVisible: boolean;
  setShareModalVisible: Dispatch<SetStateAction<boolean>>;
  feedbackTripId: string[];
  setFeedbackTripId: Dispatch<SetStateAction<string[]>>;
  userLocation: userLocationInterface;
  setUserLocation: Dispatch<SetStateAction<userLocationInterface>>;
  getUserLocation: () => Promise<userLocationInterface>;
  userOrigin: userLocationInterface | undefined;
  setUserOrigin: Dispatch<SetStateAction<userLocationInterface | undefined>>;
  userDestination: userLocationInterface | undefined;
  setUserDestination: Dispatch<SetStateAction<userLocationInterface | undefined>>;
  suggestedLine: lineDisplayInterface[];
  setSuggestedLine: Dispatch<SetStateAction<lineDisplayInterface[]>>;
  createWalklines: (origin: coordinate, destination: coordinate) => Promise<lineDisplayInterface[]>;

  mapDisplayOptions: mapDisplayOptions;
  setMapDisplayOptions: Dispatch<SetStateAction<mapDisplayOptions>>;
  mapView: string;
  setMapView: Dispatch<SetStateAction<string>>;
}

export const defaultLocation = {
  locationType: "default",
  coordinates: {
    lat: theme.defaultLocation.lat,
    lng: theme.defaultLocation.lng,
  },
  city: "Los Angeles",
  state: "Ca",
  zipCode: "90000",
};

export const GlobalContext = createContext<globalContextInterface>({} as globalContextInterface);

export const GlobalContextProvider: React.FC<{
  value?: { [key: string]: any };
}> = ({ children, value }) => {
  // user == null for not logged in, user == undefined for loading
  const [user, setUser] = useState<userOrgsInterface | null | undefined>(undefined);
  const [selectedOrganization, setSelectedOrganization] = useState<
    userOrganizationInterface | undefined
  >(undefined);
  const [selectedAccount, setSelectedAccount] = useState<userAccountInterface | undefined>(
    undefined,
  );
  const [userLocation, setUserLocation] = useState<userLocationInterface>(defaultLocation);
  const [userOrigin, setUserOrigin] = useState<userLocationInterface | undefined>();
  const [userDestination, setUserDestination] = useState<userLocationInterface | undefined>();
  const [suggestedLine, setSuggestedLine] = useState<lineDisplayInterface[]>([]);
  const [mapView, setMapView] = useState<string>("default");
  const [mapDisplayOptions, setMapDisplayOptions] =
    useState<mapDisplayOptions>(defaultMapDisplayOptions);
  const [errorModalData, setErrorModalData] = useState<errorModalPopUpInterface>({
    show: false,
    title: "",
    content: "",
  });
  const [shareModalVisible, setShareModalVisible] = useState<boolean>(false);
  const [helpModalVisible, setHelpModalVisible] = useState<boolean>(false);
  const [feedbackTripId, setFeedbackTripId] = useState<string[]>([]);

  const [allRoutes, setAllRoutes] = useState<routeDisplayInterface[]>([]);
  const [displayRoutes, setDisplayRoutes] = useState<routeDisplayInterface[]>([]);
  const [selectedRouteID, setSelectedRouteID] = useState<string | null>(null);
  const [highlightedRouteID, setHighlightedRoute] = useState<string | null>(null);

  const _setAccount = (account?: userAccountInterface) => {
    setSelectedAccount(account);
    localStorage.setItem("accountId", account?.id || "null");
  };

  const _setOrganization = (organization?: userOrganizationInterface) => {
    setSelectedOrganization(organization);
    localStorage.setItem("orgId", organization?.id || "null");
  };

  const setAccount = (accountId: string | null) => {
    const thisAccount = user?.accounts.find((account) => account.id === accountId);
    _setAccount(thisAccount);
  };

  const setOrganization = (orgId: string | null) => {
    const thisOrg = user?.organizations.find((org) => org.id === orgId);
    _setOrganization(thisOrg);
  };

  const getCurrentUser = () => {
    return fetchCurrentUserOrgs()
      .then((thisUser) => {
        if (!thisUser) {
          _setAccount(undefined);
          _setOrganization(undefined);
          setUser(null);
          return null;
        }

        // select the saved account if available, otherwise default to the first account
        const thisAccount = thisUser.accounts.find(
          (x) => x.id === localStorage.getItem("accountId"),
        );

        const thisOrganization = thisUser.organizations.find(
          (x) => x.id === localStorage.getItem("orgId"),
        );

        _setAccount(thisAccount);
        _setOrganization(thisOrganization);
        setUser(thisUser);

        // set map location to organization default (will be overridden per-page)
        const coords = thisUser?.organizations?.find((x) => !!x.defaultMapLocation)
          ?.defaultMapLocation?.coordinates;
        if (coords) {
          setUserLocation({
            coordinates: coords,
            locationType: "orgDefault",
          });
        }
        return thisUser;
      })
      .catch((error) => {
        logout();
        throw error;
      });
  };

  // The permissions below are explicit, rather than implicit;
  // This means higher level permissions do not cascade to lower levels.
  // For example, an admin will NOT have accManager or orgManager roles.
  // This means that to expose a feature, all allowed roles should be listed explicitly
  // rather than relying on the cascade.

  const isMBAdmin = user?.accounts.find((acc) => acc.isMagicBus) !== undefined;

  /// true if the user can manage the current account
  const isAccManagerRole =
    !isMBAdmin &&
    [ORG_USER_ROLE.MANAGER, ORG_USER_ROLE.OWNER].some(
      (x) => selectedAccount?.roles.includes(x) || false,
    );

  /// true if the user can manage the current organization
  const isOrgManagerRole =
    !isAccManagerRole &&
    [ORG_USER_ROLE.MANAGER, ORG_USER_ROLE.OWNER].some(
      (x) => selectedOrganization?.roles.includes(x) || false,
    );

  /// true if the user is a vanpool manager (but not an admin)
  const isVPMRole =
    !isOrgManagerRole &&
    [ORG_USER_ROLE.VANPOOL_MANAGER].some((x) => selectedOrganization?.roles.includes(x) || false);

  // The permissions below are implicit; they include higher level permissions
  const isAccManagerRoleOrGreater = isAccManagerRole || isMBAdmin;
  const isOrgManagerRoleOrGreater = isOrgManagerRole || isAccManagerRoleOrGreater;
  const isVPMRoleOrGreater = isVPMRole || isOrgManagerRoleOrGreater;

  // This user can manage a vanpool (independent of selected account/organization)
  const managerRoles = [ORG_USER_ROLE.OWNER, ORG_USER_ROLE.MANAGER, ORG_USER_ROLE.VANPOOL_MANAGER];
  const isVanpoolManager =
    (user?.accounts || []).length > 0 ||
    user?.organizations.some((x) => x.roles.some((y) => managerRoles.includes(y))) ||
    false;

  useEffect(() => {
    user === undefined && getCurrentUser();
  }, [user]);

  const ssoLogin = (params) => {
    return authorize_action_log_in_sso(params).then(() => {
      return getCurrentUser().then((u) => {
        if (u === null) throw "login failed";
        return u;
      });
    });
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem("routeData");
    authorize_action_log_out().finally(() => {
      window.location.href = routes.user.login;
    });
  };

  const resetRoutes = () => {
    setHighlightedRoute(null);
    setUserOrigin(undefined);
    setUserDestination(undefined);
    setAllRoutes([]);
    setDisplayRoutes([]);
    setSuggestedLine([]);
    setMapDisplayOptions(defaultMapDisplayOptions);
  };

  const getUserLocation = (): Promise<userLocationInterface> => {
    return fetchUserLocation()
      .then((res) => {
        const geoLocation: userLocationInterface = {
          locationType: "geoip",
          coordinates: {
            lat: +res.data.loc.split(",")[0],
            lng: +res.data.loc.split(",")[1],
          },
          city: res.data.city,
          state: res.data.region,
          zipCode: res.data.postal,
        };
        return geoLocation;
      })
      .catch((error) => {
        //try GPS
        return new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const geoLocation: userLocationInterface = {
                locationType: "gps",
                coordinates: {
                  lat: +position.coords.latitude,
                  lng: +position.coords.longitude,
                },
                city: "",
                state: "",
                zipCode: "",
              };
              resolve(geoLocation);
            },
            (error) => {
              reject(error);
            },
            { timeout: 2000 },
          );
        });
      });
  };

  const _createWalkline = (
    start: coordinate,
    end: coordinate,
  ): Promise<lineDisplayInterface | undefined> => {
    return new Promise((resolve, reject) => {
      getRoute(start.lng, start.lat, end.lng, end.lat, "walking")
        .then((thisLine) => {
          const coords = thisLine.data.routes[0].geometry.coordinates;
          resolve(
            coords
              ? {
                  coordinates: coords,
                  travelDuration: thisLine.data.routes[0].duration,
                }
              : undefined,
          );
        })
        .catch(() => {
          resolve(undefined);
        });
    });
  };

  const createWalklines = (
    origin: coordinate,
    destination: coordinate,
  ): Promise<lineDisplayInterface[]> => {
    const lines: Promise<lineDisplayInterface | undefined>[] = [];
    userOrigin && lines.push(_createWalkline(userOrigin.coordinates, origin));
    userDestination && lines.push(_createWalkline(userDestination.coordinates, destination));

    return Promise.all(lines).then((value) => {
      const values = value.filter((x) => x !== undefined) as lineDisplayInterface[];
      return values.map((l) => ({
        ...l,
        display: {
          type: MAP_LINE_TYPE.WALK,
        },
      }));
    });
  };

  const updateRouteWithStops = (route: routeDisplayInterface, props: tripPropertiesInterface) => {
    createWalklines(
      route.stops[props.pickup].place.coordinates,
      route.stops[props.dropoff].place.coordinates,
    ).then((lines) => {
      setSuggestedLine(lines);
    });

    const updatedRoute = computeDisplayRoute(
      route.service,
      props.pickup,
      props.dropoff,
      route.colorHexCode,
    );
    setDisplayRoutes([updatedRoute]);

    // update route in allRoutes with new stops
    const index = allRoutes.findIndex((x) => x.id === updatedRoute.id);
    if (index == -1) {
      setAllRoutes([...allRoutes, updatedRoute]);
    } else {
      let routes = [...allRoutes];
      routes.splice(index, 1, updatedRoute);
      setAllRoutes(routes);
    }

    return updatedRoute;
  };

  const showRouteForTrip = (trip: tripDetailInterface) => {
    const route: serviceResultInterface = {
      // make these up...
      id: trip.ride.serviceId,
      days: [],
      name: "",
      rules: [],
      dynamic: false,
      userFlags: [],

      // but these we have
      startDate: trip.ride.date,
      outbound: {
        stops: trip.ride.stops,
        legs: trip.ride.legs,
        basePrice: 0,
      },
    };

    const display = computeDisplayRoute(route, trip.properties.pickup, trip.properties.dropoff);

    setAllRoutes([display]);
    setDisplayRoutes([display]);
    setSelectedRouteID(display.id);
  };

  const findDynamicRoutes = (radius: number, start?: coordinate, end?: coordinate) => {
    return searchResults(radius, start, end).then((services) => {
      const colors = Object.values(colorBlindFriendlyPalette);
      const routes = services
        .filter((service) => service.dynamic)
        .map((s, index) => defaultDisplayRoute(s, start, end, colors[index % colors.length]));
      setAllRoutes(routes);
      setDisplayRoutes(routes);
      return routes;
    });
  };

  return (
    <GlobalContext.Provider
      value={{
        ...value,
        user,
        selectedAccount,
        setAccount,
        selectedOrganization,
        setOrganization,
        isMBAdmin,
        isAccManagerRole,
        isAccManagerRoleOrGreater,
        isOrgManagerRole,
        isOrgManagerRoleOrGreater,
        isVPMRole,
        isVPMRoleOrGreater,
        isVanpoolManager,
        ssoLogin,
        userLocation,
        setUserLocation,
        getUserLocation,
        userOrigin,
        setUserOrigin,
        userDestination,
        setUserDestination,
        logout,
        findDynamicRoutes,
        allRoutes,
        setAllRoutes,
        updateRouteWithStops,
        displayRoutes,
        setDisplayRoutes,
        selectedRouteID,
        setSelectedRouteID,
        highlightedRouteID,
        setHighlightedRoute,
        resetRoutes,
        suggestedLine,
        setSuggestedLine,
        createWalklines,
        errorModalData,
        setErrorModalData,
        shareModalVisible,
        setShareModalVisible,
        helpModalVisible,
        setHelpModalVisible,
        feedbackTripId,
        setFeedbackTripId,
        mapDisplayOptions,
        setMapDisplayOptions,
        mapView,
        setMapView,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
