import React, { useEffect, useState } from "react";
import useMBContext from "context/useMBContext";
import { riderMyPoolDisplayInterface, riderMyPoolInterface } from "interfaces/vanpool";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import { routes } from "misc/http";
import moment from "moment";
import "./MyPoolsPage.scss";
import { MyPoolDetails } from "../MyPoolDetails/MyPoolDetails";
import { params } from "interfaces/params";
import { MyPoolDates } from "../MyPoolDates/MyPoolDates";
import { MyPoolManagement } from "../MyPoolManagement/MyPoolManagement";
import TripStops from "components/common/TripStops/TripStops";
import { MyPoolRidePage } from "../MyPoolRidePage/MyPoolRidePage";
import { ConfirmModalContextProvider } from "components/common/ConfirmModal";
import { computeMyPoolDisplay, getService } from "../utils";
import { AssignModalContextProvider } from "../AssignModal/AssignModal";
import { MyPoolSettings } from "../MyPoolSettings/MyPoolSettings";
import { MyPoolRulesEditor } from "../MyPoolRulesEditor/MyPoolRulesEditor";
import { styled } from "baseui";
import { MyPoolProfilePage } from "../MyPoolProfilePage";
import { riderMyPools } from "api/vanpools";
import { MyPoolManageUserPage } from "../MyPoolManageUserPage";
import { ButtonStack } from "components/common/Modals/BaseModal/BaseModal";
import { MBButton } from "components/common/Buttons/Buttons";
import { MyPoolNewUserPage } from "../MyPoolNewUserPage";
import { MyPoolRidershipReportPage } from "../MyPoolRidershipReport/MyPoolRidershipReport";

export const MyPoolLabel = styled("div", ({ $theme }) => ({
  fontSize: "12px",
  textAlign: "left",
}));

export const MyPoolContainer = styled("div", ({ $theme }) => ({
  marginLeft: "25px",
  marginRight: "25px",
}));

export const MyPoolsLayout = styled("div", ({ $theme }) => ({
  width: "100%",
  overflowY: "auto",
  boxShadow: "0px 20px 10px -17px rgba(0, 0, 0, 0.1) inset",
}));

export const MyPoolsLayoutInner = styled("div", ({ $theme }) => ({
  margin: "2rem auto",
  maxWidth: "710px",
  height: "100%",
}));

interface PoolItemProps {
  pool: riderMyPoolInterface;
  onSelect?: (pool: riderMyPoolInterface) => void;
}

const PoolItem: React.FC<PoolItemProps> = ({ pool, onSelect }) => {
  const activeService = getService(pool.services, pool.profile.serviceId, moment());

  return (
    <>
      <div
        className="PoolItemContainer"
        onClick={() => {
          onSelect?.(pool);
        }}
      >
        <div className="PoolName">{pool.name || activeService.name}</div>
        {activeService && pool.profile.properties && (
          <div className="StopInfo">
            <TripStops
              pickupStop={activeService.outbound.stops[pool.profile.properties.pickup]}
              dropoffStop={activeService.outbound.stops[pool.profile.properties.dropoff]}
            />
          </div>
        )}
      </div>
    </>
  );
};

interface MyPoolsDetailsRouterProps {
  pools: riderMyPoolDisplayInterface[];
  updatePoolsCallback?: (arg: riderMyPoolDisplayInterface[]) => void;
  pathPrefix?: string;
  showAsMember?: boolean;
}

export const MyPoolsDetailsRouter: React.FC<MyPoolsDetailsRouterProps> = ({
  pools,
  updatePoolsCallback,
  pathPrefix = "",
  showAsMember = true,
}) => {
  const { vanpool } = useParams<params>();
  const [pool, setPool] = useState<riderMyPoolDisplayInterface>();
  const { user } = useMBContext();

  useEffect(() => {
    setPool(pools.find((p) => p.id === vanpool));
  }, [vanpool, pools]);

  return (
    <ConfirmModalContextProvider>
      <AssignModalContextProvider>
        {pool && user && (
          <Switch>
            <Route path={pathPrefix + `${routes.user.mypools}/:vanpool/details/:date`}>
              <MyPoolRidePage pathPrefix={pathPrefix} showAsMember={showAsMember} pool={pool} />
            </Route>
            <Route path={pathPrefix + `${routes.user.mypools}/:vanpool/dates`}>
              <MyPoolDates pathPrefix={pathPrefix} pool={pool} />
            </Route>
            <Route path={pathPrefix + `${routes.user.mypools}/:vanpool/manage/add/:userId`}>
              <MyPoolNewUserPage
                pathPrefix={pathPrefix}
                pool={pool}
                updateProfileCallback={(vp) => setPool({ ...pool, ...vp })}
              />
            </Route>
            <Route path={pathPrefix + `${routes.user.mypools}/:vanpool/manage/settings/rules`}>
              <MyPoolRulesEditor pool={pool} pathPrefix={pathPrefix} />
            </Route>
            <Route path={pathPrefix + `${routes.user.mypools}/:vanpool/manage/settings`}>
              <MyPoolSettings pool={pool} pathPrefix={pathPrefix} />
            </Route>
            <Route path={pathPrefix + `${routes.user.mypools}/:vanpool/manage/user/:userId`}>
              <MyPoolManageUserPage
                pathPrefix={pathPrefix}
                showAsMember={false}
                pool={pool}
                updateProfileCallback={(vp) => setPool({ ...pool, ...vp })}
              />
            </Route>
            <Route path={pathPrefix + `${routes.user.mypools}/:vanpool/manage`}>
              <MyPoolManagement
                pathPrefix={pathPrefix}
                pool={pool}
                updatePoolCallback={(vp) => setPool({ ...pool, ...vp })}
                showAsMember={showAsMember}
              />
            </Route>
            <Route path={pathPrefix + `${routes.user.mypools}/:vanpool/profile`}>
              <MyPoolProfilePage
                pathPrefix={pathPrefix}
                showAsMember={showAsMember}
                pool={pool}
                profile={pool.profile}
                user={user}
                updateProfileCallback={(vp) => setPool({ ...pool, ...vp })}
                onLeave={() => {
                  updatePoolsCallback?.(pools.filter((p) => p.id !== pool.id));
                  setPool(undefined);
                }}
              />
            </Route>
            <Route
              path={[
                pathPrefix +
                  `${routes.user.mypools}/:vanpool/ridership/:reportId/:year/:month/:riders`,
                pathPrefix + `${routes.user.mypools}/:vanpool/ridership/:reportId/:year/:month`,
                pathPrefix + `${routes.user.mypools}/:vanpool/ridership/:reportId`,
                pathPrefix + `${routes.user.mypools}/:vanpool/ridership`,
              ]}
            >
              <MyPoolRidershipReportPage pool={pool} pathPrefix={pathPrefix} />
            </Route>
            <Route path={pathPrefix + `${routes.user.mypools}/:vanpool`}>
              <MyPoolDetails pathPrefix={pathPrefix} showAsMember={showAsMember} pool={pool} />
            </Route>
          </Switch>
        )}
      </AssignModalContextProvider>
    </ConfirmModalContextProvider>
  );
};

const GetStartedWelcome = () => {
  const history = useHistory();

  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        display: "flex",
      }}
    >
      <div>
        <p style={{ fontSize: "27px", fontWeight: 400, marginBottom: "15px" }}>
          Find a ride to work
        </p>
        <p style={{ marginBottom: "30px" }}>
          Join a vanpool to improve your commute, save time &amp; money, and benefit the
          environment.
        </p>
        <ButtonStack>
          <MBButton
            onClick={() => {
              history.push(routes.onboarding.intro);
            }}
          >
            Get started
          </MBButton>
        </ButtonStack>
      </div>
    </div>
  );
};

export const MyPoolsPage: React.FC = () => {
  const { user } = useMBContext();
  const [pools, setPools] = useState<riderMyPoolDisplayInterface[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const history = useHistory();

  useEffect(() => {
    if (user === null) window.location.replace(routes.onboarding.intro);
    if (!user) return;

    setLoading(true);
    riderMyPools().then((pools) => {
      setPools(pools.map((x) => computeMyPoolDisplay(x)));
      setLoading(false);
    });
  }, [user]);

  const handleSelect = (pool: riderMyPoolInterface) => {
    history.push(`${routes.user.mypools}/${pool.id}`);
  };

  return (
    <>
      {user && !loading && (
        <Switch>
          <Route path={`${routes.user.mypools}/:vanpool`}>
            <MyPoolsDetailsRouter pools={pools} updatePoolsCallback={(pools) => setPools(pools)} />
          </Route>
          <Route path={routes.user.mypools}>
            <div className="Navbar">
              <div className="Navbar-header">
                <div className="Navbar-filler" />
                <div className="Navbar-header-title">Your Pools</div>
                <div className="Navbar-filler" />
              </div>
            </div>
            <MyPoolsLayout>
              <MyPoolsLayoutInner style={{ margin: "0 auto" }}>
                <div className="MyPools">
                  {pools.length > 0 ? (
                    <div style={{ margin: "2rem auto" }}>
                      {pools.map((pool) => (
                        <PoolItem
                          key={pool.id}
                          pool={pool}
                          onSelect={(p) => {
                            handleSelect(p);
                          }}
                        />
                      ))}
                    </div>
                  ) : (
                    <GetStartedWelcome />
                  )}
                </div>
              </MyPoolsLayoutInner>
            </MyPoolsLayout>
          </Route>
        </Switch>
      )}
      {!user && !loading && <GetStartedWelcome />}
    </>
  );
};
