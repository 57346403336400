import { riderInstabookWithDetails } from "api/vanpools";
import { MBButton } from "components/common/Buttons/Buttons";
import { ConfirmModalContext } from "components/common/ConfirmModal";
import {
  INSTABOOK_ACTION,
  INSTABOOK_ROLE,
  riderVanpoolDayDetailsInterface,
  riderVanpoolDayInterface,
} from "interfaces/vanpool";
import React, { useContext } from "react";

interface UncancelRideProps {
  vanpoolId: string;
  day: riderVanpoolDayInterface;
  onUncancel?: (poolDays: riderVanpoolDayDetailsInterface[]) => void;
}

export const UncancelRide: React.FC<UncancelRideProps> = ({ vanpoolId, day, onUncancel }) => {
  const { showConfirmModal } = useContext(ConfirmModalContext);

  const uncancelRide = () => {
    riderInstabookWithDetails({
      action: INSTABOOK_ACTION.BOOK,
      role: INSTABOOK_ROLE.RIDE,
      dates: [day.date],
      vanpoolId,
    }).then((poolDays) => {
      onUncancel?.(poolDays);
    });
  };

  const showUncancelPrompt = () => {
    showConfirmModal({
      headerElement: (
        <>Are you sure you want to uncancel the ride for {day.date.format("dddd, MMMM D")}?</>
      ),
      bodyElement: (
        <>
          <p>Proceeding will notify all riders and drivers that their trip has been restored.</p>
        </>
      ),
      confirmButtonText: "Yes, uncancel the ride",
      cancelButtonText: "No",
      onConfirmCallback: uncancelRide,
      confirmType: "default",
      message: "",
    });
  };

  return (
    <div className="ButtonContainer">
      <MBButton kind="secondary" onClick={showUncancelPrompt}>
        Uncancel Ride
      </MBButton>
    </div>
  );
};
