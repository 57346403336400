import { userBaseInterface } from "interfaces/user";
import React from "react";

interface DriverDetailsInterface {
  driver?: userBaseInterface;
}

export const DriverDetails: React.FC<DriverDetailsInterface> = ({ driver }) => {
  return (
    <div className="DriverDetails">
      {driver ? (
        <>
          <p>{driver.name}</p>
          <a href={`tel:${driver.phone}`}>{driver.phone}</a>
        </>
      ) : (
        <p>⚠️ Driver pending</p>
      )}
    </div>
  );
};
