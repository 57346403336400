import { coordinate, serviceResultInterface } from "interfaces/route";
import { tripPropertiesInterface } from "interfaces/trip";
import {
  riderVanpoolDayInterface,
  INSTABOOK_ACTION,
  INSTABOOK_ROLE,
  riderVanpoolInterface,
  riderMyPoolInterface,
  riderVanpoolDayDetailsInterface,
  autoAssignSettingsInterface,
  riderVanpoolListInterface,
} from "interfaces/vanpool";
import { DateTime } from "luxon";
import { gqlOp, GQLSchema } from "misc/utils";
import moment from "moment";

const searchSchema: GQLSchema = {
  params: {
    start: "LocationInput",
    end: "LocationInput",
    radius: "Float!",
  },
  name: "findServices",
  op: "query",
};

export const searchResults = (radius: number, start?: coordinate, end?: coordinate) => {
  const params = { radius, start, end };
  return gqlOp<serviceResultInterface[]>(searchSchema, params, [new serviceResultInterface()]);
};

const riderMyPoolsActionSchema: GQLSchema = {
  params: {
    vanpoolId: "String!",
    action: "INSTABOOK_ACTION!",
    properties: "TripPropertiesInput",
    serviceId: "String",
    userId: "String",
    rolesAdd: "[VANPOOLER_ROLE]",
    rolesRemove: "[VANPOOLER_ROLE]",
    autoAssign: "AutoAssignSettingsInput",
    applyToTrips: "Boolean",
    lastRideDate: "Date",
    orgId: "String",
    accountId: "String",
  },
  name: "riderMyPoolsAction",
  op: "mutation",
};

export const riderMyPoolsAction = (params: {
  vanpoolId: string;
  action: INSTABOOK_ACTION;
  userId?: string;
  properties?: tripPropertiesInterface;
  serviceId?: string;
  rolesAdd?: string[];
  rolesRemove?: string[];
  autoAssign?: autoAssignSettingsInterface;
  applyToTrips?: boolean;
  lastRideDate?: DateTime;
  orgId?: string;
  accountId?: string;
}) => {
  const params2 = {
    ...params,
    lastRideDate: params.lastRideDate?.toFormat("yyyy-MM-dd"),
  };
  return gqlOp(riderMyPoolsActionSchema, params2, [new riderMyPoolInterface()]);
};

const riderMyPoolsSchema: GQLSchema = {
  params: {},
  name: "riderMyPools",
  op: "query",
};

export const riderMyPools = () => {
  return gqlOp(riderMyPoolsSchema, {}, [new riderMyPoolInterface()]);
};

const riderVanpoolDaySchema: GQLSchema = {
  params: {
    vanpoolId: "String!",
    startDate: "String",
    endDate: "String",
    orgId: "String",
    nextDate: "Boolean",
  },
  name: "riderVanpoolDay",
  op: "query",
};

export const getVanpoolDays = (
  vanpoolId: string,
  startDate: moment.Moment,
  endDate: moment.Moment,
) => {
  const variables = {
    vanpoolId,
    startDate: startDate.format("YYYY-MM-DD"),
    endDate: endDate.format("YYYY-MM-DD"),
  };
  return gqlOp<riderVanpoolDayInterface[]>(riderVanpoolDaySchema, variables, [
    new riderVanpoolDayInterface(),
  ]).then((days) => days.map((d) => riderVanpoolDayInterface.fromGQL(d)));
};

export const getVanpoolDayDetails = (params: {
  vanpoolId: string;
  startDate?: moment.Moment;
  endDate?: moment.Moment;
  nextDate?: boolean;
}) => {
  type thing = riderVanpoolDayDetailsInterface;
  const variables = {
    startDate: params.startDate?.format("YYYY-MM-DD"),
    endDate: params.endDate?.format("YYYY-MM-DD"),
    ...params,
  };
  return gqlOp<thing[]>(riderVanpoolDaySchema, variables, [
    new riderVanpoolDayDetailsInterface(),
  ]).then((y) => y.map((d) => riderVanpoolDayDetailsInterface.fromGQL(d) as thing));
};

export interface InstabookInterface {
  vanpoolId: string;
  action: INSTABOOK_ACTION;
  role: INSTABOOK_ROLE;
  dates: moment.Moment[];
  userId?: string;
  vehicleId?: string;
  properties?: tripPropertiesInterface;
}

const riderInstabookSchema: GQLSchema = {
  params: {
    vanpoolId: "String!",
    dates: "[String]!",
    action: "INSTABOOK_ACTION!",
    role: "INSTABOOK_ROLE!",
    vehicleId: "String",
    orgId: "String",
    accountId: "String",
    userId: "String",
  },
  name: "riderInstabook",
  op: "mutation",
};

export const riderInstabook = (params: InstabookInterface) => {
  const variables = {
    ...params,
    dates: params.dates.map((date) => {
      return date.format("YYYY-MM-DD");
    }),
  };
  return gqlOp<riderVanpoolDayInterface[]>(riderInstabookSchema, variables, [
    new riderVanpoolDayInterface(),
  ]).then<riderVanpoolDayInterface[]>((days) =>
    days.map((d) => riderVanpoolDayInterface.fromGQL(d)),
  );
};

export const riderInstabookWithDetails = (params: InstabookInterface) => {
  const variables = {
    ...params,
    dates: params.dates.map((date) => {
      return date.format("YYYY-MM-DD");
    }),
  };
  type thing = riderVanpoolDayDetailsInterface;
  return gqlOp(riderInstabookSchema, variables, [new riderVanpoolDayDetailsInterface()]).then(
    (days) => days.map((d) => riderVanpoolDayDetailsInterface.fromGQL(d) as thing),
  );
};

const riderInstabookManageListSchema: GQLSchema = {
  params: {
    accountId: "String",
    orgId: "String",
    ids: "[String]",
  },
  name: "riderInstabookManageList",
  op: "query",
};

export const riderInstabookManageDetails = (params: {
  accountId?: string;
  orgId?: string;
  ids?: [string];
}) => {
  return gqlOp(riderInstabookManageListSchema, params, [new riderVanpoolInterface()]);
};

export const riderInstabookManageList = (params: {
  accountId?: string;
  orgId?: string;
  ids?: [string];
}) => {
  return gqlOp(riderInstabookManageListSchema, params, [new riderVanpoolListInterface()]);
};

const riderInstabookManageSchema: GQLSchema = {
  params: {
    vanpoolId: "String!",
    rules: "[String]",
    vehicleAutoAssign: "AutoAssignSettingsInput",
    orgId: "String",
    accountId: "String",
  },
  name: "riderInstabookManage",
  op: "mutation",
};

export interface InstabookManageInterface {
  vanpoolId: string;
  rules?: string[];
  vehicleAutoAssign?: autoAssignSettingsInterface;
}

export const riderInstabookManage = (
  params: InstabookManageInterface & {
    orgId?: string;
    accountId?: string;
  },
) => {
  return gqlOp(riderInstabookManageSchema, params, new riderVanpoolInterface());
};
