import { stopInterface } from "interfaces/stop";
import { riderMyPoolInterface, vanpoolDayDetailsDisplayInterface } from "interfaces/vanpool";
import React, { useEffect, useState } from "react";
import { EditRiders } from "./EditRider";
import { StopList } from "./StopList";
import moment from "moment";
import { CollapsibleContainer } from "components/MyPools/components/CollapsibleContainer";
import { styled } from "baseui";
import { getMyPoolUserPerms } from "components/MyPools/utils";

const EditRidersBlock = styled("div", ({ $theme }) => ({
  marginBottom: "25px",
}));

interface MyPoolStopListInterface {
  pool: riderMyPoolInterface;
  rideDay: vanpoolDayDetailsDisplayInterface;
  rideType: "OUTBOUND" | "INBOUND";
  showAsMember: boolean;
  updateDataCallback?: () => void;
  defaultExpanded?: boolean;
}

export const MyPoolStopList: React.FC<MyPoolStopListInterface> = ({
  pool,
  rideDay,
  rideType,
  showAsMember,
  updateDataCallback,
  defaultExpanded = true,
}) => {
  const [expanded, setExpanded] = useState<boolean>(defaultExpanded);
  const [stopsToShow, setStopsToShow] = useState<stopInterface[]>([]);
  const userPerms = getMyPoolUserPerms(pool.profile);
  const perms = rideDay.pool.perms;
  const status = rideDay.status;
  const riderList =
    rideType === "OUTBOUND" ? rideDay.outbound.riders : rideDay.inbound?.riders || [];

  // allow attendance for drivers/coordinators up to 30 days prior,
  // >=VPM any prior date
  const today = moment();
  const allowAttendance =
    rideDay.date.isSameOrBefore(today, "day") &&
    status.rideConfirmed &&
    (((perms.canDrive || perms.canProvideVehicle) &&
      rideDay.date.isSameOrAfter(today.add(-30, "day"))) ||
      (userPerms.isVPM && !showAsMember));

  // show all rider dropoffs/pickups if there is no vehicle, otherwise only show non-waitlisted dropoffs/pickups
  const riders = status.vehicleNeeded ? riderList : riderList.slice(0, rideDay.capacity);

  const showAllStopsAndRiders =
    perms.canDrive ||
    perms.canProvideVehicle ||
    (status.rideConfirmed && status.isRiding && !status.isWaitlisted);

  useEffect(() => {
    const stops =
      rideType === "OUTBOUND"
        ? rideDay.service.outbound.stops
        : rideDay.service.inbound?.stops || [];

    if (showAllStopsAndRiders) {
      setStopsToShow(stops);
    } else {
      // if the user isn't riding or is waitlisted then show them only their pickup/dropoff stops
      const userStops = stops.filter((stop, index) => {
        return [pool.profile.properties?.pickup, pool.profile.properties?.dropoff].includes(index);
      });
      setStopsToShow(userStops);
    }
  }, [rideDay, pool]);

  return (
    <CollapsibleContainer label={rideType}>
      {perms.canProvideVehicle && (
        <EditRidersBlock>
          <EditRiders pool={pool} day={rideDay} onEdit={updateDataCallback} />
        </EditRidersBlock>
      )}
      <StopList
        stops={stopsToShow}
        riders={riders}
        showRiders={showAllStopsAndRiders}
        allowAttendance={allowAttendance}
        updateDataCallback={updateDataCallback}
      />
    </CollapsibleContainer>
  );
};
