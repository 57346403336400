import React, { useEffect, useState } from "react";
import { MBInput } from "components/common/Inputs/MBInput";
import { geoPointDataInterface } from "interfaces/map";
import { geocodeLatLng, getSuggestions } from "api/mapbox";
import { placeInterface, userLocationInterface } from "interfaces/place";
import { styled } from "baseui";
import { mapboxToUserLocation, placeToUserLocation } from "misc/utils";
import { MBButton } from "components/common/Buttons/Buttons";
import { ButtonStack } from "components/common/Modals/BaseModal/BaseModal";
import { expand } from "inline-style-expand-shorthand";

export enum ADDRESS_TYPE {
  HOME,
  WORK,
}

const AddressSelectorContainer = styled("div", () => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "calc(100% - 7.75rem)",
}));

const SearchBarContainer = styled("div", () => ({
  ...expand({
    padding: "0.5rem",
  }),
}));

const PlaceListScroller = styled<"div", { $searchBar?: boolean }>("div", ({ $searchBar }) => ({
  overflowY: "auto",
  overflowX: "hidden",
  ...expand({
    padding: "0.5rem",
  }),
}));

const PlaceContainer = styled<"div", { $selected?: boolean }>("div", ({ $theme, $selected }) => ({
  ":hover": { backgroundColor: $theme.colors.mono200, cursor: "pointer" },
  ...expand({
    padding: $selected ? "6px 16px" : "8px 18px",
    border: $selected ? `solid 2px ${$theme.colors.primary}` : "none",
    borderRadius: "8px",
  }),
}));

const PlaceName = styled("div", ({ $theme }) => ({
  fontSize: "18px",
  color: $theme.colors.mono800,
  whiteSpace: "nowrap",
}));

const PlaceAddress = styled("div", ({ $theme }) => ({
  fontSize: "12px",
  color: $theme.colors.mono700,
}));

const ConfirmButtonContainer = styled("div", () => ({
  width: "100%",
  backgroundColor: "white",
  borderTopRightRadius: "15px",
  borderTopLeftRadius: "15px",
  ...expand({
    padding: "0.5rem",
  }),
}));

const addressIcon: { [key in ADDRESS_TYPE] } = {
  [ADDRESS_TYPE.HOME]: "🏠",
  [ADDRESS_TYPE.WORK]: "🏢",
};

const searchPlaceholderText: { [key in ADDRESS_TYPE] } = {
  [ADDRESS_TYPE.HOME]: "Your address",
  [ADDRESS_TYPE.WORK]: "Work address",
};

interface AddressSelectorProps {
  addressType: ADDRESS_TYPE;
  submitCallback: (address: userLocationInterface) => void;
  defaultPlace?: placeInterface;
  allowedPlaces?: placeInterface[];
}

export const AddressSelector: React.FC<AddressSelectorProps> = ({
  addressType,
  submitCallback,
  defaultPlace = undefined,
  allowedPlaces = [],
}) => {
  const [selectedPlace, setSelectedPlace] = useState<userLocationInterface>();
  const [defaultPlaceData, setDefaultPlaceData] = useState<geoPointDataInterface>();
  const [searchValue, setSearchValue] = useState<string>("");
  const [places, setPlaces] = useState<geoPointDataInterface[]>([]);

  useEffect(() => {
    if (!defaultPlace) return;

    const coord = defaultPlace.coordinates;
    geocodeLatLng(coord.lat, coord.lng).then((data: any) => {
      if (data.data.features[0]) {
        setDefaultPlaceData(data.data.features[0]);
        setSelectedPlace(mapboxToUserLocation(data.data.features[0]));
        setPlaces(data.data.features);
      }
    });
  }, [defaultPlace]);

  const resetData = () => {
    setPlaces(defaultPlaceData ? [defaultPlaceData] : []);
    setSearchValue("");
  };

  const submitSelection = (place: userLocationInterface) => {
    resetData();
    submitCallback(place);
  };

  const handlePlaceSearch = (value: string) => {
    value !== "" &&
      getSuggestions(value, {
        types: "address,poi",
        country: "US",
      }).then((data: any) => {
        setPlaces(data.data.features);
      });
  };

  const checkSelectedPlace = (place: placeInterface | userLocationInterface) => {
    return (
      selectedPlace &&
      selectedPlace.coordinates.lat === place.coordinates.lat &&
      selectedPlace.coordinates.lng === place.coordinates.lng
    );
  };

  return (
    <AddressSelectorContainer>
      {allowedPlaces.length > 0 ? (
        <PlaceListScroller>
          {allowedPlaces.map((place) => {
            const selected = checkSelectedPlace(place);
            return (
              <PlaceContainer
                key={place.id}
                onClick={() => {
                  selected
                    ? setSelectedPlace(undefined)
                    : setSelectedPlace(placeToUserLocation(place));
                }}
                $selected={selected}
              >
                <PlaceName>{place.placeName}</PlaceName>
                <PlaceAddress>{place.streetAddress}</PlaceAddress>
              </PlaceContainer>
            );
          })}
        </PlaceListScroller>
      ) : (
        <>
          <SearchBarContainer>
            <MBInput
              startEnhancer={addressIcon[addressType]}
              placeholder={searchPlaceholderText[addressType]}
              overrides={{
                Root: {
                  style: {
                    height: "44px",
                    ...expand({ borderRadius: "8px" }),
                  },
                },
                Input: { style: { margin: "0 0 0 -2px" } },
              }}
              value={searchValue}
              onChange={(value) => {
                setSearchValue(value.currentTarget.value);
                handlePlaceSearch(value.currentTarget.value);
              }}
            />
            {places.length > 0 && (
              <PlaceListScroller $searchBar>
                {places.map((place) => {
                  const placeNameTokens = place.place_name.split(",");
                  const selected = checkSelectedPlace(mapboxToUserLocation(place));

                  return (
                    <PlaceContainer
                      key={place.id}
                      onClick={() => {
                        selected
                          ? setSelectedPlace(undefined)
                          : setSelectedPlace(mapboxToUserLocation(place));
                      }}
                      $selected={selected}
                    >
                      <PlaceName>{placeNameTokens[0]}</PlaceName>
                      <PlaceAddress>
                        {/* remove place name since already shown above and don't show country */}
                        {placeNameTokens.slice(1, -1).join(", ")}
                      </PlaceAddress>
                    </PlaceContainer>
                  );
                })}
              </PlaceListScroller>
            )}
          </SearchBarContainer>
        </>
      )}
      <ConfirmButtonContainer>
        <ButtonStack>
          <MBButton
            onClick={() => {
              selectedPlace && submitSelection(selectedPlace);
            }}
            disabled={selectedPlace === undefined}
          >
            Continue
          </MBButton>
        </ButtonStack>
      </ConfirmButtonContainer>
    </AddressSelectorContainer>
  );
};
