import { mapBounds } from "interfaces/map";
import { addressInterface, placeInterface, userLocationInterface } from "interfaces/place";
import { LngLatBounds } from "mapbox-gl";
import moment from "moment";
import { gql_post } from "./http";

/* eslint-disable */
const polyline = require("@mapbox/polyline");

export class GQLBase extends Object {}

export class GQLTransformer {
  static fromGQL(data) {}
}

export function slice<T1 extends object, T2 extends object>(klass: T1, data: T2) {
  const res: T1 = {} as T1;
  Object.keys(klass).forEach((k) => {
    if (data.hasOwnProperty(k)) {
      res[k] = data[k];
    }
  });
  return res;
}

export function makeFields<T extends GQLBase>(obj: T) {
  const names = Object.entries(obj)
    .map(([key, value]) => {
      const v = Array.isArray(value) ? value[0] : value;
      return v instanceof GQLBase
        ? key + " { " + makeFields(v) + " }"
        : Array.isArray(value) && v === undefined
        ? ""
        : key;
    })
    .join(" ");
  return names;
}

type GQLOperation = "query" | "mutation";

export interface GQLSchema {
  params: {};
  name: string;
  op: GQLOperation;
}

export function gqlOp<T>(schema: GQLSchema, params: Record<string, any>, extraction: T) {
  const _sig1 = Object.entries(schema.params)
    .map(([key, value]) => `\$${key}: ${value}`)
    .join(", ");
  const sig1 = _sig1.length > 0 ? `(${_sig1})` : "";
  const _sig2 = Object.entries(schema.params)
    .map(([key, value]) => `${key}: \$${key}`)
    .join(", ");
  const sig2 = _sig2.length > 0 ? `(${_sig2})` : "";
  const fields = makeFields(Array.isArray(extraction) ? extraction[0] : extraction);
  const query = `${schema.op} ${schema.op === "query" ? "get_" : "mutate_"}${
    schema.name
  }${sig1} { ${schema.name}${sig2} { ${fields} } }`;
  return gql_post<T>(schema.name, query, params);
}

export const flattenObject = (flattendObj, obj) => {
  if (obj) {
    Object.keys(obj).forEach((key) => {
      const newKey = `${key}`;
      if (typeof obj[key] === "object") {
        // calling the function again
        flattenObject(flattendObj, obj[key]);
      } else {
        flattendObj[newKey] = obj[key];
      }
    });
  }
};

export const dateFormat = (date) => {
  let month = date.getMonth() + 1;
  let day = date.getDate();
  if (month < 10) {
    month = "0" + month;
  }
  if (day < 10) {
    day = "0" + day;
  }
  return date.getFullYear() + "-" + month + "-" + day;
};

export const placeToUserLocation = (place: placeInterface) => {
  return {
    locationType: "geocoded",
    coordinates: place.coordinates,
    placeName: place.placeName,
    streetAddress: place.streetAddress,
    city: place.city,
    state: place.stateCode,
    zipCode: place.zipCode,
    county: place.county,
  };
};

export const userLocationToAddress = (location: userLocationInterface) => {
  const address: addressInterface = {
    coordinates: location.coordinates,
    name: location.placeName || "",
    streetAddress: location.streetAddress || "",
    city: location.city || "",
    state: location.state || "",
    zip: location.zipCode || "",
    county: location.county || "",
  };

  return address;
};

export const addressToUserLocation = (address: addressInterface) => {
  const userLocation: userLocationInterface = {
    locationType: "geocoded",
    coordinates: address.coordinates,
    placeName: address.name,
    streetAddress: address.streetAddress,
    city: address.city,
    state: address.state,
    zipCode: address.zip,
    county: address.county,
  };

  return userLocation;
};

export const mapboxToAddress = (item) => {
  const address = {
    name: item.place_name,
    coordinates: {
      lat: item.center[1],
      lng: item.center[0],
    },
    city: "",
    state: "",
    zip: "",
    county: "",
  } as addressInterface;
  if (item.place_type[0] === "address") {
    address.streetAddress = item.address ? item.address + " " + item.text : item.text;
  }
  if (item.place_type[0] === "poi") {
    address.streetAddress = item.properties.address ? item.properties.address : item.text;
  }
  if (item.place_type[0] === "place") {
    address.city = item.text;
  }
  item.context.forEach((element) => {
    if (element.id.includes("place")) {
      address.city = element.text;
    }
    if (element.id.includes("region")) {
      address.state = element.short_code.split("-")[1];
    }
    if (element.id.includes("postcode")) {
      address.zip = element.text;
    }
    if (element.id.includes("district")) {
      address.county = element.text.replace(" County", "");
    }
  });
  return address;
};

export const mapboxToUserLocation = (item) => {
  return addressToUserLocation(mapboxToAddress(item));
};

// polylines and coordinate arrays for display have opposite (lat,lng) <-> (lng,lat)
// orderings, so we flip on decode and encode.
export const decodePolyline = (poly: string) => {
  const decodedPoly = polyline.decode(poly);
  return decodedPoly.map((coords) => [coords[1], coords[0]]);
};

export const encodePolyline = (coordinates: number[]) => {
  const flippedRoute = coordinates.map((coords) => {
    return [coords[1], coords[0]];
  });
  return polyline.encode(flippedRoute);
};

export const boundsFromPolyline = (polyline: number[]) => {
  const bounds = new LngLatBounds();
  polyline.map((segment, index) => {
    bounds.extend([segment[0], segment[1]]);
  });
  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();
  let mapBounds: mapBounds = {
    ne: {
      lng: ne.lng,
      lat: ne.lat,
    },
    sw: {
      lng: sw.lng,
      lat: sw.lat,
    },
  };
  return mapBounds;
};

export const parseTime = (time: string) => {
  // 7:30 PM -> 1930
  const m = moment(time, "hh:mm A");
  return m.hours() * 100 + m.minutes();
};

export const parseTo24HourTime = (time: string) => {
  // 7:30 PM -> 19:30
  return moment(time, "hh:mm A").format("HH:mm");
};

export const parseFrom24HourTime = (time: string) => {
  // 19:30 -> 7:30 PM
  return moment(time, "HH:mm").format("h:mm A");
};

export const checkValidStopTimes = (arriveWorkTime, leaveWorkTime) => {
  const arrive = parseTo24HourTime(arriveWorkTime);
  const depart = parseTo24HourTime(leaveWorkTime);

  return arrive !== depart && (arrive < depart || depart === "00:00");
};

export const urlReturnToCurrent = (dest) => {
  return `${dest}?return=${window.location.pathname + window.location.search}`;
};

export const goToReturnToCurrent = (dest) => {
  window.location.href = urlReturnToCurrent(dest);
};

export enum DAY {
  SU = 0,
  MO = 1,
  TU = 2,
  WE = 3,
  TH = 4,
  FR = 5,
  SA = 6,
}

const DAYS_NUM_TO_SHORT: [DAY, string][] = [
  [DAY.SU, "SU"],
  [DAY.MO, "MO"],
  [DAY.TU, "TU"],
  [DAY.WE, "WE"],
  [DAY.TH, "TH"],
  [DAY.FR, "FR"],
  [DAY.SA, "SA"],
];

export const dayShortToNum = (short: string): DAY => {
  return DAYS_NUM_TO_SHORT.find((x) => x[1] === short)![0];
};
