import React from "react";
import { myPoolMemberProfileInterface } from "interfaces/user";
import { vanpoolDayDisplayInterface } from "interfaces/vanpool";
import { DateRow } from "./DateRow/DateRow";
import moment from "moment";
import { InstabookInterface } from "api/vanpools";
import "./DateList.scss";
import { useDateList } from "./useDateList";

interface DateListInterface {
  vanpoolDays: vanpoolDayDisplayInterface[];
  profile: myPoolMemberProfileInterface;
  instabookCallback: (ib: InstabookInterface) => void;
  showRedirect?: boolean;
  pathPrefix?: string;
}

// Wrapper component that renders list of DateRows and handles all DateRow actions and modals

export const DateList: React.FC<DateListInterface> = ({
  vanpoolDays,
  profile,
  instabookCallback,
  showRedirect = true,
  pathPrefix = "",
}) => {
  const {
    bookOrCancelRide,
    bookOrCancelDrive,
    provideOrRemoveVehicle,
    assignOrRemoveDriver,
    redirectToRideDetails,
  } = useDateList({ instabookCallback, pathPrefix });

  const pool = vanpoolDays[0]?.pool;

  const Divider = ({ index }: { index: number }) => {
    const dateRange = [vanpoolDays[index].date, vanpoolDays[index + 1]?.date];
    const today = moment();
    const isToday =
      today.isAfter(dateRange[0], "day") &&
      (!dateRange[1] || today.isSameOrBefore(dateRange[1], "day"));

    return isToday ||
      (index !== vanpoolDays.length - 1 && dateRange[0].isSame(dateRange[1], "M")) ? (
      <div key={index} className="DateList DateDivider">
        {isToday && <div className="DateList Dot"></div>}
        <div className={`Divider ${isToday ? "DateList Today" : ""}`} />
      </div>
    ) : (
      <></>
    );
  };

  return (
    <>
      {vanpoolDays.map((vanpoolDay, index) => {
        const selectedVehicle = pool.vehicles.find(
          (vehicle) => vehicle.id === vanpoolDay.vehicleId,
        );
        // selected driver only if coordinator
        const selectedDriver = pool.perms.canProvideVehicle
          ? pool.drivers.find((driver) => driver.id === vanpoolDay.driverId)
          : undefined;

        const showMonthHeader =
          index === 0 || !vanpoolDay.date.isSame(vanpoolDays[index - 1].date, "month");
        const status = vanpoolDay.status;
        return (
          <div key={`id_${index}_${vanpoolDay.date.format("YYYY-MM-DD")}`}>
            {showMonthHeader && (
              <div className="DateList CurrentMonth">{vanpoolDay.date.format("MMMM YYYY")}</div>
            )}
            <div
              data-item={vanpoolDay.date.format("YYYY-MM-DD")}
              id={`id_${vanpoolDay.date.format("YYYY-MM-DD")}`}
              key={index}
              className={`DateList Root
                ${status.rideCanceled ? " Canceled" : " Date"}`}
            >
              <DateRow
                vanpoolDay={vanpoolDay}
                userProfile={profile}
                rideCallback={() => bookOrCancelRide(vanpoolDay)}
                driveCallback={() => bookOrCancelDrive(vanpoolDay)}
                provideVehicleCallback={() => provideOrRemoveVehicle(vanpoolDay)}
                assignDriverCallback={() => assignOrRemoveDriver(vanpoolDay)}
                redirectCallback={
                  showRedirect
                    ? () => {
                        redirectToRideDetails(vanpoolDay);
                      }
                    : undefined
                }
                vehicle={selectedVehicle?.description}
                driver={selectedDriver?.name}
              />

              <Divider index={index} />
            </div>
          </div>
        );
      })}
    </>
  );
};
