import { GlobalContext } from "context/global";
import { routes } from "misc/http";
import React, { useContext, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";

const { REACT_APP_FB_APP_ID } = process.env;

declare global {
  interface Window {
    FB?: any;
    fbAsyncInit?: () => void;
  }
}

const FBSSO: React.FC = () => {
  const { user, ssoLogin } = useContext(GlobalContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [unauthorized, setUnauthorized] = useState<boolean>(false);

  const doSSOLogin = (params) => {
    console.log("do sso login", params);
    ssoLogin(params)
      .then((user) => {
        setLoading(false);
        setUnauthorized(false);
      })
      .catch((error) => {
        setLoading(false);
        setUnauthorized(true);
      });
  };

  const fbInit = () => {
    console.log("window.fb: ", window.FB);
    if (!window.FB) return;

    window.FB.init({
      appId: REACT_APP_FB_APP_ID,
      cookie: true, // Enable cookies to allow the server to access the session.
      xfbml: true, // Parse social plugins on this webpage.
      version: "v13.0", // Use this Graph API version for this call.
    });

    window.FB.getLoginStatus((res) => {
      console.log("login status", res);
      if (res.status === "connected") {
        doSSOLogin({
          facebook: { accessToken: res.authResponse?.accessToken },
        });
      } else {
        setLoading(false);
      }
    });

    window.FB.Event.subscribe("auth.statusChange", (res) => {
      if (res.status === "connected") {
        doSSOLogin({
          facebook: { accessToken: res.authResponse?.accessToken },
        });
      }
      console.log("status change", res);
    });
  };
  window.fbAsyncInit = fbInit;

  useEffect(() => {
    if (document.getElementById("fb-jssdk")) return;

    const script = document.createElement("script");
    script.id = "fb-jssdk";
    script.src = "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v13.0&appId=2216934654";
    script.async = true;
    script.defer = true;
    script.crossOrigin = "anonymous";
    script.nonce = "T1nKyy9i";

    const fjs = document.getElementsByTagName("script")[0];
    fjs.parentNode?.insertBefore(script, fjs);
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "grid",
        alignItems: "center",
        justifyItems: "center",
      }}
    >
      {/* The login button needs to be rendered unconditionally,
       as the SDK must see it to transform it into the iframe code */}
      <div
        className="fb-login-button"
        data-width="300"
        data-scope="public_profile,email"
        data-size="large"
        data-button-type="continue_with"
        data-layout="default"
        data-auto-logout-link="false"
        data-use-continue-as="false"
        style={{
          display: user || loading || unauthorized ? "none" : "block",
        }}
      ></div>
      {user ? (
        <Redirect to={routes.main} />
      ) : loading ? (
        <></>
      ) : unauthorized ? (
        <div>You are not authorized to access this page.</div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default FBSSO;
