import React from "react";
import { formatTime } from "helpers/formatHelpers";
import "./StopInfo.scss";
import { stopDisplayInterface } from "interfaces/route";

interface StopInfoProps {
  stop: stopDisplayInterface;
  isArrival?: boolean;
  showAddress?: boolean;
  showWalkTime?: boolean;
}

const StopInfo: React.FC<StopInfoProps> = ({
  stop,
  isArrival = false,
  showAddress = true,
  showWalkTime = false,
}) => {
  return (
    <div className="StopInfo-stop-card">
      <div className="StopInfo-timestamp">
        {formatTime(isArrival ? stop.arrival : stop.departure)}
      </div>
      {showAddress && <div className="StopInfo-stop-address">{stop.place.streetAddress}</div>}
      {showWalkTime &&
        (stop.walkMinutes !== undefined ? (
          <div className="StopInfo-stop-address">{stop.walkMinutes} min walk</div>
        ) : (
          // invisible div to act as spaceholder while walkMinutes are calculated
          <div className="StopInfo-stop-spaceholder">.</div>
        ))}
    </div>
  );
};

export default StopInfo;
