import { styled } from "baseui";
import { MBButton, ResponsiveContainer } from "components/common/Buttons/Buttons";
import { DragAndDropEditor } from "components/common/DragAndDropEditor/DragAndDropEditor";
import { riderMyPoolInterface } from "interfaces/vanpool";
import moment from "moment";
import React, { useContext, useRef, useState } from "react";
import { MyPoolLabel } from "../MyPoolsPage/MyPoolsPage";
import { getActiveServiceForDate } from "../utils";
import { arrayMove, arrayRemove } from "baseui/dnd-list";
import { ConfirmModalContext } from "components/common/ConfirmModal";
import { riderInstabookManage } from "api/vanpools";
import { useHistory } from "react-router-dom";
import { routes } from "misc/http";
import { toaster } from "baseui/toast";

interface MyPoolRulesEditorProps {
  pool: riderMyPoolInterface;
  pathPrefix?: string;
}

// don't define these divs inside of the component because it causes them
// to rerender without state preservation (input field loses focus with every keystroke)
const RulesHeader = styled("div", ({ $theme }) => ({
  marginLeft: "25px",
  marginRight: "25px",
  marginTop: "10px",
}));

const RulesContainer = styled("div", ({ $theme }) => ({
  marginLeft: "17px",
  marginRight: "15px",
}));

export const MyPoolRulesEditor: React.FC<MyPoolRulesEditorProps> = ({ pool, pathPrefix = "" }) => {
  const history = useHistory();
  const { showConfirmModal } = useContext(ConfirmModalContext);
  const [saving, setSaving] = useState<boolean>(false);
  const activeService = getActiveServiceForDate(pool, moment());
  const initialRules = activeService
    ? activeService.rules.map((rule) => {
        return rule.rule;
      })
    : [];

  const [savedRules, setSavedRules] = useState<string[]>([...initialRules]);
  const [rules, setRules] = useState<string[]>([...initialRules]);
  const [stale, setStale] = useState<boolean>(false);
  const rulesRef = useRef<string[]>(rules);
  rulesRef.current = rules;

  const updatePoolRules = (index, update: string) => {
    rulesRef.current.splice(index, 1, update);
    setRules([...rulesRef.current]);
    setStale(true);
  };

  const removeRule = (index) => {
    showConfirmModal({
      headerElement: <>Are you sure you want to delete this rule?</>,
      confirmButtonText: "Yes, delete",
      cancelButtonText: "No",
      onConfirmCallback: () => {
        rulesRef.current.splice(index, 1);
        setRules([...rulesRef.current]);
      },
      confirmType: "destructive",
      message: "",
    });
  };

  const cancelEdit = () => {
    const goBackToSettings = () => {
      history.push(pathPrefix + `${routes.user.mypools}/${pool.id}/manage?default=settings`);
    };

    if (!stale) {
      goBackToSettings();
    } else {
      showConfirmModal({
        headerElement: <>Unsaved changes</>,
        confirmButtonText: "Yes, leave",
        cancelButtonText: "No",
        onConfirmCallback: goBackToSettings,
        confirmType: "destructive",
        message: "Are you sure you want to leave? Any unsaved changes will be lost.",
      });
    }
  };

  const saveRules = () => {
    setSaving(true);
    riderInstabookManage({ vanpoolId: pool.id, rules })
      .then((updatedPool) => {
        pool.services = updatedPool.services;

        setSavedRules([...rules]);
        setStale(false);
        toaster.show("Rules successfully updated.", {
          autoHideDuration: 4500,
        });
      })
      .catch((errors) => {
        toaster.show("Unable to update rules", {
          kind: "negative",
          autoHideDuration: 4500,
        });
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return (
    <>
      <div className="Navbar">
        <div className="Navbar-header">
          <MBButton
            kind="secondary"
            overrides={{
              BaseButton: {
                style: {
                  width: "78px",
                },
              },
            }}
            onClick={cancelEdit}
            disabled={saving}
          >
            {stale ? "Cancel" : "Done"}
          </MBButton>
          <div className="Navbar-header-title">Edit rules</div>
          <MBButton
            overrides={{
              BaseButton: {
                style: {
                  width: "78px",
                },
              },
            }}
            disabled={!stale || saving}
            onClick={saveRules}
          >
            {saving ? "Saving..." : "Save"}
          </MBButton>
        </div>
      </div>
      <ResponsiveContainer>
        <RulesHeader>
          <MyPoolLabel>RULES</MyPoolLabel>
        </RulesHeader>
        <RulesContainer>
          <DragAndDropEditor
            items={rules}
            removable
            onChange={({ oldIndex, newIndex }) =>
              setRules(
                newIndex === -1
                  ? arrayRemove(rules, oldIndex)
                  : arrayMove(rules, oldIndex, newIndex),
              )
            }
            updateListCallback={updatePoolRules}
            removeItemCallback={removeRule}
            ghostItemText={"Add rule"}
          />
        </RulesContainer>
      </ResponsiveContainer>
    </>
  );
};
