import { styled } from "baseui";
import { Select } from "baseui/select";
import { vehicleInterface } from "interfaces/trip";
import { VANPOOLER_ROLE } from "interfaces/user";
import {
  autoAssignDayInterface,
  autoAssignSettingsInterface,
  INSTABOOK_ROLE,
} from "interfaces/vanpool";
import moment, { Moment } from "moment";
import React, { useState } from "react";
import { POOL_ACTION_BUTTON_LABEL } from "../utils";
import { PoolActionButton } from "./PoolActionButton";

export enum DAY_LABEL {
  SU = "Sunday" as any,
  MO = "Monday" as any,
  TU = "Tuesday" as any,
  WE = "Wednesday" as any,
  TH = "Thursday" as any,
  FR = "Friday" as any,
  SA = "Saturday" as any,
}

const DayRow = styled<"div", { $active?: boolean }>("div", ({ $theme, $active }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  opacity: $active ? 1 : 0.33,
  pointerEvents: $active ? "auto" : "none",
}));

interface AutoAssignRowInterface {
  types: VANPOOLER_ROLE[];
  day: DAY_LABEL;
  active?: boolean;
  autoAssignDay?: autoAssignDayInterface;
  vehicle?: vehicleInterface;
  rideCallback?: () => void;
  driveCallback?: () => void;
  provideVehicleCallback?: () => void;
}

export const AutoAssignRow: React.FC<AutoAssignRowInterface> = ({
  types,
  day,
  active = true,
  autoAssignDay,
  vehicle,
  rideCallback,
  driveCallback,
  provideVehicleCallback,
}) => {
  return (
    <DayRow $active={active}>
      <p style={{ fontSize: "14px" }}>Every {day}</p>
      <div className="DateRow secondColumn">
        {(types.includes(VANPOOLER_ROLE.RIDER) || types.includes(VANPOOLER_ROLE.DRIVER)) && (
          <div className="DateRow ActionRow">
            {types.includes(VANPOOLER_ROLE.RIDER) && (
              <div className="DateRow ActionButton">
                <PoolActionButton
                  label={
                    autoAssignDay?.role === INSTABOOK_ROLE.RIDER
                      ? POOL_ACTION_BUTTON_LABEL.RIDING
                      : POOL_ACTION_BUTTON_LABEL.RIDE
                  }
                  status="ok"
                  onClick={() => rideCallback && rideCallback()}
                  selected={autoAssignDay?.role === INSTABOOK_ROLE.RIDER}
                />
              </div>
            )}
            {types.includes(VANPOOLER_ROLE.DRIVER) && (
              <div className="DateRow ActionButton">
                <PoolActionButton
                  label={
                    autoAssignDay?.role === INSTABOOK_ROLE.DRIVER
                      ? POOL_ACTION_BUTTON_LABEL.DRIVING
                      : POOL_ACTION_BUTTON_LABEL.DRIVE
                  }
                  status="ok"
                  onClick={() => driveCallback && driveCallback()}
                  selected={autoAssignDay?.role === INSTABOOK_ROLE.DRIVER}
                />
              </div>
            )}
          </div>
        )}
        {types.includes(VANPOOLER_ROLE.COORDINATOR) && (
          <div className="DateRow ActionRow">
            <div className="DateRow ActionButton">
              <PoolActionButton
                label={POOL_ACTION_BUTTON_LABEL.PROVIDE_VEHICLE}
                status="ok"
                onClick={() => provideVehicleCallback && provideVehicleCallback()}
                selected={autoAssignDay?.role === INSTABOOK_ROLE.VEHICLE}
                vehicle={vehicle?.description}
              />
            </div>
          </div>
        )}
      </div>
    </DayRow>
  );
};

interface AutoAssignScheduleInterface {
  types: VANPOOLER_ROLE[];
  days: string[];
  autoAssignSettings?: autoAssignSettingsInterface;
  vehicles?: vehicleInterface[];
  autoAssignRideCallback?: (
    day: string,
    autoAssignDay: autoAssignDayInterface | undefined,
    dateNextApply: Moment,
  ) => void;
  autoAssignDriveCallback?: (
    day: string,
    autoAssignDay: autoAssignDayInterface | undefined,
    dateNextApply: Moment,
  ) => void;
  autoAssignVehicleCallback?: (
    day: string,
    autoAssignDay: autoAssignDayInterface | undefined,
    dateNextApply: Moment,
  ) => void;
  updateDateCallback?: (dateNextApply: Moment) => void;
}

export const AutoAssignSchedule: React.FC<AutoAssignScheduleInterface> = ({
  types,
  days,
  autoAssignSettings,
  vehicles,
  autoAssignRideCallback,
  autoAssignDriveCallback,
  autoAssignVehicleCallback,
  updateDateCallback,
}) => {
  const [autoAssignApplyDate, setAutoAssignApplyDate] = useState<Moment>(
    autoAssignSettings ? moment(autoAssignSettings.dateNextApply) : moment().day(7),
  );

  const nextSunday = moment().day(7);
  const nextFiveSundays = [nextSunday];
  for (let i = 1; i < 5; i++) {
    nextFiveSundays.push(nextSunday.clone().add("weeks", i));
  }
  const applyDateOptions = nextFiveSundays.map((date) => ({
    id: date.toString(),
    label: date.format("dddd, MMM D, YYYY"),
    value: date,
  }));

  const allDays = [
    DAY_LABEL.SU,
    DAY_LABEL.MO,
    DAY_LABEL.TU,
    DAY_LABEL.WE,
    DAY_LABEL.TH,
    DAY_LABEL.FR,
    DAY_LABEL.SA,
  ];

  return (
    <div style={{ width: "100%" }}>
      {allDays.map((day) => {
        const autoAssignDay = autoAssignSettings
          ? autoAssignSettings.items.find((autoAssignDay) => autoAssignDay.day === DAY_LABEL[day])
          : undefined;

        const isActiveDay = days.includes(DAY_LABEL[day]);

        return (
          <div style={{ marginTop: "15px" }} key={day}>
            <AutoAssignRow
              day={day}
              active={isActiveDay}
              types={types}
              autoAssignDay={autoAssignDay}
              vehicle={vehicles?.find((vehicle) => vehicle.id === autoAssignDay?.vehicleId)}
              provideVehicleCallback={() =>
                autoAssignVehicleCallback &&
                autoAssignVehicleCallback(DAY_LABEL[day], autoAssignDay, autoAssignApplyDate)
              }
              rideCallback={() =>
                autoAssignRideCallback &&
                autoAssignRideCallback(DAY_LABEL[day], autoAssignDay, autoAssignApplyDate)
              }
              driveCallback={() =>
                autoAssignDriveCallback &&
                autoAssignDriveCallback(DAY_LABEL[day], autoAssignDay, autoAssignApplyDate)
              }
            />
          </div>
        );
      })}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "15px",
          marginTop: "15px",
        }}
      >
        <p style={{ fontSize: "14px" }}>Apply this schedule starting</p>
        <Select
          options={applyDateOptions}
          value={applyDateOptions.filter((x) => {
            return x.value.isSame(autoAssignApplyDate, "day");
          })}
          onChange={(params) => {
            setAutoAssignApplyDate(params.option?.value);
            updateDateCallback && updateDateCallback(params.option?.value);
          }}
          clearable={false}
          overrides={{ Root: { style: { maxWidth: "380px" } } }}
        />
      </div>
    </div>
  );
};
