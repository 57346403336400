import { Moment } from "moment";
import React from "react";

export interface DateDisplayInterface {
  date: Moment;
}

export const DateDisplay: React.FC<DateDisplayInterface> = ({ date }) => {
  return (
    <div>
      <p>{date.format("dddd")}</p>
      <p>{date.format("MMMM D, YYYY")}</p>
    </div>
  );
};
