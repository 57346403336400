import React, { useEffect, useState } from "react";
import "./ManageModal.scss";
import { MBButton, MBProfileCheckbox } from "components/common/Buttons/Buttons";
import { riderVanpoolInterface, riderVanpoolDayDetailsInterface } from "interfaces/vanpool";
import { myPoolMemberInterface, userInterface, VANPOOLER_ROLE } from "interfaces/user";
import { SimpleDate } from "../../components/SimpleDate";
import { MBInput } from "components/common/Inputs/MBInput";
import { BaseModal, ModalSeparator } from "components/common/Modals/BaseModal/BaseModal";

export enum MANAGE_TYPE {
  DRIVER,
  COORDINATOR,
  RIDER,
}

const headers: { [key in MANAGE_TYPE] } = {
  [MANAGE_TYPE.DRIVER]: "Approve drivers",
  [MANAGE_TYPE.COORDINATOR]: "Approve coordinators",
  [MANAGE_TYPE.RIDER]: "Edit riders",
};

export const manageUserDataList: { [key in MANAGE_TYPE] } = {
  [MANAGE_TYPE.DRIVER]: (userIdList: string[]) => {
    return { drivers: userIdList };
  },
  [MANAGE_TYPE.COORDINATOR]: (userIdList: string[]) => {
    return { coordinators: userIdList };
  },
  [MANAGE_TYPE.RIDER]: (userIdList: string[]) => {
    return userIdList;
  },
};

const allowEmptyApproval: { [key in MANAGE_TYPE] } = {
  [MANAGE_TYPE.DRIVER]: false,
  [MANAGE_TYPE.COORDINATOR]: false,
  [MANAGE_TYPE.RIDER]: true,
};

const approveButtonText: { [key in MANAGE_TYPE] } = {
  [MANAGE_TYPE.DRIVER]: "Approve",
  [MANAGE_TYPE.COORDINATOR]: "Approve",
  [MANAGE_TYPE.RIDER]: "Update",
};

export interface ManageModalSettings {
  show: boolean;
  type: MANAGE_TYPE;
}

interface ManageModalInterface {
  pool: riderVanpoolInterface;
  settings: ManageModalSettings;
  assignCallback: (userIdList: string[]) => void;
  hideModalCallback: () => void;
  rideDay?: riderVanpoolDayDetailsInterface;
}

export const ManageModal: React.FC<ManageModalInterface> = ({
  pool,
  settings,
  assignCallback,
  hideModalCallback,
  rideDay = undefined,
}) => {
  const type = settings.type;

  const currentUserList: { [key in MANAGE_TYPE] } = {
    [MANAGE_TYPE.DRIVER]: pool.drivers.map((driver) => {
      return driver.id;
    }),
    [MANAGE_TYPE.COORDINATOR]: pool.coordinators.map((coordinator) => {
      return coordinator.id;
    }),
    [MANAGE_TYPE.RIDER]: rideDay?.outbound.riders.map((rider) => {
      return rider.rider.id;
    }),
  };

  const memberList: { [key in MANAGE_TYPE]: myPoolMemberInterface[] } = {
    [MANAGE_TYPE.DRIVER]: pool.members.filter((member) => {
      return !member.profile.userRoles.includes(VANPOOLER_ROLE.DRIVER);
    }),
    [MANAGE_TYPE.COORDINATOR]: pool.members.filter((member) => {
      return !member.profile.userRoles.includes(VANPOOLER_ROLE.COORDINATOR);
    }),
    [MANAGE_TYPE.RIDER]: pool.members.filter((member) => {
      return member.profile.userRoles.includes(VANPOOLER_ROLE.RIDER);
    }),
  };

  // list of user IDs
  const [selectedUsers, setSelectedUsers] = useState<string[]>(currentUserList[type]);
  // list of selected/unselected users not including pre-selected ones
  const [deltaUsers, setDeltaUsers] = useState<string[]>([]);
  const [searchString, setSearchString] = useState<string>("");

  useEffect(() => {
    if (!settings.show) return;

    // reset modal to defaults when opening
    setSearchString("");
    setDeltaUsers([]);
    setSelectedUsers(currentUserList[type]);
  }, [settings.show]);

  if (type === MANAGE_TYPE.RIDER && !rideDay) {
    throw new Error("Must provide vanpoolDay when managing riders");
  }

  const updateUserArray = (memberId: string, userArray: string[]) => {
    if (!userArray.includes(memberId)) {
      userArray.push(memberId);
    } else {
      userArray.splice(userArray.indexOf(memberId), 1);
    }
  };

  const selectOrUnselectMember = (member: userInterface) => {
    const selectedUsersCopy = [...selectedUsers];
    updateUserArray(member.id, selectedUsersCopy);

    const deltaUsersCopy = [...deltaUsers];
    updateUserArray(member.id, deltaUsersCopy);

    setDeltaUsers(deltaUsersCopy);
    setSelectedUsers(selectedUsersCopy);
  };

  return (
    <BaseModal.Modal isOpen={settings.show} onClose={hideModalCallback}>
      <BaseModal.Header>
        <div className="ManageModalHeader">
          {rideDay && (
            <div className="HeaderDate">
              <SimpleDate date={rideDay.date} />
            </div>
          )}
          <div className="Title">{headers[type]}</div>
        </div>
      </BaseModal.Header>
      <BaseModal.Body>
        <div className="ManageModalBody">
          <ModalSeparator />
          <div className="Search">
            <MBInput
              value={searchString}
              onChange={(e) => {
                setSearchString(e.currentTarget.value);
              }}
              clearable={true}
              placeholder="Search..."
              overrides={{
                Root: {
                  style: {
                    border: "none",
                    backgroundColor: "white",
                  },
                },
              }}
            />
          </div>
          <ModalSeparator />
          <div className="MemberList">
            {memberList[type].map((member) => {
              // don't list users that are already assigned this role or whose names don't start with the search string
              if (searchString && !member.name.toLowerCase().includes(searchString.toLowerCase()))
                return;

              return (
                <div className="Member" key={member.id}>
                  <MBProfileCheckbox
                    checked={selectedUsers.includes(member.id)}
                    onChange={(e) => {
                      selectOrUnselectMember(member);
                    }}
                  >
                    {member.name}
                  </MBProfileCheckbox>
                </div>
              );
            })}
          </div>
          <ModalSeparator />
        </div>
      </BaseModal.Body>
      <BaseModal.Footer>
        <div className="ManageModalFooter">
          <div className="ButtonStack">
            <MBButton
              // don't allow update if no users are selected and type doesnt allow
              // empty updates or if there has been no change since initial state
              disabled={
                (selectedUsers.length === 0 && !allowEmptyApproval[type]) || deltaUsers.length === 0
              }
              onClick={() => {
                assignCallback(selectedUsers);
                hideModalCallback();
              }}
            >
              {approveButtonText[type]}
            </MBButton>
            <MBButton kind="tertiary" onClick={hideModalCallback}>
              Cancel
            </MBButton>
          </div>
        </div>
      </BaseModal.Footer>
    </BaseModal.Modal>
  );
};
