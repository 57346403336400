import { ATTENDANCE_TYPE, tripInterface } from "interfaces/trip";
import { INSTABOOK_ACTION } from "interfaces/vanpool";
import { gql_post } from "misc/http";

export const fetchRiderAttendance = (params: {
  tripId: string;
  action: INSTABOOK_ACTION;
  type: ATTENDANCE_TYPE;
  orgId?: string;
  accountId?: string;
}) => {
  const data = `
    mutation riderAttendance($tripId: String!, $action: INSTABOOK_ACTION!, $type: ATTENDANCE_TYPE!, $orgId: String, $accountId: String) {
      riderAttendance(tripId: $tripId, action: $action, type: $type, orgId: $orgId, accountId: $accountId) {
        attendance { type }
      }
    }
  `;
  return gql_post<tripInterface[]>("riderAttendance", data, params);
};
