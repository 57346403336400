import { vehicleInterface } from "interfaces/trip";
import React from "react";

interface VehicleDetailsInterface {
  vehicle?: vehicleInterface;
}

export const VehicleDetails: React.FC<VehicleDetailsInterface> = ({ vehicle }) => {
  return (
    <div className="VehicleDetails">
      {vehicle ? (
        <>
          <p>{vehicle.description}</p>
          <p>{vehicle.licensePlate}</p>
        </>
      ) : (
        <p>⚠️ Vehicle pending</p>
      )}
    </div>
  );
};
