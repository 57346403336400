import React from "react";
import { useStyletron } from "baseui";

const RightArrowIcon = () => {
  const [css, theme] = useStyletron();

  return (
    <div className={css({ cursor: "pointer", height: "22px" })}>
      <svg
        width="22px"
        height="22px"
        viewBox="5 5 22 22"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g fillRule="evenodd" className={css({ fill: theme.colors.mono500 })}>
          <polygon
            transform="translate(16.176114, 15.505587) rotate(-180.000000) translate(-16.176114, -15.505587) "
            points="19.8636039 7.00287478 21.1363961 8.27566699 13.784 15.627 21.1250228 22.7131594 19.8749772 24.0082988 11.2158327 15.650646"
          ></polygon>
        </g>
      </svg>
    </div>
  );
};

export default RightArrowIcon;
