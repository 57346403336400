import CheckmarkIcon from "assets/img/checkmark-icon";
import { ReactComponent as DriveIcon } from "assets/img/drive-icon.svg";
import { styled } from "baseui";
import { riderMyPoolInterface, USER_RIDE_STATUS } from "interfaces/vanpool";
import { routes } from "misc/http";
import React, { useState, useEffect } from "react";
import { Route, Switch, useHistory, useParams, NavLink } from "react-router-dom";
import { StickyTable } from "components/common/StickyTable/StickyTable";
import { getActiveServiceForDate, getMyPoolUserStatus, isServiceDay } from "../utils";
import { ButtonGroup } from "baseui/button-group";
import { MBButton } from "components/common/Buttons/Buttons";
import { ridershipReport } from "interfaces/reports";
import { fetchRidershipReport } from "api/reports";
import { DatePicker } from "baseui/datepicker";
import { MyPoolLabel } from "../MyPoolsPage/MyPoolsPage";
import { ButtonOverrides } from "baseui/button";
import { ReactComponent as CalendarIcon } from "assets/img/days_of_the_week.svg";
import { MBInput } from "components/common/Inputs/MBInput";
import { MBTheme } from "assets/theme";
import { expand } from "inline-style-expand-shorthand";
import { DateTime } from "luxon";
import moment from "moment";
import { ridershipNTDReport } from "./MyPoolRidershipNTDReport";
import { params } from "interfaces/params";
import {
  Header,
  HeaderContainer,
  PageContainer,
  RIDERSHIP_REPORT,
  RIDERSHIP_REPORTS,
  SelectContainer,
} from "./MyPoolRidershipReportCommon";
import { MBSelect } from "components/common/Inputs/MBSelect";

const RidershipRow = styled("div", ({ $theme }) => ({
  fontSize: "14px",
  display: "grid",
  gridTemplateColumns: "40% auto",
  alignItems: "center",
}));

interface RidershipSummaryProps {
  bookedPercent: number;
  missedPercent: number;
  drovePercent?: number;
  showDrovePercent?: boolean;
}

const RidershipSummary: React.FC<RidershipSummaryProps> = ({
  bookedPercent,
  missedPercent,
  drovePercent,
  showDrovePercent = false,
}) => {
  return (
    <div
      style={{
        width: "65px",
      }}
    >
      <RidershipRow>
        <CheckmarkIcon color={"#02CC9E"} />
        {bookedPercent.toFixed(0)}%
      </RidershipRow>
      <RidershipRow>
        <div style={{ color: "red" }}>X</div> {missedPercent.toFixed(0)}%
      </RidershipRow>
      {showDrovePercent && (
        <RidershipRow>
          <div>
            <DriveIcon />
          </div>
          {drovePercent?.toFixed(0)}%
        </RidershipRow>
      )}
    </div>
  );
};

const calcDatePeriod = (
  date: DateTime,
  period: "year" | "quarter" | "month" | "week" | "day",
  includeYear: boolean = false,
) => {
  let periodStr: string;

  if (period === "week") {
    const formatString = includeYear ? "MMM D, YYYY" : "MMM D";
    const weekStart = date.startOf("week");
    const weekEnd = date.endOf("week");
    periodStr = `${weekStart.toFormat(formatString)} - ${weekEnd.toFormat("MMM D")}`;
  } else {
    periodStr = date.toFormat(
      {
        year: "YYYY",
        quarter: includeYear ? "[Q]Q YYYY" : "[Q]Q",
        month: includeYear ? "MMM YYYY" : "MMM",
        day: includeYear ? "MMM D, YYYY" : "MMM D",
      }[period],
    );
  }

  return periodStr;
};

const groupRidershipForPeriodByRiderId = (
  pool: riderMyPoolInterface,
  periodList: string[],
  ridershipDays: ridershipReport.day[],
  period: "year" | "quarter" | "month" | "week" | "day",
): Map<string, Map<string, Map<DateTime, USER_RIDE_STATUS>>> => {
  // Map<userId, Map<period, Map<RideDate, USER_RIDE_STATUS>>>
  // take list of ridershipDays and return map of ride statuses grouped by period for each userId
  const userIdRidershipByPeriod = new Map<string, Map<string, Map<DateTime, USER_RIDE_STATUS>>>();

  periodList.forEach((period) => {
    pool.members.forEach((member) => {
      const initialPeriodRidership =
        userIdRidershipByPeriod.get(member.id) ||
        new Map<string, Map<DateTime, USER_RIDE_STATUS>>();
      initialPeriodRidership.set(period, new Map<DateTime, USER_RIDE_STATUS>());
      userIdRidershipByPeriod.set(member.id, initialPeriodRidership);
    });
  });

  ridershipDays.forEach((day) => {
    pool.members.forEach((member) => {
      const userRidershipByPeriod =
        userIdRidershipByPeriod.get(member.id) ||
        new Map<string, Map<DateTime, USER_RIDE_STATUS>>();

      const memberRidershipForDay =
        day.members.find((ridershipMember) => ridershipMember.userId === member.id)?.ridership ||
        USER_RIDE_STATUS.NONE;

      const periodStr = calcDatePeriod(day.date, period, true);

      const periodRidership =
        userRidershipByPeriod.get(periodStr) || new Map<DateTime, USER_RIDE_STATUS>();
      periodRidership.set(day.date, memberRidershipForDay);
      userRidershipByPeriod.set(periodStr, periodRidership);

      userIdRidershipByPeriod.set(member.id, userRidershipByPeriod);
    });
  });

  return userIdRidershipByPeriod;
};

const aggregateRidershipTotalsForPeriod = (
  // Map<period, Map<RideDate, USER_RIDE_STATUS>>
  ridershipForPeriod: Map<string, Map<DateTime, USER_RIDE_STATUS>>,
) => {
  const ridershipTotalsForPeriod = new Map<
    string,
    {
      bookedTotal: number;
      missedTotal: number;
      droveTotal: number;
    }
  >();

  ridershipForPeriod.forEach((ridership, period) => {
    let bookedTotal = 0;
    let missedTotal = 0;
    let droveTotal = 0;

    ridership.forEach((rideStatus) => {
      const dayStatus = getMyPoolUserStatus(rideStatus);
      dayStatus.isBooked && bookedTotal++;
      dayStatus.isMissed && missedTotal++;
      dayStatus.isDriving && droveTotal++;
    });

    ridershipTotalsForPeriod.set(period, {
      bookedTotal,
      missedTotal,
      droveTotal,
    });
  });

  return ridershipTotalsForPeriod;
};

function generatePeriodsBetweenDates(
  pool: riderMyPoolInterface,
  startDate: DateTime,
  endDate: DateTime,
  period: "year" | "quarter" | "month" | "week" | "day",
): { periods: string[]; displayPeriods: string[] } {
  const periods: string[] = [];
  // displayPeriods are the display strings shown on the headers
  // must be different from the periods used to record ridership because we only
  // want to show year for the first period of the year on the headers
  const displayPeriods: string[] = [];

  let currentDate = startDate;
  let previousDate = currentDate;

  while (currentDate <= endDate) {
    if (period === "day") {
      // we only want to show service days
      const activeService = getActiveServiceForDate(pool, moment(currentDate.toJSDate()));
      const dayIsInService = activeService && isServiceDay(activeService, currentDate);

      if (!dayIsInService) {
        currentDate = currentDate.plus({ [period]: 1 });
        continue;
      }
    }

    const periodStr = calcDatePeriod(currentDate, period, true);
    periods.push(periodStr);
    const displayPeriod = calcDatePeriod(
      currentDate,
      period,
      currentDate.year !== previousDate.year ? true : false,
    );
    displayPeriods.push(displayPeriod);
    previousDate = currentDate;
    currentDate = currentDate.plus({ [period]: 1 });
  }

  return { periods, displayPeriods };
}

interface MyPoolRidershipReportProps {
  pool: riderMyPoolInterface;
  pathPrefix?: string;
}

export const MyPoolRidershipReport: React.FC<MyPoolRidershipReportProps> = ({
  pool,
  pathPrefix,
}) => {
  const [selectedPeriod, setSelectedPeriod] = useState<
    "year" | "quarter" | "month" | "week" | "day"
  >("day");
  const [dateRange, setDateRange] = useState<DateTime[]>([
    DateTime.now().minus({ weeks: 2 }),
    DateTime.now(),
  ]);
  const [ridershipDays, setRidershipDays] = useState<ridershipReport.day[]>([]);
  const [tableHeader, setTableHeader] = useState<any[]>([]);
  const [tableData, setTableData] = useState<any[][]>([]);
  const [tableFooter, setTableFooter] = useState<any[]>([]);

  const currentDate = DateTime.now();

  useEffect(() => {
    if (dateRange.length < 2) {
      return;
    }

    fetchRidershipReport({
      startDate: dateRange[0].startOf(selectedPeriod),
      // there was some weirdness with endOf("day") including an extra day, so we floor the day
      endDate: dateRange[1].endOf(selectedPeriod).startOf("day"),
      vanpoolIds: [pool.id],
    }).then((report: ridershipReport.report) => {
      setRidershipDays(report.perPool[0].days);
    });
  }, [dateRange, selectedPeriod]);

  useEffect(() => {
    const periods = generatePeriodsBetweenDates(
      pool,
      dateRange[0].startOf(selectedPeriod),
      dateRange[1].endOf(selectedPeriod).startOf("day"),
      selectedPeriod,
    );

    const headers = ["Rider"].concat(periods.displayPeriods);
    headers.push("Total");

    const footerPeriodTotals = new Map<
      string,
      { bookedTotal: number; missedTotal: number; totalNumRides: number }
    >();

    const updatedTableData: React.ReactElement[][] = [];

    const ridershipForPeriodByUserId = groupRidershipForPeriodByRiderId(
      pool,
      periods.periods,
      ridershipDays,
      selectedPeriod,
    );

    ridershipForPeriodByUserId.forEach((ridershipForPeriod, userId) => {
      const tableRow: React.ReactElement[] = [];
      tableRow.push(<p>{pool.members.find((member) => member.id === userId)?.name}</p>);

      const ridershipTotalsByPeriod = aggregateRidershipTotalsForPeriod(ridershipForPeriod);

      // totals used to calculate final Total column
      let totalNumServiceDaysForRider = 0;
      let totalBookedCountForRider = 0;
      let totalMissedCountForRider = 0;
      let totalDroveCountForRider = 0;

      ridershipTotalsByPeriod.forEach((ridershipTotals, period) => {
        const numServiceDaysThisPeriod =
          ridershipForPeriodByUserId.get(userId)?.get(period)?.size || 0;
        if (numServiceDaysThisPeriod === 0) {
          // there were no rides in this period, don't show anything
          tableRow.push(<></>);
          return;
        }

        totalBookedCountForRider += ridershipTotals.bookedTotal;
        totalMissedCountForRider += ridershipTotals.missedTotal;
        totalDroveCountForRider += ridershipTotals.droveTotal;
        totalNumServiceDaysForRider += numServiceDaysThisPeriod;

        const currentPeriodTotal = footerPeriodTotals.get(period) || {
          bookedTotal: 0,
          missedTotal: 0,
          totalNumRides: 0,
        };
        footerPeriodTotals.set(period, {
          bookedTotal: currentPeriodTotal.bookedTotal + ridershipTotals.bookedTotal,
          missedTotal: currentPeriodTotal.missedTotal + ridershipTotals.missedTotal,
          totalNumRides: currentPeriodTotal.totalNumRides + numServiceDaysThisPeriod,
        });

        const bookedPercent = (ridershipTotals.bookedTotal / numServiceDaysThisPeriod) * 100;
        const missedPercent =
          (ridershipTotals.missedTotal / ridershipTotals.bookedTotal) * 100 || 0;

        // on single day view show what the user did
        selectedPeriod === "day"
          ? tableRow.push(
              ridershipTotals.missedTotal === 1 ? (
                <div style={{ color: "red" }}>X</div>
              ) : ridershipTotals.droveTotal === 1 ? (
                <DriveIcon />
              ) : ridershipTotals.bookedTotal === 1 ? (
                <CheckmarkIcon color={"#02CC9E"} />
              ) : (
                <></>
              ),
            )
          : tableRow.push(
              bookedPercent === 0 && missedPercent === 0 ? (
                <></>
              ) : (
                <RidershipSummary bookedPercent={bookedPercent} missedPercent={missedPercent} />
              ),
            );
      });

      // final Total column for the rider
      tableRow.push(
        <RidershipSummary
          bookedPercent={(totalBookedCountForRider / totalNumServiceDaysForRider) * 100 || 0}
          missedPercent={(totalMissedCountForRider / totalBookedCountForRider) * 100 || 0}
          drovePercent={(totalDroveCountForRider / totalBookedCountForRider) * 100 || 0}
        />,
      );

      const allRiderTotal = footerPeriodTotals.get("Total") || {
        bookedTotal: 0,
        missedTotal: 0,
        totalNumRides: 0,
      };
      footerPeriodTotals.set("Total", {
        bookedTotal: allRiderTotal.bookedTotal + totalBookedCountForRider,
        missedTotal: allRiderTotal.missedTotal + totalMissedCountForRider,
        totalNumRides: allRiderTotal.totalNumRides + totalNumServiceDaysForRider,
      });

      updatedTableData.push(tableRow);
    });

    const footer: React.ReactElement[] = [<>Total</>];
    periods.periods.forEach((period) => {
      // calculate what percentage of riders booked in this period
      const currentPeriodTotal = footerPeriodTotals.get(period);
      if (!currentPeriodTotal) {
        // there were no rides in this period, don't show anything
        footer.push(<></>);
        return;
      }

      const footerBookedPercent =
        (currentPeriodTotal.bookedTotal / currentPeriodTotal.totalNumRides) * 100;
      const footerMissedPercent =
        (currentPeriodTotal.missedTotal / currentPeriodTotal.bookedTotal) * 100 || 0;
      footer.push(
        <RidershipSummary
          bookedPercent={footerBookedPercent || 0}
          missedPercent={footerMissedPercent || 0}
        />,
      );
    });

    // calculate how many of the total number of rides were booked (bottom right cell of table)
    const total = footerPeriodTotals.get("Total") || {
      bookedTotal: 0,
      missedTotal: 0,
      totalNumRides: 0,
    };
    const totalBookedPercent = (total.bookedTotal / total.totalNumRides) * 100 || 0;
    const footerMissedPercent = (total.missedTotal / total.bookedTotal) * 100 || 0;

    footer.push(
      <RidershipSummary
        bookedPercent={totalBookedPercent || 0}
        missedPercent={footerMissedPercent || 0}
      />,
    );

    setTableHeader(headers);
    setTableFooter(footer);
    setTableData(updatedTableData);
  }, [ridershipDays]);

  const periodSelectorCommonStyle: React.CSSProperties = { width: "75px" };
  const periodSelectorMiddleButtonStyle: ButtonOverrides = {
    BaseButton: {
      style: {
        ...periodSelectorCommonStyle,
        ...expand({
          borderLeft: "0px",
        }),
        borderTopRightRadius: "0px",
        borderBottomRightRadius: "0px",
        borderTopLeftRadius: "0px",
        borderBottomLeftRadius: "0px",
      },
    },
  };

  interface reportState {
    vanpoolId: string;
    reportId: RIDERSHIP_REPORT;
    // TODO include date range and view by in url state
  }

  const history = useHistory();

  const updateState = (up: Partial<reportState>) => {
    const val: reportState = { reportId: RIDERSHIP_REPORT.FULL, vanpoolId: pool.id, ...up };

    // push history state so the select view params are preserved
    const url = `${pathPrefix}${routes.user.mypools}/${val.vanpoolId}/ridership/${val.reportId}`;
    history.push(url);
  };

  return (
    <>
      <div className="Navbar">
        <div className="Navbar-header" style={{ margin: "0 0 15px 25px", justifyContent: "unset" }}>
          <NavLink to={pathPrefix + `${routes.user.mypools}/${pool.id}`}>
            <div className="Navbar-back"></div>
          </NavLink>
          <div className="Navbar-header-title">Ridership Report</div>
          <div className="Navbar-filler" />
        </div>
      </div>
      <PageContainer>
        <HeaderContainer>
          <Header>
            <SelectContainer>
              <MyPoolLabel>REPORT</MyPoolLabel>
              <MBSelect
                options={RIDERSHIP_REPORTS}
                value={[RIDERSHIP_REPORTS.find((x) => x.id === RIDERSHIP_REPORT.FULL)!]}
                onChange={(evt) => updateState({ reportId: evt.option?.id as RIDERSHIP_REPORT })}
                placeholder={false}
                clearable={false}
                overrides={{
                  Root: {
                    style: {
                      minWidth: "180px",
                    },
                  },
                }}
              />
            </SelectContainer>
            <div style={{ width: "300px", display: "flex", flexDirection: "column", gap: "5px" }}>
              <MyPoolLabel>DATE RANGE</MyPoolLabel>
              <DatePicker
                formatString="MMM d, yyyy"
                range
                value={dateRange.map((date) => date.toJSDate())}
                onChange={({ date }) => {
                  setDateRange(date.map((d) => DateTime.fromJSDate(d)));
                }}
                quickSelect
                quickSelectOptions={[
                  {
                    id: "Last Week",
                    beginDate: DateTime.now().minus({ weeks: 1 }).startOf("week").toJSDate(),
                    endDate: DateTime.now().minus({ weeks: 1 }).endOf("week").toJSDate(),
                  },
                  {
                    id: "Past 30 Days",
                    beginDate: DateTime.now().minus({ days: 30 }).toJSDate(),
                    endDate: currentDate.toJSDate(),
                  },
                  {
                    id: "Last Month",
                    beginDate: DateTime.now().minus({ month: 1 }).startOf("month").toJSDate(),
                    endDate: DateTime.now().minus({ month: 1 }).endOf("month").toJSDate(),
                  },
                  {
                    id: "Past 90 Days",
                    beginDate: DateTime.now().minus({ days: 90 }).toJSDate(),
                    endDate: currentDate.toJSDate(),
                  },
                  {
                    id: "Last Quarter",
                    beginDate: DateTime.now().minus({ quarters: 1 }).startOf("quarter").toJSDate(),
                    endDate: DateTime.now().minus({ quarters: 1 }).endOf("quarter").toJSDate(),
                  },
                  {
                    id: "Past 6 Months",
                    beginDate: DateTime.now().minus({ months: 6 }).toJSDate(),
                    endDate: currentDate.toJSDate(),
                  },
                  {
                    id: "Year to Date",
                    beginDate: DateTime.now().startOf("year").toJSDate(),
                    endDate: currentDate.toJSDate(),
                  },
                  {
                    id: "Past 12 Months",
                    beginDate: DateTime.now().minus({ months: 12 }).toJSDate(),
                    endDate: currentDate.toJSDate(),
                  },
                  {
                    id: "Last Year",
                    beginDate: DateTime.now().minus({ years: 1 }).startOf("year").toJSDate(),
                    endDate: DateTime.now().minus({ years: 1 }).endOf("year").toJSDate(),
                  },
                ]}
                overrides={{
                  Input: ({ ...rest }) => (
                    <MBInput
                      {...rest}
                      startEnhancer={<CalendarIcon />}
                      overrides={{
                        Root: {
                          style: {
                            height: "50px",
                            ...expand({
                              border: `1px solid ${MBTheme.colors.borderOpaque}`,
                              borderRadius: "8px",
                            }),
                          },
                        },
                      }}
                    />
                  ),
                }}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <MyPoolLabel>VIEW BY</MyPoolLabel>
              <ButtonGroup overrides={{ Root: { style: { height: "50px" } } }}>
                <MBButton
                  onClick={() => {
                    setSelectedPeriod("year");
                  }}
                  overrides={{
                    BaseButton: {
                      style: {
                        ...periodSelectorCommonStyle,
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                      },
                    },
                  }}
                  disabled={selectedPeriod === "year"}
                >
                  Year
                </MBButton>
                <MBButton
                  onClick={() => {
                    setSelectedPeriod("quarter");
                  }}
                  overrides={periodSelectorMiddleButtonStyle}
                >
                  Quarter
                </MBButton>
                <MBButton
                  overrides={periodSelectorMiddleButtonStyle}
                  onClick={() => {
                    setSelectedPeriod("month");
                  }}
                >
                  Month
                </MBButton>
                <MBButton
                  overrides={periodSelectorMiddleButtonStyle}
                  onClick={() => {
                    setSelectedPeriod("week");
                  }}
                >
                  Week
                </MBButton>
                <MBButton
                  onClick={() => {
                    setSelectedPeriod("day");
                  }}
                  overrides={{
                    BaseButton: {
                      style: {
                        ...expand({
                          borderLeft: "0px",
                        }),
                        borderTopLeftRadius: "0px",
                        borderBottomLeftRadius: "0px",
                        ...periodSelectorCommonStyle,
                      },
                    },
                  }}
                >
                  Day
                </MBButton>
              </ButtonGroup>
            </div>
          </Header>
        </HeaderContainer>
        <StickyTable header={tableHeader} footer={tableFooter} data={tableData} />
      </PageContainer>
    </>
  );
};

interface MyPoolRidershipReportPageProps {
  pool: riderMyPoolInterface;
  pathPrefix?: string;
}

export const MyPoolRidershipReportPage: React.FC<MyPoolRidershipReportPageProps> = ({
  pool,
  pathPrefix,
}) => {
  let { reportId } = useParams<params>();
  reportId = reportId || RIDERSHIP_REPORT.FULL;

  return reportId === RIDERSHIP_REPORT.NTD ? (
    <ridershipNTDReport.NTDReportPage pool={pool} pathPrefix={pathPrefix} />
  ) : (
    <MyPoolRidershipReport pool={pool} pathPrefix={pathPrefix} />
  );
};
