import React, { FormEvent, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./Profile.scss";
import useMBContext from "context/useMBContext";
import InputValidator from "components/common/Form/InputValidator";
import LoadingButton from "components/LoadingButton";
import Avatar from "components/common/Avatar";
import { userInterface } from "interfaces/user";
import { fetchUserProfile, updateUserProfile, confirmUserPhoneUpdate } from "api/user";
import { formatPhoneNumber, formatPhoneNumberDisplay } from "helpers/formatHelpers";
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import { toaster } from "baseui/toast";
import { Button } from "baseui/button";
import { routes } from "misc/http";
import { MBInput } from "components/common/Inputs/MBInput";
import { getSuggestions } from "api/mapbox";
import { geoPointDataInterface } from "interfaces/map";
import { addressInterface, placeInterface } from "interfaces/place";
import DataList from "components/common/Form/DataList";
import {
  checkValidStopTimes,
  mapboxToAddress,
  parseFrom24HourTime,
  parseTo24HourTime,
  placeToUserLocation,
  userLocationToAddress,
} from "misc/utils";
import { vanpoolSearchSettingsInterface } from "interfaces/vanpool";
import { Checkbox, LABEL_PLACEMENT, STYLE_TYPE } from "baseui/checkbox";
import DayPicker from "components/common/Form/DayPicker";
import moment from "moment";
import { styled } from "baseui";
import { routeMakerConfig } from "api/pledge";
import { Option, Value } from "baseui/select";
import { MBSelect } from "components/common/Inputs/MBSelect";

interface SmsVerificationInterface {
  setPendingPhoneVerification;
}

const SmsVerification: React.FC<SmsVerificationInterface> = ({ setPendingPhoneVerification }) => {
  const [code, setCode] = useState<string>("");
  const [validCode, setValidCode] = useState<boolean>(false);
  const [codeVisited, setCodeVisited] = useState<boolean>(false);
  const [processingRequest, setProcessingRequest] = useState<boolean>(false);

  const codeChangeHandler = (code: string) => {
    setCode(code);
    setValidCode(/^[0-9]{6}$/.test(code));
  };

  const submitCodeHandler = () => {
    setProcessingRequest(true);
    confirmUserPhoneUpdate(code)
      .then(() => {
        toaster.show("Your phone number has successfully been updated.", {
          autoHideDuration: 4500,
        });
        setProcessingRequest(false);
        endPhoneUpdate();
      })
      .catch((errors) => {
        if (errors[0].code === "WRONG_SMS_CODE") {
          toaster.negative("The SMS code you've entered is incorrect. Please try again.", {
            autoHideDuration: 4500,
          });
        } else {
          toaster.negative("There was an error in submitting your SMS code. Please try again.", {
            autoHideDuration: 4500,
          });
        }
        setProcessingRequest(false);
      });
  };

  const validateCode = (e: FormEvent) => {
    e.preventDefault();
    if (validCode) {
      submitCodeHandler();
    }
  };

  const endPhoneUpdate = () => {
    setPendingPhoneVerification(false);
  };

  return (
    <div className="SmsVerification">
      <p className="SmsVerification-Instructions">
        We just sent a confirmation code to your mobile phone - please enter it below.
      </p>
      <form className="SmsVerification-Code" onSubmit={validateCode}>
        <FormControl
          label="Code"
          error={!validCode && codeVisited ? "Please enter a valid six digit code" : null}
        >
          <Input
            value={code}
            onBlur={() => setCodeVisited(true)}
            onChange={(event) => codeChangeHandler(event.currentTarget.value)}
          />
        </FormControl>
        <Button
          overrides={{
            BaseButton: {
              style: () => ({
                width: "100%",
              }),
            },
          }}
          isLoading={processingRequest}
          disabled={!validCode}
        >
          Submit
        </Button>
      </form>

      <button className="btn btn-secondary SmsVerification-Cancel" onClick={endPhoneUpdate}>
        Cancel
      </button>
    </div>
  );
};

const AddressSubtext = styled("div", ({ $theme }) => ({
  fontSize: "12px",
  marginTop: "-10px",
}));

const ProfilePage: React.FC = () => {
  const { user, logout } = useMBContext();

  const [profileUpdated, setProfileUpdated] = useState<boolean>(false);
  const [processingRequest, setProcessingRequest] = useState<boolean>(false);

  const [name, setName] = useState<string>("");
  const [validName, setValidName] = useState<boolean>(true);

  const [email, setEmail] = useState<string>("");
  const [validEmail, setValidEmail] = useState<boolean>(true);
  const [emailVisited, setEmailVisited] = useState<boolean>(false);

  const [phone, setPhone] = useState<string | undefined>("");
  const [validPhone, setValidPhone] = useState<boolean>(true);
  const [phoneVisited, setPhoneVisited] = useState<boolean>(false);
  const [pendingPhoneVerification, setPendingPhoneVerification] = useState<boolean>(false);

  const [shareEmail, setShareEmail] = useState<string>("");
  const [validShareEmail, setValidShareEmail] = useState<boolean>(false);
  const [profile, setProfile] = useState<userInterface | undefined>();

  const [homePlaces, setHomePlaces] = useState<geoPointDataInterface[]>([]);
  const [homeAddress, setHomeAddress] = useState<addressInterface>();
  const [homeAddressVisited, setHomeAddressVisited] = useState<boolean>(false);

  const [searching, setSearching] = useState<boolean>(false);
  const [workPlaces, setWorkPlaces] = useState<geoPointDataInterface[]>([]);
  const [allowedWorkPlaces, setAllowedWorkPlaces] = useState<placeInterface[]>([]);
  const [workAddress, setWorkAddress] = useState<addressInterface>();
  const [workAddressVisited, setWorkAddressVisited] = useState<boolean>(false);
  const [arriveWorkTime, setArriveWorkTime] = useState<Value>([{ id: "9:00 AM" }]);
  const [leaveWorkTime, setLeaveWorkTime] = useState<Value>([{ id: "5:00 PM" }]);
  const [rideDays, setRideDays] = useState<Array<string>>([]);
  const [rideDaysVisited, setRideDaysVisited] = useState<boolean>(false);
  const [canDrive, setCanDrive] = useState<boolean>(false);
  const [driveDays, setDriveDays] = useState<Array<string>>([]);
  const [driveDaysVisited, setDriveDaysVisited] = useState<boolean>(false);
  const [vehicleCapacity, setVehicleCapacity] = useState<number>();
  const [vehicleCapacityVisited, setVehicleCapacityVisited] = useState<boolean>(false);

  const { REACT_APP_ENVIRONMENT } = process.env;

  const nameChangeHandler = (name: string) => {
    setProfileUpdated(true);
    setName(name);
    setValidName(/.{1,}$/.test(name));
  };

  const emailChangeHandler = (email: string) => {
    setProfileUpdated(true);
    setEmail(email);
    setValidEmail(/\S+@\S+\.\S+/.test(email));
  };

  const shareEmailChangeHandler = (email: string, valid: boolean) => {
    setShareEmail(email);
    setValidShareEmail(valid);
  };

  const phoneChangeHandler = (phone: string) => {
    setProfileUpdated(true);
    setPhone(phone);
    setValidPhone(validatePhone(phone));
  };

  useEffect(() => {
    if (workAddress !== profile?.search?.work) {
      setProfileUpdated(true);
    }
  }, [workAddress]);

  useEffect(() => {
    if (homeAddress !== profile?.home) {
      setProfileUpdated(true);
    }
  }, [homeAddress]);

  const showAllFormErrors = () => {
    setEmailVisited(true);
    setPhoneVisited(true);
    setHomeAddressVisited(true);
    setWorkAddressVisited(true);
    setRideDaysVisited(true);
    setDriveDaysVisited(true);
    setVehicleCapacityVisited(true);
  };

  const validateForm = (e: FormEvent) => {
    e.preventDefault();
    if (profile && validName && validEmail && validPhone && phone && checkValidSearchProfile()) {
      setProcessingRequest(true);
      const formattedPhone = formatPhoneNumber(phone);
      const formattedSearch: vanpoolSearchSettingsInterface | undefined =
        searching && homeAddress && workAddress && arriveWorkTime[0].id && leaveWorkTime[0].id
          ? {
              work: workAddress,
              arriveTime: parseTo24HourTime(arriveWorkTime[0].id.toString()),
              departTime: parseTo24HourTime(leaveWorkTime[0].id.toString()),
              rideDays: rideDays,
              driveDays: canDrive ? driveDays : [],
              vehicleCapacity: canDrive ? vehicleCapacity : undefined,
            }
          : undefined;

      updateUserProfile({
        name,
        email,
        phone: formattedPhone,
        home: homeAddress,
        search: formattedSearch,
      })
        .then((success) => {
          setProfileUpdated(false);
          setProcessingRequest(false);
          // take user to phone verification screen if they changed their phone number
          if (formattedPhone !== profile.phone) {
            setPendingPhoneVerification(true);
          }
          if (email.toLowerCase() !== profile.email.toLowerCase()) {
            toaster.show("We have sent a confirmation email to your new email address.", {
              autoHideDuration: 4500,
            });
          }
        })
        .catch((e) => {
          if (e[0].code === "EMAIL_EXISTS") {
            toaster.negative("That email address is already in use.", {
              autoHideDuration: 4500,
            });
          } else {
            toaster.negative("There was an error in updating your information.", {
              autoHideDuration: 4500,
            });
          }
          setProcessingRequest(false);
        });
    } else {
      toaster.negative("There was an error in updating your information.", {
        autoHideDuration: 4500,
      });
      showAllFormErrors();
    }
  };

  const validatePhone = (phone) => {
    // test for US specific 10 digit phone formats with optional country code
    // or test for E.164 format (number up to 15 digit length starting with +)
    return (
      /^(\+[1-9]\d{1,14})$|^((\+?1)?)\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(
        phone.toString().trim(),
      ) && formatPhoneNumber(phone) !== null
    );
  };

  const handleAddressSearch = (value: string, type: "HOME" | "WORK") => {
    value !== "" &&
      getSuggestions(value, {
        types: "address,poi",
        country: "US",
      }).then((data: any) => {
        if (type === "HOME") {
          setHomePlaces(data.data.features);
        } else {
          setWorkPlaces(data.data.features);
        }
      });
  };

  const checkValidSearchProfile = () => {
    return (
      !searching ||
      (searching &&
        homeAddress !== undefined &&
        workAddress !== undefined &&
        (rideDays.length > 0 || driveDays.length > 0) &&
        (!canDrive ||
          (canDrive && driveDays.length > 0 && vehicleCapacity && vehicleCapacity > 0)) &&
        checkValidStopTimes(arriveWorkTime[0].id, leaveWorkTime[0].id))
    );
  };

  const timeList: Array<Option> = [];
  const startTime = moment("00:00", "hh:mm");
  for (let i = 0; i <= 1440; i += 15) {
    startTime.add(i === 0 ? 0 : 15, "minutes");
    timeList.push({ id: startTime.format("h:mm A"), label: startTime.format("h:mm A") });
  }

  useEffect(() => {
    fetchUserProfile().then((user) => {
      setProfile(user);
      setName(user.name);
      setEmail(user.email);
      setPhone(formatPhoneNumberDisplay(user.phone));
      setHomeAddress(user.home);

      if (!user.search) return;

      setSearching(user.search !== null);
      setWorkAddress(user.search.work);
      setRideDays(user.search.rideDays);
      setArriveWorkTime([{ id: parseFrom24HourTime(user.search.arriveTime) }]);
      setLeaveWorkTime([{ id: parseFrom24HourTime(user.search.departTime) }]);

      if (!user.search.driveDays) return;

      setCanDrive(user.search.driveDays.length > 0);
      setDriveDays(user.search.driveDays);
      setVehicleCapacity(user.search.vehicleCapacity);
    });
  }, [pendingPhoneVerification]);

  useEffect(() => {
    if (!user) return;

    const userOrganizationId = user.organizations.find((x) => !!x.routeMaker)?.id;
    if (!userOrganizationId) return;

    routeMakerConfig(userOrganizationId).then((config) => {
      if (config.defaultPlace) {
        setWorkAddress(userLocationToAddress(placeToUserLocation(config.defaultPlace)));
      }
      if (config.allowedPlaces) {
        setAllowedWorkPlaces(
          config.allowedPlaces.map((place) => {
            return { ...place };
          }),
        );
      }
    });
  }, [user]);

  return (
    <>
      {profile && (
        <div className="Profile">
          <div className="Profile-container">
            {!pendingPhoneVerification ? (
              <>
                <div className="Profile-header">
                  <span className="text">Profile</span>
                  <Avatar />
                </div>
                <form className="Profile-form" onSubmit={validateForm} autoComplete="off">
                  <FormControl label="Name" error={!name ? "Please enter your name" : null}>
                    <MBInput
                      value={name}
                      onChange={(event) => nameChangeHandler(event.currentTarget.value)}
                    />
                  </FormControl>
                  <FormControl
                    label="Email"
                    error={
                      !validEmail && emailVisited ? "Please enter a valid email address" : null
                    }
                  >
                    <MBInput
                      value={email}
                      onBlur={() => setEmailVisited(true)}
                      onChange={(event) => emailChangeHandler(event.currentTarget.value)}
                    />
                  </FormControl>
                  <FormControl
                    label="Phone"
                    error={!validPhone && phoneVisited ? "Please enter a valid phone number" : null}
                  >
                    <MBInput
                      value={phone}
                      onBlur={() => setPhoneVisited(true)}
                      onChange={(event) => phoneChangeHandler(event.currentTarget.value)}
                    />
                  </FormControl>
                  <FormControl
                    label="Home address"
                    error={
                      !homeAddress && searching && homeAddressVisited
                        ? "Home address is required when looking for a vanpool"
                        : null
                    }
                  >
                    <>
                      <DataList
                        keyProp="id"
                        nameProp="text"
                        descrProp="place_name"
                        icon="🏠"
                        options={homePlaces}
                        onChange={(val) => {
                          if (val === "") {
                            setHomeAddress(undefined);
                          } else {
                            handleAddressSearch(val, "HOME");
                          }
                        }}
                        onSelect={(place) => {
                          setHomeAddress(mapboxToAddress(place));
                        }}
                        onBlur={() => {
                          setHomeAddressVisited(true);
                        }}
                        val={homeAddress?.name}
                        placeholder="Home address"
                        clearable
                      />
                      <AddressSubtext>
                        Home address is used to calculate walking distance from stops and when
                        looking for a vanpool.
                      </AddressSubtext>
                    </>
                  </FormControl>
                  <FormControl>
                    <Checkbox
                      checked={searching}
                      checkmarkType={STYLE_TYPE.toggle_round}
                      onChange={(e) => {
                        setProfileUpdated(true);
                        setSearching(e.currentTarget.checked);
                      }}
                      labelPlacement={LABEL_PLACEMENT.left}
                      overrides={{
                        Root: { style: { display: "flex", justifyContent: "space-between" } },
                        Label: { style: { fontWeight: "normal" } },
                      }}
                    >
                      I'm looking for a vanpool
                    </Checkbox>
                  </FormControl>
                  {searching && (
                    <>
                      <FormControl
                        label="Work address"
                        error={
                          searching && workAddressVisited && !workAddress
                            ? "Please select a destination"
                            : null
                        }
                      >
                        <DataList
                          keyProp="id"
                          nameProp={allowedWorkPlaces.length > 0 ? "placeName" : "text"}
                          descrProp={allowedWorkPlaces.length > 0 ? "streetAddress" : "place_name"}
                          icon="🏢"
                          options={allowedWorkPlaces.length > 0 ? allowedWorkPlaces : workPlaces}
                          onChange={(val) => {
                            if (val === "") {
                              setWorkAddress(undefined);
                            } else {
                              handleAddressSearch(val, "WORK");
                            }
                          }}
                          onSelect={(place) => {
                            const address =
                              allowedWorkPlaces.length > 0
                                ? userLocationToAddress(placeToUserLocation(place))
                                : mapboxToAddress(place);
                            setWorkAddress(address);
                          }}
                          onBlur={() => {
                            setWorkAddressVisited(true);
                          }}
                          val={workAddress?.name}
                          placeholder="Work address"
                          clearable={allowedWorkPlaces.length === 0}
                          searchable={allowedWorkPlaces.length === 0}
                        />
                      </FormControl>
                      <FormControl
                        label="Arrive at"
                        error={
                          !checkValidStopTimes(arriveWorkTime[0].id, leaveWorkTime[0].id)
                            ? "Arrive time must be earlier than depart time"
                            : ""
                        }
                      >
                        <MBSelect
                          options={timeList}
                          value={arriveWorkTime}
                          clearable={false}
                          onChange={({ value }) => {
                            setProfileUpdated(true);
                            setArriveWorkTime(value);
                          }}
                        />
                      </FormControl>
                      <FormControl
                        label="Depart at"
                        error={
                          !checkValidStopTimes(arriveWorkTime[0].id, leaveWorkTime[0].id)
                            ? "Depart time must be later than depart time"
                            : ""
                        }
                      >
                        <MBSelect
                          options={timeList}
                          value={leaveWorkTime}
                          clearable={false}
                          onChange={({ value }) => {
                            setProfileUpdated(true);
                            setLeaveWorkTime(value);
                          }}
                        />
                      </FormControl>
                      <FormControl
                        label="I want to ride on these days"
                        error={
                          searching &&
                          rideDaysVisited &&
                          rideDays.length === 0 &&
                          driveDays.length === 0
                            ? "Please select days to ride"
                            : null
                        }
                      >
                        <DayPicker
                          className="RideDayPicker"
                          selectedDays={rideDays}
                          onChange={(days) => {
                            setRideDaysVisited(true);
                            setProfileUpdated(true);
                            setRideDays(days);
                          }}
                        />
                      </FormControl>
                      <Checkbox
                        checked={canDrive}
                        checkmarkType={STYLE_TYPE.toggle_round}
                        onChange={(e) => {
                          setProfileUpdated(true);
                          setCanDrive(e.currentTarget.checked);
                        }}
                        labelPlacement={LABEL_PLACEMENT.left}
                        overrides={{
                          Root: {
                            style: {
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: "15px",
                            },
                          },
                          Label: { style: { fontWeight: "normal" } },
                        }}
                      >
                        I'm interested in driving
                      </Checkbox>
                      {canDrive && (
                        <>
                          <FormControl
                            label="I can drive on these days"
                            error={
                              searching && canDrive && driveDaysVisited && driveDays.length === 0
                                ? "Please select days to drive"
                                : null
                            }
                          >
                            <DayPicker
                              className="DriveDayPicker"
                              selectedDays={driveDays}
                              onChange={(days) => {
                                setDriveDaysVisited(true);
                                setProfileUpdated(true);
                                setDriveDays(days);
                              }}
                            />
                          </FormControl>
                          <FormControl
                            label="I can drive this many passengers"
                            error={
                              searching &&
                              canDrive &&
                              vehicleCapacityVisited &&
                              (!vehicleCapacity || (vehicleCapacity && vehicleCapacity < 1))
                                ? "Please enter the number of passengers you can drive"
                                : null
                            }
                          >
                            <MBInput
                              type="number"
                              min={1}
                              value={vehicleCapacity}
                              onBlur={() => setVehicleCapacityVisited(true)}
                              onChange={(e) => {
                                setProfileUpdated(true);
                                setVehicleCapacity(parseInt(e.currentTarget.value));
                              }}
                            />
                          </FormControl>
                        </>
                      )}
                    </>
                  )}
                  <Button
                    overrides={{
                      BaseButton: {
                        style: () => ({
                          width: "100%",
                        }),
                      },
                    }}
                    isLoading={processingRequest}
                    disabled={!profileUpdated}
                  >
                    Save changes
                  </Button>
                </form>
                {false ? (
                  <div>
                    <div className="Profile-sub-header">
                      <span className="text">Promo Code</span>
                    </div>
                    <div className="Profile-tip">Share MagicBus savings with friends</div>
                    <InputValidator
                      type="text"
                      name="Share Promo Code"
                      onChange={shareEmailChangeHandler}
                      placeholder="hello@domain.com"
                      pattern="\S+@\S+\.\S+"
                      error="Please enter a valid email to share your promo code."
                    />
                    <NavLink to="/" className="Copy-Link" data-testid="forgot-link">
                      Copy Promo Link
                    </NavLink>
                    <LoadingButton
                      label="Send Invite"
                      disabled={!validShareEmail}
                      loading={processingRequest}
                      style="Button"
                    />
                  </div>
                ) : (
                  ""
                )}
                <button className="btn btn-secondary Profile-Logout" onClick={logout}>
                  Log Out
                </button>
              </>
            ) : (
              <SmsVerification setPendingPhoneVerification={setPendingPhoneVerification} />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ProfilePage;
