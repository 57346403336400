import React from "react";
import { myPoolMemberProfileInterface, VANPOOLER_ROLE } from "interfaces/user";
import { vanpoolDayDisplayInterface } from "interfaces/vanpool";
import moment from "moment";
import { useDateRow } from "components/MyPools/DateList/DateRow/useDateRow";
import { POOL_ACTION_BUTTON_LABEL } from "components/MyPools/utils";

import "./DateRowSummary.scss";
import CheckmarkIcon from "assets/img/checkmark-icon";

interface DateRowInterface {
  vanpoolDay: vanpoolDayDisplayInterface;
  userProfile: myPoolMemberProfileInterface;
  actionRoles?: VANPOOLER_ROLE[];
}

export const DateRowSummary: React.FC<DateRowInterface> = ({
  vanpoolDay,
  userProfile,
  actionRoles = userProfile.userRoles,
}) => {
  const { viewPerms, status, rideActionProps, driveActionProps } = useDateRow({
    vanpoolDay,
    userProfile,
    actionRoles,
  });

  const RideMessage = () => {
    const currentRideStatus =
      viewPerms.canDrive && status.isDriving
        ? driveActionProps.label
        : viewPerms.canRide
        ? rideActionProps.label
        : undefined;
    let message;
    if (currentRideStatus === POOL_ACTION_BUTTON_LABEL.CHECKEDIN) {
      message = (
        <>
          <p className="DateRowSummary_tickMark">
            <CheckmarkIcon color="#0076ff" />
          </p>
          <p>You were checked in for this ride.</p>
        </>
      );
    } else if (currentRideStatus === POOL_ACTION_BUTTON_LABEL.DRIVING) {
      if (status.rideConfirmed) {
        message = (
          <>
            <p className="DateRowSummary_tickMark">
              <CheckmarkIcon color="#0076ff" />
            </p>
            <p>You were the driver for this ride.</p>
          </>
        );
      } else {
        message = "Your requested ride was unavailable due to lack of riders or vehicle.";
      }
    } else if (currentRideStatus === POOL_ACTION_BUTTON_LABEL.MISSED) {
      message = "⚠️ You missed this ride.";
    } else if (currentRideStatus === POOL_ACTION_BUTTON_LABEL.CANCELED) {
      message = "❌ This ride was canceled.";
    } else if (currentRideStatus === POOL_ACTION_BUTTON_LABEL.REQUESTED) {
      message = "Your requested ride was unavailable due to lack of driver or vehicle.";
    } else if (currentRideStatus === POOL_ACTION_BUTTON_LABEL.WAITLISTED) {
      message = "You were on the waitlisted for this ride.";
    } else {
      message = "You were not booked for this ride.";
    }

    return <p className="DateRowSummary_message">{message}</p>;
  };

  const currentDate = vanpoolDay.date.format("dddd, MMMM Do YYYY");
  const rideTimeline = moment().diff(vanpoolDay.date, "days");
  return (
    <div className="DateRowSummary">
      <p className="DateRowSummary_date">{currentDate}</p>
      <p className="DateRowSummary_timeline">{`${rideTimeline} day${
        rideTimeline > 1 ? "s" : ""
      } ago`}</p>
      <RideMessage />
    </div>
  );
};
