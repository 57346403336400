import { MBInput } from "components/common/Inputs/MBInput";
import React, { useEffect, useState } from "react";
import "./DataList.scss";

interface optionInterface {
  id: string | number;
  [key: string]: any;
}

interface iPropsDataList {
  keyProp: string;
  nameProp: string;
  descrProp?: string;
  label?: string;
  icon?: string;
  options: Array<optionInterface>;
  onChange?: (val: string) => void;
  onSelect?: (val: any) => void;
  onBlur?: () => void;
  val?: string;
  placeholder?: string;
  className?: string;
  clearable?: boolean;
  searchable?: boolean;
}

const DataList: React.FC<iPropsDataList> = ({
  keyProp,
  nameProp,
  descrProp = "",
  label = "",
  icon,
  options,
  onChange,
  onSelect,
  onBlur,
  val = "",
  placeholder = "",
  className,
  clearable = false,
  searchable = true,
}) => {
  const [inputVal, setInputVal] = useState<string>(val);
  const [showDropDown, setShowDropDown] = useState<boolean>(false);

  const handleChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInputVal(value);
    setShowDropDown(true);
    onChange && onChange(value);
  };

  const handleSelect = (item: optionInterface) => {
    onSelect && onSelect(item);
    setInputVal(`${item[nameProp]} ${item[descrProp]}`);
    setShowDropDown(false);
  };

  const handleDropDown = (isOpen: boolean, e: any) => {
    setShowDropDown(isOpen);
  };

  useEffect(() => {
    setInputVal(val);
  }, [val]);

  return (
    <div className={`DataList ${className}`}>
      {label && (
        <label className="DataList-label" htmlFor="">
          {label}
        </label>
      )}
      <MBInput
        startEnhancer={icon && icon}
        name="start"
        type="text"
        autoComplete="off"
        onChange={searchable ? handleChange : undefined}
        onFocus={(e) => handleDropDown(true, e)}
        value={inputVal}
        placeholder={placeholder}
        onBlur={onBlur}
        clearable={clearable}
      />
      {showDropDown && (
        <>
          <div
            className="DataList-dropdown-backdrop"
            onClick={(e) => handleDropDown(false, e)}
          ></div>
          <ul className="DataList-dropdown">
            {Array.isArray(options) && options.length
              ? options.map((item) => (
                  <li
                    key={item[keyProp]}
                    onClick={() => handleSelect(item)}
                    className="DataList-dropdown-item"
                  >
                    <span className="DataList-dropdown-item-name">{item[nameProp]} </span>
                    {descrProp ? <span> {item[descrProp]} </span> : null}
                  </li>
                ))
              : inputVal && <li className="DataList-dropdown-item empty"> Nothing found</li>}
          </ul>
        </>
      )}
    </div>
  );
};

export default DataList;
