import { lineDisplayInterface } from "components/Map/mapline";
import { pinDisplayProperties } from "components/Map/MapMarkerPin";
import { stopInterface } from "interfaces/stop";
import { ORG_USER_FLAG, userInterface } from "./user";
import { GQLBase } from "misc/utils";

export class coordinate extends GQLBase {
  lat = 0;
  lng = 0;
}

export class legInterface extends GQLBase {
  polyline = "";
  cost = 0; // cents
  distance = 0; // meters
}

export interface stopDisplayInterface extends stopInterface {
  display?: pinDisplayProperties;
  highlighted?: pinDisplayProperties;
  selected?: pinDisplayProperties;
  walkMinutes?: number;
}

export interface legDisplayInterface extends legInterface, lineDisplayInterface {}

export interface routeDisplayInterface {
  id: string;
  service: serviceResultInterface;
  outboundPickup: number;
  outboundDropoff: number;
  inboundPickup?: number;
  inboundDropoff?: number;
  cost: number;
  stops: stopDisplayInterface[];
  legs: legDisplayInterface[];
  colorHexCode?: string;
  drivers?: userInterface[];
}

export class serviceRuleInterface extends GQLBase {
  rule = "";
}

export class scheduleResultInterface extends GQLBase {
  stops = [new stopInterface()];
  legs = [new legInterface()]; // .length == stops.length-1
  basePrice = 0; // cents
}

export class serviceResultInterface extends GQLBase {
  id = "";
  name?: string;
  days = [""];
  startDate = "";
  endDate?: string;
  dynamic = false;
  rules = [new serviceRuleInterface()];
  outbound = new scheduleResultInterface();
  inbound? = new scheduleResultInterface();
  drivers? = [new userInterface()];
  userFlags = [ORG_USER_FLAG.NO_FEE];
}
