import React from "react";
import "../../MyPools/MyPoolRideReceipt/MyPoolRideReceipt.scss";
import { StopList } from "../../MyPools/MyPoolRideDetails/components/StopList";
import { RuleList } from "../../MyPools/components/RuleList";
import { routeDisplayInterface } from "interfaces/route";
import { CollapsibleContainer } from "components/MyPools/components/CollapsibleContainer";
import { styled } from "baseui";

interface VanpoolSummaryProps {
  route: routeDisplayInterface;
}

const DetailBlock = styled("div", ({ $theme }) => ({
  marginBottom: "25px",
}));

export const VanpoolSummary: React.FC<VanpoolSummaryProps> = ({ route }) => {
  const outbound = [
    route.service.outbound.stops[route.outboundPickup],
    route.service.outbound.stops[route.outboundDropoff],
  ];
  const inbound =
    route.service.inbound && route.inboundPickup !== undefined && route.inboundDropoff !== undefined
      ? [
          route.service.inbound.stops[route.inboundPickup],
          route.service.inbound.stops[route.inboundDropoff],
        ]
      : null;

  return (
    <>
      {route.drivers && route.drivers.length > 0 && (
        <DetailBlock>
          <CollapsibleContainer label={"DRIVER"} collapsible={false}>
            {route.drivers[0].name}
          </CollapsibleContainer>
        </DetailBlock>
      )}
      <DetailBlock>
        <CollapsibleContainer label={"OUTBOUND"}>
          <StopList
            stops={outbound}
            riders={[]}
            showRiders={false}
            allowAttendance={false}
            showWalkTimes={true}
          />
        </CollapsibleContainer>
      </DetailBlock>
      {inbound && (
        <DetailBlock>
          <CollapsibleContainer label={"INBOUND"}>
            <StopList
              stops={inbound}
              riders={[]}
              showRiders={false}
              allowAttendance={false}
              showWalkTimes={true}
            />
          </CollapsibleContainer>
        </DetailBlock>
      )}
      {route.service.rules.length > 0 && <RuleList rules={route.service.rules} collapsible />}
    </>
  );
};
