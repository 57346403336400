import { styled } from "baseui";
import React, { useState } from "react";
import { MyPoolLabel } from "../MyPoolsPage/MyPoolsPage";
import { ReactComponent as CollapseOpenIcon } from "assets/img/collapse-open-icon.svg";
import { ReactComponent as CollapseClosedIcon } from "assets/img/collapsed-closed-icon.svg";

const CollapsibleHeader = styled("div", ({ $theme }) => ({
  alignItems: "center",
  justifyContent: "space-between",
  display: "flex",
  marginBottom: "5px",
}));

export interface CollapsibleContainerInterface {
  label: string;
  children: React.ReactNode;
  collapsible?: boolean;
  defaultExpanded?: boolean;
}
export const CollapsibleContainer: React.FC<CollapsibleContainerInterface> = ({
  label,
  children,
  collapsible = true,
  defaultExpanded = true,
}) => {
  const [expanded, setExpanded] = useState<boolean>(defaultExpanded);

  return (
    <>
      <CollapsibleHeader
        onClick={() => {
          collapsible && setExpanded(!expanded);
        }}
      >
        <MyPoolLabel>{label}</MyPoolLabel>
        <div>{collapsible && (expanded ? <CollapseOpenIcon /> : <CollapseClosedIcon />)}</div>
      </CollapsibleHeader>
      {expanded && children}
    </>
  );
};
