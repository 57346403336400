import React from "react";
import { NavLink } from "react-router-dom";
import "./MyPoolDates.scss";
import { routes } from "misc/http";
import { riderMyPoolDisplayInterface } from "interfaces/vanpool";
import { DateList } from "../DateList/DateList";
import { useMyPoolDates } from "./useMyPoolDates";
import moment from "moment";
import { MyPoolsLayout, MyPoolsLayoutInner } from "../MyPoolsPage/MyPoolsPage";

interface MyPoolDatesProps {
  pool: riderMyPoolDisplayInterface;
  pathPrefix?: string;
}

export const MyPoolDates: React.FC<MyPoolDatesProps> = ({ pool, pathPrefix = "" }) => {
  const params = new URLSearchParams(window.location.search);
  const startDate = moment(params.get("date") || moment());

  const { scrollSpacerRef, loadMoreRef, vanpoolDays, instabook, currentMonth, handleScroll } =
    useMyPoolDates({
      pool,
      initialStartDate: startDate,
    });

  return (
    <>
      <div className="Navbar">
        <div className="Navbar-header">
          <NavLink
            to={
              pathPrefix +
              `${routes.user.mypools}/${pool.id}?date=${vanpoolDays[0]?.date.format("YYYY-MM-DD")}`
            }
          >
            <div className="Navbar-back" />
          </NavLink>
          <div className="MyPoolDates_header">
            <p className="MyPoolDates_header_chooseDates">Choose Dates</p>
            <p className="MyPoolDates_header_month">
              {vanpoolDays && vanpoolDays.length > 0 && currentMonth}
            </p>
          </div>
          <div className="Navbar-filler" />
        </div>
      </div>
      <MyPoolsLayout onScroll={handleScroll}>
        {vanpoolDays.length > 0 && (
          <MyPoolsLayoutInner>
            <div className="MyPoolDates">
              <div ref={loadMoreRef} data-scroller style={{ height: "50px" }}></div>
              <div ref={scrollSpacerRef} style={{ height: "2px" }}></div>
              <DateList
                vanpoolDays={vanpoolDays}
                profile={pool.profile}
                instabookCallback={instabook}
                pathPrefix={pathPrefix}
              />
            </div>
          </MyPoolsLayoutInner>
        )}
      </MyPoolsLayout>
    </>
  );
};
