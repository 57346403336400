import { PoolActionButtonInterface } from "../../components/PoolActionButton";
import {
  getMyPoolUserPerms,
  getWarnings,
  POOL_ACTION_BUTTON_LABEL,
} from "components/MyPools/utils";
import { myPoolMemberProfileInterface, VANPOOLER_ROLE } from "interfaces/user";
import { vanpoolDayDisplayInterface } from "interfaces/vanpool";
import { useEffect, useState } from "react";

interface UseDataRowProps {
  vanpoolDay: vanpoolDayDisplayInterface;
  userProfile: myPoolMemberProfileInterface;
  actionRoles?: VANPOOLER_ROLE[];
}

export const useDateRow = ({
  vanpoolDay,
  userProfile,
  actionRoles = userProfile.userRoles,
}: UseDataRowProps) => {
  const [warnings, setWarnings] = useState<string[]>([]);

  // user perms
  // viewPerms determine what info to display to the user, actionPerms determine what buttons to render
  const viewPerms = getMyPoolUserPerms(userProfile);
  const actionPerms = getMyPoolUserPerms({
    userRoles: actionRoles,
    orgRoles: userProfile.orgRoles,
  });

  // user and ride statuses
  const status = vanpoolDay.status;

  const showOverbooked =
    status.isOverbooked &&
    (status.isWaitlisted || viewPerms.canProvideVehicle) &&
    !status.vehicleNeeded;

  // if there is no vehicle, only show rider count to coordinators, if no one is riding, don't show rider count
  const showRiderCount =
    !status.noRiders &&
    !status.rideCanceled &&
    (!status.vehicleNeeded || viewPerms.canProvideVehicle);

  // if ride isn't full and rider is riding, show number of riders
  // if ride is full and rider is waitlisted, show vehicle capacity and make icon red
  // if ride is overbooked, show total number of riders + waitlisted to only coordinator
  const riderCount =
    vanpoolDay.numRiders > vanpoolDay.capacity && !viewPerms.canProvideVehicle
      ? vanpoolDay.capacity
      : vanpoolDay.numRiders;

  useEffect(() => {
    const warn: string[] = getWarnings(vanpoolDay, userProfile);

    setWarnings([...warn]);
  }, [vanpoolDay]);

  const commonProps: Partial<PoolActionButtonInterface> = {
    warnings: warnings,
    inactive: status.isPast,
    canceled: status.rideCanceled,
    status: status.rideConfirmed ? "ok" : "warn",
    selected: !status.isPast,
  };

  const rideActionProps: PoolActionButtonInterface = {
    ...commonProps,
    ...(status.isWaitlisted || (status.isRiding && !status.rideConfirmed)
      ? {
          label: POOL_ACTION_BUTTON_LABEL.REQUESTED,
          status: "warn",
          icon: status.isPast ? undefined : "warn",
        }
      : status.isRiding
      ? {
          label: POOL_ACTION_BUTTON_LABEL.RIDING,
          icon: "check",
          ...(status.isPast &&
            (status.isMissed
              ? {
                  label: POOL_ACTION_BUTTON_LABEL.MISSED,
                  status: "warn",
                  icon: "warn",
                }
              : {
                  label: POOL_ACTION_BUTTON_LABEL.CHECKEDIN,
                  icon: "check",
                })),
        }
      : {
          label: POOL_ACTION_BUTTON_LABEL.RIDE,
          status: "ok",
          selected: false,
          ...(status.isPast && {
            label: POOL_ACTION_BUTTON_LABEL.NOTRIDING,
            status: "none",
          }),
        }),
  };

  const driveActionProps: PoolActionButtonInterface = {
    ...commonProps,
    ...(actionPerms.canProvideVehicle
      ? {
          // coordinator view of driver
          ...(vanpoolDay.driverId
            ? {
                label: POOL_ACTION_BUTTON_LABEL.DRIVING,
                icon: status.rideConfirmed ? "check" : "warn",
              }
            : {
                selected: false,
                label: POOL_ACTION_BUTTON_LABEL.ASSIGN_DRIVER,
                status: "ok",
                ...(status.isPast && {
                  label: POOL_ACTION_BUTTON_LABEL.NO_DRIVER,
                  status: "none",
                }),
              }),
          ...(status.isPast && {
            // If driver prevented the ride, warn
            ...(!status.rideConfirmed && !(status.noRiders || status.vehicleNeeded)
              ? {
                  status: "warn",
                  icon: "warn",
                }
              : {
                  icon: undefined,
                }),
          }),
        }
      : {
          // driver view of driver
          label: POOL_ACTION_BUTTON_LABEL.DRIVE,
          selected: status.isDriving,
          status: "ok",
          ...(status.isDriving
            ? {
                label: POOL_ACTION_BUTTON_LABEL.DRIVING,
                status: !status.rideConfirmed ? "warn" : "ok",
                icon: !status.rideConfirmed ? "warn" : "check",
              }
            : vanpoolDay.driverId && {
                // someone else is driving
                disabled: true,
              }),
          ...(status.isPast && {
            selected: false,
            label: !status.isDriving
              ? POOL_ACTION_BUTTON_LABEL.NOT_DRIVING
              : POOL_ACTION_BUTTON_LABEL.DRIVING,
          }),
        }),
  };

  const vehicleActionProps: PoolActionButtonInterface = {
    ...commonProps,
    ...(vanpoolDay.vehicleId
      ? {
          label: POOL_ACTION_BUTTON_LABEL.PROVIDING_VEHICLE,
          icon: status.rideConfirmed ? "check" : status.isPast ? undefined : "warn",
        }
      : {
          label: POOL_ACTION_BUTTON_LABEL.PROVIDE_VEHICLE,
          selected: false,
          status: "ok",
          ...(status.isPast && {
            label: POOL_ACTION_BUTTON_LABEL.NO_VEHICLE,
            // If vehicle prevented the ride, warn
            ...(status.noRiders || status.driverNeeded
              ? {
                  status: "none",
                }
              : {
                  status: "warn",
                  icon: "warn",
                }),
          }),
        }),
  };

  return {
    status,
    viewPerms,
    actionPerms,
    showOverbooked,
    showRiderCount,
    riderCount,
    rideActionProps,
    driveActionProps,
    vehicleActionProps,
  };
};
