import React from "react";
import { RiderCountIcon } from "assets/img/count-icons";
import RightArrowIcon from "assets/img/right-arrow-icon";
import { myPoolMemberProfileInterface, VANPOOLER_ROLE } from "interfaces/user";
import { vanpoolDayDisplayInterface } from "interfaces/vanpool";
import { PoolActionButton } from "../../components/PoolActionButton";
import "./DateRow.scss";
import { SimpleDate } from "../../components/SimpleDate";
import { useDateRow } from "./useDateRow";
import { POOL_ACTION_BUTTON_LABEL } from "components/MyPools/utils";

interface DateRowInterface {
  vanpoolDay: vanpoolDayDisplayInterface;
  userProfile: myPoolMemberProfileInterface;
  vehicle?: string;
  driver?: string;
  actionRoles?: VANPOOLER_ROLE[];
  rideCallback?: () => void;
  driveCallback?: () => void;
  provideVehicleCallback?: () => void;
  assignDriverCallback?: () => void;
  redirectCallback?: () => void;
}

export const DateRow: React.FC<DateRowInterface> = ({
  vanpoolDay,
  userProfile,
  actionRoles = userProfile.userRoles,
  vehicle,
  driver,
  rideCallback,
  driveCallback,
  provideVehicleCallback,
  assignDriverCallback,
  redirectCallback,
}) => {
  const {
    status,
    actionPerms,
    showOverbooked,
    showRiderCount,
    riderCount,
    rideActionProps,
    driveActionProps,
    vehicleActionProps,
  } = useDateRow({ vanpoolDay, userProfile, actionRoles });

  const isPast = status.isPast;

  return (
    <div
      className={"DateRow" + (isPast ? " past" : "")}
      onClick={isPast ? redirectCallback : undefined}
    >
      <div className="DateRow ActionRow info">
        <div className="DateRow firstColumn">
          <SimpleDate date={vanpoolDay.date} />
          {showRiderCount && (
            <div className={"DateRow ActionRow RiderCount" + (showOverbooked ? " Overbooked" : "")}>
              <RiderCountIcon riderCount={riderCount} overbooked={showOverbooked} />
            </div>
          )}
        </div>
        <div className="DateRow secondColumn">
          {status.rideCanceled && actionRoles.length ? (
            <div className="DateRow ActionRow">
              <div className="DateRow ActionButton">
                <PoolActionButton
                  label={POOL_ACTION_BUTTON_LABEL.CANCELED}
                  canceled={true}
                  onClick={redirectCallback}
                />
              </div>
            </div>
          ) : (
            <>
              {(actionPerms.canRide || actionPerms.canDrive) && (
                <div className="DateRow ActionRow">
                  {actionPerms.canRide && (
                    <div className="DateRow ActionButton">
                      <PoolActionButton
                        {...rideActionProps}
                        onClick={isPast ? redirectCallback : rideCallback}
                      />
                    </div>
                  )}
                  {(actionPerms.canDrive || actionPerms.canProvideVehicle) && (
                    <div className="DateRow ActionButton">
                      <PoolActionButton
                        {...driveActionProps}
                        driver={driver}
                        onClick={
                          isPast
                            ? redirectCallback
                            : actionPerms.canProvideVehicle
                            ? assignDriverCallback
                            : driveCallback
                        }
                      />
                    </div>
                  )}
                </div>
              )}
              {actionPerms.canProvideVehicle && (
                <div className="DateRow ActionRow">
                  <div className="DateRow ActionButton">
                    <PoolActionButton
                      {...vehicleActionProps}
                      onClick={isPast ? redirectCallback : provideVehicleCallback}
                      vehicle={vehicle}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        {redirectCallback && (
          <div className="DateRow lastColumn" onClick={redirectCallback}>
            <RightArrowIcon />
          </div>
        )}
      </div>
    </div>
  );
};
