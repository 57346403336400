import React from "react";
import { ReactComponent as CalendarIcon } from "assets/img/days_of_the_week.svg";
import { styled } from "baseui";

const DaysOfWeekDisplayContainer = styled("div", ({ $theme }) => ({
  display: "flex",
  gap: "5px",
  alignItems: "center",
  fontSize: "13px",
  color: $theme.colors.mono800,
  fontWeight: 500,
}));

interface DaysOfWeekDisplayProps {
  days: string[];
}

export const DaysOfWeekDisplay: React.FC<DaysOfWeekDisplayProps> = ({ days }) => {
  const dayMap = [
    { label: "U", value: "SU" },
    { label: "M", value: "MO" },
    { label: "T", value: "TU" },
    { label: "W", value: "WE" },
    { label: "R", value: "TH" },
    { label: "F", value: "FR" },
    { label: "S", value: "SA" },
  ];

  const getDisplay = () => {
    if (
      days.length === 5 &&
      ["MO", "TU", "WE", "TH", "FR"].every((day) => {
        return days.includes(day);
      })
    ) {
      return "Weekdays";
    } else if (
      days.length === 2 &&
      ["SU", "SA"].every((day) => {
        return days.includes(day);
      })
    ) {
      return "Weekends";
    } else if (days.length === 7) {
      return "Every day";
    } else {
      return dayMap.map((day) => {
        return days.includes(day.value) ? day.label : "";
      });
    }
  };

  return (
    <DaysOfWeekDisplayContainer>
      <CalendarIcon />
      {getDisplay()}
    </DaysOfWeekDisplayContainer>
  );
};
