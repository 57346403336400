import React from "react";
import ReactDOM from "react-dom";
import "assets/styles/index.scss";
import App from "components/App";
import { GlobalContextProvider } from "./context/global";
import * as serviceWorker from "./serviceWorker";
import { CookiesProvider } from "react-cookie";
//import { debugContextDevtool } from 'react-context-devtool';
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import { datadogLogs } from "@datadog/browser-logs";
import { BaseProvider } from "baseui";
import { MBTheme } from "assets/theme";

import TagManager from "react-gtm-module";
import jwt_decode from "jwt-decode";
import { BrowserRouter } from "react-router-dom";

const engine = new Styletron();

const { REACT_APP_GA_ID, REACT_APP_ENVIRONMENT, REACT_APP_LOG_ENVIRONMENT } = process.env;
const tagManagerArgs = {
  gtmId: REACT_APP_GA_ID,
};

TagManager.initialize(tagManagerArgs);

// Only use DD logging in dev/stage/prod, not on local testing
const LOG_ENV =
  REACT_APP_ENVIRONMENT === "dev" ? REACT_APP_LOG_ENVIRONMENT || "local" : REACT_APP_ENVIRONMENT;

if (LOG_ENV !== "local") {
  datadogLogs.init({
    clientToken: "puba3fe08fc38da902396d8b77686ee63e6",
    site: "datadoghq.com",
    service: "rider-app",
    env: LOG_ENV,
    forwardErrorsToLogs: true,
    sampleRate: 100,
    beforeSend: (log) => {
      const token = localStorage.getItem("token");
      let userId = null;
      if (token) {
        try {
          const decoded: any = jwt_decode(token);
          userId = decoded?.identity;
        } catch (error) {}
      }
      log.userToken = token;
      log.userId = userId;
    },
  });
}

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <GlobalContextProvider>
        <StyletronProvider value={engine}>
          <BaseProvider theme={MBTheme} zIndex={5}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </BaseProvider>
        </StyletronProvider>
      </GlobalContextProvider>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// Attach root container
//const container = document.getElementById("root");
//debugContextDevtool(container);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
