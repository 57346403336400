import { pledgeInterface, routeMakerInterface } from "interfaces/pledge";
import { gql_post } from "misc/http";

export const createPledge = async (pledge: pledgeInterface) => {
  const data = `mutation createPledge($pledge: PledgeInput!) {
    createPledge(pledge: $pledge) {
      id
    }
  }`;

  const variables = { pledge };
  return gql_post("createPledge", data, variables);
};

export const GQL_PLACE_FRAGMENT = `
  fragment PlaceFields on Place {
    id placeName streetAddress city stateCode zipCode
    coordinates { lng lat }
  }
`;

export const routeMakerConfig = async (organization: string) => {
  const data = `
    ${GQL_PLACE_FRAGMENT}
    query routeMakerConfig($organization: String!) {
      routeMakerConfig(organization: $organization) {
        logoURL welcomeMessage organizationName
        defaultPlace { ...PlaceFields }
        allowedPlaces { ...PlaceFields }
      }}`;

  const variables = { organization };
  return gql_post<routeMakerInterface>("routeMakerConfig", data, variables);
};
