import {
  autoAssignDayInterface,
  INSTABOOK_ACTION,
  INSTABOOK_ROLE,
  riderMyPoolDisplayInterface,
} from "interfaces/vanpool";
import { routes } from "misc/http";
import React, { useContext, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import "./MyPoolManagement.scss";
import { FILL } from "baseui/tabs-motion";
import {
  userInterface,
  userSimpleInterface,
  myPoolMemberInterface,
  VANPOOLER_ROLE,
} from "interfaces/user";
import { riderVanpoolInterface } from "interfaces/vanpool";
import {
  makeTabScrollableContent,
  makeTabScrollableContentFixedBottom,
  MBButton,
  ResponsiveContainer,
  TabsScrollableContent,
} from "components/common/Buttons/Buttons";
import { ReactComponent as DriveIcon } from "assets/img/drive-icon.svg";
import { getActiveServiceForDate, getMyPoolUserPerms } from "../utils";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "baseui/modal";
import { Input } from "baseui/input";
import moment, { Moment } from "moment";
import { AdminAddMemberModal } from "./AdminAddMemberModal";
import { ReactComponent as ProfileIcon } from "assets/img/default-profile-picture.svg";
import { RuleList } from "../components/RuleList";
import { ButtonStack } from "components/common/Modals/BaseModal/BaseModal";
import { MyPoolLabel } from "../MyPoolsPage/MyPoolsPage";
import { AutoAssignSchedule } from "../components/AutoAssignSchedule";
import { AssignModalContext, ASSIGN_TYPE } from "../AssignModal/AssignModal";
import { riderInstabookManage } from "api/vanpools";
import { toaster } from "baseui/toast";

enum USER_LIST_TYPE {
  MEMBERS,
  DRIVERS,
  COORDINATORS,
}

const userDisplay: { [key in USER_LIST_TYPE] } = {
  [USER_LIST_TYPE.MEMBERS]: (user: myPoolMemberInterface) => {
    return (
      <div className="UserDisplay">
        <ProfileIcon />
        <div>{user.name}</div>
        {user.profile.userRoles.includes(VANPOOLER_ROLE.DRIVER) && <DriveIcon />}
        {user.profile.userRoles.includes(VANPOOLER_ROLE.COORDINATOR) && "📝"}
      </div>
    );
  },
  [USER_LIST_TYPE.DRIVERS]: (user: userInterface) => {
    return (
      <div className="UserDisplay">
        <ProfileIcon />
        {user.name}
      </div>
    );
  },
  [USER_LIST_TYPE.COORDINATORS]: (user: userInterface) => {
    return (
      <div className="UserDisplay">
        <ProfileIcon />
        {user.name}
      </div>
    );
  },
};

interface UserListProps {
  type: USER_LIST_TYPE;
  users: userSimpleInterface[];
  canEdit: boolean;
  editCallback?: (member: myPoolMemberInterface) => void;
}

export const UserList: React.FC<UserListProps> = ({ type, users, canEdit, editCallback }) => {
  return (
    <>
      <div className="UserList">
        {users.map((user) => {
          return (
            <div className="UserItem" key={user.id}>
              {userDisplay[type](user)}
              {canEdit && (
                <div className="RemoveUser">
                  <MBButton
                    size="compact"
                    kind="secondary"
                    onClick={() => {
                      editCallback?.(user as myPoolMemberInterface);
                    }}
                  >
                    Edit
                  </MBButton>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

interface MyPoolManagementProps {
  pool: riderMyPoolDisplayInterface;
  updatePoolCallback: (arg: riderVanpoolInterface) => void;
  pathPrefix?: string;
  showAsMember?: boolean;
}

export const MyPoolManagement: React.FC<MyPoolManagementProps> = ({
  pool,
  updatePoolCallback,
  pathPrefix = "",
  showAsMember = true,
}) => {
  const { REACT_APP_MY_URL } = process.env;

  const { showAssignModal } = useContext(AssignModalContext);

  const params = new URLSearchParams(window.location.search);
  const defaultTab = params.get("default");

  const activeService =
    pool.services.find((service) => service.id === pool.profile.serviceId) || pool.services[0];

  const [activeTab, setActiveTab] = useState<React.Key>(defaultTab ? defaultTab : "users");
  const [showInviteModal, setShowInviteModal] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const history = useHistory();

  const userPerms = getMyPoolUserPerms(pool.profile);

  const inviteUrl = `${REACT_APP_MY_URL}${routes.vanpools.details}/${
    getActiveServiceForDate(pool, moment())?.id
  }`;

  const handleVanpoolUpdate = (riderVanpool: riderVanpoolInterface) => {
    updatePoolCallback({ ...pool, ...riderVanpool });
  };

  const autoAssignVehicle = (
    day: string,
    autoAssignDay: autoAssignDayInterface | undefined,
    dateNextApply: Moment,
  ) => {
    showAssignModal({
      pool,
      numRiders: pool.members.length,
      date: moment(),
      type: ASSIGN_TYPE.VEHICLE,
      selectedId: autoAssignDay?.vehicleId,
      assignCallback: (ib) => {
        const newAutoAssignDays: autoAssignDayInterface[] = [];
        const newAutoAssignDay = {
          role: INSTABOOK_ROLE.VEHICLE,
          day: day,
          vehicleId: ib.vehicleId,
        };

        if (pool.vehicleAutoAssign) {
          newAutoAssignDays.push(...pool.vehicleAutoAssign.items);

          const existingAutoAssignDayIndex = newAutoAssignDays.findIndex(
            (autoAssignDay) => autoAssignDay.day === day,
          );

          if (existingAutoAssignDayIndex !== -1) {
            if (ib.action === INSTABOOK_ACTION.CANCEL) {
              newAutoAssignDays.splice(existingAutoAssignDayIndex, 1);
            } else if (ib.action === INSTABOOK_ACTION.BOOK) {
              newAutoAssignDays[existingAutoAssignDayIndex] = newAutoAssignDay;
            }
          } else if (ib.action === INSTABOOK_ACTION.BOOK) {
            newAutoAssignDays.push(newAutoAssignDay);
          }
        } else {
          newAutoAssignDays.push(newAutoAssignDay);
        }
        const updatedAutoAssign = { items: newAutoAssignDays, dateNextApply: dateNextApply };
        pool.vehicleAutoAssign = updatedAutoAssign;
      },
    });
  };

  const savePoolChanges = () => {
    setSaving(true);
    riderInstabookManage({ vanpoolId: pool.id, vehicleAutoAssign: pool.vehicleAutoAssign })
      .then((updatedPool) => {
        pool.services = updatedPool.services;

        toaster.show("Pool successfully updated.", {
          autoHideDuration: 4500,
        });
      })
      .catch((errors) => {
        toaster.show("Unable to update Pool", {
          kind: "negative",
          autoHideDuration: 4500,
        });
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return (
    <>
      <div className="Navbar">
        <div className="Navbar-header">
          <NavLink to={pathPrefix + `${routes.user.mypools}/${pool.id}`}>
            <div className="Navbar-back"></div>
          </NavLink>
          <div className="Navbar-header-title">Manage pool</div>
          <div className="Navbar-filler" />
        </div>
      </div>
      {userPerms.canProvideVehicle || userPerms.isVPM ? (
        pool && (
          <ResponsiveContainer>
            <TabsScrollableContent
              fill={FILL.fixed}
              activeKey={activeTab}
              onChange={({ activeKey }) => {
                setActiveTab(activeKey);
              }}
            >
              {makeTabScrollableContentFixedBottom(
                "USERS",
                <UserList
                  users={pool.members}
                  canEdit={userPerms.canProvideVehicle || userPerms.isVPM}
                  type={USER_LIST_TYPE.MEMBERS}
                  editCallback={(member) => {
                    history.push(
                      pathPrefix + `${routes.user.mypools}/${pool.id}/manage/user/${member.id}`,
                    );
                  }}
                />,
                <>
                  <MBButton
                    flex
                    kind="secondary"
                    onClick={() => {
                      setShowInviteModal(true);
                    }}
                  >
                    + Invite riders
                  </MBButton>
                  {userPerms.isVPM && (
                    <AdminAddMemberModal
                      pool={pool}
                      addMembersCallback={(newUserIds) => {
                        // redirect to new user profile editor page
                        history.push(
                          pathPrefix +
                            `${routes.user.mypools}/${pool.id}/manage/add/${newUserIds[0]}`,
                        );
                      }}
                    />
                  )}
                </>,
              )}
              {makeTabScrollableContent(
                "SETTINGS",
                <div style={{ padding: "0px 15px" }}>
                  <>
                    <RuleList rules={pool.rules} />
                    <ButtonStack>
                      <MBButton
                        kind="secondary"
                        onClick={() => {
                          history.push(
                            pathPrefix + `${routes.user.mypools}/${pool.id}/manage/settings/rules`,
                          );
                        }}
                      >
                        {pool.rules.length > 0 ? "Edit rules" : "Add rules"}
                      </MBButton>
                    </ButtonStack>
                    <MyPoolLabel style={{ marginTop: "25px", marginBottom: "10px" }}>
                      AUTOMATICALLY PROVIDE VEHICLES
                    </MyPoolLabel>
                    <p style={{ fontSize: "14px", margin: "20px 0px" }}>
                      Automatically provide vehicles each week on:
                    </p>
                    <div
                      style={{
                        maxWidth: "600px",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      <AutoAssignSchedule
                        days={activeService.days}
                        types={[VANPOOLER_ROLE.COORDINATOR]}
                        autoAssignSettings={pool.vehicleAutoAssign}
                        vehicles={pool.vehicles}
                        autoAssignVehicleCallback={autoAssignVehicle}
                        updateDateCallback={(date) => {
                          pool.vehicleAutoAssign = {
                            items: pool.vehicleAutoAssign ? pool.vehicleAutoAssign.items : [],
                            dateNextApply: date,
                          };
                        }}
                      />
                    </div>
                    <ButtonStack style={{ marginTop: "20px" }}>
                      <MBButton kind="primary" onClick={savePoolChanges}>
                        Save settings
                      </MBButton>
                    </ButtonStack>
                  </>
                </div>,
              )}
            </TabsScrollableContent>
            <Modal
              isOpen={showInviteModal}
              onClose={() => {
                setShowInviteModal(false);
              }}
              overrides={{
                Dialog: {
                  style: () => ({
                    textAlign: "center",
                  }),
                },
              }}
            >
              <ModalHeader>Invite riders to pool</ModalHeader>
              <ModalBody>
                <div className="ManageVanpoolInvite">
                  <div className="InfoText">
                    Share the link below with members. Members can use this link to join the pool
                    and choose their preferred stops.
                  </div>
                  <div className="InviteUrlBlock">
                    <Input value={inviteUrl} autoFocus={false} />
                    <MBButton
                      kind="tertiary"
                      onClick={() => {
                        navigator.clipboard.writeText(inviteUrl);
                      }}
                    >
                      Copy
                    </MBButton>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <MBButton
                  flex
                  kind="primary"
                  onClick={() => {
                    setShowInviteModal(false);
                  }}
                >
                  Done
                </MBButton>
              </ModalFooter>
            </Modal>
          </ResponsiveContainer>
        )
      ) : (
        <p>You do not have permission to view this page.</p>
      )}
    </>
  );
};
