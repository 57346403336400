import React from "react";
import { vanpoolDayDetailsDisplayInterface } from "interfaces/vanpool";
import "./MyPoolRideReceipt.scss";
import { StopList } from "../MyPoolRideDetails/components/StopList";
import { RuleList } from "../components/RuleList";
import useMBContext from "context/useMBContext";

interface MyPoolRideSummaryProps {
  rideDay: vanpoolDayDetailsDisplayInterface;
}

export const MyPoolRideReceipt: React.FC<MyPoolRideSummaryProps> = ({ rideDay }) => {
  const { user } = useMBContext();
  const outboundProps = rideDay.outbound.riders.find((x) => x.rider.id === user?.id)?.trip
    .properties;
  const inboundProps = rideDay.inbound?.riders.find((x) => x.rider.id === user?.id)?.trip
    .properties;

  const outbound = outboundProps
    ? [
        rideDay.service.outbound.stops[outboundProps.pickup],
        rideDay.service.outbound.stops[outboundProps.dropoff],
      ]
    : null;
  const inbound =
    inboundProps && rideDay.service.inbound
      ? [
          rideDay.service.inbound.stops[inboundProps.pickup],
          rideDay.service.inbound.stops[inboundProps.dropoff],
        ]
      : null;
  return (
    <div className="MyPoolRideSummary">
      <div className="details">
        <div className="header">DRIVER</div>
        <div className="text">{rideDay.outbound.driver && rideDay.outbound.driver.name}</div>
      </div>
      <div className="details">
        <div className="header">VEHICLE</div>
        <div className="text">
          {rideDay.outbound.vehicle && rideDay.outbound.vehicle.description}
        </div>
      </div>
      {outbound && (
        <div className="details">
          <div className="header">OUTBOUND</div>
          <div className="text">
            <StopList stops={outbound} riders={[]} showRiders={false} allowAttendance={false} />
          </div>
        </div>
      )}
      {inbound && (
        <div className="details">
          <div className="header">INBOUND</div>
          <div className="text">
            <StopList stops={inbound} riders={[]} showRiders={false} allowAttendance={false} />
          </div>
        </div>
      )}
      <RuleList rules={rideDay.pool.rules} collapsible />
    </div>
  );
};
