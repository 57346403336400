import { AddressSelector, ADDRESS_TYPE } from "components/AddressSelector/AddressSelector";
import { MAP_BOUNDS } from "components/Map";
import useMBContext from "context/useMBContext";
import { placeInterface } from "interfaces/place";
import { routes } from "misc/http";
import React, { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { SelectAddressPageLayout } from "./SelectWorkAddressPage";

export const SelectHomeAddressPage: React.FC = ({}) => {
  const { user, setUserOrigin, setMapDisplayOptions, userDestination } = useMBContext();
  const history = useHistory();

  const [defaultHomeAddress, setDefaultHomeAddress] = useState<placeInterface>();

  useEffect(() => {
    if (!userDestination) history.push(routes.onboarding.selectAddress.work);
  }, []);

  useEffect(() => {
    if (!user || !user.home) return;

    // if user has a saved home address then preselect it
    setDefaultHomeAddress({
      // id isn't used here so just provide a fake one
      id: "1",
      placeName: user.home.name,
      stateCode: user.home.state,
      zipCode: user.home.zip,
      ...user.home,
    });
  }, [user]);

  return (
    <SelectAddressPageLayout>
      <div className="Navbar">
        <div className="Navbar-header">
          <NavLink to={`${routes.onboarding.selectAddress.work}`}>
            <div className="Navbar-back" />
          </NavLink>
          <div className="Navbar-header-title">Where are you coming from?</div>
          <div className="Navbar-filler" />
        </div>
      </div>
      <AddressSelector
        addressType={ADDRESS_TYPE.HOME}
        submitCallback={(address) => {
          setUserOrigin(address);
          setMapDisplayOptions({
            bounds: MAP_BOUNDS.USER_ORIG_DEST,
          });
          history.push(routes.onboarding.searchResults);
        }}
        defaultPlace={defaultHomeAddress}
      />
    </SelectAddressPageLayout>
  );
};
