import { routeMakerConfig } from "api/pledge";
import { styled } from "baseui";
import { AddressSelector, ADDRESS_TYPE } from "components/AddressSelector/AddressSelector";
import { MAP_BOUNDS } from "components/Map";
import useMBContext from "context/useMBContext";
import { placeInterface } from "interfaces/place";
import { routes } from "misc/http";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

export const SelectAddressPageLayout = styled("div", ({ $theme }) => ({
  margin: "0 auto",
  maxWidth: "710px",
  height: "100%",
}));

export const SelectWorkAddressPage: React.FC = ({}) => {
  const { user, setUserDestination, setMapDisplayOptions } = useMBContext();
  const history = useHistory();

  const [defaultWorkAddress, setDefaultWorkAddress] = useState<placeInterface>();
  const [allowedPlaces, setAllowedPlaces] = useState<placeInterface[]>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!user) return;

    const userOrganizationId = user.organizations.find((x) => !!x.routeMaker)?.id;

    userOrganizationId
      ? routeMakerConfig(userOrganizationId)
          .then((config) => {
            if (config.defaultPlace) {
              setDefaultWorkAddress(config.defaultPlace);
            } else if (user.search) {
              // if org doesn't have default work address then preselect user's saved search address
              setDefaultWorkAddress({
                // id isn't used here so just provide a fake one
                id: "1",
                placeName: user.search.work.name,
                stateCode: user.search.work.state,
                zipCode: user.search.work.zip,
                ...user.search.work,
              });
            }
            if (config.allowedPlaces) {
              setAllowedPlaces(config.allowedPlaces);
            }
          })
          .finally(() => {
            setLoading(false);
          })
          .catch((errors) => {})
      : setLoading(false);
  }, [user]);

  return (
    <SelectAddressPageLayout>
      <div className="Navbar">
        <div className="Navbar-header">
          <div className="Navbar-filler" />
          <div className="Navbar-header-title">Where are you going?</div>
          <div className="Navbar-filler" />
        </div>
      </div>
      {!loading && (
        <AddressSelector
          addressType={ADDRESS_TYPE.WORK}
          submitCallback={(address) => {
            setUserDestination(address);
            setMapDisplayOptions({
              bounds: MAP_BOUNDS.USER_ORIG_DEST,
            });
            history.push(routes.onboarding.selectAddress.home);
          }}
          allowedPlaces={allowedPlaces}
          defaultPlace={defaultWorkAddress}
        />
      )}
    </SelectAddressPageLayout>
  );
};
