import { styled } from "baseui";
import { MBButton, ResponsiveContainer } from "components/common/Buttons/Buttons";
import { ButtonStack } from "components/common/Modals/BaseModal/BaseModal";
import { riderMyPoolInterface } from "interfaces/vanpool";
import { routes } from "misc/http";
import moment from "moment";
import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import { getActiveServiceForDate } from "../utils";
import { MyPoolContainer } from "../MyPoolsPage/MyPoolsPage";
import { RuleList } from "../components/RuleList";

const SettingsSection = styled("div", ({ $theme }) => ({
  marginTop: "6px",
}));

interface MyPoolSettingsProps {
  pool: riderMyPoolInterface;
  pathPrefix?: string;
}

export const MyPoolSettings: React.FC<MyPoolSettingsProps> = ({ pool, pathPrefix = "" }) => {
  const history = useHistory();
  const activeService = getActiveServiceForDate(pool, moment());

  const handleEditRules = () => {
    history.push(pathPrefix + `${routes.user.mypools}/${pool.id}/manage/settings/rules`);
  };

  return (
    <>
      <div className="Navbar">
        <div className="Navbar-header">
          <NavLink to={pathPrefix + `${routes.user.mypools}/${pool.id}/manage`}>
            <div className="Navbar-back"></div>
          </NavLink>
          <div className="Navbar-header-title">Pool settings</div>
          <div className="Navbar-filler" />
        </div>
      </div>
      <ResponsiveContainer>
        <MyPoolContainer>
          {pool.rules.length > 0 && (
            <SettingsSection>
              <RuleList rules={pool.rules} />
              <ButtonStack>
                <MBButton kind="secondary" onClick={handleEditRules}>
                  {pool.rules.length > 0 ? "Edit rules" : "Add rules"}
                </MBButton>
              </ButtonStack>
            </SettingsSection>
          )}
        </MyPoolContainer>
      </ResponsiveContainer>
    </>
  );
};
