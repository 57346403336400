import React from "react";
import { Layer, Feature } from "react-mapbox-gl";
import { theme } from "assets/theme";

export enum MAP_LINE_TYPE {
  BASIC = 1, // gray
  SELECTED, // blue
  HIGHLIGHTED, // blue transparent
  WALK, // dashed gray
}

export interface lineDisplayProperties {
  type?: MAP_LINE_TYPE; // default none
}

export interface lineDisplayInterface {
  coordinates: number[];
  travelDuration?: number;
  display?: lineDisplayProperties;
  highlighted?: lineDisplayProperties;
  selected?: lineDisplayProperties;
  color?: string;
}

interface MapLineInterface {
  line: lineDisplayInterface;
  selected?: boolean;
  highlighted?: boolean;
}

export const MapLine: React.FC<MapLineInterface> = ({ line, selected, highlighted }) => {
  const props: lineDisplayProperties = {
    ...line.display,
    ...(highlighted ? line.highlighted : {}),
    ...(selected ? line.selected : {}),
  };

  const colors: { [key in MAP_LINE_TYPE]: string } = {
    [MAP_LINE_TYPE.BASIC]: "rgba(100, 100, 100, 0.3)",
    [MAP_LINE_TYPE.HIGHLIGHTED]: `rgba(${theme.primaryRGB}, 0.6)`,
    [MAP_LINE_TYPE.SELECTED]: `rgba(${theme.primaryRGB}, 1)`,
    [MAP_LINE_TYPE.WALK]: "#999999",
  };
  const color = line.color ? line.color : colors[props.type || MAP_LINE_TYPE.BASIC];

  const defaultLineWidth = 6;
  const lineWidths = {};
  lineWidths[MAP_LINE_TYPE.WALK] = 4;
  const lineWidth = lineWidths[props.type || MAP_LINE_TYPE.BASIC] || defaultLineWidth;

  const paint = {
    "line-color": color,
    "line-width": lineWidth,
    "line-opacity": selected || highlighted ? 1 : 0.44,
  };
  if (props.type === MAP_LINE_TYPE.WALK) {
    paint["line-dasharray"] = [2, 2];
  }

  return (
    <>
      {props.type && (
        <Layer
          type="line"
          layout={{
            "line-join": "round",
            "line-cap": "round",
          }}
          paint={paint}
        >
          <Feature coordinates={line.coordinates} />
        </Layer>
      )}
    </>
  );
};
