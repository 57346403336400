import React, { useEffect, useMemo, useRef, useState } from "react";
import { ListProps, List, SharedStylePropsArgT } from "baseui/dnd-list";
import { mergeOverrides, styled } from "baseui";
import { Overrides } from "baseui/overrides";
import { MBButton } from "../Buttons/Buttons";
import { MBTextarea } from "../Inputs/MBTextarea";
import GrabHandleIcon from "assets/img/grab-handle";

const GrabHandleContainer = styled("div", ({ $theme }) => ({
  marginLeft: "10px",
  marginRight: "15px",
}));

interface DragAndDropEditorProps {
  updateListCallback: (index: number, updateValue: string) => void;
  removeItemCallback: (index: number) => void;
  ghostItemText?: string;
}

// baseweb forgot to include index under SharedStylePropsArgT
type SharedStylePropsArgTWithIndex = SharedStylePropsArgT & { $index: number };

export const DragAndDropEditor: React.FC<ListProps & DragAndDropEditorProps> = ({
  items,
  overrides,
  updateListCallback,
  removeItemCallback,
  ghostItemText = "Add item",
  ...props
}) => {
  // append an empty string onto the end of displayItems to have an empty ghost
  // row that you can use to create new items
  const [displayItems, setDisplayItems] = useState<string[]>([...(items as string[]), ""]);

  useEffect(() => {
    setDisplayItems([...(items as string[]), ""]);
  }, [items]);

  const itemsRef = useRef<string[]>(displayItems);
  itemsRef.current = displayItems;

  const isGhostItem = (index: number) => {
    return index === itemsRef.current.length - 1;
  };

  const ov = useMemo(() => {
    return {
      Item: {
        style: (props) => {
          const { $index, $value } = props as SharedStylePropsArgTWithIndex;
          const ghostItem = isGhostItem($index);

          return {
            padding: "5px 0px",
            pointerEvents: ghostItem ? "none" : "auto",
            // 0 opacity 2px border prevents a weird mousehover hover animation that happens
            // when just setting border none because of the width adjusting to the hover border
            ":hover": { border: "2px solid rgba(0, 0, 0, 0)" },
            borderRadius: "8px",
            opacity: ghostItem ? "0.33" : "1",
          };
        },
      },
      DragHandle: {
        component: () => {
          return (
            <GrabHandleContainer>
              <GrabHandleIcon />
            </GrabHandleContainer>
          );
        },
      },
      CloseHandle: {
        component: (props) => {
          const { $index, $value } = props as SharedStylePropsArgTWithIndex;

          return (
            <MBButton
              kind="secondary"
              overrides={{
                BaseButton: {
                  style: {
                    color: "red",
                    width: "34px",
                    height: "34px",
                    marginLeft: "13px",
                    marginRight: "10px",
                  },
                },
              }}
              onClick={() => {
                removeItemCallback($index);
              }}
            >
              –
            </MBButton>
          );
        },
      },
      Label: {
        component: (props) => {
          const { $index, $value } = props as SharedStylePropsArgTWithIndex;

          return (
            <MBTextarea
              placeholder={isGhostItem($index) ? ghostItemText : ""}
              value={$value as string}
              onChange={(updateValue) => {
                updateListCallback($index, updateValue.currentTarget.value);
              }}
              overrides={{
                Input: {
                  style: {
                    // pointerEvents are being disabled on Item for ghostItem so
                    // we need to override that to allow the textbox to be clickable
                    pointerEvents: "auto",
                  },
                },
              }}
            />
          );
        },
      },
    };
  }, []);

  return (
    <>
      <List
        overrides={mergeOverrides(ov as Overrides<any>, overrides as Overrides<any>)}
        items={displayItems}
        {...props}
      />
    </>
  );
};
