import React, { useContext, useEffect, useState } from "react";
import "./ShareModal.scss";
import Icon from "components/common/Icon";
import email from "assets/img/email.png";
import facebook from "assets/img/facebook.png";
import twitter from "assets/img/twitter.png";
import { GlobalContext } from "context/global";

const ShareModal: React.FC = () => {
  const { shareModalVisible, setShareModalVisible } = useContext(GlobalContext);
  const [shareURL, setShareURL] = useState<string>("");

  useEffect(() => {
    if (shareURL === "" || shareModalVisible) {
      setShareURL(
        `${process.env.REACT_APP_SHARE_BASE_URL}${window.location.pathname}${
          window.location.search ? window.location.search : "?"
        }`,
      );
    }
  }, [shareModalVisible, shareURL]);

  const closeModal = () => {
    setShareModalVisible && setShareModalVisible(false);
  };

  const openEmail = () => {
    const emailBody = `Check out this new vanpool route I want to start - ${shareURL}`;
    window.open(
      "mailto:?subject=MagicBus&body=" + escape(emailBody),
      "_blank",
      "noopener,noreferrer",
    );
  };

  const openFacebookSharer = () => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareURL)}`,
      "_blank",
      "noopener,noreferrer",
    );
  };

  const openTwitterSharer = () => {
    window.open(
      `https://twitter.com/home?status=${encodeURIComponent(shareURL)}`,
      "_blank",
      "noopener,noreferrer",
    );
  };

  const copyLink = () => {
    navigator.clipboard.writeText(shareURL);
  };

  return (
    <div>
      {shareModalVisible && (
        <div className="ShareModal">
          <div className="ShareModal-Box">
            <div className="ShareModal-Title">
              Share this route
              <label
                htmlFor="nav-controller"
                className="ShareModal-icon-close"
                onClick={closeModal}
              >
                <Icon name="close" />
              </label>
            </div>
            <div className="ShareModal-Body">
              <div className="ShareModal-shareIcons">
                <div className="ShareModal-badge">
                  <img alt="email" className="ShareModal-icon" src={email} onClick={openEmail} />
                  Email
                </div>
                <div className="ShareModal-badge">
                  <img
                    alt="facebook"
                    className="ShareModal-icon"
                    src={facebook}
                    onClick={openFacebookSharer}
                  />
                  Facebook
                </div>
                <div className="ShareModal-badge">
                  <img
                    alt="twitter"
                    className="ShareModal-icon"
                    src={twitter}
                    onClick={openTwitterSharer}
                  />
                  Twitter
                </div>
              </div>
              <div className="ShareModal-sharelink">
                <input
                  className="mb ShareModal-sharelink-input"
                  type="text"
                  value={shareURL}
                  readOnly
                />
                <button className="btn ShareModal-sharelink-button" onClick={copyLink}>
                  COPY
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShareModal;
