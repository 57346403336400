import React, { useContext, useEffect, useState } from "react";
import "./HelpModal.scss";
import Icon from "components/common/Icon";
import { GlobalContext } from "context/global";
import InputValidator from "components/common/Form/InputValidator";
import { contact, tripFeedback } from "api/contact";
import { toaster } from "baseui/toast";
import { MBTextarea } from "../Inputs/MBTextarea";

const HelpModal: React.FC = () => {
  const { helpModalVisible, setHelpModalVisible, feedbackTripId, setFeedbackTripId, user } =
    useContext(GlobalContext);
  const [name, setName] = useState<string>("");
  const [validName, setValidName] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [validEmail, setValidEmail] = useState<boolean>(false);
  const [phone, setPhone] = useState<string>("");
  const [validPhone, setValidPhone] = useState<boolean>(false);
  const [helpMessage, setHelpMessage] = useState<string>("");
  const [statusMessage, setStatusMessage] = useState<string>("");

  const closeModal = () => {
    setHelpModalVisible && setHelpModalVisible(false);
    setFeedbackTripId && setFeedbackTripId([]);
    setStatusMessage("");
    setHelpMessage("");
  };

  const nameChangeHandler = (name: string, valid: boolean) => {
    setName(name);
    setValidName(valid);
  };

  const emailChangeHandler = (email: string, valid: boolean) => {
    setEmail(email);
    setValidEmail(valid);
  };

  const phoneChangeHandler = (phone: string, valid: boolean) => {
    setPhone(phone);
    setValidPhone(valid);
  };

  const messageChangeHandler = (e: any) => {
    setHelpMessage(e?.target?.value);
  };

  const submitHandler = () => {
    if (user || (validName && validEmail && validPhone)) {
      const contactResponse = feedbackTripId!.length
        ? tripFeedback(feedbackTripId!, helpMessage)
        : contact(helpMessage, name, email, phone);
      contactResponse &&
        contactResponse
          .then((response) => {
            if (response.success) {
              toaster.positive(feedbackTripId!.length ? "Feedback sent" : "Support message sent", {
                autoHideDuration: 4500,
              });
              closeModal();
            } else {
              setStatusMessage("Error sending message - please try again");
            }
          })
          .catch((error) => {
            setStatusMessage("Error sending message - please try again");
          });
    } else {
      setStatusMessage("Please enter an email or mobile phone number");
    }
  };

  return (
    <div>
      {helpModalVisible && (
        <div className="HelpModal">
          <div className="HelpModal-Box">
            <div className="HelpModal-Title">
              MagicRide Support
              <label htmlFor="nav-controller" className="HelpModal-icon-close" onClick={closeModal}>
                <Icon name="close" />
              </label>
            </div>
            <div className="HelpModal-Body">
              {!user && (
                <>
                  <p>Please provide your name, an email and phone number so we can contact you.</p>
                  <div>
                    <InputValidator
                      type="text"
                      name="Name"
                      onChange={nameChangeHandler}
                      placeholder="Enter your name"
                      pattern=".+"
                      error="Please enter a name"
                    />
                  </div>
                  <div>
                    <InputValidator
                      type="text"
                      name="Email"
                      onChange={emailChangeHandler}
                      placeholder="Enter your email address"
                      pattern="\S+@\S+\.\S+"
                      error="Please enter a valid email"
                    />
                  </div>
                  <div>
                    <InputValidator
                      type="phone"
                      name="Mobile Phone"
                      onChange={phoneChangeHandler}
                      placeholder="(555) 555-5555"
                      pattern="^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$"
                      error="Please enter a 10 digit phone number"
                    />
                  </div>
                </>
              )}
              <div>
                <label htmlFor="message" className="HelpModal-Label">
                  Message
                </label>
                <MBTextarea
                  rows={4}
                  name="message"
                  value={helpMessage}
                  onChange={messageChangeHandler}
                />
              </div>
              <button
                disabled={
                  !((user || (validEmail && validPhone && validName)) && helpMessage.length > 3)
                }
                className="HelpModal-Submit"
                onClick={submitHandler}
              >
                {feedbackTripId!.length ? "Send Feedback" : "Contact Support"}
              </button>
            </div>
            <div className="HelpModal-status">{statusMessage}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HelpModal;
